import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';

import seaGrass from 'src/images/memory/seaGrass2.png';

import { Container } from './components';

const MemoryStepOneExtraGroups = ({
  getGroups,
  hasLoaded,
  memoryStepOneExtraCurrency,
  memoryStepOneExtraGroups,
  student,
}) => {
  return (
    <Container
      cardBackgroundImage={seaGrass}
      className="memory-groups-container--step-one-extra"
      collectionsPath={paths.MEMORY_STEP_ONE_EXTRA_COLLECTIONS}
      currency={memoryStepOneExtraCurrency}
      getGroups={getGroups}
      hasLoaded={hasLoaded}
      items={memoryStepOneExtraGroups}
      student={student}
    />
  );
};

MemoryStepOneExtraGroups.propTypes = {
  getGroups: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  memoryStepOneExtraCurrency: PropTypes.shape({}).isRequired,
  memoryStepOneExtraGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.memoryStepOneExtraGroups.getHasLoaded(state),
  memoryStepOneExtraCurrency: selectors.memoryStepOneExtraGroups.getCurrencyData(
    state
  ),
  memoryStepOneExtraGroups: selectors.memoryStepOneExtraGroups.getGroupsData(
    state
  ),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.memoryStepOneExtra,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(MemoryStepOneExtraGroups);
