import { actions } from 'src/constants';

const initialState = {
  studentSelectedParts: {},
  parts: {},
  cheapParts: {},
  pageCount: 0,
  hasLoaded: false,
  isLoading: false,
};

const actionMap = {
  [actions.PARROT_PARTS_CHEAP_GET_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [actions.PARROT_PARTS_CHEAP_GET_SUCCESS]: (state, { result }) => ({
    ...state,
    cheapParts: result.data,
    isLoading: false,
  }),
  [actions.PARROT_PARTS_CHEAP_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [actions.PARROT_SELECTED_PARTS_GET_REQUEST]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: true,
  }),
  [actions.PARROT_SELECTED_PARTS_GET_SUCCESS]: (state, { result }) => ({
    ...state,
    studentSelectedParts: result.data,
    hasLoaded: true,
    isLoading: false,
  }),
  [actions.PARROT_SELECTED_PARTS_GET_FAILURE]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: false,
  }),

  [actions.PARROT_PARTS_BY_CATEGORY_LABEL_GET_REQUEST]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: true,
  }),
  [actions.PARROT_PARTS_BY_CATEGORY_LABEL_GET_SUCCESS]: (
    state,
    { result }
  ) => ({
    ...state,
    parts: result.data.parrotParts,
    pageCount: result.data.pageCount,
    hasLoaded: true,
    isLoading: false,
  }),
  [actions.PARROT_PARTS_BY_CATEGORY_LABEL_GET_FAILURE]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: false,
  }),

  [actions.PARROT_PARTS_OWNED_GET_REQUEST]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: true,
  }),
  [actions.PARROT_PARTS_OWNED_GET_SUCCESS]: (state, { result }) => ({
    ...state,
    parts: result.data.parrotParts,
    pageCount: result.data.pageCount,
    hasLoaded: true,
    isLoading: false,
  }),
  [actions.PARROT_PARTS_OWNED_GET_FAILURE]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: false,
  }),

  [actions.PARROT_SELECTED_PARTS_UPDATE_REQUEST]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: true,
  }),
  [actions.PARROT_SELECTED_PARTS_UPDATE_SUCCESS]: (state, { result }) => ({
    ...state,
    studentSelectedParts: { ...state.studentSelectedParts, ...result.data },
    hasLoaded: true,
    isLoading: false,
  }),
  [actions.PARROT_SELECTED_PARTS_UPDATE_FAILURE]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: false,
  }),
  [actions.PARROT_SELECTED_PARTS_REMOVE_REQUEST]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: true,
  }),
  [actions.PARROT_SELECTED_PARTS_REMOVE_SUCCESS]: (state, { result }) => ({
    ...state,
    studentSelectedParts: { ...state.studentSelectedParts, ...result.data },
    hasLoaded: true,
    isLoading: false,
  }),
  [actions.PARROT_SELECTED_PARTS_REMOVE_FAILURE]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: false,
  }),
  [actions.PARROT_PARTS_BUY_POST_REQUEST]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: true,
  }),
  [actions.PARROT_PARTS_BUY_POST_SUCCESS]: (state, { result }) => ({
    ...state,
    parts: { ...state.parts, [result.data.id]: { ...result.data } },
    studentSelectedParts: {
      ...state.studentSelectedParts,
      [result.data.ParrotPartType.label]: {
        id: result.data.id,
        canvasImageUrl: result.data.canvasImageUrl,
      },
    },
    hasLoaded: true,
    isLoading: false,
  }),
  [actions.PARROT_PARTS_BUY_POST_FAILURE]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: false,
  }),
};

export default (state = initialState, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
