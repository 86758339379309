import React from 'react';
import { actions, notifications } from 'src/constants';

import { Localize } from 'src/components/common';

const initialState = {
  type: notifications.type.INFO,
  message: '',
  duration: notifications.duration.INDEFINITE,
};

const actionMap = {
  [actions.SHOW_NOTIFICATION]: (state, { result: { data } }) => ({
    ...state,
    type: data.type,
    message: data.message,
    duration: data.duration,
  }),
  [actions.CLEAR_NOTIFICATION]: (state) => ({
    ...state,
    type: notifications.type.INFO,
    message: '',
    duration: notifications.duration.INDEFINITE,
  }),

  [actions.PARROT_PARTS_BUY_POST_SUCCESS]: (state, { result: { data } }) => ({
    ...state,
    type: notifications.type.SUCCESS,
    message: <Localize id={data?.message} />,
    duration: notifications.duration.SHORT,
  }),
  [actions.PARROT_SELECTED_PARTS_UPDATE_SUCCESS]: (
    state,
    { result: { data } }
  ) => ({
    ...state,
    type: notifications.type.SUCCESS,
    message: <Localize id={data?.message} />,
    duration: notifications.duration.SHORT,
  }),
  [actions.PARROT_SELECTED_PARTS_REMOVE_SUCCESS]: (
    state,
    { result: { data } }
  ) => ({
    ...state,
    type: notifications.type.SUCCESS,
    message: <Localize id={data?.message} />,
    duration: notifications.duration.SHORT,
  }),
};

export default (state = initialState, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
