import { actions } from 'src/constants';

export default {
  clearNotification: () => ({
    type: actions.CLEAR_NOTIFICATION,
  }),

  showNotification: (message, type, duration) => ({
    type: actions.SHOW_NOTIFICATION,
    result: { data: { message, type, duration } },
  }),
};
