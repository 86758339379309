import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { actions, selectors } from 'src/store';
import { paths, subscriptions } from 'src/constants';
import { useIntlMessages, useInviteUser } from 'src/hooks';

import {
  InviteUserModal,
  OverlayLoader,
  Page,
  PageLoader,
  Navbar,
  Image,
} from 'src/components/common';

import kokolingoTextImage from 'src/images/kokolingo.svg';

import AdditionalStudents from './AdditionalStudents';
import PackageStudents from './PackageStudents';

import './index.scss';

const SpeechTherapistHome = ({
  hasLoaded,
  isSpeechTherapist,
  resolveUsers,
  user,
  getStudents,
  isSubmitting,
  userOnlyStudents,
  isSubmittingStudent,
}) => {
  const messages = useIntlMessages();
  const handleInviteUser = useInviteUser();

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [studentToInvite, setStudentToInvite] = useState(null);

  useEffect(() => {
    resolveUsers();
  }, [resolveUsers]);

  useEffect(() => {
    getStudents();
  }, [getStudents]);

  const handleInviteUserAgain = useCallback(
    (email) => {
      const foundStudent =
        userOnlyStudents.find((student) => student.parentEmail === email) || {};
      handleInviteUser(email, foundStudent.id);
    },
    [handleInviteUser, userOnlyStudents]
  );

  if (!isSpeechTherapist && hasLoaded) {
    return <Redirect to={paths.FORBIDDEN} />;
  }

  if (!user?.subscriptionKey) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      {(isSubmitting || isSubmittingStudent) && (
        <OverlayLoader className="speech-therapist__overlay-loader" />
      )}
      <Navbar.Title>
        <Image src={kokolingoTextImage} alt="" />
      </Navbar.Title>
      <div className="speech-therapist">
        <Page className="speech-therapist__page speech-therapist__page--package-students">
          <Page.Header className="speech-therapist__page__header">
            {
              messages({
                subscription:
                  subscriptions[user?.subscriptionKey]?.LABEL ?? '-',
              }).page.speechTherapistsHome.tab.packageStudents
            }
            {/* TODO add tooltip */}
          </Page.Header>
          <Page.Body className="speech-therapist__page__body">
            <PackageStudents
              handleInviteUser={(student) => {
                setStudentToInvite(student);
                setShowInviteModal(true);
              }}
            />
          </Page.Body>
        </Page>
        <Page className="speech-therapist__page speech-therapist__page--home-students">
          <Page.Header className="speech-therapist__page__header">
            {messages.page.speechTherapistsHome.tab.additionalStudents}
            {/* TODO add tooltip */}
          </Page.Header>
          <Page.Body className="speech-therapist__page__body">
            <AdditionalStudents
              handleInviteNewUser={() => {
                setStudentToInvite(null);
                setShowInviteModal(true);
              }}
              handleInviteUserAgain={handleInviteUserAgain}
            />
          </Page.Body>
        </Page>
      </div>
      {showInviteModal && (
        <InviteUserModal
          student={studentToInvite}
          showInviteModal={showInviteModal}
          handleCloseModal={() => setShowInviteModal(false)}
          handleInviteUser={handleInviteUser}
        />
      )}
    </PageLoader>
  );
};

SpeechTherapistHome.propTypes = {
  hasLoaded: PropTypes.bool.isRequired,
  isSpeechTherapist: PropTypes.bool.isRequired,
  resolveUsers: PropTypes.func.isRequired,
  user: PropTypes.shape({
    subscriptionKey: PropTypes.string,
    id: PropTypes.number.isRequired,
  }).isRequired,
  getStudents: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  userOnlyStudents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSubmittingStudent: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.authentication.getHasLoaded(state),
  isSpeechTherapist: selectors.authentication.getIsSpeechTherapist(state),
  user: selectors.authentication.getUser(state),
  isSubmitting: selectors.user.getIsSubmitting(state),
  userOnlyStudents: selectors.students.getUserOnlyStudents(state),
  isSubmittingStudent: selectors.student.getIsSubmitting(state),
});

const mapDispatchToProps = {
  ...actions.authentication,
  ...actions.students,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(SpeechTherapistHome);
