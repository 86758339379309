import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { video } from 'src/constants';

import { MenuFrame, PageLoader, Navbar } from 'src/components/common';

import GroupsSection from './GroupsSection';

import './index.scss';

const Container = ({
  collectionsPath,
  currency,
  getGroups,
  hasLoaded,
  items,
  title,
}) => {
  useEffect(() => {
    getGroups();
  }, [getGroups]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      <Navbar.GameNavbar
        currency={currency}
        title={title}
        videoName={video.NAME.TREASURE_HUNT}
      />
      <div className="treasure-hunt-groups-container">
        <MenuFrame title={title} currencyImageUrl={currency.imageUrl}>
          <div className="treasure-hunt-groups-container__groups-container">
            <GroupsSection items={items} collectionsPath={collectionsPath} />
          </div>
        </MenuFrame>
      </div>
    </PageLoader>
  );
};

Container.propTypes = {
  collectionsPath: PropTypes.string.isRequired,
  getGroups: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  currency: PropTypes.shape({
    imageUrl: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  student: PropTypes.shape({
    hasPassedTreasureHuntEducation: PropTypes.bool.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default memo(Container);
