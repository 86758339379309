import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

const Container = React.forwardRef(({ children, className, ...props }, ref) => {
  const containerClassNames = useMemo(
    () => classNames('container', className),
    [className]
  );
  return (
    <div ref={ref} className={containerClassNames} {...props}>
      {children}
    </div>
  );
});

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Container.defaultProps = {
  children: null,
  className: '',
};

export default memo(Container);
