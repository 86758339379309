import { actions, paths } from 'src/constants';

export default {
  inviteUser: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.INVITE_USER_REQUEST,
        actions.INVITE_USER_SUCCESS,
        actions.INVITE_USER_FAILURE,
      ],
      promise: (client) =>
        client.post(paths.api.SPEECH_THERAPIST_INVITE_USER, data),
    },
  }),

  sendMobileInvitation: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.SEND_MOBILE_USER_INVITE_REQUEST,
        actions.SEND_MOBILE_USER_INVITE_SUCCESS,
        actions.SEND_MOBILE_USER_INVITE_FAILURE,
      ],
      promise: (client) => client.post(paths.api.MOBILE_USERS_INVITE, data),
    },
  }),

  patchUser: (data, userId) => ({
    [actions.API_CALL]: {
      types: [
        actions.PATCH_USER_REQUEST,
        actions.PATCH_USER_SUCCESS,
        actions.PATCH_USER_FAILURE,
      ],
      promise: (client) =>
        client.put(paths.api.USERS_ME, data, {
          params: { userId },
        }),
    },
  }),

  userCheckpoint: (checkpoint) => ({
    type: actions.USER_CHECKPOINT,
    payload: checkpoint,
  }),

  setShowShopModal: (payload) => ({
    type: actions.SET_SHOW_SHOP_MODAL,
    payload,
  }),

  clearUser: () => ({
    type: actions.CLEAR_USER_STORE_DATA,
  }),
};
