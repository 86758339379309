import PropTypes from 'prop-types';
import React, { Fragment, memo, useMemo } from 'react';
import cn from 'classnames';

import './index.scss';

const Menu = ({ isSpeechTherapist, items, onItemClick, selectedItem }) => {
  const sortedItems = useMemo(
    () => items.sort((a, b) => a.orderNumber - b.orderNumber),
    [items]
  );

  const mainItems = useMemo(
    () =>
      sortedItems.filter((item) => {
        if (
          (item.key === 'SPEECH_THERAPISTS_HELP' && !isSpeechTherapist) ||
          item.HelpId
        ) {
          return false;
        }

        return true;
      }),
    [isSpeechTherapist, sortedItems]
  );

  const subItems = useMemo(() => sortedItems.filter((item) => item.HelpId), [
    sortedItems,
  ]);

  return (
    <ul className="help-menu">
      {mainItems.map((item) => (
        <Fragment key={item.id}>
          <li
            className={cn({
              'help-menu--active': selectedItem.parentId === item.id,
            })}
            onClick={() => onItemClick(item.id)}
          >
            {item.title}
          </li>
          {item.id === selectedItem.parentId &&
            subItems
              .filter((subItem) => subItem.HelpId === item.id)
              .map((subItem) => (
                <li
                  className={cn('help-menu__subitem', {
                    'help-menu__subitem--active':
                      selectedItem.childId === subItem.id,
                  })}
                  key={subItem.id}
                  onClick={() => onItemClick(subItem.id, true)}
                >
                  {subItem.title}
                </li>
              ))}
        </Fragment>
      ))}
    </ul>
  );
};

Menu.propTypes = {
  isSpeechTherapist: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      orderNumber: PropTypes.number,
      title: PropTypes.string,
    })
  ).isRequired,
  onItemClick: PropTypes.func.isRequired,
  selectedItem: PropTypes.shape({
    parentId: PropTypes.number,
    childId: PropTypes.number,
  }).isRequired,
};

export default memo(Menu);
