import { gameKeys } from 'src/constants';

const shuffle = (array) => {
  return [...array].sort(() => Math.random() - 0.5);
};

const getParsedGameKey = (string) =>
  string
    .replace('_BONUS', '')
    .replace('_STEP_ONE_EXTRA', '')
    .replace('_STEP_TWO_EXTRA', '')
    .replace('_STEP_THREE_EXTRA', '')
    .replace('_STEP_ONE', '')
    .replace('_STEP_THREE', '')
    .replace('_STEP_TWO', '');

const getParsedGameKeys = () =>
  Object.values(gameKeys).reduce(
    (previousData, currentData) => ({
      ...previousData,
      [getParsedGameKey(currentData)]: getParsedGameKey(currentData),
    }),
    {}
  );

const getActivityIconBackground = (activityKey) =>
  activityKey.includes('-special') ? activityKey.split('-')[0] : activityKey;

const getDateFromNow = (daysFromNow = 0) => {
  const date = new Date();
  date.setDate(new Date().getDate() + daysFromNow);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

export function getDaysFromNow(dateFromNow) {
  return Math.ceil(
    (new Date(dateFromNow) - new Date()) / (24 * 60 * 60 * 1000)
  );
}

const getLastNDates = (lastNDates) =>
  new Array(lastNDates)
    .fill('')
    .map((_, index) => getDateFromNow(index * -1))
    .reverse();

const formatDate = (date) => {
  const [year, month, day] = date.split('-');

  return `${day}.${month}.${year}.`;
};

const getCurrentTime = (seconds) => ({
  minutes: Math.floor((seconds % (60 * 60)) / 60),
  seconds: Math.ceil((seconds % (60 * 60)) % 60),
});

const getFormattedCurrentTime = (currentTime) =>
  `${`0${currentTime.minutes}`.slice(-2)}:${`0${currentTime.seconds}`.slice(
    -2
  )}`;

// Shortens YYYY-MM-DD to MM-DD format
// Removes year digits and -
const shortenDate = (date) => date.substr(5);

const formatExtendedDate = (dateStamp, delimiter = '.') => {
  const date = new Date(dateStamp);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${[day, month, year].join(delimiter)}.`;
};

const getMediaContentUrl = () => process.env.REACT_APP_AWS_S3_BASE_URL;

export default {
  formatDate,
  formatExtendedDate,
  getActivityIconBackground,
  getCurrentTime,
  getDateFromNow,
  shortenDate,
  getDaysFromNow,
  getFormattedCurrentTime,
  getLastNDates,
  getParsedGameKey,
  getParsedGameKeys,
  shuffle,
  getMediaContentUrl,
};
