import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Card, Badge, Image } from 'src/components/common';

import './index.scss';

const LevelCard = ({ level, paintedTerms, selectedTermId, onClick }) => {
  const [badge, setBadge] = useState(false);

  const currentTerm = paintedTerms.find(
    (paintedTerm) => paintedTerm.selectedTermId === level.id
  );

  const isPainted = paintedTerms.some(
    (paintedTerm) => paintedTerm.selectedTermId === selectedTermId
  );

  const levelCardClassNames = useMemo(
    () =>
      cn(
        'painting-level-card-container',
        `painting-level-card-container--${currentTerm && currentTerm.paint}`,
        {
          'painting-level-card-container--selected':
            selectedTermId === level.id && !isPainted,
        }
      ),
    [currentTerm, isPainted, selectedTermId, level]
  );

  if (selectedTermId === level.id && isPainted && !badge) {
    setBadge(true);
  }

  return (
    <div className={levelCardClassNames}>
      <Card onClick={() => onClick(level)}>
        <Image
          className="painting-level-card-container__image"
          alt={level.key}
          src={level.imageUrl}
        />
        {badge && process.env.REACT_APP_LITERAL_LANGUAGE === 'true' && (
          <Badge
            className="painting-level-card-container__badge"
            type={currentTerm.paint === 'red' ? 'orange' : currentTerm.paint}
          >
            {level.correctSpeechSound}
          </Badge>
        )}
      </Card>
    </div>
  );
};

LevelCard.propTypes = {
  level: PropTypes.shape({
    id: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    correctSpeechSound: PropTypes.string.isRequired,
  }).isRequired,
  paintedTerms: PropTypes.arrayOf(
    PropTypes.shape({
      selectedTermId: PropTypes.number.isRequired,
      paint: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedTermId: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

LevelCard.defaultProps = {
  selectedTermId: null,
};

export default memo(LevelCard);
