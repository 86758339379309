import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Image } from 'src/components/common';
import './index.scss';

const LevelImage = ({ level, isHidden, shake, onClick }) => {
  const levelImageContainerClassNames = useMemo(
    () =>
      cn('read-together-bonus-level-image-container', {
        'read-together-bonus-level-image-container--hidden': isHidden,
      }),
    [isHidden]
  );

  const levelImageClassNames = useMemo(
    () =>
      cn('read-together-bonus-level-image-container__image', {
        'read-together-bonus-level-image-container__image--shake': shake,
      }),
    [shake]
  );

  return (
    <div
      className={levelImageContainerClassNames}
      onClick={() => onClick(level)}
      onKeyPress={() => onClick(level)}
      tabIndex={0}
      role="button"
    >
      <Image
        className={levelImageClassNames}
        alt={level.key}
        src={level.imageUrl}
      />
    </div>
  );
};

LevelImage.propTypes = {
  level: PropTypes.shape({
    key: PropTypes.string,
    imageUrl: PropTypes.string,
  }).isRequired,
  isHidden: PropTypes.bool,
  shake: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

LevelImage.defaultProps = {
  isHidden: false,
  shake: false,
};

export default memo(LevelImage);
