import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

import { Image, Localize, Modal, PageLoader } from 'src/components/common';
import { useQuery } from 'src/hooks';
import { actions } from 'src/store';
import loader from 'src/videos/loader.gif';

import './index.scss';
/**
 * This one is used for trial, if in the future comes up a new magic link add components directory.
 */
const Provider = ({ clearUser, activateMagicLink }) => {
  const queryParams = useQuery();
  const history = useHistory();
  const magicToken = queryParams.get('magicToken');

  useEffect(() => {
    if (!magicToken) {
      history.replace('/');
      return;
    }

    clearUser();
    localStorage.clear();

    activateMagicLink(magicToken);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageLoader />
      <Modal className="magic-link-modal" show>
        <Modal.Title>
          <Localize id="dialog.magicLink.processing.title" />
        </Modal.Title>
        <Modal.Description>
          <Localize id="dialog.magicLink.processing.description" />
        </Modal.Description>
        <Image src={loader} alt="Loader" />
      </Modal>
    </>
  );
};

Provider.propTypes = {
  clearUser: PropTypes.func.isRequired,
  activateMagicLink: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  ...actions.users,
  ...actions.magicLink,
};

export default connect(undefined, mapDispatchToProps)(Provider);
