import { actions, paths } from 'src/constants';

export default {
  getActivityGroupsData: () => ({
    [actions.API_CALL]: {
      types: [
        actions.ACTIVITY_GROUPS_GET_REQUEST,
        actions.ACTIVITY_GROUPS_GET_SUCCESS,
        actions.ACTIVITY_GROUPS_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.build(paths.api.ACTIVITY_GROUPS)),
    },
  }),
  resetActivityGroupsState: () => ({
    type: actions.ACTIVITY_GROUPS_RESET,
  }),
};
