import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Card } from 'src/components/common';

import './index.scss';

const SpeechSoundCard = ({ speechSound, isSelected, onClick, isCluster }) => {
  const speechSoundCardClassNames = useMemo(
    () =>
      cn('speech-sound-card-container', {
        'speech-sound-card-container--selected': isSelected,
        'speech-sound-card-container--cluster': isCluster,
      }),
    [isSelected, isCluster]
  );

  const speechSoundTextClassNames = useMemo(
    () =>
      cn('speech-sound-card-container__text', {
        'speech-sound-card-container__text-extended':
          speechSound.label.length > 2,
      }),
    [speechSound.label]
  );

  return (
    <Card className={speechSoundCardClassNames} onClick={onClick}>
      <p className={speechSoundTextClassNames}>{speechSound.label}</p>
    </Card>
  );
};

SpeechSoundCard.propTypes = {
  speechSound: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool,
  isCluster: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

SpeechSoundCard.defaultProps = {
  isSelected: false,
  isCluster: false,
};

export default memo(SpeechSoundCard);
