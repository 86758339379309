import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import cn from 'classnames';

import './index.scss';

const Button = ({
  id,
  className,
  isSubmit,
  variant,
  label,
  disabled,
  onClick,
  children,
  collapsed,
  size,
}) => {
  const buttonClassNames = useMemo(
    () =>
      cn('btn', `btn--${variant}`, {
        'btn--disabled': disabled,
        [`btn--${size}`]: size,
        collapsed,
        [className]: className,
      }),
    [variant, disabled, className, collapsed, size]
  );

  return (
    <>
      {/* eslint-disable-next-line react/button-has-type */}
      <button
        id={id}
        type={isSubmit ? 'submit' : 'button'}
        className={buttonClassNames}
        disabled={disabled}
        onClick={onClick}
      >
        {label}
        {children}
      </button>
    </>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'register',
    'danger',
  ]),
  isSubmit: PropTypes.bool,
  label: PropTypes.node,
  disabled: PropTypes.bool,
  collapsed: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  id: null,
  className: '',
  variant: 'primary',
  isSubmit: false,
  label: '',
  disabled: false,
  collapsed: false,
  children: null,
  onClick: null,
  size: null,
};

export default memo(Button);
