import React from 'react';

import { usePaymentModule, useQuery } from 'src/hooks';

const Payment = () => {
  const query = useQuery();

  const paymentProvider = query.get('paymentProvider');

  const CheckoutComponent = usePaymentModule(paymentProvider.toUpperCase())
    ?.Checkout;

  if (!CheckoutComponent) {
    return null;
  }

  return <CheckoutComponent />;
};

export default Payment;
