import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { selectors } from 'src/store';
import Container from 'src/components/pages/Activities/ReadAndThink/BonusLevels/Container';

const ReadAndThinkBonusLevels = ({
  currency,
  getLevelsByCollectionId,
  hasLoaded,
  levels,
  student,
  ...props
}) => {
  useEffect(() => {
    getLevelsByCollectionId();
  }, [getLevelsByCollectionId]);

  return (
    <Container
      hasLoaded={hasLoaded}
      items={levels}
      currency={currency}
      student={student}
      {...props}
    />
  );
};

ReadAndThinkBonusLevels.propTypes = {
  currency: PropTypes.shape({}),
  getLevelsByCollectionId: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})),
  student: PropTypes.shape({}).isRequired,
};

ReadAndThinkBonusLevels.defaultProps = {
  currency: null,
  levels: [],
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.dailyMap.getHasLoadedLevels(state),
  currency: selectors.dailyMap.getDailyMapCollection(state)?.currency,
  levels: selectors.dailyMap.getDailyMapCollection(state)?.levels,
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ReadAndThinkBonusLevels);
