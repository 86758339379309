import React, { memo, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import Title from './Title';

const TitlePortal = (props) => {
  const [titleContainer, setTitleContainer] = useState(
    document.getElementById('navbar-container-left')
  );

  useEffect(() => {
    if (!titleContainer) {
      setTitleContainer(document.getElementById('navbar-container-left'));
    }
  }, [titleContainer, setTitleContainer]);

  return titleContainer
    ? ReactDOM.createPortal(<Title {...props} />, titleContainer)
    : null;
};

TitlePortal.propTypes = {
  ...Title.propTypes,
};

TitlePortal.defaultProps = {
  ...Title.defaultProps,
};

export default memo(TitlePortal);
