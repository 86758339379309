import PropTypes from 'prop-types';
import React, { memo, useRef } from 'react';
import { isMobile } from 'react-device-detect';

import { useIntlMessages, useOutsideClick } from 'src/hooks';

import { Form, Input, Textarea, Icon } from 'src/components/common';

import validationSchema from './validationSchema';

import './index.scss';

const NoteFormModal = ({ onSubmit, onClose, children, note }) => {
  const modalRef = useRef();

  useOutsideClick(onClose, modalRef);

  const messages = useIntlMessages();

  const defaultValues = {
    label: note.label,
    description: note.description,
  };

  return (
    <>
      <div className="note-form-background" />
      <div className="note-form" ref={modalRef}>
        <Form
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          defaultValues={defaultValues}
        >
          <Input
            className="note-form__input note-form__input--note-label"
            labelClassName="note-form__input__label"
            label={messages.label.noteTitle}
            name="label"
            required
          />
          <Textarea
            className="note-form__input note-form__input--note-description"
            labelClassName="note-form__input__label"
            label={messages.label.noteDescription}
            name="description"
            required
          />
          {children}
        </Form>
        <div className="note-form__button-close" onKeyPress={onClose}>
          <Icon
            name="close"
            size={isMobile ? 20 : 40}
            onClick={onClose}
            color="#E3C19A"
          />
        </div>
      </div>
    </>
  );
};

NoteFormModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  note: PropTypes.shape({
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
};

NoteFormModal.defaultProps = {
  children: null,
  note: {},
};

export default memo(NoteFormModal);
