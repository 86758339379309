import React, { memo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { actions, selectors } from 'src/store';
import { useIntlMessages } from 'src/hooks';
import { paths } from 'src/constants';

import {
  Badge,
  Button,
  Form,
  Modal,
  OverlayLoader,
  Page,
  PageLoader,
  RadioButton,
  SpeechSoundBadge,
  Title,
  Zoom,
} from 'src/components/common';

import validationSchemaActivate from './validationSchemaActivate';

import './index.scss';

const DailyMapSettings = ({
  activateDailyMap,
  getDailyMapTemplates,
  dailyMap,
  student,
  dailyMapTemplates,
  hasLoadedMap,
  hasLoadedTemplates,
  hasLoadedDailyMaps,
  isSubmitting,
  getDailyMaps,
  dailyMaps,
  speechSounds,
  getSpeechSounds,
  updateStudentById,
  resetDailyMap,
}) => {
  const history = useHistory();
  const messages = useIntlMessages();
  const [showModal, setShowModal] = useState(false);
  const [templateValue, setTemplateValue] = useState(null);
  const [selectedSpeechSoundId, setSelectedSpeechSoundId] = useState();

  useEffect(() => {
    if (!hasLoadedDailyMaps) {
      getDailyMaps();
    }
  }, [getDailyMaps, hasLoadedDailyMaps]);

  useEffect(() => {
    if (speechSounds.length <= 1) {
      getSpeechSounds();
    }
  }, [getSpeechSounds, speechSounds]);

  useEffect(() => {
    if (!hasLoadedTemplates) {
      getDailyMapTemplates();
    }
  }, [hasLoadedTemplates, getDailyMapTemplates]);

  useEffect(() => {
    if (dailyMap) {
      setTemplateValue(dailyMap.DailyMapTemplateId);
    }
  }, [dailyMap]);

  const runDailyMap = useCallback(
    (speechSoundId) => {
      updateStudentById(student.id, {
        SpeechSoundId: speechSoundId,
      }).then(() => {
        resetDailyMap();
        history.push(paths.DAILY_MAP);
      });
    },
    [resetDailyMap, history, updateStudentById, student.id]
  );

  const handleActivationSubmit = useCallback(
    (data) => {
      activateDailyMap(
        data.templateSelection,
        student.id,
        selectedSpeechSoundId
      ).then(() => runDailyMap(selectedSpeechSoundId));

      setShowModal(false);
    },
    [activateDailyMap, student, selectedSpeechSoundId, runDailyMap]
  );

  if (!hasLoadedTemplates || !hasLoadedMap) {
    return <PageLoader />;
  }

  return (
    <Zoom mobileWidth={320} mobileHeight={560} mobileRatio="calc(100% - 80px)">
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        className="daily-map-selection-modal"
      >
        <Modal.Title>{messages.label.dailyMap}</Modal.Title>
        <Modal.Description>
          <Form
            validationSchema={validationSchemaActivate}
            onSubmit={handleActivationSubmit}
            submitBtnLabel={messages.button.save}
            formClassName="daily-map-form"
          >
            {dailyMapTemplates
              .filter((template) =>
                template.meta.speechSoundIds.includes(selectedSpeechSoundId)
              )
              .map((template) => (
                <RadioButton
                  key={template.id.toString()}
                  checked={templateValue === template.id}
                  value={template.id.toString()}
                  className="item-radio-button"
                  name="templateSelection"
                  onChange={() => setTemplateValue(template.id)}
                >
                  {template.title}
                  <p>{template.shortDescription}</p>
                </RadioButton>
              ))}
          </Form>
        </Modal.Description>
      </Modal>
      <Page containerClassName="daily-map-settings-container">
        <Page.Header className="daily-map-settings__header">
          <Title text={messages.page.dailyMapSettings.title} />
        </Page.Header>
        <Page.Body>
          {isSubmitting && <OverlayLoader />}
          <div>
            {dailyMaps.length > 0 &&
              dailyMaps.map((activeDailyMap) => (
                <div
                  className="daily-map-settings__list"
                  key={activeDailyMap.id}
                >
                  <Badge type="green">
                    {
                      speechSounds.find(
                        (speechSound) =>
                          speechSound.id === activeDailyMap.SpeechSoundId
                      )?.label
                    }
                  </Badge>
                  <p className="daily-map-settings__list__text">
                    {activeDailyMap.DailyMapTemplate.title}
                  </p>
                  <Button
                    className="daily-map-settings__list__button"
                    onClick={() => runDailyMap(activeDailyMap.SpeechSoundId)}
                    size="small"
                  >
                    {messages.button.go.toUpperCase()}
                  </Button>
                </div>
              ))}
            {speechSounds
              .filter(
                (speechSound) =>
                  !dailyMaps.some(
                    (activeDailyMap) =>
                      activeDailyMap.SpeechSoundId === speechSound.id
                  )
              )
              .map((speechSound) => (
                <div
                  className="daily-map-settings__list"
                  key={speechSound.label}
                >
                  <SpeechSoundBadge speechSound={speechSound} />
                  <p className="daily-map-settings__list__text">
                    {messages.label.noActiveMap}
                  </p>
                  <Button
                    className="daily-map-settings__list__button"
                    onClick={() => {
                      setSelectedSpeechSoundId(speechSound.id);
                      setShowModal(true);
                    }}
                    size="small"
                  >
                    {messages.button.chooseMap.toUpperCase()}
                  </Button>
                </div>
              ))}
          </div>
        </Page.Body>
      </Page>
    </Zoom>
  );
};

DailyMapSettings.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  hasLoadedMap: PropTypes.bool.isRequired,
  hasLoadedTemplates: PropTypes.bool.isRequired,
  hasLoadedDailyMaps: PropTypes.bool.isRequired,
  student: PropTypes.shape({
    id: PropTypes.number.isRequired,
    SpeechSoundId: PropTypes.number,
  }).isRequired,
  dailyMap: PropTypes.shape({
    id: PropTypes.number,
    meta: PropTypes.object,
    DailyMapTemplateId: PropTypes.number,
    DailyMapTemplate: PropTypes.object,
  }),
  dailyMapTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      shortDescription: PropTypes.string,
    })
  ).isRequired,
  getDailyMapTemplates: PropTypes.func.isRequired,
  activateDailyMap: PropTypes.func.isRequired,
  getDailyMaps: PropTypes.func.isRequired,
  dailyMaps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getSpeechSounds: PropTypes.func.isRequired,
  speechSounds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateStudentById: PropTypes.func.isRequired,
  resetDailyMap: PropTypes.func.isRequired,
};

DailyMapSettings.defaultProps = {
  dailyMap: null,
};

const mapStateToProps = (state) => ({
  daysActive: selectors.dailyMap.getDaysActive(state),
  dailyMap: selectors.dailyMap.getSelectedDailyMap(state),
  dailyMaps: selectors.dailyMap.getDailyMaps(state),
  dailyMapTemplates: selectors.dailyMap.getDailyMapTemplates(state),
  hasLoadedMap: selectors.dailyMap.getHasLoadedMap(state),
  hasLoadedTemplates: selectors.dailyMap.getHasLoadedTemplates(state),
  hasLoadedDailyMaps: selectors.dailyMap.getHasLoadedDailyMaps(state),
  isSubmitting: selectors.student.getIsSubmitting(state),
  student: selectors.authentication.getStudent(state),
  speechSounds: selectors.speechSounds.getSpeechSoundsData(state),
});

const mapDispatchToProps = {
  ...actions.dailyMap,
  ...actions.speechSounds,
  ...actions.students,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(DailyMapSettings);
