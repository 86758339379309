import queryString from 'query-string';

import { actions } from 'src/constants';

const actionMap = {
  [actions.LOCATION_CHANGED]: (state) => {
    // TODO - REFACTOR!!!
    const { page } = queryString.parse(window.location.search);

    if (page) {
      localStorage.setItem(window.location.pathname, page);
    }

    const savedPage = localStorage.getItem(window.location.pathname);

    if (savedPage) {
      window.history.pushState(
        {},
        '',
        `${window.location.pathname}?page=${savedPage}`
      );
    }

    return {
      ...state,
    };
  },
};

export default (state = {}, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
