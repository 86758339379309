import React, { memo, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';

import { actions, selectors } from 'src/store';
import { paths, payment, subscriptions } from 'src/constants';
import { common } from 'src/utils';
import { useMakePayment } from 'src/hooks';

import { Page, Button, Localize, Dialog } from 'src/components/common';

import { SubscriptionOffer, SubscriptionsOffer } from './components';

import './index.scss';

const SPECIAL_DURATION_TAG = 'ljetni_paket';

const SubscriptionPromo = ({
  specialOfferSubscriptions,
  hasLoaded,
  subscriptionStatus,
}) => {
  const [showSecondaryPaymentModal, setShowSecondaryPaymentModal] = useState(
    false
  );
  const [selectedPackage, setSelectedPackage] = useState();
  const history = useHistory();

  const makePayment = useMakePayment(
    selectedPackage?.subscriptionId,
    selectedPackage?.durationKey
  );

  useEffect(() => {
    if (!selectedPackage && specialOfferSubscriptions.length > 0) {
      setSelectedPackage({
        subscriptionId:
          specialOfferSubscriptions[1]?.id ?? specialOfferSubscriptions[0].id,
        durationKey:
          specialOfferSubscriptions.length === 1 &&
          specialOfferSubscriptions[0].tag === SPECIAL_DURATION_TAG
            ? subscriptions.DURATION.KEYS.TWO_MONTHS_FORWARD
            : subscriptions.DURATION.KEYS.ONE_MONTH_FORWARD,
      });
    }
  }, [selectedPackage, setSelectedPackage, specialOfferSubscriptions]);

  if (
    subscriptionStatus?.lastTransactionStatus ===
    payment.PAYMENT_STATUS.IN_PROGRESS
  ) {
    return <Redirect to={paths.SUBSCRIPTION_STATUS} />;
  }

  const validUntilDate = common.formatExtendedDate(
    specialOfferSubscriptions.reduce((previous, current) => {
      return previous.validTo > current.validTo ? previous : current;
    }, 0)?.validTo
  );

  return (
    <>
      <Dialog
        showDialog={showSecondaryPaymentModal}
        title={
          <Localize id="page.subscriptions.promo.secondaryPaymentDialog.title" />
        }
        text={
          <Localize id="page.subscriptions.promo.secondaryPaymentDialog.description" />
        }
        onPrimaryBtnClick={() =>
          makePayment(subscriptions.PAYMENT_PROVIDER.KEYS.BANK)
        }
        showBackground
        primaryBtnLabel={
          <Localize id="page.subscriptions.promo.secondaryPaymentDialog.primaryButton" />
        }
        secondaryBtnLabel={
          <Localize id="page.subscriptions.promo.secondaryPaymentDialog.secondaryButton" />
        }
        onOutsideClick={() => setShowSecondaryPaymentModal(false)}
        onSecondaryBtnClick={() => setShowSecondaryPaymentModal(false)}
      />
      <Page>
        <Page.Body className="subscription-promo__container">
          <div className="subscription-promo">
            <h1 className="subscription-promo__title">
              <Localize id="page.subscriptions.promo.title" />
            </h1>
            <h3 className="subscription-promo__valid-to">
              <Localize
                id="page.subscriptions.promo.validTo"
                values={{
                  date: validUntilDate,
                }}
              />
            </h3>
            <div className="subscription-promo__subscription-container">
              {hasLoaded && selectedPackage && (
                <>
                  {specialOfferSubscriptions.length > 1 ? (
                    <SubscriptionsOffer
                      className="subscription-promo__subscription-container__subscription-card"
                      key={specialOfferSubscriptions.id}
                      selectedSubscriptionId={selectedPackage.subscriptionId}
                      specialOfferSubscriptions={specialOfferSubscriptions}
                      selectPackage={setSelectedPackage}
                    />
                  ) : (
                    <SubscriptionOffer
                      className="subscription-promo__subscription-container__subscription-card"
                      key={specialOfferSubscriptions[0].id}
                      subscription={specialOfferSubscriptions[0]}
                      durationKey={selectedPackage.durationKey}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </Page.Body>
        <Page.Footer>
          <span
            className="subscription-promo__regular-payment"
            onClick={() =>
              history.replace(
                paths.buildQuery(paths.SUBSCRIPTIONS, {
                  regular: true,
                })
              )
            }
          >
            <Localize id="page.subscriptions.promo.regularPayment" />
          </span>
          <span
            className="subscription-promo__secondary-payment"
            onClick={() => setShowSecondaryPaymentModal(true)}
          >
            <Localize id="page.subscriptions.promo.bankPayment" />
          </span>
          <Button
            variant="register"
            collapsed
            onClick={() =>
              makePayment(subscriptions.PAYMENT_PROVIDER.KEYS.CORVUS)
            }
          >
            <Localize id="page.subscriptions.promo.cardPayment" />
          </Button>
        </Page.Footer>
      </Page>
    </>
  );
};

SubscriptionPromo.propTypes = {
  hasLoaded: PropTypes.bool.isRequired,
  specialOfferSubscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      key: PropTypes.string,
      label: PropTypes.string,
      description: PropTypes.string,
      tag: PropTypes.string,
      price: PropTypes.number,
    })
  ),
  subscriptionStatus: PropTypes.shape({
    lastTransactionStatus: PropTypes.string,
  }).isRequired,
};

SubscriptionPromo.defaultProps = {
  specialOfferSubscriptions: null,
};

const mapStateToProps = (state) => ({
  specialOfferSubscriptions: selectors.subscriptions.getSpecialOfferSubscriptions(
    state
  ),
  subscriptionStatus: selectors.subscriptionStatus.getSubscriptionStatus(state),
  hasLoaded: selectors.subscriptions.getHasLoaded(state),
});

const mapDispatchToProps = {
  ...actions.notifications,
  ...actions.subscriptions,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(SubscriptionPromo);
