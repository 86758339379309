import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.memoryStepTwoExtraCollections.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getCurrencyData = createSelector(
  (state) => state.memoryStepTwoExtraCollections.data.currency,
  (currency = {}) => currency
);
const getCollectionsData = createSelector(
  (state) =>
    state.memoryStepTwoExtraCollections.data.memoryStepTwoExtraCollections,
  (collections = []) => collections
);
const getGroupLabel = createSelector(
  (state) =>
    state.memoryStepTwoExtraCollections.data.memoryStepTwoExtraGroupLabel,
  (groupLabel = '') => groupLabel
);
const getPageCount = createSelector(
  (state) => state.memoryStepTwoExtraCollections.data.pageCount,
  (pageCount) => pageCount || 0
);

export default {
  getCollectionsData,
  getCurrencyData,
  getGroupLabel,
  getHasLoaded,
  getPageCount,
};
