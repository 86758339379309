import { actions } from 'src/constants';

const initialState = {
  data: {},
  hasLoaded: false,
  isLoading: false,
};

const actionMap = {
  [actions.CLIMB_STEP_TWO_LEVELS_GET_REQUEST]: (state) => ({
    ...state,
    data: initialState.data,
    hasLoaded: false,
    isLoading: true,
  }),
  [actions.CLIMB_STEP_TWO_LEVELS_GET_SUCCESS]: (state, { result }) => ({
    ...state,
    data: result.data,
    hasLoaded: true,
    isLoading: false,
  }),
  [actions.CLIMB_STEP_TWO_LEVELS_GET_FAILURE]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: false,
  }),
};

export default (state = initialState, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
