import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { useIntlMessages, useRadioButtons } from 'src/hooks';
import { actions, selectors } from 'src/store';

import { RadioButton, Title, Page } from 'src/components/common';

import { PaymentModuleComponent } from './components';

import './index.scss';
import ButtonNext from '../ButtonNext';

const PaymentMethodSelection = ({
  nextStep,
  setStepsState,
  paymentMethods,
}) => {
  const messages = useIntlMessages();

  const { selectedValue, radioButtonProps, setSelectedValue } = useRadioButtons(
    'payment-method-select'
  );

  useEffect(() => {
    setSelectedValue(paymentMethods[0]?.key.toString());
  }, [paymentMethods, setSelectedValue]);

  useEffect(() => {
    if (paymentMethods.length < 2) {
      nextStep();
    }
  }, [nextStep, paymentMethods]);

  useEffect(() => {
    const paymentInfo = paymentMethods?.find(
      (method) => method.key.toString() === selectedValue
    );

    setStepsState({
      paymentMethod: selectedValue,
      paymentProvider: paymentInfo?.providerKey,
      paymentLabel: paymentInfo?.label,
    });
  }, [selectedValue, setStepsState, paymentMethods]);

  return (
    <>
      <Page.Body className="payment-selection-page">
        <Title text={messages.page.paymentMethodSelection.title} />
        <div className="payment-selection-page__options">
          {paymentMethods &&
            paymentMethods.map((method) => {
              return (
                <div
                  key={method.key}
                  className="payment-selection-page__radio-btn-container"
                >
                  <RadioButton
                    checked={selectedValue === method.key.toString()}
                    value={method.key}
                    {...radioButtonProps}
                    className="payment-selection-page__radio-btn-container__radio-button"
                  >
                    <div className="payment-selection-page__radio-btn-container__label-container">
                      {method.label}
                    </div>
                  </RadioButton>
                  <div className="payment-selection-page__radio-btn-container__offer-container">
                    <div className="payment-selection-page__radio-btn-container__offer-container__additional">
                      <PaymentModuleComponent module={method.key} />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Page.Body>
      <Page.Footer>
        <ButtonNext onClick={nextStep} />
      </Page.Footer>
    </>
  );
};

PaymentMethodSelection.propTypes = {
  nextStep: PropTypes.func.isRequired,
  setStepsState: PropTypes.func.isRequired,
  wizardState: PropTypes.shape({
    paymentMethod: PropTypes.string,
    paymentProvider: PropTypes.string,
  }).isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      providerKey: PropTypes.string,
    })
  ).isRequired,
};

const mapStateToProps = (state) => ({
  subscriptionStatus: selectors.subscriptionStatus.getSubscriptionStatus(state),
  paymentMethods: selectors.payment.getPaymentMethods(state),
});

const mapDispatchToProps = {
  ...actions.payment,
};

export default compose(
  memo,
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentMethodSelection);
