import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ContentContainer, Image } from 'src/components/common';

import './index.scss';

const AnswerCard = ({ answer, shakeAnswerImageUrl, onClick, outline }) => {
  const answerCardClassNames = useMemo(
    () =>
      cn('replenishment-answer-card', {
        'replenishment-answer-card--shake': shakeAnswerImageUrl === answer,
        'replenishment-answer-card--outline': outline,
      }),
    [answer, shakeAnswerImageUrl, outline]
  );

  return (
    <ContentContainer
      className={answerCardClassNames}
      onClick={() => onClick(answer)}
    >
      <Image
        className="replenishment-answer-card__image"
        alt="image"
        src={answer}
      />
    </ContentContainer>
  );
};

AnswerCard.propTypes = {
  answer: PropTypes.string.isRequired,
  shakeAnswerImageUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  outline: PropTypes.bool.isRequired,
};

export default memo(AnswerCard);
