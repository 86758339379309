export default {
  ARTICULATION_GYMNASTICS: 'ARTICULATION_GYMNASTICS',
  PHONEMIC_DISCRIMINATION: 'PHONEMIC_DISCRIMINATION',
  LOGATOMS_STEP_ONE: 'LOGATOMS_STEP_ONE',
  WORDS_STEP_ONE: 'WORDS_STEP_ONE',
  LOGATOMS_STEP_ONE_EXTRA: 'LOGATOMS_STEP_ONE_EXTRA',
  WORDS_STEP_ONE_EXTRA: 'WORDS_STEP_ONE_EXTRA',
  LOGATOMS_STEP_TWO: 'LOGATOMS_STEP_TWO',
  WORDS_STEP_TWO: 'WORDS_STEP_TWO',
  WORDS_STEP_TWO_EXTRA: 'WORDS_STEP_TWO_EXTRA',
  LOGATOMS_STEP_TWO_EXTRA: 'LOGATOMS_STEP_TWO_EXTRA',
  LOGATOMS_STEP_THREE: 'LOGATOMS_STEP_THREE',
  WORDS_STEP_THREE: 'WORDS_STEP_THREE',
  WORDS_STEP_THREE_EXTRA: 'WORDS_STEP_THREE_EXTRA',
  LOGATOMS_STEP_THREE_EXTRA: 'LOGATOMS_STEP_THREE_EXTRA',
  WHEEL_OF_FORTUNE: 'WHEEL_OF_FORTUNE',
  REPLENISHMENT: 'REPLENISHMENT',
  READ_TOGETHER: 'READ_TOGETHER',
  READ_AND_THINK: 'READ_AND_THINK',
};
