import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { gameKeys } from 'src/constants';

import Activities from 'src/components/pages/Activities';

const ActivityRenderer = (props) => {
  const { activityType, children } = props;

  switch (activityType) {
    case gameKeys.ARTICULATION_GYMNASTICS:
      return <Activities.ArticulationGymnastics.Levels.DailyMap {...props} />;
    case gameKeys.CLIMB_STEP_ONE:
      return <Activities.Climb.Levels.DailyMap.StepOne {...props} />;
    case gameKeys.CLIMB_STEP_ONE_EXTRA:
      return <Activities.Climb.Levels.DailyMap.StepOneExtra {...props} />;
    case gameKeys.CLIMB_STEP_TWO:
      return <Activities.Climb.Levels.DailyMap.StepTwo {...props} />;
    case gameKeys.CLIMB_STEP_TWO_EXTRA:
      return <Activities.Climb.Levels.DailyMap.StepTwoExtra {...props} />;
    case gameKeys.CLIMB_STEP_THREE:
      return <Activities.Climb.Levels.DailyMap.StepThree {...props} />;
    case gameKeys.CLIMB_STEP_THREE_EXTRA:
      return <Activities.Climb.Levels.DailyMap.StepThreeExtra {...props} />;
    case gameKeys.DISCOVER_STEP_ONE:
      return <Activities.Discover.Levels.DailyMap.StepOne {...props} />;
    case gameKeys.DISCOVER_STEP_ONE_EXTRA:
      return <Activities.Discover.Levels.DailyMap.StepOneExtra {...props} />;
    case gameKeys.DISCOVER_STEP_TWO:
      return <Activities.Discover.Levels.DailyMap.StepTwo {...props} />;
    case gameKeys.DISCOVER_STEP_TWO_EXTRA:
      return <Activities.Discover.Levels.DailyMap.StepTwoExtra {...props} />;
    case gameKeys.DISCOVER_STEP_THREE:
      return <Activities.Discover.Levels.DailyMap.StepThree {...props} />;
    case gameKeys.DISCOVER_STEP_THREE_EXTRA:
      return <Activities.Discover.Levels.DailyMap.StepThreeExtra {...props} />;
    case gameKeys.FIND_WORDS_STEP_ONE:
      return <Activities.FindWords.Levels.DailyMap.StepOne {...props} />;
    case gameKeys.FIND_WORDS_STEP_ONE_EXTRA:
      return <Activities.FindWords.Levels.DailyMap.StepOneExtra {...props} />;
    case gameKeys.FIND_WORDS_STEP_TWO:
      return <Activities.FindWords.Levels.DailyMap.StepTwo {...props} />;
    case gameKeys.FIND_WORDS_STEP_TWO_EXTRA:
      return <Activities.FindWords.Levels.DailyMap.StepTwoExtra {...props} />;
    case gameKeys.FIND_WORDS_STEP_THREE:
      return <Activities.FindWords.Levels.DailyMap.StepThree {...props} />;
    case gameKeys.FIND_WORDS_STEP_THREE_EXTRA:
      return <Activities.FindWords.Levels.DailyMap.StepThreeExtra {...props} />;
    case gameKeys.FLASH_CARDS_STEP_ONE:
      return <Activities.FlashCards.Levels.DailyMap.StepOne {...props} />;
    case gameKeys.FLASH_CARDS_STEP_ONE_EXTRA:
      return <Activities.FlashCards.Levels.DailyMap.StepOneExtra {...props} />;
    case gameKeys.FLASH_CARDS_STEP_TWO:
      return <Activities.FlashCards.Levels.DailyMap.StepTwo {...props} />;
    case gameKeys.FLASH_CARDS_STEP_TWO_EXTRA:
      return <Activities.FlashCards.Levels.DailyMap.StepTwoExtra {...props} />;
    case gameKeys.FLASH_CARDS_STEP_THREE:
      return <Activities.FlashCards.Levels.DailyMap.StepThree {...props} />;
    case gameKeys.FLASH_CARDS_STEP_THREE_EXTRA:
      return (
        <Activities.FlashCards.Levels.DailyMap.StepThreeExtra {...props} />
      );
    case gameKeys.MEMORY_STEP_ONE:
      return <Activities.Memory.Levels.DailyMap.StepOne {...props} />;
    case gameKeys.MEMORY_STEP_ONE_EXTRA:
      return <Activities.Memory.Levels.DailyMap.StepOneExtra {...props} />;
    case gameKeys.MEMORY_STEP_TWO:
      return <Activities.Memory.Levels.DailyMap.StepTwo {...props} />;
    case gameKeys.MEMORY_STEP_TWO_EXTRA:
      return <Activities.Memory.Levels.DailyMap.StepTwoExtra {...props} />;
    case gameKeys.MEMORY_STEP_THREE:
      return <Activities.Memory.Levels.DailyMap.StepThree {...props} />;
    case gameKeys.MEMORY_STEP_THREE_EXTRA:
      return <Activities.Memory.Levels.DailyMap.StepThreeExtra {...props} />;
    case gameKeys.PAINTING:
      return <Activities.Painting.Levels.DailyMap {...props} />;
    case gameKeys.READ_AND_THINK:
      return <Activities.ReadAndThink.Levels.DailyMap {...props} />;
    case gameKeys.READ_AND_THINK_BONUS:
      return <Activities.ReadAndThink.BonusLevels.DailyMap {...props} />;
    case gameKeys.READ_TOGETHER:
      return <Activities.ReadTogether.Levels.DailyMap {...props} />;
    case gameKeys.READ_TOGETHER_BONUS:
      return <Activities.ReadTogether.BonusLevels.DailyMap {...props} />;
    case gameKeys.REPLENISHMENT:
      return <Activities.Replenishment.Levels.DailyMap {...props} />;
    case gameKeys.TREASURE_HUNT:
      return <Activities.TreasureHunt.Levels.DailyMap {...props} />;
    case gameKeys.WHEEL_OF_FORTUNE:
      return <Activities.WheelOfFortune.Levels.DailyMap {...props} />;
    default:
      return children;
  }
};

ActivityRenderer.propTypes = {
  activityType: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ActivityRenderer.defaultProps = {
  activityType: '',
};

export default memo(ActivityRenderer);
