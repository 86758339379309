import PropTypes from 'prop-types';
import React, { memo } from 'react';
import cn from 'classnames';

import './index.scss';

const Header = ({ className, children }) => {
  const headerClassName = cn('header', className);

  return <div className={headerClassName}>{children}</div>;
};

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

Header.defaultProps = {
  className: '',
  children: undefined,
};

export default memo(Header);
