import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';

import { paths } from 'src/constants';

import { ContentContainer } from 'src/components/common';

import './index.scss';

const GroupCard = ({ collectionsPath, group }) => {
  const history = useHistory();
  const { groupKey, activityId } = useParams();

  const handleSelect = useCallback(
    (groupId) => {
      history.push(paths.build(collectionsPath, groupKey, activityId, groupId));
    },
    [activityId, collectionsPath, groupKey, history]
  );

  return (
    <ContentContainer
      className="painting-group-card-container"
      onClick={() => handleSelect(group.id)}
    >
      <div className="painting-group-card-container__text">{group.label}</div>
    </ContentContainer>
  );
};

GroupCard.propTypes = {
  collectionsPath: PropTypes.string.isRequired,
  group: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(GroupCard);
