import _throttle from 'lodash/throttle';

import { actions } from 'src/store';
import { actions as actionTypes } from 'src/constants';
import authentication from '../selectors/authentication';

const throttledSubscriptionCheck = _throttle(
  (dispatch) => dispatch(actions.subscriptions.getStatus()),
  // Status check can be done occasionally, not really needed as long
  // as feedback provides new trial status.
  // We should take care that new subscription is also communicated
  // instantaneously. For now it's solved by reloading the page once
  // user come back from payment gateway.
  60 * 1000,
  { leading: true, trailing: false }
);

export default () => (store) => (next) => (action) => {
  // First resolve action then proceed with verification.
  // Order is important, the "current" action can authorise user
  // so right after it we may want to check for subscriptions
  const result = next(action);

  const state = store.getState();
  if (
    authentication.getIsAuthenticated(state) &&
    localStorage.getItem('selectedStudentId')
  ) {
    throttledSubscriptionCheck(store.dispatch);
  } else if (action.type === actionTypes.CLEAR_USER_STORE_DATA) {
    // reset throttle so that on new login status is fetch right away
    throttledSubscriptionCheck.cancel();
  }

  return result;
};
