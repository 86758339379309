import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Topbar = ({ children }) => <div id="topbar">{children}</div>;

Topbar.propTypes = {
  children: PropTypes.node,
};

Topbar.defaultProps = {
  children: undefined,
};

export default Topbar;
