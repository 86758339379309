import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const TreasureHuntGroups = ({
  getGroups,
  hasLoaded,
  student,
  treasureHuntCurrency,
  treasureHuntGroups,
  updateStudentById,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      collectionsPath={paths.TREASURE_HUNT_COLLECTIONS}
      currency={treasureHuntCurrency}
      getGroups={getGroups}
      hasLoaded={hasLoaded}
      items={treasureHuntGroups}
      student={student}
      title={messages.subactivity.treasureHunt}
      updateStudentById={updateStudentById}
    />
  );
};

TreasureHuntGroups.propTypes = {
  getGroups: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  student: PropTypes.shape({}).isRequired,
  treasureHuntCurrency: PropTypes.shape({}).isRequired,
  treasureHuntGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateStudentById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.treasureHuntGroups.getHasLoaded(state),
  treasureHuntCurrency: selectors.treasureHuntGroups.getCurrencyData(state),
  treasureHuntGroups: selectors.treasureHuntGroups.getGroupsData(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.treasureHunt,
  ...actions.students,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(TreasureHuntGroups);
