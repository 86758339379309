import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { common } from 'src/utils';

import Image from '../Image';

import backgrounds from './backgrounds';
import ghostBackgrounds from './backgrounds/outlines';

import './index.scss';

const Coin = ({ currency, type, size, className, ghost }) => {
  const background =
    currency.imageUrl ||
    (!ghost
      ? backgrounds[common.getActivityIconBackground(type)]
      : ghostBackgrounds[common.getActivityIconBackground(type)]);

  const classNames = cn('coin', className, size);

  return (
    <div role="button" className={classNames} tabIndex={0}>
      <Image src={background} alt="background" />
    </div>
  );
};

Coin.propTypes = {
  currency: PropTypes.shape({
    imageUrl: PropTypes.string,
  }),
  className: PropTypes.string,
  size: PropTypes.oneOf(['extra-small', 'small', 'large', 'regular']),
  type: PropTypes.oneOf([
    'purple',
    'purple-special',
    'blue',
    'gold',
    'orange',
    'orange-special',
    'green',
    'green-special',
    'red',
  ]),
  ghost: PropTypes.bool,
};

Coin.defaultProps = {
  currency: {},
  className: '',
  size: 'regular',
  type: 'gold',
  ghost: false,
};

export default Coin;
