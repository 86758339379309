const getRegistrationArgs = (userRole) => {
  return {
    event: 'trackInteraction',
    eventCategory: 'Free Trial',
    eventAction: 'Success',
    eventLabel: userRole,
  };
};

export default {
  getRegistrationArgs,
};
