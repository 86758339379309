import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Localize } from '@kokolingo/localisation';

import './index.scss';

const PageInfoIndicator = ({ pages, currentPage }) => {
  return (
    <div className="page-info-indicator">
      <p>
        <Localize
          id="label.introVideo"
          values={{ currentPage, totalPages: pages.length }}
        />
      </p>
    </div>
  );
};

PageInfoIndicator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pages: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default memo(PageInfoIndicator);
