import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import cn from 'classnames';

import { dialog } from 'src/constants';

import Badge from '../Badge';
import Button from '../Button';

import './index.scss';

const getBadgeProps = (type) => {
  switch (type) {
    case dialog.type.ERROR:
      return { iconName: 'close', type: 'danger' };

    case dialog.type.INFO:
      return { iconName: 'info' };

    case dialog.type.SUCCESS:
      return { iconName: 'check', type: 'success' };

    case dialog.type.WARNING:
      return { iconName: 'exclamation' };

    case dialog.type.PAYMENT:
      return { iconName: 'card' };

    default:
      return '';
  }
};

const Dialog = ({
  className,
  onClose,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  primaryBtnLabel,
  secondaryBtnLabel,
  showBackground,
  showDialog,
  text,
  title,
  type,
  onOutsideClick,
}) => {
  const showClose = useMemo(() => !!onClose, [onClose]);

  const dialogClassNames = useMemo(() => cn('dialog', className), [className]);

  if (!showDialog) {
    return null;
  }

  return (
    <>
      {showBackground && (
        <div className="dialog-background" onClick={onOutsideClick} />
      )}
      <div className={dialogClassNames}>
        <Badge {...getBadgeProps(type)} />
        <div className="dialog__title">
          <div>{title}</div>
        </div>
        {showClose && (
          <Button
            className="dialog__button-close"
            label="x"
            onClick={onClose}
          />
        )}
        <div className="dialog__text">
          <span>{text}</span>
        </div>
        <div className="dialog__buttons">
          {secondaryBtnLabel && (
            <Button
              className="dialog__buttons--secondary"
              label={secondaryBtnLabel}
              onClick={onSecondaryBtnClick}
            />
          )}
          {primaryBtnLabel && (
            <Button
              className="dialog__buttons--primary"
              label={primaryBtnLabel}
              onClick={onPrimaryBtnClick}
              variant="register"
            />
          )}
        </div>
      </div>
    </>
  );
};

Dialog.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  text: PropTypes.node,
  onClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onPrimaryBtnClick: PropTypes.func,
  onSecondaryBtnClick: PropTypes.func,
  onOutsideClick: PropTypes.func,
  primaryBtnLabel: PropTypes.node,
  secondaryBtnLabel: PropTypes.node,
  type: PropTypes.oneOf([
    dialog.type.ERROR,
    dialog.type.INFO,
    dialog.type.PAYMENT,
    dialog.type.SUCCESS,
    dialog.type.WARNING,
  ]),
  showDialog: PropTypes.bool,
  showBackground: PropTypes.bool,
};

Dialog.defaultProps = {
  className: '',
  onClose: false,
  primaryBtnLabel: '',
  secondaryBtnLabel: '',
  onPrimaryBtnClick: () => {},
  onOutsideClick: () => {},
  onSecondaryBtnClick: () => {},
  showBackground: false,
  showDialog: false,
  text: null,
  title: '',
  type: dialog.type.INFO,
};

export default memo(Dialog);
