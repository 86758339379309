import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Localize } from '@kokolingo/localisation';

import { Button } from 'src/components/common';

import StudentsSection from './StudentsSection';

import './index.scss';

const SelectionMenu = ({
  handleStudentSelect,
  hasAcceptedInvitation,
  userExtraStudents,
  userOnlyStudents,
}) => {
  return (
    <div className="students-selection-menu">
      <p className="students-selection-menu__label">
        <Localize id="label.selectStudent" />
      </p>
      <StudentsSection
        items={hasAcceptedInvitation ? userExtraStudents : userOnlyStudents}
        handleStudentSelect={handleStudentSelect}
      />
      <Button
        className="students-selection-menu__button"
        label={<Localize id="label.addNewStudent" />}
        variant="tertiary"
        disabled
      />
    </div>
  );
};

SelectionMenu.propTypes = {
  handleStudentSelect: PropTypes.func.isRequired,
  hasAcceptedInvitation: PropTypes.bool,
  userExtraStudents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userOnlyStudents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

SelectionMenu.defaultProps = {
  hasAcceptedInvitation: false,
};

export default memo(SelectionMenu);
