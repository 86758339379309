import { actions } from 'src/constants';

const initialState = {
  isSubmitting: false,
  showShopModal: false,
};

const actionMap = {
  [actions.INVITE_USER_REQUEST]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [actions.INVITE_USER_SUCCESS]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
  [actions.INVITE_USER_FAILURE]: (state) => ({
    ...state,
    isSubmitting: false,
  }),

  [actions.SEND_MOBILE_USER_INVITE_REQUEST]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [actions.SEND_MOBILE_USER_INVITE_SUCCESS]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
  [actions.SEND_MOBILE_USER_INVITE_FAILURE]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
  [actions.SET_SHOW_SHOP_MODAL]: (state, { payload }) => ({
    ...state,
    showShopModal: payload,
  }),
};

export default (state = initialState, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
