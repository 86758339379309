import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const FlashCardsStepThreeCollections = ({
  flashCardsStepThreeCollections,
  flashCardsStepThreeCurrency,
  getCollections,
  hasLoaded,
  pageCount,
  student,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="flash-cards-collections-container--step-three"
      collectionsPath={paths.FLASH_CARDS_STEP_THREE}
      currency={flashCardsStepThreeCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={flashCardsStepThreeCollections}
      levelsPath={paths.FLASH_CARDS_STEP_THREE_LEVELS}
      pageCount={pageCount}
      student={student}
      title={messages.game.flashCards}
    />
  );
};

FlashCardsStepThreeCollections.propTypes = {
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  flashCardsStepThreeCollections: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  flashCardsStepThreeCurrency: PropTypes.shape({}).isRequired,
  pageCount: PropTypes.number.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.flashCardsStepThreeCollections.getHasLoaded(state),
  flashCardsStepThreeCurrency: selectors.flashCardsStepThreeCollections.getCurrencyData(
    state
  ),
  flashCardsStepThreeCollections: selectors.flashCardsStepThreeCollections.getCollectionsData(
    state
  ),
  pageCount: selectors.flashCardsStepThreeCollections.getPageCount(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.flashCardsStepThree,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(FlashCardsStepThreeCollections);
