import CouponSettings from './CouponSettings';
import DailyMapSettings from './DailyMapSettings';
import LicenceStatus from './LicenceStatus';
import Payment from './Payment';
import SoundSettings from './SoundSettings';
import SpeechSoundSelection from './SpeechSoundSelection';
import StudentSettings from './StudentSettings';
import PaymentRouter from './PaymentRouter';
import SubscriptionStatus from './SubscriptionStatus';
import SubscriptionWizard from './SubscriptionWizard';
import SubscriptionPromo from './SubscriptionPromo';
import Subscriptions from './Subscriptions';
import UserSettings from './UserSettings';

export default {
  CouponSettings,
  DailyMapSettings,
  LicenceStatus,
  Payment,
  SoundSettings,
  SpeechSoundSelection,
  StudentSettings,
  PaymentRouter,
  SubscriptionStatus,
  SubscriptionWizard,
  Subscriptions,
  UserSettings,
  SubscriptionPromo,
};
