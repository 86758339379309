import { actions, statusCodes } from 'src/constants';

const initialState = {
  data: [],
  hasLoaded: false,
  isLoading: false,
  isSubmitting: false,
};

const actionMap = {
  [actions.AUTHENTICATE_USER_SUCCESS]: (state, { result }) => ({
    ...state,
    data: result.data.subscriptions,
    isLoading: false,
  }),

  [actions.AUTHENTICATE_USER_FAILURE]: (state, { error }) => {
    return {
      ...state,
      data: error.response.data.subscriptions,
      isAuthenticated: error.response.status === statusCodes.PAYMENT_REQUIRED,
      isLoading: false,
    };
  },

  [actions.SUBSCRIPTIONS_GET_REQUEST]: (state) => ({
    ...state,
    hasLoaded: false,
  }),
  [actions.SUBSCRIPTIONS_GET_SUCCESS]: (state, { result: { data } }) => ({
    ...state,
    data,
    hasLoaded: true,
  }),
  // Subscriptions are loaded automatically with user.
  // This is not necessary the behaviour we want..
  // TODO
  //  - load subscriptions separately
  //  - create provider for subscriptions; subscriptions must be loaded right away so that subscription page has no loading
  [actions.RESOLVE_USERS_SUCCESS]: (state, { result: { data } }) => ({
    ...state,
    data: data.subscriptions,
    hasLoaded: true,
  }),
  [actions.SUBSCRIPTIONS_GET_FAILURE]: (state) => ({
    ...state,
    hasLoaded: true,
  }),

  [actions.SUBSCRIPTION_CANCEL_REQUEST]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [actions.SUBSCRIPTION_CANCEL_SUCCESS]: (state) => ({
    ...state,
    isSubmitting: false,
    subscriptionCheckFailed: false,
  }),
  [actions.SUBSCRIPTION_CANCEL_FAILURE]: (state) => ({
    ...state,
    isSubmitting: false,
  }),

  [actions.DISMISS_TRIAL_EXPIRATION_ALERT_REQUEST]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [actions.DISMISS_TRIAL_EXPIRATION_ALERT_SUCCESS]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
  [actions.DISMISS_TRIAL_EXPIRATION_ALERT_FAILURE]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
};

export default (state = initialState, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
