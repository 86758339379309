import { createSelector } from 'reselect';

const getType = createSelector(
  (state) => state.notifications.type,
  (type) => type
);
const getMessage = createSelector(
  (state) => state.notifications.message,
  (message) => message
);
const getDuration = createSelector(
  (state) => state.notifications.duration,
  (duration) => duration
);

export default {
  getType,
  getMessage,
  getDuration,
};
