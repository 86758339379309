import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import wheel from 'src/images/wheelOfFortune/wheel.svg';
import wheelBackground from 'src/images/wheelOfFortune/wheelBackground.svg';
import wheelPointer from 'src/images/wheelOfFortune/wheelPointer.svg';
import wheelShadow from 'src/images/wheelOfFortune/wheelShadow.svg';

import { Image } from 'src/components/common';
import AnswersSection from '../AnswersSection';

import './index.scss';

const FortuneWheel = ({
  answers,
  isWheelSpinning,
  onClick,
  spinWheelAnimation,
}) => {
  const wheelContainerClassNames = useMemo(
    () =>
      cn('fortune-wheel-container__wheel-container', {
        'fortune-wheel-container__wheel-container--animation-one-third':
          spinWheelAnimation === 'one-third',
        'fortune-wheel-container__wheel-container--animation-two-thirds':
          spinWheelAnimation === 'two-thirds',
        'fortune-wheel-container__wheel-container--animation-three-thirds':
          spinWheelAnimation === 'three-thirds',
      }),
    [spinWheelAnimation]
  );

  return (
    <div
      className="fortune-wheel-container"
      onClick={isWheelSpinning ? null : onClick}
    >
      <Image
        className="fortune-wheel-container__wheel-pointer"
        alt="wheelPointer"
        src={wheelPointer}
      />
      <Image
        className="fortune-wheel-container__wheel-background"
        alt="wheelBackground"
        src={wheelBackground}
      />
      <Image
        className="fortune-wheel-container__wheel-shadow"
        alt="wheelShadow"
        src={wheelShadow}
      />
      <div className={wheelContainerClassNames}>
        <Image
          className="fortune-wheel-container__wheel-container__wheel"
          alt="wheel"
          src={wheel}
        />
        <AnswersSection items={answers} />
      </div>
    </div>
  );
};

FortuneWheel.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isWheelSpinning: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  spinWheelAnimation: PropTypes.string,
};

FortuneWheel.defaultProps = {
  spinWheelAnimation: '',
};

export default memo(FortuneWheel);
