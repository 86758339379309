import React, { memo } from 'react';

import { Page } from 'src/components/common';

import './index.scss';

const SuccessPage = () => {
  // @TODO Remove after unused
  return (
    <Page containerClassName="success-page">
      <div>Uspješno odobrena pretplata!</div>
    </Page>
  );
};

export default memo(SuccessPage);
