import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const DiscoverStepThreeCollections = ({
  discoverStepThreeCollections,
  discoverStepThreeCurrency,
  getCollections,
  hasLoaded,
  student,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="discover-collections-container--step-three"
      currency={discoverStepThreeCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={discoverStepThreeCollections}
      levelsPath={paths.DISCOVER_STEP_THREE_LEVELS}
      student={student}
      title={messages.game.discover}
    />
  );
};

DiscoverStepThreeCollections.propTypes = {
  discoverStepThreeCollections: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  discoverStepThreeCurrency: PropTypes.shape({}).isRequired,
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.discoverStepThreeCollections.getHasLoaded(state),
  discoverStepThreeCollections: selectors.discoverStepThreeCollections.getCollectionsData(
    state
  ),
  discoverStepThreeCurrency: selectors.discoverStepThreeCollections.getCurrencyData(
    state
  ),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.discoverStepThree,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(DiscoverStepThreeCollections);
