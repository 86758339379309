import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import cn from 'classnames';

import { Icon } from 'src/components/common';

import './index.scss';

const SwitchSetting = ({ enabled, onClick }) => {
  const containerClassNames = useMemo(
    () =>
      cn('switch-setting-container', {
        'switch-setting-container--off': !enabled,
      }),
    [enabled]
  );

  return (
    <div
      className={containerClassNames}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      {!enabled && <div className="switch-setting-container__circle" />}
      <Icon name={enabled ? 'check' : 'close'} size={30} />
      {enabled && <div className="switch-setting-container__circle" />}
    </div>
  );
};

SwitchSetting.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(SwitchSetting);
