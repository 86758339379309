import { actions, paths } from 'src/constants';

export default {
  selectSubscription: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.SUBSCRIPTION_SELECT_REQUEST,
        actions.SUBSCRIPTION_SELECT_SUCCESS,
        actions.SUBSCRIPTION_SELECT_FAILURE,
      ],
      promise: (client) => client.post(paths.api.SUBSCRIPTIONS, data),
    },
  }),

  getStatus: () => ({
    [actions.API_CALL]: {
      types: [
        actions.SUBSCRIPTION_STATUS_GET_REQUEST,
        actions.SUBSCRIPTION_STATUS_GET_SUCCESS,
        actions.SUBSCRIPTION_STATUS_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.api.SUBSCRIPTIONS_STATUS),
    },
  }),

  cancelSubscription: () => ({
    [actions.API_CALL]: {
      types: [
        actions.SUBSCRIPTION_CANCEL_REQUEST,
        actions.SUBSCRIPTION_CANCEL_SUCCESS,
        actions.SUBSCRIPTION_CANCEL_FAILURE,
      ],
      promise: (client) => client.post(paths.api.SUBSCRIPTIONS_CANCEL),
    },
  }),

  dismissTrialExpirationAlert: () => ({
    [actions.API_CALL]: {
      types: [
        actions.DISMISS_TRIAL_EXPIRATION_ALERT_REQUEST,
        actions.DISMISS_TRIAL_EXPIRATION_ALERT_SUCCESS,
        actions.DISMISS_TRIAL_EXPIRATION_ALERT_FAILURE,
      ],
      promise: (client) =>
        client.post(paths.api.SUBSCRIPTIONS_DISMISS_TRIAL_EXPIRATION_ALERT),
    },
  }),

  getSubscriptions: () => ({
    [actions.API_CALL]: {
      types: [
        actions.SUBSCRIPTIONS_GET_REQUEST,
        actions.SUBSCRIPTIONS_GET_SUCCESS,
        actions.SUBSCRIPTIONS_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.api.SUBSCRIPTIONS),
    },
  }),

  setShowTrialEndedModal: (payload) => ({
    type: actions.SET_SHOW_TRIAL_ENDED_MODAL,
    payload,
  }),

  setShowPromoModal: (payload) => ({
    type: actions.SET_SHOW_PROMO_MODAL,
    payload,
  }),
};
