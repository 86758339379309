import { actions, paths } from 'src/constants';

export default {
  getPaymentInfo: (paymentMethod) => ({
    [actions.API_CALL]: {
      types: [
        actions.PAYMENT_INFO_REQUEST,
        actions.PAYMENT_INFO_SUCCESS,
        actions.PAYMENT_INFO_FAILURE,
      ],
      promise: (client) =>
        client.get(paths.api.AUTHENTICATE_PAYMENT, {
          params: { paymentMethod },
        }),
    },
  }),

  handleBankPayment: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.PAYMENT_BANK_REQUEST,
        actions.PAYMENT_BANK_SUCCESS,
        actions.PAYMENT_BANK_FAILURE,
      ],
      promise: (client) => client.post(paths.api.PAYMENT_BANK, data),
    },
  }),

  cancelPayment: () => ({
    [actions.API_CALL]: {
      types: [
        actions.PAYMENT_CANCEL_REQUEST,
        actions.PAYMENT_CANCEL_SUCCESS,
        actions.PAYMENT_CANCEL_FAILURE,
      ],
      promise: (client) => client.patch(paths.api.PAYMENT_CANCEL),
    },
  }),

  getPaymentProviders: () => ({
    [actions.API_CALL]: {
      types: [
        actions.PAYMENT_PROVIDERS_GET_REQUEST,
        actions.PAYMENT_PROVIDERS_GET_SUCCESS,
        actions.PAYMENT_PROVIDERS_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.api.PAYMENT_PROVIDERS),
    },
  }),
};
