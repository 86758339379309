import React from 'react';
import PropTypes from 'prop-types';

import { usePaymentModule } from 'src/hooks';

const PaymentModuleComponent = ({ module }) => {
  const paymentModule = usePaymentModule(module);

  const SpecialOfferComponent =
    paymentModule?.MethodSpecialOffer || (() => null);

  return <SpecialOfferComponent />;
};

PaymentModuleComponent.propTypes = {
  module: PropTypes.string.isRequired,
};

export default PaymentModuleComponent;
