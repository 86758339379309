import _map from 'lodash/map';
import _without from 'lodash/without';

import {
  payment,
  subscriptions as subscriptionsConstants,
} from 'src/constants';

import common from './common';

const getIsSpeechTherapistSubscription = (subscriptionKey) =>
  [
    subscriptionsConstants.KEYS.KOKOLINGO_1,
    subscriptionsConstants.KEYS.KOKOLINGO_5,
    subscriptionsConstants.KEYS.KOKOLINGO_10,
    subscriptionsConstants.KEYS.KOKOLINGO_25,
  ].includes(subscriptionKey);

const getNotAllowedMessage = (subscriptions, messages) => {
  const subscriptionStrings = subscriptions
    .filter(
      (subscription) => !getIsSpeechTherapistSubscription(subscription.key)
    )
    .map((subscription) => subscription.key);

  if (subscriptionStrings.length > 1) {
    return messages({ subscriptionStrings: subscriptionStrings.join(', ') })
      .notification.subscriptionsAvailability;
  }

  return messages({ subscriptionStrings }).notification
    .subscriptionAvailability;
};

const getPriceWithDiscount = (price, discount) => price - price * discount;

const getDiscountedPriceForDuration = (durationKey, price) => {
  const discount = subscriptionsConstants.DURATION_DISCOUNTS[durationKey];
  const durationInMonths = subscriptionsConstants.DURATION_VALUES[durationKey];

  return durationInMonths < 1
    ? 1
    : getPriceWithDiscount(price * durationInMonths, discount);
};

const getPriceForDuration = (durationKey, price) => {
  const durationInMonths = subscriptionsConstants.DURATION_VALUES[durationKey];
  const sum = durationInMonths < 1 ? price : price * durationInMonths;
  if (durationInMonths < 1) {
    return Math.ceil(sum);
  }
  return sum;
};

const getSubscriptionsOffer = (subscriptions, messages) =>
  subscriptions
    .filter(
      (subscription) => !getIsSpeechTherapistSubscription(subscription.key)
    )
    .map((subscription) => ({
      key: subscription.key,
      value: messages({
        label: subscription.label,
        price: subscription.price,
      })['page.subscription.price'],
    }));

const getSubscriptionsOfferByDuration = (messages) =>
  _without(
    _map(subscriptionsConstants.DURATION, (value, key) => {
      const discount = subscriptionsConstants.DURATION_DISCOUNTS[key] * 100;
      const discountMessage = discount
        ? messages({ discount })['label.subscription.discount']
        : '';

      return (
        key !== 'KEYS' && {
          key,
          value: `${messages()[value]} ${discountMessage}`,
        }
      );
    }),
    false
  );

const getSubscriptionPaymentMethods = (messages) =>
  _without(
    _map(
      subscriptionsConstants.PAYMENT_PROVIDER,
      (value, key) =>
        key !== 'KEYS' && {
          key,
          value: messages()[value],
        }
    ),
    false
  );

const getSubscriptionStatusData = (subscriptionStatus, messages) => {
  let currentPackage = '-';
  let state = '-';
  let description = '-';
  const { paymentMethod } = subscriptionStatus;

  if (subscriptionStatus.isCouponActive) {
    currentPackage = subscriptionStatus.subscription;

    state = messages.page.subscriptionStatus.package.state.coupon;
    description = messages({
      subscription: subscriptionStatus.subscription,
      expirationDate: common.formatDate(subscriptionStatus.expireDate),
    }).page.subscriptionStatus.package.description.coupon;
  } else if (
    subscriptionStatus.lastTransactionStatus ===
    payment.PAYMENT_STATUS.IN_PROGRESS
  ) {
    currentPackage = subscriptionStatus.subscription;

    state = messages.page.subscriptionStatus.package.state.pending;
  } else if (
    subscriptionStatus.isTrial ||
    !subscriptionStatus.subscriptionExpirationDate
  ) {
    state = messages()['page.subscriptionStatus.package.state.trialExpired'];
    description = messages()[
      'page.subscriptionStatus.package.description.trialExpired'
    ];

    if (subscriptionStatus.daysLeft > 0) {
      currentPackage = subscriptionStatus.subscription;

      state = messages()['page.subscriptionStatus.package.state.trial'];
      description = messages({ daysLeft: subscriptionStatus.daysLeft }).page
        .subscriptionStatus.package.description.trial;
    } else if (subscriptionStatus.pointsLeft > 0) {
      currentPackage = subscriptionStatus.subscription;

      state = messages()['page.subscriptionStatus.package.state.trial'];
      description = messages({ pointsLeft: subscriptionStatus.pointsLeft }).page
        .subscriptionStatus.package.description.trialPoints;
    }
  } else {
    currentPackage = '-';
    state = messages.page.subscriptionStatus.package.state.subscriptionExpired;
    description =
      messages.page.subscriptionStatus.package.description.subscriptionExpired;

    if (subscriptionStatus.daysLeft > 0) {
      currentPackage = subscriptionStatus.subscription;

      state = messages.page.subscriptionStatus.package.state.subscription;
      description = messages({
        expirationDate: common.formatDate(subscriptionStatus.expireDate),
      }).page.subscriptionStatus.package.description.subscription;
    }
  }

  return { currentPackage, state, description, paymentMethod };
};

const getSubscriptionSelectErrorMessage = (messages) =>
  messages.notification.subscriptionIsActive;

const getIsSubscriptionActive = (user) => {
  if (
    user.freeTrialMaxPoints &&
    user.freeTrialPoints < user.freeTrialMaxPoints
  ) {
    return true;
  }
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return (
    new Date(user.freeTrialExpirationDate) > today ||
    new Date(user.subscriptionExpirationDate) > today
  );
};

const getDailyPrice = (price, subscriptionDuration) =>
  (price / subscriptionDuration).toFixed(2);

const getIsPaymentInProgress = (subscriptionStatus) =>
  subscriptionStatus.lastTransactionStatus ===
  payment.PAYMENT_STATUS.IN_PROGRESS;

const getHasSubscriptionExpired = (user) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return new Date(user.subscriptionExpirationDate) < today;
};

export default {
  getPriceForDuration,
  getDailyPrice,
  getDiscountedPriceForDuration,
  getIsSpeechTherapistSubscription,
  getIsSubscriptionActive,
  getNotAllowedMessage,
  getPriceWithDiscount,
  getSubscriptionSelectErrorMessage,
  getSubscriptionStatusData,
  getSubscriptionsOffer,
  getSubscriptionsOfferByDuration,
  getSubscriptionPaymentMethods,
  getIsPaymentInProgress,
  getHasSubscriptionExpired,
};
