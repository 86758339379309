import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const MemoryStepTwoExtraCollections = ({
  getCollectionsByGroupId,
  hasLoaded,
  memoryStepTwoExtraCollections,
  memoryStepTwoExtraCurrency,
  pageCount,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="memory-collections-container--step-two-extra"
      collectionsPath={paths.MEMORY_STEP_TWO_EXTRA_COLLECTIONS}
      currency={memoryStepTwoExtraCurrency}
      getCollectionsByGroupId={getCollectionsByGroupId}
      hasLoaded={hasLoaded}
      items={memoryStepTwoExtraCollections}
      levelsPath={paths.MEMORY_STEP_TWO_EXTRA_LEVELS}
      pageCount={pageCount}
      title={messages.game.memory}
    />
  );
};

MemoryStepTwoExtraCollections.propTypes = {
  getCollectionsByGroupId: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  memoryStepTwoExtraCollections: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  memoryStepTwoExtraCurrency: PropTypes.shape({}).isRequired,
  pageCount: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.memoryStepTwoExtraCollections.getHasLoaded(state),
  memoryStepTwoExtraCurrency: selectors.memoryStepTwoExtraCollections.getCurrencyData(
    state
  ),
  memoryStepTwoExtraCollections: selectors.memoryStepTwoExtraCollections.getCollectionsData(
    state
  ),
  pageCount: selectors.memoryStepTwoExtraCollections.getPageCount(state),
});

const mapDispatchToProps = {
  ...actions.memoryStepTwoExtra,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(MemoryStepTwoExtraCollections);
