import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import cn from 'classnames';

import Icon from '../Icon';
import Input from '../Input';

import './index.scss';

const PasswordInput = ({ className, iconClassName, ...otherProps }) => {
  const [showPassword, setShowPassword] = useState(false);
  const showPasswordClassName = cn(
    'password-input-container__icon',
    iconClassName,
    { 'password-input-container__icon--has-label': !!otherProps.label }
  );

  return (
    <div className="password-input-container">
      <Input
        maxLength={30}
        type={showPassword ? 'text' : 'password'}
        className={className}
        {...otherProps}
      />
      <div
        className={showPasswordClassName}
        onKeyPress={() => setShowPassword(!showPassword)}
        onClick={() => setShowPassword(!showPassword)}
        role="presentation"
      >
        <Icon name={showPassword ? 'eye-invisible' : 'eye'} />
      </div>
    </div>
  );
};

PasswordInput.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

PasswordInput.defaultProps = {
  className: '',
  iconClassName: '',
};

export default memo(PasswordInput);
