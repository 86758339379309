import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';

import {
  auth,
  dispatch,
  subscriptions,
  notifications,
  tooltips,
  magicLink,
  userMeta,
  parrotParts,
  errors,
} from '../middlewares';

import createReducer from '../reducers';

export default function configureStore(history) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    createReducer(),
    composeEnhancers(
      applyMiddleware(
        thunk,
        dispatch(),
        magicLink(),
        auth(),
        subscriptions(),
        notifications(),
        tooltips(),
        userMeta(),
        routerMiddleware(history),
        parrotParts(),
        errors()
      )
    )
  );

  store.asyncReducers = {};

  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };

  return store;
}
