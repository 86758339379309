import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';

import _map from 'lodash/map';
import _mapValues from 'lodash/mapValues';

import { actions, selectors } from 'src/store';

import beak from 'src/images/parrot/beak.svg';
import body from 'src/images/parrot/body.svg';
import breasts from 'src/images/parrot/breast.svg';
import eye from 'src/images/parrot/eye.svg';
import footLeft from 'src/images/parrot/foot-left.svg';
import footRight from 'src/images/parrot/foot-right.svg';
import head from 'src/images/parrot/head.svg';
import tail from 'src/images/parrot/tail.svg';
import wingLeft from 'src/images/parrot/wing-left.svg';
import wingRight from 'src/images/parrot/wing-right.svg';

import Image from '../Image';
import './index.scss';

const PARROT_PARTS = {
  beak,
  body,
  breasts,
  eye,
  footLeft,
  footRight,
  head,
  tail,
  wingLeft,
  wingRight,
};

const Parrot = ({
  height,
  getStudentSelectedParts,
  studentSelectedParrotParts,
  shopDressedParrotParts,
  className,
  centered,
}) => {
  const classNames = useMemo(
    () =>
      cn('parrot', className, {
        parrot__centered: centered,
      }),
    [className, centered]
  );

  useEffect(() => {
    getStudentSelectedParts();
  }, [getStudentSelectedParts]);

  const selectedParrotPartsImages = _mapValues(
    studentSelectedParrotParts,
    (part) => part.canvasImageUrl
  );

  return (
    <div style={{ height }} className={classNames}>
      {_map(
        {
          ...PARROT_PARTS,
          ...selectedParrotPartsImages,
          ...shopDressedParrotParts,
        },
        (src, name) => (
          <Image
            className={`parrot__part parrot__part--${name}`}
            src={src}
            key={name}
            alt={name}
          />
        )
      )}
    </div>
  );
};

Parrot.propTypes = {
  height: PropTypes.string.isRequired,
  getStudentSelectedParts: PropTypes.func.isRequired,
  studentSelectedParrotParts: PropTypes.shape({}).isRequired,
  shopDressedParrotParts: PropTypes.shape({}),
  className: PropTypes.string,
  centered: PropTypes.bool,
};

Parrot.defaultProps = {
  shopDressedParrotParts: {},
  className: '',
  centered: false,
};

const mapDispatchToProps = {
  ...actions.parrot,
};

const mapStateToProps = (state) => ({
  studentSelectedParrotParts: selectors.parrot.getStudentSelectedParts(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(Parrot);
