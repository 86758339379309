export default {
  type: {
    ERROR: 'error',
    SUCCESS: 'success',
    INFO: 'info',
  },
  duration: {
    INDEFINITE: 0,
    SHORT: 5000,
    LONG: 10000,
  },
};
