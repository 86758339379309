import React from 'react';
import PropTypes from 'prop-types';

import { useIntlMessages } from 'src/hooks';

import { Badge, Button, Image, Modal } from 'src/components/common';

import './index.scss';

const ConfirmBuyModal = ({ selectedItem, onBuy, onCancel }) => {
  const messages = useIntlMessages();

  return (
    <div id="confirm-buy-modal" className="confirm-buy-modal">
      <Modal
        className="confirm-buy-modal__modal"
        getRootDOMNode={() => document.getElementById('confirm-buy-modal')}
        show
      >
        <Badge
          className="confirm-buy-modal__badge"
          iconName="cart"
          type="success"
        />
        <Modal.Title className="confirm-buy-modal__title">
          {messages.page.shop.confirmBuyDialog.title}
        </Modal.Title>
        <Image src={selectedItem.thumbnailUrl} alt={selectedItem.label} />
        <div className="confirm-buy-modal__costs">
          {selectedItem.ParrotPartCostsCurrencies.map((cost) => (
            <div
              key={cost.currency.key}
              className="confirm-buy-modal__costs__display"
            >
              <Image
                className="confirm-buy-modal__costs__display__currency-img"
                alt={cost.currency.key}
                src={cost.currency.imageUrl}
              />
              <div className="confirm-buy-modal__costs__display__amount">
                {cost.amount}
              </div>
            </div>
          ))}
        </div>
        <div className="confirm-buy-modal__buttons">
          <Button className="confirm-buy-modal__buttons__btn" onClick={onBuy}>
            {messages.page.shop.confirmBuyDialog.buyButton}
          </Button>
          <Button
            className="confirm-buy-modal__buttons__btn"
            variant="tertiary"
            onClick={onCancel}
          >
            {messages.page.shop.confirmBuyDialog.cancelButton}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

ConfirmBuyModal.propTypes = {
  selectedItem: PropTypes.shape({
    label: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    ParrotPartCostsCurrencies: PropTypes.arrayOf(PropTypes.shape({}))
      .isRequired,
  }),
  onBuy: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ConfirmBuyModal.defaultProps = {
  selectedItem: null,
};

export default ConfirmBuyModal;
