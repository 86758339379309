import { actions, paths } from 'src/constants';

export default {
  getWallet: () => ({
    [actions.API_CALL]: {
      types: [
        actions.WALLET_GET_REQUEST,
        actions.WALLET_GET_SUCCESS,
        actions.WALLET_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.api.WALLET),
    },
  }),
};
