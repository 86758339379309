import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.subactivities.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getSubactivitiesData = createSelector(
  (state) => state.subactivities.data.subactivities,
  (subactivitiesData = []) => subactivitiesData
);
const getActivityData = createSelector(
  (state) => state.subactivities.data.activityData,
  (activityData = {}) => activityData
);

export default {
  getActivityData,
  getHasLoaded,
  getSubactivitiesData,
};
