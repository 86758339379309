import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Image } from 'src/components/common';

const GroupCardBackground = ({ className, size, image }) => {
  const groupCardBackground = [];
  for (let i = 0; i < size; i += 1) {
    groupCardBackground.push(
      <Image
        key={i}
        className={className}
        alt="memory-group-card"
        src={image}
      />
    );
  }
  return groupCardBackground;
};

GroupCardBackground.propTypes = {
  className: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
};

export default memo(GroupCardBackground);
