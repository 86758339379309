import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'react-redux';

import _map from 'lodash/map';
import _lowerCase from 'lodash/lowerCase';

import payments from './payments';

const PaymentContext = React.createContext(undefined);

const PaymentProvider = ({ children }) => {
  const store = useStore();

  const [paymentModules, setPaymentModules] = useState();

  useEffect(() => {
    // dynamically import only selected language payment
    // methods and set them as object in context value
    Promise.all(
      _map(payments, (paymentPath, paymentKey) =>
        import(`src/payments${paymentPath}`).then((paymentModule) => ({
          key: paymentKey,
          module: paymentModule.default,
        }))
      )
    ).then((loadedPayments) => {
      // set payments modules only once after all imports are finished
      const paymentsObject = loadedPayments.reduce((accumulator, payment) => {
        accumulator[payment.key] = payment.module;

        if (payment.module.reducer) {
          store.injectReducer(
            `${_lowerCase(payment.module.key)}`,
            payment.module.reducer
          );
        }

        return accumulator;
      }, {});

      setPaymentModules(paymentsObject);
    });
  }, [store]);

  return (
    <PaymentContext.Provider value={paymentModules}>
      {children}
    </PaymentContext.Provider>
  );
};

PaymentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PaymentProvider, PaymentContext };
