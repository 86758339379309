export default {
  ARTICULATION_GYMNASTICS: 'ARTICULATION_GYMNASTICS',
  TREASURE_HUNT: 'TREASURE_HUNT',
  PAINTING: 'PAINTING',
  CLIMB_STEP_ONE: 'CLIMB_STEP_ONE',
  DISCOVER_STEP_ONE: 'DISCOVER_STEP_ONE',
  FLASH_CARDS_STEP_ONE: 'FLASH_CARDS_STEP_ONE',
  FIND_WORDS_STEP_ONE: 'FIND_WORDS_STEP_ONE',
  MEMORY_STEP_ONE: 'MEMORY_STEP_ONE',
  CLIMB_STEP_ONE_EXTRA: 'CLIMB_STEP_ONE_EXTRA',
  DISCOVER_STEP_ONE_EXTRA: 'DISCOVER_STEP_ONE_EXTRA',
  FLASH_CARDS_STEP_ONE_EXTRA: 'FLASH_CARDS_STEP_ONE_EXTRA',
  FIND_WORDS_STEP_ONE_EXTRA: 'FIND_WORDS_STEP_ONE_EXTRA',
  MEMORY_STEP_ONE_EXTRA: 'MEMORY_STEP_ONE_EXTRA',
  CLIMB_STEP_TWO: 'CLIMB_STEP_TWO',
  CLIMB_STEP_TWO_EXTRA: 'CLIMB_STEP_TWO_EXTRA',
  DISCOVER_STEP_TWO: 'DISCOVER_STEP_TWO',
  DISCOVER_STEP_TWO_EXTRA: 'DISCOVER_STEP_TWO_EXTRA',
  FLASH_CARDS_STEP_TWO: 'FLASH_CARDS_STEP_TWO',
  FLASH_CARDS_STEP_TWO_EXTRA: 'FLASH_CARDS_STEP_TWO_EXTRA',
  FIND_WORDS_STEP_TWO: 'FIND_WORDS_STEP_TWO',
  FIND_WORDS_STEP_TWO_EXTRA: 'FIND_WORDS_STEP_TWO_EXTRA',
  MEMORY_STEP_TWO: 'MEMORY_STEP_TWO',
  MEMORY_STEP_TWO_EXTRA: 'MEMORY_STEP_TWO_EXTRA',
  CLIMB_STEP_THREE: 'CLIMB_STEP_THREE',
  DISCOVER_STEP_THREE: 'DISCOVER_STEP_THREE',
  DISCOVER_STEP_THREE_EXTRA: 'DISCOVER_STEP_THREE_EXTRA',
  FLASH_CARDS_STEP_THREE: 'FLASH_CARDS_STEP_THREE',
  FLASH_CARDS_STEP_THREE_EXTRA: 'FLASH_CARDS_STEP_THREE_EXTRA',
  FIND_WORDS_STEP_THREE: 'FIND_WORDS_STEP_THREE',
  FIND_WORDS_STEP_THREE_EXTRA: 'FIND_WORDS_STEP_THREE_EXTRA',
  MEMORY_STEP_THREE: 'MEMORY_STEP_THREE',
  MEMORY_STEP_THREE_EXTRA: 'MEMORY_STEP_THREE_EXTRA',
  CLIMB_STEP_THREE_EXTRA: 'CLIMB_STEP_THREE_EXTRA',
  WHEEL_OF_FORTUNE: 'WHEEL_OF_FORTUNE',
  REPLENISHMENT: 'REPLENISHMENT',
  READ_TOGETHER: 'READ_TOGETHER',
  READ_TOGETHER_BONUS: 'READ_TOGETHER_BONUS',
  READ_AND_THINK: 'READ_AND_THINK',
  READ_AND_THINK_BONUS: 'READ_AND_THINK_BONUS',
};
