import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import _map from 'lodash/map';
import _without from 'lodash/without';
import cn from 'classnames';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';

import { gameNames, statistics } from 'src/constants';
import { common } from 'src/utils';
import { useIntlMessages } from 'src/hooks';

import './index.scss';

const LineChartContainer = ({
  className,
  dataType,
  description,
  aspect,
  hideLegend,
  hideTooltip,
  statisticsData,
  width,
  xAxisData,
  xTickFormatter,
}) => {
  const messages = useIntlMessages();

  const lineChartClassNames = useMemo(
    () => cn('line-chart-container', className),
    [className]
  );

  const lines = useMemo(
    () =>
      _map(common.getParsedGameKeys(), (_gameValue, gameKey) => {
        if (
          !(gameKey in statisticsData) ||
          (dataType === statistics.analyseType.GAMES_PLAYED &&
            statisticsData[gameKey].played < 1)
        ) {
          return false;
        }

        return (
          <Line
            key={gameKey}
            dataKey={messages()[gameNames[gameKey]]}
            stroke={statistics.colors[gameKey]}
            activeDot={{ r: 8 }}
          />
        );
      }),
    [messages, dataType, statisticsData]
  );

  const lineChartData = useMemo(
    () =>
      xAxisData.map((days) => {
        const data = _without(
          _map(common.getParsedGameKeys(), (_gameValue, gameKey) => {
            if (!(gameKey in statisticsData)) {
              return false;
            }

            const [gameData] = _without(
              _map(statisticsData[gameKey], (_dateValue, dateKey) => {
                if (days !== dateKey) {
                  return false;
                }

                return dataType === statistics.analyseType.EARNED_POINTS
                  ? statisticsData[gameKey][dateKey].points
                  : statisticsData[gameKey][dateKey].played;
              }),
              false
            );

            if (!gameData) {
              return false;
            }

            return {
              [messages()[gameNames[gameKey]]]: gameData,
            };
          }),
          false
        ).reduce(
          (previousData, currentData) => ({
            ...previousData,
            ...currentData,
          }),
          {}
        );

        return {
          days,
          ...data,
        };
      }),
    [messages, statisticsData, xAxisData, dataType]
  );

  return (
    <div className={lineChartClassNames}>
      <div className="line-chart-container__description">{description}</div>
      <ResponsiveContainer width={width} aspect={aspect}>
        <LineChart data={lineChartData} syncId="anyId">
          <XAxis dataKey="days" tickFormatter={xTickFormatter} />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          {_without(lines, false)}
          {!hideTooltip && <Tooltip />}
          {!hideLegend && <Legend formatter={() => 'T'} />}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

LineChartContainer.propTypes = {
  className: PropTypes.string,
  dataType: PropTypes.string,
  description: PropTypes.string.isRequired,
  aspect: PropTypes.number.isRequired,
  hideLegend: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  statisticsData: PropTypes.shape({}).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xAxisData: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  xTickFormatter: PropTypes.func,
};

LineChartContainer.defaultProps = {
  className: '',
  dataType: 'earnedPoints',
  hideLegend: false,
  hideTooltip: false,
  width: '100%',
  xTickFormatter: undefined,
};

export default memo(LineChartContainer);
