import { createSelector } from 'reselect';

import { player } from 'src/utils';

const getHasLoaded = createSelector(
  (state) => state.student.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getStudent = createSelector(
  (state) => state.student.data,
  (student = {}) => student
);
const getIsSubmitting = createSelector(
  (state) => state.student.isSubmitting,
  (isSubmitting) => isSubmitting
);
const getSelectedStudent = createSelector(
  (state) => state.student.selectedStudent,
  (selectedStudent) => selectedStudent
);
const getSelectedStudentId = createSelector(
  () => player.getSelectedStudentId(),
  (selectedStudent) => selectedStudent
);

export default {
  getHasLoaded,
  getIsSubmitting,
  getStudent,
  getSelectedStudent,
  getSelectedStudentId,
};
