import React, { memo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { gameKeys, paths } from 'src/constants';
import Container from 'src/components/pages/Activities/ReadAndThink/Levels/Container';

const TIMEOUT_DURATION = 500;

const ReadAndThinkLevels = ({
  getLevelsByCollectionId,
  getSpeechSoundById,
  hasLoaded,
  collectionData,
  currency,
  levels,
  student,
  studentSpeechSound,
  updateFeedback,
}) => {
  const history = useHistory();
  const gameKey = gameKeys.READ_AND_THINK;
  const { groupKey, collectionId } = useParams();

  const handleCollectionEnd = () => {
    setTimeout(() => {
      history.push(
        paths.build(paths.READ_AND_THINK_BONUS_LEVELS, groupKey, collectionId)
      );
    }, TIMEOUT_DURATION);
  };

  const handleLevelAnswer = (levelId, isCorrect, currencyId) => {
    updateFeedback({ currencyId, gameKey, levelId, isCorrect });
  };

  useEffect(() => {
    getLevelsByCollectionId(collectionId);
  }, [getLevelsByCollectionId, collectionId]);

  useEffect(() => {
    getSpeechSoundById(student.SpeechSoundId);
  }, [getSpeechSoundById, student]);

  return (
    <Container
      collection={collectionData}
      currency={currency}
      handleCollectionEnd={handleCollectionEnd}
      handleLevelAnswer={handleLevelAnswer}
      hasLoaded={hasLoaded}
      items={levels}
      studentSpeechSound={studentSpeechSound}
    />
  );
};

ReadAndThinkLevels.propTypes = {
  getLevelsByCollectionId: PropTypes.func.isRequired,
  getSpeechSoundById: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  collectionData: PropTypes.shape({}).isRequired,
  currency: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  student: PropTypes.shape({
    SpeechSoundId: PropTypes.number.isRequired,
  }).isRequired,
  studentSpeechSound: PropTypes.shape({}).isRequired,
  updateFeedback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.readAndThinkLevels.getHasLoaded(state),
  collectionData: selectors.readAndThinkLevels.getCollectionData(state),
  currency: selectors.readAndThinkLevels.getCurrencyData(state),
  levels: selectors.readAndThinkLevels.getLevelsData(state),
  student: selectors.authentication.getStudent(state),
  studentSpeechSound: selectors.speechSounds.getSpeechSound(state),
});

const mapDispatchToProps = {
  ...actions.feedback,
  ...actions.readAndThink,
  ...actions.speechSounds,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ReadAndThinkLevels);
