import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useGoToLevel, usePagination } from 'src/hooks';
import { video } from 'src/constants';

import {
  ContentContainer,
  MenuFrame,
  PageLoader,
  Navbar,
  Image,
} from 'src/components/common';

import './index.scss';

const Container = ({
  currency,
  getCollections,
  hasLoaded,
  items,
  levelsPath,
  title,
}) => {
  const goToLevel = useGoToLevel(levelsPath);

  const {
    currentPage,
    pageCount,
    handlePreviousPage,
    handleNextPage,
    pageItems,
  } = usePagination(items);

  useEffect(() => {
    getCollections();
  }, [getCollections]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      <Navbar.GameNavbar
        currency={currency}
        videoName={video.NAME.ARTICULATION_GYMNASTICS}
        title={title}
      />
      <div className="articulation-gymnastics-collections-container">
        <MenuFrame
          title={title}
          currencyImageUrl={currency.imageUrl}
          currentPageNumber={currentPage}
          pagesCount={pageCount}
          onBack={handlePreviousPage}
          onForward={handleNextPage}
        >
          {pageItems?.map((collection) => (
            <ContentContainer
              key={collection.id}
              isLocked={collection.isLocked}
              onClick={() => goToLevel(collection.id)}
              showFeedback={collection.showFeedback}
              isFeedbackPositive={collection.isFeedbackPositive}
              variant="small"
            >
              <Image alt="thumbnail" src={collection.thumbnailUrl} />
            </ContentContainer>
          ))}
        </MenuFrame>
      </div>
    </PageLoader>
  );
};

Container.propTypes = {
  currency: PropTypes.shape({
    imageUrl: PropTypes.string,
  }).isRequired,
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  levelsPath: PropTypes.string.isRequired,
  student: PropTypes.shape({
    hasPassedArticulationGymnasticsEducation: PropTypes.bool.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default memo(Container);
