import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.articulationGymnasticsCollections.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getCollections = createSelector(
  (state) => state.articulationGymnasticsCollections.data.collections,
  (collections) => collections || []
);
const getCurrency = createSelector(
  (state) => state.articulationGymnasticsCollections.data.currency,
  (currency) => currency || {}
);
const getPageCount = createSelector(
  (state) => state.articulationGymnasticsCollections.data.pageCount,
  (pageCount) => pageCount || 0
);

export default {
  getCollections,
  getCurrency,
  getHasLoaded,
  getPageCount,
};
