import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useIntlMessages } from 'src/hooks';
import { subscriptions } from 'src/constants';
import { Localize } from 'src/components/common';

import './index.scss';

const SubscriptionCard = ({ className, subscription, durationKey }) => {
  const messages = useIntlMessages();

  const durationMessageKey = subscriptions.DURATION[durationKey];
  const duration = subscriptions.DURATION_VALUES[durationKey];
  const { description = '', label, price, fullPrice } = subscription;

  const containerClassNames = cn('subscription-offer', className);

  return (
    <div className={containerClassNames}>
      <div className="ribbon ribbon-top-right">
        <span />
      </div>
      <div className="subscription-offer__title">
        {label} {messages[durationMessageKey]}
      </div>
      <div className="subscription-offer__description">{description}</div>
      <div className="subscription-offer__prices">
        <div className="subscription-offer__price-container">
          <div className="subscription-offer__price-container__label">
            {messages.page.subscriptionSelection.card.regularPrice}
          </div>
          <div>
            <span className="subscription-offer__price-container__price">
              <Localize.Currency
                id="meta.currency"
                value={fullPrice * duration}
              />
            </span>
          </div>
        </div>
        <div className="subscription-offer__price-container">
          <div className="subscription-offer__price-container__label--highlighted">
            {messages.page.subscriptionSelection.card.discountedPrice}
            <span className="subscription-offer__price-container__asterisk">
              *
            </span>
          </div>
          <div>
            <span className="subscription-offer__price-container__price--highlighted">
              <Localize.Currency id="meta.currency" value={price * duration} />
            </span>
          </div>
        </div>
      </div>
      <div className="subscription-offer__discount">
        {
          messages({
            discount: <span>{Math.floor((1 - price / fullPrice) * 100)}%</span>,
          }).page.subscriptions.promo.discount
        }
      </div>
      {new Date(subscription.validTo).getTime() >= new Date().getTime() && (
        <div className="subscription-offer__note">
          {messages.page.subscriptions.promo.disclaimer}
        </div>
      )}
    </div>
  );
};

SubscriptionCard.propTypes = {
  className: PropTypes.string,
  durationKey: PropTypes.string.isRequired,
  subscription: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    fullPrice: PropTypes.number,
    validTo: PropTypes.string,
  }).isRequired,
};

SubscriptionCard.defaultProps = {
  className: '',
};

export default SubscriptionCard;
