import React from 'react';

import { actions } from 'src/store';
import { notifications, actions as actionConstants } from 'src/constants';
import { Localize } from 'src/components/common';

const FAILED_ACTIONS = [
  actionConstants.AUTHENTICATE_USER_FAILURE,
  actionConstants.SUBSCRIPTION_STATUS_GET_FAILURE,
  actionConstants.RESOLVE_USERS_FAILURE,
];

export default () => (store) => (next) => (action) => {
  if (
    FAILED_ACTIONS.includes(action.type) &&
    action.error?.response?.status === 500
  ) {
    localStorage.clear();
    store.dispatch(actions.users.clearUser());
    store.dispatch(
      actions.notifications.showNotification(
        <Localize id="error.feedback.somethingWentWrong" />,
        notifications.type.ERROR,
        notifications.duration.LONG
      )
    );
  }

  return next(action);
};
