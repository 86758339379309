export default {
  BASIC_INFORMATION: 'BASIC_INFORMATION',
  INITIAL_EDUCATION: 'INITIAL_EDUCATION',
  SOUNDS: 'SOUNDS',
  SOUNDS_K: 'SOUNDS_K',
  SOUNDS_G: 'SOUNDS_G',
  SOUNDS_L: 'SOUNDS_L',
  SOUNDS_LJ: 'SOUNDS_LJ',
  SOUNDS_R: 'SOUNDS_R',
  SOUNDS_S: 'SOUNDS_S',
  SOUNDS_Z: 'SOUNDS_Z',
  SOUNDS_C: 'SOUNDS_C',
  SOUNDS_Š: 'SOUNDS_Š',
  SOUNDS_Ž: 'SOUNDS_Ž',
  SOUNDS_Č: 'SOUNDS_Č',
  ACTIVITIES_AND_GRADES: 'ACTIVITIES_AND_GRADES',
  ICONS: 'ICONS',
  ARTICULATION_GYMNASTICS: 'ARTICULATION_GYMNASTICS',
  TREASURE_HUNT: 'TREASURE_HUNT',
  PAINTING: 'PAINTING',
  CLIMB: 'CLIMB',
  DISCOVER: 'DISCOVER',
  FLASH_CARDS: 'FLASH_CARDS',
  FIND_WORDS: 'FIND_WORDS',
  MEMORY: 'MEMORY',
  WHEEL_OF_FORTUNE: 'WHEEL_OF_FORTUNE',
  REPLENISHMENT: 'REPLENISHMENT',
  READ_TOGETHER: 'READ_TOGETHER',
  READ_AND_THINK: 'READ_AND_THINK',
  SHOP: 'SHOP',
  SPEECH_THERAPISTS_HELP: 'SPEECH_THERAPISTS_HELP',
};
