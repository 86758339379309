import { actions, paths } from 'src/constants';

export default {
  activateMagicLink: (magicToken) => ({
    [actions.API_CALL]: {
      types: [
        actions.MAGIC_LINK_REQUEST,
        actions.MAGIC_LINK_SUCCESS,
        actions.MAGIC_LINK_FAILURE,
      ],
      promise: (client) => client.post(paths.api.MAGIC_LINK, { magicToken }),
    },
  }),
};
