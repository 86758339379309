import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const FlashCardsStepTwoCollections = ({
  flashCardsStepTwoCollections,
  flashCardsStepTwoCurrency,
  getCollections,
  hasLoaded,
  pageCount,
  student,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="flash-cards-collections-container--step-two"
      collectionsPath={paths.FLASH_CARDS_STEP_TWO}
      currency={flashCardsStepTwoCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={flashCardsStepTwoCollections}
      levelsPath={paths.FLASH_CARDS_STEP_TWO_LEVELS}
      pageCount={pageCount}
      student={student}
      title={messages.game.flashCards}
    />
  );
};

FlashCardsStepTwoCollections.propTypes = {
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  flashCardsStepTwoCollections: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  flashCardsStepTwoCurrency: PropTypes.shape({}).isRequired,
  pageCount: PropTypes.number.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.flashCardsStepTwoCollections.getHasLoaded(state),
  flashCardsStepTwoCurrency: selectors.flashCardsStepTwoCollections.getCurrencyData(
    state
  ),
  flashCardsStepTwoCollections: selectors.flashCardsStepTwoCollections.getCollectionsData(
    state
  ),
  pageCount: selectors.flashCardsStepTwoCollections.getPageCount(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.flashCardsStepTwo,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(FlashCardsStepTwoCollections);
