import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { player } from 'src/utils';
import { actions } from 'src/store';
import { video as videoConstants } from 'src/constants';
import config from 'src/config.json';

import Coin from 'src/components/common/Coin';
import Title from '../Title/TitlePortal';
import Actions from '../Actions';
import EducationAction from '../EducationAction';

const GameNavbar = ({
  currency,
  videoName,
  videoLabel,
  title,
  updateStudentById,
}) => {
  const handleVideoEnded = useCallback(() => {
    updateStudentById(player.getSelectedStudentId(), {
      [videoConstants.EDUCATION[videoName]]: true,
    });
  }, [videoName, updateStudentById]);

  const videos = useMemo(
    () => [
      {
        ...config.videos.activity.find(
          (video) => video.videoName === videoName
        ),
        label: videoLabel || title,
      },
    ],
    [title, videoName, videoLabel]
  );

  return (
    <>
      <Title title={title} icon={<Coin currency={currency} />} />
      <Actions>
        <EducationAction videos={videos} handleVideoEnded={handleVideoEnded} />
      </Actions>
    </>
  );
};

GameNavbar.propTypes = {
  currency: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  updateStudentById: PropTypes.func.isRequired,
  videoName: PropTypes.string.isRequired,
  videoLabel: PropTypes.string,
};

GameNavbar.defaultProps = {
  videoLabel: '',
};

const mapDispatchToProps = {
  ...actions.students,
};

export default compose(connect(null, mapDispatchToProps), memo)(GameNavbar);
