import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { paths } from 'src/constants';

import accessories from 'src/images/store/accessories.png';
import glasses from 'src/images/store/glasses.png';
import hat from 'src/images/store/hat.png';
import wardrobe from 'src/images/store/wardrobe.png';

import ShopCategoriesMenuItem from '../ShopCategoriesMenuItem';

import './index.scss';

const MENU_ITEMS = [
  {
    key: 'hats',
    img: hat,
  },
  {
    key: 'glasses',
    img: glasses,
  },
  {
    key: 'accessories',
    img: accessories,
  },
  {
    key: 'wardrobe',
    img: wardrobe,
  },
];

const ShopCategoriesMenu = ({ activeCategory }) => {
  const history = useHistory();

  const redirect = useCallback(
    (categoryLabel) => {
      if (categoryLabel !== activeCategory) {
        history.push(`${paths.SHOP}?category=${categoryLabel}`);
      }
    },
    [activeCategory, history]
  );

  return (
    <div className="store-menu">
      {MENU_ITEMS.map((item) => (
        <ShopCategoriesMenuItem
          key={item.key}
          img={item.img}
          onClick={() => redirect(item.key)}
          selected={activeCategory === item.key}
        />
      ))}
    </div>
  );
};

ShopCategoriesMenu.propTypes = {
  activeCategory: PropTypes.string.isRequired,
};

export default memo(ShopCategoriesMenu);
