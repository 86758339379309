import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const ClimbStepOneExtraCollections = ({
  climbStepOneExtraCollections,
  climbStepOneExtraCurrency,
  getCollections,
  hasLoaded,
  student,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="climb-collections-container--step-one-extra"
      currency={climbStepOneExtraCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={climbStepOneExtraCollections}
      levelsPath={paths.CLIMB_STEP_ONE_EXTRA_LEVELS}
      student={student}
      title={messages.subactivity.climbStepOneExtra}
    />
  );
};

ClimbStepOneExtraCollections.propTypes = {
  climbStepOneExtraCollections: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  climbStepOneExtraCurrency: PropTypes.shape({}).isRequired,
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.climbStepOneExtraCollections.getHasLoaded(state),
  climbStepOneExtraCollections: selectors.climbStepOneExtraCollections.getCollectionsData(
    state
  ),
  climbStepOneExtraCurrency: selectors.climbStepOneExtraCollections.getCurrencyData(
    state
  ),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.climbStepOneExtra,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ClimbStepOneExtraCollections);
