import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Form, PasswordInput } from 'src/components/common';

import validationSchema from './validationSchema';

import './index.scss';

const FormContainer = ({ resetUserPassword }) => {
  const messages = useIntlMessages();
  return (
    <div className="reset-password-container">
      <Form
        submitBtnLabel={messages.label.changePassword}
        onSubmit={resetUserPassword}
        validationSchema={validationSchema}
        formClassName="reset-password-container__form"
      >
        <PasswordInput
          name="password"
          label={messages.label.newPassword}
          iconClassName="reset-password-container__form__password-input-icon"
          required
        />
        <PasswordInput
          name="repeatPassword"
          label={messages.label.repeatPassword}
          iconClassName="reset-password-container__form__password-input-icon"
          required
        />
      </Form>
      <div className="reset-password-container__register-redirect">
        <span>
          {messages.label.dontHaveAccount}{' '}
          <Link to={paths.LOGIN}>{messages.button.register}</Link>
        </span>
      </div>
    </div>
  );
};

FormContainer.propTypes = {
  resetUserPassword: PropTypes.func.isRequired,
};

export default memo(FormContainer);
