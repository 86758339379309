import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useQuery } from 'src/hooks';
import { selectors } from 'src/store';

import SubscriptionPromo from '../SubscriptionPromo';
import SubscriptionWizard from '../SubscriptionWizard';

const Subscriptions = ({ specialOfferSubscriptions, ...props }) => {
  const query = useQuery();

  if (specialOfferSubscriptions.length > 0 && !query.get('regular')) {
    return <SubscriptionPromo {...props} />;
  }

  return <SubscriptionWizard {...props} />;
};

Subscriptions.propTypes = {
  specialOfferSubscriptions: PropTypes.arrayOf(PropTypes.shape({})),
};

Subscriptions.defaultProps = {
  specialOfferSubscriptions: [],
};

const mapStateToProps = (state) => ({
  specialOfferSubscriptions: selectors.subscriptions.getSpecialOfferSubscriptions(
    state
  ),
});

export default connect(mapStateToProps)(Subscriptions);
