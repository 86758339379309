import { common } from 'src/utils';

const baseUrl = common.getMediaContentUrl();

export default {
  HAPPY_SOUND_URL: `${baseUrl}common/feedbackSounds/happySound.mp3`,
  BAD_SOUND_URL: `${baseUrl}common/feedbackSounds/badSound.wav`,
  LEVEL_END_SOUND_URL: `${baseUrl}common/feedbackSounds/levelEndSound.mp3`,
  COIN_COLLECT_SOUND_URL: `${baseUrl}common/feedbackSounds/coinCollectSound.mp3`,
  SHOP_SOUND_URL: `${baseUrl}common/feedbackSounds/shopSound.mp3`,
};
