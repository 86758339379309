import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { paths } from 'src/constants';
import { useNavigateBack } from 'src/hooks';
import { actions, selectors } from 'src/store';

const pathLeftovers = ['subactivity', 'collections', 'student', 'settings'];

const useLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = useCallback(() => {
    localStorage.clear();
    dispatch(actions.users.clearUser());
    history.push(paths.LOGIN);
  }, [dispatch, history]);

  return { logout };
};

const useNavigateBackForButton = () => {
  const isSpeechTherapist = useSelector(
    selectors.authentication.getIsSpeechTherapist
  );
  const navigateBack = useNavigateBack({ pathLeftovers, isSpeechTherapist });

  return navigateBack;
};

export default { useLogout, useNavigateBackForButton };
