import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';

import { useWizard } from 'src/hooks';
import { video as videoUtil } from 'src/utils';

import ArrowPagination from '../../../ArrowPagination';

import Wizard from '../Wizard';

import './index.scss';
import Page from '../../../Page/Page';

const Index = ({ handleVideoEnded, videos, markPlayed }) => {
  const steps = useMemo(
    () =>
      videos.map((video) =>
        videoUtil.formatVideo(video, handleVideoEnded, markPlayed)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [videos, handleVideoEnded, markPlayed]
  );

  const wizardProps = useWizard(steps);

  const content = (
    <Wizard {...wizardProps}>
      <Page.Body>
        <ArrowPagination
          pageCount={steps.length}
          activePage={wizardProps.activeStepIndex + 1}
          next={wizardProps.nextStep}
          prev={wizardProps.prevStep}
          collapse
        >
          {wizardProps.Component}
        </ArrowPagination>
      </Page.Body>
    </Wizard>
  );

  return content;
};

Index.propTypes = {
  handleVideoEnded: PropTypes.func.isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      videoName: PropTypes.string,
      description: PropTypes.node,
    })
  ).isRequired,
  markPlayed: PropTypes.func,
};

Index.defaultProps = {
  markPlayed: null,
};

export default memo(Index, (prevProps, nextProps) => {
  const { markPlayed: prevMarkPlayed, ...remainingPrevProps } = prevProps;
  const { markPlayed: nextMarkPlayed, ...remainingNextProps } = nextProps;

  return _isEqual(remainingPrevProps, remainingNextProps);
});
