import { useEffect, useRef } from 'react';

import _defer from 'lodash/defer';

export default () => {
  const ref = useRef(false);

  useEffect(() => {
    _defer(() => {
      ref.current = true;
    });
  }, []);

  return ref.current;
};
