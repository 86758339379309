import React from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import { useAudio } from 'src/hooks';
import { media } from 'src/utils';
import { Badge, Icon } from 'src/components/common';

const SoundBadge = ({ isLeft, speechSound }) => {
  const [playAudio] = useAudio();
  const playAudioHandle = () => {
    if (process.env.REACT_APP_LITERAL_LANGUAGE === 'true') {
      return;
    }
    const audioUrl = media.getSpeechSoundAudioUrl(speechSound);
    playAudio(audioUrl);
  };

  return (
    <Badge
      size={isMobile ? 'normal' : 'large'}
      type={isLeft ? 'orange' : 'success'}
      onClick={playAudioHandle}
    >
      {process.env.REACT_APP_LITERAL_LANGUAGE === 'true' ? (
        speechSound
      ) : (
        <Icon name="sound" />
      )}
    </Badge>
  );
};

SoundBadge.propTypes = {
  isLeft: PropTypes.bool,
  speechSound: PropTypes.string.isRequired,
};

SoundBadge.defaultProps = {
  isLeft: false,
};

export default SoundBadge;
