import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { useIntlMessages } from 'src/hooks';

import { Modal, PasswordInput, Form, Image } from 'src/components/common';
import loader from 'src/videos/loader.gif';

import validationSchema from './validationSchema';

const PasswordInputModal = ({ show, close, onSubmit, isLoading }) => {
  const messages = useIntlMessages();

  return (
    <Modal
      className="password"
      show={show}
      onClose={close}
      onOutsideClick={close}
    >
      <Modal.Title>{messages.modal.password.title}</Modal.Title>
      <br />
      <Modal.Description>
        {isLoading ? (
          <Image src={loader} alt="Loader" />
        ) : (
          <Form
            submitBtnLabel={messages.button.login}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            formClassName="login-form-container__form"
          >
            <PasswordInput
              name="password"
              placeholder={messages.input.placeholder.password}
              className="login-form-container__form__input"
            />
          </Form>
        )}
      </Modal.Description>
    </Modal>
  );
};

PasswordInputModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default memo(PasswordInputModal);
