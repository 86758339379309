import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const Title = ({ className, text }) => {
  const titleClassName = cn('title-container', className);

  return (
    <div className={titleClassName}>
      <div className="title-container__title">{text}</div>
    </div>
  );
};

Title.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

Title.defaultProps = {
  className: '',
};

export default Title;
