import { createSelector } from 'reselect';

const getIsLoading = createSelector(
  (state) => state.subscriptions.isLoading,
  (hasLoaded) => hasLoaded
);
const getIsSubmitting = createSelector(
  (state) => state.subscriptions.isSubmitting,
  (isSubmitting) => isSubmitting
);
const getHasLoaded = createSelector(
  (state) => state.subscriptions.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getSubscriptions = createSelector(
  (state) =>
    state.subscriptions.data.filter(({ specialOffer }) => !specialOffer),
  (subscriptions = []) => subscriptions
);
const getSpecialOfferSubscriptions = createSelector(
  (state) =>
    state.subscriptions.data.filter(({ specialOffer }) => specialOffer),
  (subscriptions) => subscriptions
);

export default {
  getHasLoaded,
  getIsLoading,
  getIsSubmitting,
  getSubscriptions,
  getSpecialOfferSubscriptions,
};
