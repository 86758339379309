import StepThree from './StepThree';
import StepTwo from './StepTwo';
import StepOne from './StepOne';
import StepOneExtra from './StepOneExtra';
import StepTwoExtra from './StepTwoExtra';
import StepThreeExtra from './StepThreeExtra';

export default {
  StepThree,
  StepOne,
  StepTwo,
  StepOneExtra,
  StepTwoExtra,
  StepThreeExtra,
};
