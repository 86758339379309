import {
  Actions,
  EducationAction,
  GameNavbar,
  Navbar,
  TitlePortal,
  SettingsMenu,
  SettingsMenuItem,
  FreeTrialAction,
} from './components';

Navbar.Actions = Actions;
Navbar.EducationAction = EducationAction;
Navbar.FreeTrialAction = FreeTrialAction;
Navbar.GameNavbar = GameNavbar;
Navbar.Title = TitlePortal;
Navbar.SettingsMenu = SettingsMenu;
Navbar.SettingsMenuItem = SettingsMenuItem;

export default Navbar;
