import StepOne from './StepOne';
import StepOneExtra from './StepOneExtra';
import StepThree from './StepThree';
import StepThreeExtra from './StepThreeExtra';
import StepTwo from './StepTwo';
import StepTwoExtra from './StepTwoExtra';

export default {
  StepOne,
  StepOneExtra,
  StepThree,
  StepThreeExtra,
  StepTwo,
  StepTwoExtra,
};
