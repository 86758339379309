import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { useIntlMessages, useRadioButtons } from 'src/hooks';

import { Modal, Form, Image, Input, RadioButton } from 'src/components/common';
import loader from 'src/videos/loader.gif';

import './index.scss';

import parent from 'src/images/parent.jpg';
import therapist from 'src/images/therapist.jpg';

import validationSchema from './validationSchema';

const UserRoleModal = ({ show, close, onSubmit, isLoading }) => {
  const messages = useIntlMessages();
  const userRoles = [
    {
      name: messages.button.parent,
      key: 'parent',
      imgSrc: parent,
    },
    {
      name: messages.button.therapist,
      key: 'therapist',
      imgSrc: therapist,
    },
  ];

  const { selectedValue, radioButtonProps } = useRadioButtons(
    'user-role-select'
  );

  return (
    <Modal
      className="user-role"
      show={show}
      onClose={close}
      onOutsideClick={close}
    >
      <Modal.Title>{messages.modal.selectRole.title}</Modal.Title>
      <Modal.Description>
        {isLoading ? (
          <Image src={loader} alt="Loader" />
        ) : (
          <Form
            submitBtnLabel={messages.button.register}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <div className="role-form-container">
              {userRoles.map((role) => (
                <RadioButton
                  checked={selectedValue === role.key}
                  key={role.key}
                  value={role.key}
                  {...radioButtonProps}
                  className="role-selection__radio-btn-container__radio-button"
                  imgSrc={role.imgSrc}
                >
                  <div className="role-selection__radio-btn-container__label-container">
                    {role.name}
                  </div>
                </RadioButton>
              ))}
            </div>
            <Input
              required
              label={messages.label.termsOfService}
              type="checkbox"
              name="gdpr"
            />
          </Form>
        )}
      </Modal.Description>
    </Modal>
  );
};

UserRoleModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default memo(UserRoleModal);
