import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import cn from 'classnames';

import './index.scss';

const Description = ({ className, children }) => {
  const descriptionClassNames = useMemo(
    () => cn('modal-description', className),
    [className]
  );

  return <div className={descriptionClassNames}>{children}</div>;
};

Description.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Description.defaultProps = {
  className: '',
};

export default memo(Description);
