import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Page from 'src/components/common/Page';
import Video from 'src/components/common/Video';
import ListenAgainBadge from '../ListenAgainBadge';

import './index.scss';

const FeedbackPreviewVideo = ({ onEnded, ...rest }) => {
  const [isVideoAutoPlayed, setIsVideoAutoPlayed] = useState(false);

  const handleVideoEnded = useCallback(() => {
    setIsVideoAutoPlayed(false);
    onEnded();
  }, [onEnded]);

  const videoClassNames = cn('feedback-video', {
    'feedback-video--opacity': !isVideoAutoPlayed,
  });

  return (
    <Page.Body className="feedback-video-container">
      {!isVideoAutoPlayed && (
        <div className="feedback-video-container__listen-again">
          <ListenAgainBadge
            handleListenAgain={() => setIsVideoAutoPlayed(true)}
          />
        </div>
      )}
      <div className={videoClassNames}>
        <Video
          onEnded={handleVideoEnded}
          autoPlay={isVideoAutoPlayed}
          {...rest}
        />
      </div>
    </Page.Body>
  );
};

FeedbackPreviewVideo.propTypes = {
  onEnded: PropTypes.func,
};

FeedbackPreviewVideo.defaultProps = {
  onEnded: () => {},
};

export default memo(FeedbackPreviewVideo);
