import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { useIntlMessages } from 'src/hooks';

import {
  InfoField,
  OverlayLoader,
  Page,
  PageLoader,
  Title,
  SwitchSetting,
} from 'src/components/common';

import './index.scss';

const SoundSettings = ({
  isSubmitting,
  student,
  updateStudentById,
  hasLoaded,
}) => {
  const messages = useIntlMessages();

  const handleErrorSoundSettingClick = useCallback(() => {
    updateStudentById(student.id, {
      hasEnabledErrorSound: !student.hasEnabledErrorSound,
    });
  }, [student.hasEnabledErrorSound, student.id, updateStudentById]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  const content = (
    <Page containerClassName="sound-settings-container">
      <Page.Header>
        <Title text={messages.page.sound.title} />
      </Page.Header>
      <Page.Body>
        {isSubmitting && <OverlayLoader />}
        <InfoField
          label={messages.label.errorSound}
          value={
            <SwitchSetting
              enabled={student.hasEnabledErrorSound}
              onClick={handleErrorSoundSettingClick}
            />
          }
          valueClassName="sound-settings-container--transparent"
        />
      </Page.Body>
    </Page>
  );

  return content;
};

SoundSettings.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  student: PropTypes.shape({
    id: PropTypes.number.isRequired,
    hasEnabledErrorSound: PropTypes.bool.isRequired,
  }).isRequired,
  updateStudentById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.authentication.getHasLoaded(state),
  isSpeechTherapist: selectors.authentication.getIsSpeechTherapist(state),
  isSubmitting: selectors.student.getIsSubmitting(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.students,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(SoundSettings);
