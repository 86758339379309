import { useMemo, useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import _chunk from 'lodash/chunk';

const INITIAL_PAGE = 1;
const MOBILE_PAGE_SIZE = 6;
const DESKTOP_PAGE_SIZE = 8;

const usePagination = (items, pageSize) => {
  const PAGE_SIZE = isMobile ? MOBILE_PAGE_SIZE : DESKTOP_PAGE_SIZE;

  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);

  useEffect(() => {
    const playedItemNumber =
      items.map((item) => item.showFeedback).lastIndexOf(true) + 2;

    if (playedItemNumber > 0) {
      setCurrentPage(Math.ceil(playedItemNumber / PAGE_SIZE));
    }
  }, [items, PAGE_SIZE]);

  const chunkedItems = useMemo(
    () => _chunk(items || [], pageSize || PAGE_SIZE),
    [items, pageSize, PAGE_SIZE]
  );

  const pageCount = chunkedItems.length;

  const handleNextPage = () => setCurrentPage((page) => page + 1);
  const handlePreviousPage = () => setCurrentPage((page) => page - 1);

  return {
    currentPage,
    pageCount,
    handlePreviousPage,
    handleNextPage,
    pageItems: chunkedItems[currentPage - 1],
  };
};

export default usePagination;
