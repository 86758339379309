import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const FindWordsStepThreeCollections = ({
  findWordsStepThreeCollections,
  findWordsStepThreeCurrency,
  getCollections,
  hasLoaded,
  pageCount,
  student,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="find-words-collections-container--step-three"
      collectionsPath={paths.FIND_WORDS_STEP_THREE}
      currency={findWordsStepThreeCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={findWordsStepThreeCollections}
      levelsPath={paths.FIND_WORDS_STEP_THREE_LEVELS}
      pageCount={pageCount}
      student={student}
      title={messages.game.findWords}
    />
  );
};

FindWordsStepThreeCollections.propTypes = {
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  findWordsStepThreeCollections: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  findWordsStepThreeCurrency: PropTypes.shape({}).isRequired,
  pageCount: PropTypes.number.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.findWordsStepThreeCollections.getHasLoaded(state),
  findWordsStepThreeCurrency: selectors.findWordsStepThreeCollections.getCurrencyData(
    state
  ),
  findWordsStepThreeCollections: selectors.findWordsStepThreeCollections.getCollectionsData(
    state
  ),
  pageCount: selectors.findWordsStepThreeCollections.getPageCount(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.findWordsStepThree,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(FindWordsStepThreeCollections);
