import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.flashCardsStepThreeCollections.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getCurrencyData = createSelector(
  (state) => state.flashCardsStepThreeCollections.data.currency,
  (currency = {}) => currency
);
const getCollectionsData = createSelector(
  (state) =>
    state.flashCardsStepThreeCollections.data.flashCardsStepThreeCollections,
  (collections = []) => collections
);
const getPageCount = createSelector(
  (state) => state.flashCardsStepThreeCollections.data.pageCount,
  (pageCount) => pageCount || 0
);

export default {
  getCollectionsData,
  getCurrencyData,
  getHasLoaded,
  getPageCount,
};
