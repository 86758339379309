import { actions as actionConstants, checkpoints } from 'src/constants';
import { actions } from 'src/store';

const tooltipCheckpoints = {
  actor: Object.values(checkpoints).filter(
    (item) =>
      item !== checkpoints.FIRST_TIME_ON_MAP &&
      item !== checkpoints.FIRST_TIME_OVER_20_POINTS &&
      item !== checkpoints.FIRST_TIME_CAN_BUY
  ),
  firstIsland: [checkpoints.FIRST_TIME_ON_MAP],
  shop: [checkpoints.FIRST_TIME_OVER_20_POINTS],
};

const getCheckpointTooltip = (checkpoint) => {
  const tooltipTypes = ['actor', 'firstIsland', 'shop'];

  return tooltipTypes.find((key) =>
    tooltipCheckpoints[key].includes(checkpoint)
  );
};

export default () => (store) => (next) => (action) => {
  if (action.type === actionConstants.USER_CHECKPOINT) {
    const checkpoint = action.payload;
    const tooltip = getCheckpointTooltip(checkpoint);

    if (tooltip) {
      store.dispatch(actions.tooltips.setTooltipVisibility(tooltip, true));
    }
  }

  return next(action);
};
