/* eslint-disable camelcase */
import messages_enUs from 'src/constants/translations_en-us.json';
import messages_enUk from 'src/constants/translations_en-uk.json';
import messages_pl from 'src/constants/translations_pl.json';
import messages_hr from 'src/constants/translations_hr.json';

const getMessages = () => {
  const messages = {
    hr: messages_hr,
    pl: messages_pl,
    'en-us': messages_enUs,
    'en-uk': messages_enUk,
  };

  return messages[process.env.REACT_APP_LANGUAGE];
};

export default { getMessages };
