import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import _get from 'lodash/get';
import cn from 'classnames';
import { Localize } from '@kokolingo/localisation';
import { useFormContext } from 'react-hook-form';

import Label from '../Label';

import './index.scss';

const Dropdown = ({
  classNames,
  defaultValue,
  id,
  label,
  labelClassName,
  name,
  onChange,
  options,
  required,
  placeholder,
}) => {
  const { errors, register } = useFormContext();
  const error = _get(errors, `${name}.message`);

  const dropDownClassNames = useMemo(
    () =>
      cn(classNames, {
        'dropdown-container--error': error,
      }),
    [classNames, error]
  );

  const labelClassNames = useMemo(
    () => cn('dropdown-container__label', labelClassName),
    [labelClassName]
  );

  const errorMessageClassName = useMemo(
    () =>
      cn('dropdown-error-message', {
        'dropdown-error-message--with-label': label,
      }),
    [label]
  );

  return (
    <div className="dropdown-container">
      <Label
        htmlFor={id || name}
        label={label}
        className={labelClassNames}
        required={required}
      />
      <select
        className={dropDownClassNames}
        id={id || name}
        name={name}
        ref={register}
        onChange={onChange}
      >
        {placeholder && (
          <option key={placeholder} value={placeholder}>
            {placeholder}
          </option>
        )}
        {options.map(({ key, value, disabled }) => (
          <option
            disabled={disabled}
            selected={value === defaultValue}
            key={key}
            value={key}
          >
            {value}
          </option>
        ))}
      </select>
      <div className={errorMessageClassName}>
        {error && <Localize id={error} />}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  classNames: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ id: PropTypes.string }),
      ]),
    })
  ).isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  required: PropTypes.bool,
};

Dropdown.defaultProps = {
  classNames: '',
  id: '',
  placeholder: '',
  defaultValue: '',
  label: '',
  labelClassName: '',
  onChange: () => {},
  required: false,
};

export default memo(Dropdown);
