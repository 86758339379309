import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { selectors } from 'src/store';
import { subscriptions } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Page, PageLoader } from 'src/components/common';

import './index.scss';

const LicenceStatus = ({ hasLoaded, user }) => {
  const messages = useIntlMessages();

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      <Page
        title={messages.page.licence.title}
        containerClassName="licence-status-page"
      >
        <div className="licence-status-page__info-container">
          <p>
            {
              messages({
                subscription: subscriptions[user.subscriptionKey].LABEL || '-',
              }).page.licence.label.licenceSubscription
            }
          </p>
          <p>
            {!user.subscriptionExpirationDate
              ? messages.page.licence.label.subscriptionStatusTrial
              : messages({
                  totalUsedStudentAccounts: user.totalUsedStudentAccounts - 1,
                  unusedStudentAccounts:
                    user.studentAccountsCapacity -
                    user.totalUsedStudentAccounts,
                  subscription: subscriptions[user.subscriptionKey].LABEL,
                }).page.licence.label.subscriptionStatus}
          </p>
          <br />
          <p>{messages.page.licence.label.licenceValidForever}</p>
          <p
            dangerouslySetInnerHTML={{
              __html: !user.subscriptionExpirationDate
                ? messages.page.licence.label.purchaseDescription
                : messages.page.licence.label.purchaseDescriptionTrial,
            }}
          />
          <br />
          <p>{messages.page.licence.label.extraInformation}</p>
        </div>
      </Page>
    </PageLoader>
  );
};

LicenceStatus.propTypes = {
  hasLoaded: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    studentAccountsCapacity: PropTypes.number,
    subscriptionExpirationDate: PropTypes.string,
    subscriptionKey: PropTypes.string,
    totalUsedStudentAccounts: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.authentication.getHasLoaded(state),
  user: selectors.authentication.getUser(state),
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(LicenceStatus);
