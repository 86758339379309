import PropTypes from 'prop-types';
import React, {
  memo,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';

import { common } from 'src/utils';

import Badge from '../Badge';
import poster16x9 from './poster16x9.png';

import './index.scss';

const Video = ({
  autoPlay,
  className,
  hideProgress,
  onEnded,
  onPause,
  onPlay,
  src,
  width,
  height,
  markPlayed,
}) => {
  const videoRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [playedPercent, setPlayedPercent] = useState(0);

  const sizes = useMemo(() => ({ width, height }), [width, height]);

  // pause all videos on page
  useEffect(() => {
    const pageVideos = document.getElementsByTagName('video');

    for (let i = 0; i < pageVideos.length; i += 1) {
      pageVideos[i].pause();
    }
  }, []);

  // mark video as played
  useEffect(() => {
    if (markPlayed) {
      if (autoPlay) {
        markPlayed();
      }

      if (!autoPlay && isPlaying) {
        markPlayed();
      }
    }
  }, [autoPlay, markPlayed, isPlaying]);

  useEffect(() => {
    setIsPlaying(autoPlay);

    videoRef.current.load();
    videoRef.current.currentTime = 0;

    if (autoPlay) {
      videoRef.current.play().catch(() => {
        setIsPlaying(false);
      });
    }
  }, [autoPlay, src]);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlayedPercent(
        (videoRef.current.currentTime / videoRef.current.duration) * 100
      );
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const handlePlayPause = useCallback(() => {
    if (isPlaying) {
      videoRef.current.pause();
      onPause();
    }

    if (!isPlaying) {
      videoRef.current.play();
      onPlay();
    }

    setIsPlaying(!isPlaying);
  }, [onPause, onPlay, isPlaying]);

  const handleEnded = useCallback(() => {
    setIsPlaying(false);
    onEnded();
  }, [onEnded]);

  const handleTimelineClick = useCallback((e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const zoomPercent =
      document.querySelector('.view-provider').style.zoom.replace('%', '') /
      100;
    const timelineWidthClick = e.clientX - rect.x * zoomPercent;

    videoRef.current.currentTime =
      videoRef.current.duration *
        (timelineWidthClick / (rect.width * zoomPercent)) || 0;
  }, []);

  const classNames = cn('video', className, {
    video__hide__timeline: hideProgress,
  });

  const remainingTime =
    (videoRef?.current?.duration ?? 0) - (videoRef?.current?.currentTime ?? 0);

  return (
    <div className={classNames} key={src} style={sizes}>
      <div className="video__shell">
        <video
          ref={videoRef}
          onClick={handlePlayPause}
          onEnded={handleEnded}
          autoPlay={autoPlay}
          poster={poster16x9}
          onContextMenu={(event) => event.preventDefault()}
        >
          <source src={src} type="video/mp4" />
        </video>
        {!hideProgress && (
          <div className="video__timeline-container">
            <div className="video__timeline-container__controls">
              <Badge
                size="small"
                iconSize={isMobile ? 13 : 20}
                key={isPlaying ? 'pause' : 'play'}
                iconName={isPlaying ? 'pause' : 'play'}
                onClick={handlePlayPause}
              />
            </div>
            <div className="video__timeline-container__time">
              <span>
                {common.getFormattedCurrentTime(
                  common.getCurrentTime(
                    Number.isNaN(remainingTime) ? 0 : Math.floor(remainingTime)
                  )
                )}
              </span>
            </div>
            <div
              className="video__timeline-container__timeline"
              onClick={handleTimelineClick}
              role="button"
              tabIndex="-1"
            >
              <div
                className="video__timeline-container__timeline__current-time"
                style={{ width: `${playedPercent}%` }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Video.propTypes = {
  autoPlay: PropTypes.bool,
  className: PropTypes.string,
  hideProgress: PropTypes.bool,
  onEnded: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  markPlayed: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  src: PropTypes.string.isRequired,
};

Video.defaultProps = {
  autoPlay: false,
  className: '',
  hideProgress: false,
  width: '',
  height: '',
  onEnded: () => {},
  onPause: () => {},
  onPlay: () => {},
  markPlayed: null,
};

export default memo(Video);
