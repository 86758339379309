import React, { useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useOutsideClick } from 'src/hooks';
import { selectors } from 'src/store';

import Actions from '../Actions';
import Badge from '../../../Badge';
import { navbarItems, navbarItemNames } from '../../constants';
import Localize from '../../../Localize';
import navbarConfiguration from './configuration';
import SettingsMenu from '../../../SettingsMenu';
import Tooltip from '../../../Tooltip';

import './index.scss';

const SettingsAction = () => {
  const menuBadgeRef = useRef();
  const settingsMenuRef = useRef();
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const hideSettings = useCallback(() => setShowSettingsMenu(false), [
    setShowSettingsMenu,
  ]);

  useOutsideClick(hideSettings, settingsMenuRef, menuBadgeRef);

  const isSpeechTherapist = useSelector(
    selectors.authentication.getIsSpeechTherapist
  );

  const handleSettingsToggle = () =>
    setShowSettingsMenu((prevShowSettingsMenu) => !prevShowSettingsMenu);

  const settings = isSpeechTherapist
    ? navbarItems.SPEECH_THERAPIST_MENU_ITEMS
    : navbarItems.USER_SETTINGS_MENU_ITEMS;

  return (
    <Actions>
      <Tooltip id="settings">
        <Localize id="tooltip.settings" />
      </Tooltip>
      <Badge
        key={navbarItemNames.SETTINGS}
        iconName="settings"
        className="settings-badge"
        onClick={handleSettingsToggle}
        tooltip="settings"
        ref={menuBadgeRef}
      />
      <div
        className="settings-menu"
        ref={settingsMenuRef}
        onClick={() => setShowSettingsMenu(false)}
      >
        {showSettingsMenu && (
          <SettingsMenu>
            {settings
              .filter((item) => item in navbarConfiguration.SETTINGS_BUTTONS)
              .map((item) => {
                const SettingsButton =
                  navbarConfiguration.SETTINGS_BUTTONS[item];
                return <SettingsButton key={item} />;
              })}
          </SettingsMenu>
        )}
      </div>
    </Actions>
  );
};

export default SettingsAction;
