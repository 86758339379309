import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { selectors } from 'src/store';
import Container from 'src/components/pages/Activities/Climb/Levels/Container';

const ClimbStepOneLevels = ({
  currency,
  levels,
  getLevelsByCollectionId,
  hasLoaded,
  ...props
}) => {
  useEffect(() => {
    getLevelsByCollectionId();
  }, [getLevelsByCollectionId]);

  return (
    <Container
      awardClassName="climb-level-scene-award--step-one"
      className="climb-levels-container--step-one"
      subactivityName="climbStepOne"
      hasLoaded={hasLoaded}
      items={levels}
      currency={currency}
      {...props}
    />
  );
};

ClimbStepOneLevels.propTypes = {
  currency: PropTypes.shape({}),
  levels: PropTypes.arrayOf(PropTypes.shape({})),
  getLevelsByCollectionId: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
};

ClimbStepOneLevels.defaultProps = {
  currency: null,
  levels: [],
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.dailyMap.getHasLoadedLevels(state),
  currency: selectors.dailyMap.getDailyMapCollection(state)?.currency,
  levels: selectors.dailyMap.getDailyMapCollection(state)?.levels,
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ClimbStepOneLevels);
