import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import { checkpoints, video } from 'src/constants';
import {
  useCheckpoint,
  useGoToLevel,
  usePagination,
  useIntlMessages,
} from 'src/hooks';

import {
  MenuFrame,
  PageLoader,
  ContentContainer,
  Navbar,
  Image,
} from 'src/components/common';

import './index.scss';

const Container = ({
  currency,
  getCollectionsByGroupId,
  groupData,
  hasLoaded,
  items,
  levelsPath,
}) => {
  const goToLevel = useGoToLevel(levelsPath);
  const { groupId } = useParams();

  const messages = useIntlMessages();

  const {
    currentPage,
    pageCount,
    handlePreviousPage,
    handleNextPage,
    pageItems,
  } = usePagination(items);

  useEffect(() => {
    getCollectionsByGroupId(groupId);
  }, [getCollectionsByGroupId, groupId]);

  useCheckpoint(checkpoints.FIRST_TIME_ON_PAINTING, hasLoaded);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      <Navbar.GameNavbar
        currency={currency}
        title={groupData.label}
        videoName={video.NAME.PAINTING}
        videoLabel={messages.subactivity.painting}
      />
      <div
        className={`painting-collections-container painting-collections-container--background-${groupData.orderNumber}`}
      >
        <MenuFrame
          title={groupData.label}
          currencyImageUrl={currency.imageUrl}
          currentPageNumber={currentPage}
          pagesCount={pageCount}
          onBack={handlePreviousPage}
          onForward={handleNextPage}
        >
          <div className="painting-collections-container__collections">
            {pageItems?.map((collection) => (
              <ContentContainer
                key={collection.id}
                isLocked={collection.isLocked}
                onClick={() => goToLevel(collection.id)}
                showFeedback={collection.showFeedback}
                isFeedbackPositive={collection.isFeedbackPositive}
                variant="small"
              >
                <Image alt="thumbnail" src={collection.imageUrl} />
              </ContentContainer>
            ))}
          </div>
        </MenuFrame>
      </div>
    </PageLoader>
  );
};

Container.propTypes = {
  currency: PropTypes.shape({
    imageUrl: PropTypes.string,
  }).isRequired,
  getCollectionsByGroupId: PropTypes.func.isRequired,
  groupData: PropTypes.shape({
    label: PropTypes.string,
    orderNumber: PropTypes.number,
  }).isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  levelsPath: PropTypes.string.isRequired,
};

export default memo(Container);
