const NAME = {
  ARTICULATION_GYMNASTICS: 'articulationGymnastics',
  CLIMB: 'climb',
  DISCOVER: 'discover',
  FIND_WORDS: 'findWords',
  FLASH_CARDS: 'flashCards',
  MEMORY: 'memory',
  PAINTING: 'painting',
  READ_AND_THINK: 'readAndThink',
  READ_TOGETHER: 'readTogether',
  REPLENISHMENT: 'replenishment',
  TREASURE_HUNT: 'treasureHunt',
  WHEEL_OF_FORTUNE: 'wheelOfFortune',
};

const EDUCATION = {
  [NAME.ARTICULATION_GYMNASTICS]: 'hasPassedArticulationGymnasticsEducation',
  [NAME.CLIMB]: 'hasPassedClimbEducation',
  [NAME.DISCOVER]: 'hasPassedDiscoverEducation',
  [NAME.FIND_WORDS]: 'hasPassedFindWordsEducation',
  [NAME.FLASH_CARDS]: 'hasPassedFlashCardsEducation',
  [NAME.MEMORY]: 'hasPassedMemoryEducation',
  [NAME.PAINTING]: 'hasPassedPaintingEducation',
  [NAME.READ_AND_THINK]: 'hasPassedReadAndThinkEducation',
  [NAME.READ_TOGETHER]: 'hasPassedReadTogetherEducation',
  [NAME.REPLENISHMENT]: 'hasPassedReplenishmentEducation',
  [NAME.TREASURE_HUNT]: 'hasPassedTreasureHuntEducation',
  [NAME.WHEEL_OF_FORTUNE]: 'hasPassedWheelOfFortuneEducation',
};

export default { NAME, EDUCATION };
