import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { notifications } from 'src/constants';
import { actions, selectors } from 'src/store';

import useIntlMessages from './useIntlMessages';

const useInviteUser = () => {
  const dispatch = useDispatch();
  const messages = useIntlMessages();

  const user = useSelector(selectors.authentication.getUser);

  const handleInviteUser = useCallback(
    (email, studentId) => {
      dispatch(
        actions.users.inviteUser({
          email,
          studentId,
          speechTherapistUserId: user.id,
        })
      ).then(() => {
        dispatch(actions.students.getStudents());
        dispatch(
          actions.notifications.showNotification(
            messages.notification.studentInvitedSuccessfully,
            notifications.type.SUCCESS,
            notifications.duration.SHORT
          )
        );
      });
    },
    [dispatch, messages, user]
  );

  return handleInviteUser;
};

export default useInviteUser;
