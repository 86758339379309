import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from 'src/store';
import { shop } from 'src/utils';

const useCanBuyParts = () => {
  const dispatch = useDispatch();
  const student = useSelector(selectors.authentication.getStudent);
  const walletData = useSelector(selectors.wallet.getData);
  const parrotParts = useSelector(selectors.parrot.getCheapParrotParts);
  const [hasEnough, setHasEnough] = useState(false);

  useEffect(() => {
    if (student && !Object.keys(walletData).length) {
      dispatch(actions.wallet.getWallet());
    }
  }, [dispatch, student, walletData]);

  useMemo(() => {
    if (Object.keys(parrotParts).length) {
      setHasEnough(
        Object.keys(parrotParts)
          .map((key) => shop.hasEnoughFunds(parrotParts[key], walletData))
          .includes(true)
      );
    }
  }, [parrotParts, walletData]);
  return hasEnough;
};

export default useCanBuyParts;
