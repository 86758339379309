import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Button } from 'src/components/common';

import './index.scss';

const Navigation = ({ activeTabId, tabs, onClick }) => {
  return (
    <div className="navigation">
      {tabs.map((tab) => (
        <Button
          key={tab.id}
          className={cn('navigation__item', {
            'navigation__item--selected': activeTabId === tab.id,
          })}
          label={tab.label}
          onClick={() => onClick(tab.id)}
        />
      ))}
    </div>
  );
};

Navigation.propTypes = {
  activeTabId: PropTypes.number.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(Navigation);
