import React, { memo } from 'react';
import PropTypes from 'prop-types';

import treasureHuntChest from 'src/images/treasureHuntChest.svg';
import treasureHuntChestOpen from 'src/images/treasureHuntChestOpen.svg';

import { ContentContainer, Image } from 'src/components/common';

import SoundBadge from '../SoundBadge';

import './index.scss';

const TreasureChest = ({ collectedSounds, isLeft, speechSound }) => {
  return (
    <div className="treasure-chest">
      <Image
        alt="treasureHuntChest"
        src={collectedSounds.length ? treasureHuntChestOpen : treasureHuntChest}
      />
      {!!collectedSounds.length && (
        <div className="treasure-chest__sounds">
          {collectedSounds.map(({ key, imageUrl }) => (
            <ContentContainer
              key={key}
              className="treasure-chest__sounds__sound"
            >
              <Image
                className="treasure-chest__sounds__sound__image"
                alt={key}
                src={imageUrl}
              />
            </ContentContainer>
          ))}
        </div>
      )}
      <div className="treasure-chest__badge">
        <SoundBadge speechSound={speechSound} isLeft={isLeft} />
      </div>
    </div>
  );
};

TreasureChest.propTypes = {
  collectedSounds: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      imageUrl: PropTypes.string,
    })
  ),
  isLeft: PropTypes.bool,
  speechSound: PropTypes.string.isRequired,
};

TreasureChest.defaultProps = {
  collectedSounds: [],
  isLeft: false,
};

export default memo(TreasureChest);
