import React, { memo, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';

import { useIntlMessages } from 'src/hooks';

import { actions, selectors } from 'src/store';

import {
  Button,
  UserRoleModal,
  PasswordInputModal,
  Localize,
} from 'src/components/common';

import { GoogleContext } from 'src/components/common/GoogleProvider';

import { notifications } from 'src/constants';

import Icon from '../Icon';

const GoogleLogin = ({
  googleLogin,
  login,
  patchUser,
  isLoading,
  showNotification,
}) => {
  const messages = useIntlMessages();
  const [showPasswordInputModal, setShowPasswordInputModal] = useState(false);
  const [showUserRoleModal, setShowUserRoleModal] = useState(false);
  const [googleUser, setGoogleUser] = useState();
  const googleAuthLoaded = useContext(GoogleContext);

  const onGoogleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      await googleLogin({ googleCode: code }).catch((err) => {
        setGoogleUser(err.response.data.data);
        if (err.response.data.message === 'error.feedback.emailInUse') {
          setShowPasswordInputModal(true);
        }
        if (err.response.data.message === 'error.feedback.fieldRequired') {
          setShowUserRoleModal(true);
        }
      });
    },
    onError: () =>
      showNotification(
        messages.error.feedback.somethingWentWrong,
        notifications.type.ERROR,
        notifications.duration.SHORT
      ),
    flow: 'auth-code',
  });

  const loginGoogleUser = async (data) => {
    const loginData = {
      ...data,
      idToken: googleUser.idToken,
    };
    googleLogin(loginData);
  };

  const loginUser = (data) => {
    const { email } = googleUser;
    const googleId = googleUser.sub;
    const loginData = {
      ...data,
      email,
    };

    login(loginData).then((res) => {
      const userId = res.result.data.id;
      patchUser({ googleId }, userId);
    });
  };

  const closeModal = useCallback(() => {
    setShowPasswordInputModal(false);
    setShowUserRoleModal(false);
  }, [setShowPasswordInputModal, setShowUserRoleModal]);

  return (
    <>
      <UserRoleModal
        show={showUserRoleModal}
        close={closeModal}
        onSubmit={loginGoogleUser}
        isLoading={isLoading}
      />
      <PasswordInputModal
        show={showPasswordInputModal}
        close={closeModal}
        onSubmit={loginUser}
        isLoading={isLoading}
      />
      {googleAuthLoaded && (
        <Button id="google-login-btn" onClick={onGoogleLogin}>
          <Localize id="button.googleLogin" />
          <Icon name="google" size={30} />
        </Button>
      )}
    </>
  );
};

GoogleLogin.propTypes = {
  googleLogin: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  patchUser: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: selectors.authentication.getIsLoading(state),
});

const mapDispatchToProps = {
  ...actions.authentication,
  ...actions.users,
  ...actions.notifications,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(GoogleLogin);
