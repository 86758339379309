import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ContentContainer, Image } from 'src/components/common';

import './index.scss';

const LevelCard = ({ level, onClick }) => {
  return (
    <div className="flash-cards-level-card-container">
      <ContentContainer
        className="flash-cards-level-card-container__card"
        onClick={onClick}
      >
        <Image
          className="flash-cards-level-card-container__card__image"
          alt={level.key}
          src={level.imageUrl}
        />
        <p className="flash-cards-level-card-container__card__text">
          {level.label.toUpperCase()}
        </p>
      </ContentContainer>
    </div>
  );
};

LevelCard.propTypes = {
  level: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(LevelCard);
