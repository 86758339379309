import React, { memo, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { actions, selectors } from 'src/store';

import './index.scss';

const Tooltip = ({ id, children, show, offset, setTooltipVisibility }) => {
  useEffect(() => {
    const elem = document.querySelector(`[data-tip="${id}"]`);
    if (show) {
      ReactTooltip.show(elem);
    } else {
      ReactTooltip.hide(elem);
    }
  }, [show, id]);

  return (
    <>
      <ReactTooltip
        id={id}
        className="tooltip"
        place="bottom"
        effect="solid"
        globalEventOff="click"
        arrowColor="#f9c41d"
        multiline
        offset={offset}
        getContent={() => children}
        afterShow={() => setTooltipVisibility(id, true)}
        afterHide={() => setTooltipVisibility(id, false)}
      />
    </>
  );
};

Tooltip.defaultProps = {
  offset: undefined,
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  offset: PropTypes.objectOf(PropTypes.number),
  setTooltipVisibility: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { id }) => ({
  show: selectors.tooltips.getVisibility(state, id),
});

const mapDispatchToProps = {
  ...actions.tooltips,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(Tooltip);
