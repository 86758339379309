import React from 'react';

import { Localize } from '@kokolingo/localisation';

import { common } from 'src/utils';

import Video from 'src/components/common/Video';

const formatVideo = (video, handleVideoEnded, markPlayed) => ({
  label: (
    <p>{video.label || (video.labelId && <Localize id={video.labelId} />)}</p>
  ),
  Component: () => (
    <>
      <Video
        autoPlay
        className="intro-education__video"
        height="80%"
        src={`${common.getMediaContentUrl()}${video.videoUrl}`}
        markPlayed={() => markPlayed?.(video.videoName)}
        onEnded={() => handleVideoEnded?.(video.videoName)}
      />
      <p className="intro-education__description">
        {video.description ||
          (video.descriptionId && <Localize id={video.descriptionId} />)}
      </p>
    </>
  ),
});

export default {
  formatVideo,
};
