import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { actions, selectors } from 'src/store';
import { player } from 'src/utils';

import LogoTitle from '../LogoTitle';
import PageLoader from '../PageLoader';

import { ParentContainer, SelectionMenu } from './components';

import './index.scss';

// Used for Parents!
const SelectStudent = ({
  getStudents,
  hasAcceptedInvitation,
  hasLoaded,
  resolveUsers,
  userExtraStudents,
  userOnlyStudents,
  children,
  selectedStudent,
  selectStudent,
}) => {
  useEffect(() => {
    getStudents();
  }, [getStudents]);

  const handleStudentSelect = useCallback(
    (studentId) => {
      player.setSelectedStudentId(studentId);
      resolveUsers().then(() => {
        selectStudent(studentId);
      });
    },
    [resolveUsers, selectStudent]
  );

  useEffect(() => {
    if (userOnlyStudents.length === 1) {
      handleStudentSelect(userOnlyStudents[0].id);
    }
    if (userExtraStudents.length === 1) {
      handleStudentSelect(userExtraStudents[0].id);
    }
  }, [userOnlyStudents, userExtraStudents, handleStudentSelect]);

  if (selectedStudent) {
    return children;
  }

  if (
    !hasLoaded ||
    userOnlyStudents.length === 1 ||
    userExtraStudents.length === 1
  ) {
    return <PageLoader />;
  }

  return (
    <div className="select-student-page">
      {!isMobile && (
        <div className="select-student-page__title-container">
          <LogoTitle />
        </div>
      )}
      <SelectionMenu
        handleStudentSelect={handleStudentSelect}
        hasAcceptedInvitation={hasAcceptedInvitation}
        userExtraStudents={userExtraStudents}
        userOnlyStudents={userOnlyStudents}
      />
    </div>
  );
};

SelectStudent.propTypes = {
  getStudents: PropTypes.func.isRequired,
  resolveUsers: PropTypes.func.isRequired,
  selectStudent: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  userOnlyStudents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  userExtraStudents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  hasAcceptedInvitation: PropTypes.bool,
  children: PropTypes.node.isRequired,
  selectedStudent: PropTypes.number,
};

SelectStudent.defaultProps = {
  hasAcceptedInvitation: false,
  selectedStudent: undefined,
};

const mapDispatchToProps = {
  ...actions.authentication,
  ...actions.students,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.students.getHasLoaded(state),
  selectedStudent: selectors.student.getSelectedStudent(state),
  userOnlyStudents: selectors.students.getUserOnlyStudents(state),
  userExtraStudents: selectors.students.getUserExtraStudents(state),
  hasAcceptedInvitation: selectors.authentication.getHasAcceptedInvitation(
    state
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ParentContainer(SelectStudent));
