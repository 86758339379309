import ClientRegistration from './ClientRegistration';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import MagicLink from './MagicLink';
import ResetPassword from './ResetPassword';
import SpeechTherapistRegistration from './SpeechTherapistRegistration';

export default {
  ClientRegistration,
  ForgotPassword,
  Login,
  MagicLink,
  ResetPassword,
  SpeechTherapistRegistration,
};
