// source: https://stackoverflow.com/a/42234988/9801221
import { useEffect } from 'react';

export default (onOutsideClick, ...refs) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        refs.every((ref) => !ref.current || !ref.current.contains(event.target))
      ) {
        onOutsideClick();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOutsideClick, ...refs]);
};
