import React, { memo, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import { Localize } from '@kokolingo/localisation';

import { checkpoints, paths } from 'src/constants';
import { actions, selectors } from 'src/store';
import {
  useCanBuyParts,
  useCheckpoint,
  useIsMounted,
  useQuery,
} from 'src/hooks';

import Badge from '../Badge';
import Modal from '../Modal/Modal';
import Parrot from '../Parrot';

const ModalShop = ({ showShopModal, setShowShopModal, isTooltipVisible }) => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const canBuyParts = useCanBuyParts();
  const mounted = useIsMounted();

  const checkpoint = useCheckpoint(
    checkpoints.FIRST_TIME_CAN_BUY,
    canBuyParts && !isTooltipVisible && mounted
  );
  const initialCheckpoint = useRef(checkpoint);

  useEffect(() => {
    if (!initialCheckpoint.current && checkpoint) {
      setTimeout(() => {
        setShowShopModal(true);
      }, 500);
    }
  }, [checkpoint, setShowShopModal]);

  const close = useCallback(() => {
    setShowShopModal(false);
  }, [setShowShopModal]);

  useEffect(() => {
    if (!query.get('shopModal') && showShopModal) {
      window.location.hash = 'shopModal';
    }
  }, [location, showShopModal, query]);

  return (
    <Modal show={showShopModal} onClose={close} onOutsideClick={close}>
      <Modal.Title>
        <Localize id="modal.shop.enoughCoins.title" />
      </Modal.Title>
      <Parrot centered height={isMobile ? '200px' : '400px'} />
      <br />
      <Modal.Description>
        <Localize id="modal.shop.enoughCoins.description" />
      </Modal.Description>
      <br />
      <Badge
        iconName="shop"
        className="shop-badge"
        size="large"
        onClick={() => {
          close();
          history.push(paths.SHOP);
        }}
      />
    </Modal>
  );
};

ModalShop.propTypes = {
  showShopModal: PropTypes.bool.isRequired,
  setShowShopModal: PropTypes.func.isRequired,
  isTooltipVisible: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  showShopModal: selectors.user.getShowShopModal(state),
  isTooltipVisible: selectors.tooltips.getIsTooltipVisible(state),
});

const mapDispatchToProps = {
  ...actions.users,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ModalShop);
