import React from 'react';
import { Localize } from '@kokolingo/localisation';
import { object, string } from 'yup';

export default object().shape({
  label: string()
    .trim()
    .min(1, () => <Localize id="error.noteTitleRequired" />)
    .required(() => <Localize id="error.noteTitleRequired" />),
  description: string()
    .trim()
    .min(1, () => <Localize id="error.noteDescriptionRequired" />)
    .max(10000, () => <Localize id="error.noteDescriptionTooLong" />)
    .required(() => <Localize id="error.noteDescriptionRequired" />),
});
