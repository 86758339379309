import _find from 'lodash/find';

import { navbarItemHelpKey, navbarItems } from '../../constants';

function getNavbarRegex(url) {
  const replacedGroupKey = url.replace(/:[a-zA-Z0-9\\-]+/g, '[a-zA-Z0-9\\-]+');

  return replacedGroupKey.replace(/:[a-zA-Z0-9]Id+/g, '\\d+');
}

const getNavbarItems = (url) => {
  const navbarItem = _find(navbarItems, (i, key) =>
    new RegExp(getNavbarRegex(key)).test(url)
  );

  const helpKey = _find(navbarItemHelpKey, (i, key) =>
    new RegExp(getNavbarRegex(key)).test(url)
  );

  return { navbarItem, helpKey };
};

export default { getNavbarRegex, getNavbarItems };
