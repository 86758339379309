import PropTypes from 'prop-types';
import React, { memo, useCallback, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import { actions } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Button, LogoTitle, Modal, Page } from 'src/components/common';

import { FormContainer } from './components';

import './index.scss';

const ForgotPassword = ({ requestResetPasswordLink }) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const history = useHistory();
  const messages = useIntlMessages();

  const sendResetPasswordLink = useCallback(
    (data) => {
      requestResetPasswordLink({ email: data.resetPasswordEmail });
      setHasSubmitted(true);
    },
    [requestResetPasswordLink]
  );

  const handleContinue = useCallback(() => history.push(paths.BASE), [history]);

  return (
    <>
      <Modal show={hasSubmitted}>
        <Modal.Title>{messages.page.forgotPassword.dialog.title}</Modal.Title>
        <Modal.Description>
          {messages.page.forgotPassword.dialog.description}
        </Modal.Description>
        <div className="container__buttons">
          <Button
            className="container__buttons"
            onClick={handleContinue}
            label={messages.button.forward}
          />
        </div>
      </Modal>
      <Page className="forgot-password-page" noNavigation>
        <Page.Section>
          {!isMobile && (
            <Page.Body color="transparent" noPadding>
              <LogoTitle />
            </Page.Body>
          )}
          <Page.Body color="light" size="sm" align="center">
            <FormContainer sendResetPasswordLink={sendResetPasswordLink} />
          </Page.Body>
        </Page.Section>
      </Page>
    </>
  );
};

ForgotPassword.propTypes = {
  requestResetPasswordLink: PropTypes.func.isRequired,
};

const mapStateToProps = undefined;

const mapDispatchToProps = {
  ...actions.authentication,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ForgotPassword);
