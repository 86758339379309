import { memo, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ children }) => {
  const [portalContainer, setPortalContainer] = useState(
    document.getElementById('topbar')
  );

  useEffect(() => {
    if (!portalContainer) {
      setPortalContainer(document.getElementById('topbar'));
    }
  }, [portalContainer, setPortalContainer]);

  return portalContainer
    ? ReactDOM.createPortal(children, portalContainer)
    : null;
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(Portal);
