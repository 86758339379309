import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {
  ContentContainer,
  Zoom,
  LevelContent,
  Image,
} from 'src/components/common';

import './index.scss';

const LevelScene = ({ awardClassName, level, showReward, onClick }) => {
  const levelSceneCardClassNames = useMemo(
    () =>
      cn('climb-level-scene-card', {
        'climb-level-scene-card--hidden': showReward,
      }),
    [showReward]
  );

  const levelSceneAwardClassNames = useMemo(
    () =>
      cn('climb-level-scene-award', awardClassName, {
        [`${awardClassName}--collected`]: showReward,
      }),
    [awardClassName, showReward]
  );

  return (
    <Zoom mobileWidth={320} mobileHeight={490} mobileRatio="calc(100% - 80px)">
      {level.label && (
        <ContentContainer
          className={levelSceneCardClassNames}
          onClick={onClick}
        >
          <LevelContent.Text label={level.label} />
        </ContentContainer>
      )}
      <Image
        className="climb-level-scene-climber"
        alt={level.key}
        src={level.climberPositionImageUrl}
      />
      <Image
        className={levelSceneAwardClassNames}
        alt={level.key}
        src={level.awardImageUrl}
      />
    </Zoom>
  );
};

LevelScene.propTypes = {
  awardClassName: PropTypes.string.isRequired,
  level: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string,
    awardImageUrl: PropTypes.string.isRequired,
    climberPositionImageUrl: PropTypes.string.isRequired,
  }).isRequired,
  showReward: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(LevelScene);
