import { actions } from 'src/constants';

const initialState = {
  tooltips: {
    firstIsland: false,
    actor: false,
    shop: false,
    settings: false,
  },
  hasLoaded: false,
  isLoading: false,
};

const actionMap = {
  [actions.SET_TOOLTIP_VISIBILITY]: (state, result) => {
    const { tooltipId, show } = result.payload;

    return {
      ...state,
      tooltips: {
        ...state.tooltips,
        [tooltipId]: show,
      },
      hasLoaded: true,
      isLoading: false,
    };
  },
};

export default (state = initialState, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
