import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import LevelImage from '../LevelImage';

import './index.scss';

const LevelCard = ({
  level,
  cleanedTerms,
  coverImage,
  discoveredTerms,
  shake,
  handleReveal,
}) => {
  const currentTermCover = discoveredTerms.find(
    (discoveredTerm) => discoveredTerm.selectedTermKey === level.key
  );

  const currentTerm = cleanedTerms.find(
    (cleanedTerm) => cleanedTerm.selectedTermKey === level.key
  );

  const levelCardContainerClassNames = useMemo(
    () => cn(`find-words-level-card-container--${level.scenePosition}`),
    [level.scenePosition]
  );

  return (
    <div className={levelCardContainerClassNames}>
      <LevelImage
        coverImage={coverImage}
        level={level}
        isCoverImage
        isHidden={currentTermCover && currentTermCover.isHidden}
        onClick={handleReveal}
      />
      <LevelImage
        level={level}
        isHidden={
          (currentTerm && currentTerm.isHidden) ||
          !(currentTermCover && currentTermCover.isHidden)
        }
        shake={shake}
      />
    </div>
  );
};

LevelCard.propTypes = {
  level: PropTypes.shape({
    key: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    scenePosition: PropTypes.string.isRequired,
  }).isRequired,
  cleanedTerms: PropTypes.arrayOf(
    PropTypes.shape({
      selectedTermKey: PropTypes.string,
      state: PropTypes.string,
    })
  ),
  discoveredTerms: PropTypes.arrayOf(
    PropTypes.shape({
      selectedTermKey: PropTypes.string,
      state: PropTypes.string,
    })
  ),
  shake: PropTypes.bool.isRequired,
  coverImage: PropTypes.string.isRequired,
  handleReveal: PropTypes.func.isRequired,
};

LevelCard.defaultProps = {
  cleanedTerms: [],
  discoveredTerms: [],
};

export default memo(LevelCard);
