import { createSelector } from 'reselect';

const getIsLoading = createSelector(
  (state) => state.payment.isLoading,
  (hasLoaded) => hasLoaded
);
const getHasLoaded = createSelector(
  (state) => state.payment.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getPaymentInfo = createSelector(
  (state) => state.payment.data,
  (paymentInfo = {}) => paymentInfo
);
const getPaymentCanceled = createSelector(
  (state) => state.payment.paymentCanceled,
  (paymentCanceled) => paymentCanceled || false
);
const getPaymentMethods = createSelector(
  (state) => state.payment.data,
  (paymentMethods = []) => paymentMethods
);

export default {
  getIsLoading,
  getHasLoaded,
  getPaymentInfo,
  getPaymentCanceled,
  getPaymentMethods,
};
