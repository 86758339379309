const SHORT_VALUE = 15;
const MEDIUM_VALUE = 30;
const LONG_VALUE = 45;
export const DURATION = {
  SHORT: 'SHORT',
  MEDIUM: 'MEDIUM',
  LONG: 'LONG',
};

export default {
  DAILY_DURATION: {
    [DURATION.SHORT]: {
      value: SHORT_VALUE,
      label: 'label.dailyMap.dailyDuration.short',
    },
    [DURATION.MEDIUM]: {
      value: MEDIUM_VALUE,
      label: 'label.dailyMap.dailyDuration.medium',
    },
    [DURATION.LONG]: {
      value: LONG_VALUE,
      label: 'label.dailyMap.dailyDuration.long',
    },
  },
};
