import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const LevelAwardCoverItem = ({
  discoveredPositions,
  positionIndex,
  positionName,
  groupKey,
}) => {
  const isDiscovered = discoveredPositions.some(
    (discoveredPosition) => discoveredPosition === positionIndex
  );

  const levelAwardCoverItemClassNames = useMemo(
    () =>
      cn(
        'discover-level-award-cover-item',
        `discover-level-award-cover-item__${groupKey}--${positionName}`,
        {
          'discover-level-award-cover-item--hidden': isDiscovered,
        }
      ),
    [isDiscovered, positionName, groupKey]
  );

  return <div className={levelAwardCoverItemClassNames} />;
};

LevelAwardCoverItem.propTypes = {
  discoveredPositions: PropTypes.arrayOf(PropTypes.number.isRequired)
    .isRequired,
  positionName: PropTypes.string.isRequired,
  groupKey: PropTypes.string.isRequired,
  positionIndex: PropTypes.number.isRequired,
};

export default memo(LevelAwardCoverItem);
