import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Card from 'src/components/common/Card';

import './index.scss';

const SentenceCard = ({ sentence, onClick }) => {
  return (
    <Card
      className="sentence-card"
      onClick={() => sentence && onClick(sentence.id)}
      ghost={!sentence}
    >
      {sentence && <p>{sentence.createdSentence}</p>}
    </Card>
  );
};

SentenceCard.propTypes = {
  sentence: PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdSentence: PropTypes.shape({}),
  }),
  onClick: PropTypes.func,
};

SentenceCard.defaultProps = {
  sentence: undefined,
  onClick: () => {},
};

export default memo(SentenceCard);
