import _get from 'lodash/get';

import { actions, selectors } from 'src/store';
import { paths, statusCodes } from 'src/constants';

export default () => (store) => (next) => (action) => {
  const status = _get(action, 'error.response.status');
  if (
    [statusCodes.UNAUTHORIZED].includes(status) &&
    selectors.authentication.getIsAuthenticated(store.getState())
  ) {
    localStorage.clear();
    store.dispatch(actions.users.clearUser());
  } else if ([statusCodes.FORBIDDEN].includes(status)) {
    window.location.href = paths.FORBIDDEN;
  }

  return next(action);
};
