import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { video, checkpoints } from 'src/constants';

import {
  ContentContainer,
  MenuFrame,
  PageLoader,
  Navbar,
  Image,
} from 'src/components/common';

import { useGoToLevel, usePagination, useCheckpoint } from 'src/hooks';

import './index.scss';

const Container = ({
  className,
  currency,
  getCollections,
  hasLoaded,
  items,
  levelsPath,
  title,
}) => {
  const goToLevel = useGoToLevel(levelsPath);

  const {
    currentPage,
    pageCount,
    handlePreviousPage,
    handleNextPage,
    pageItems,
  } = usePagination(items);

  useEffect(() => {
    getCollections();
  }, [getCollections]);

  useCheckpoint(checkpoints.FIRST_TIME_ON_CLIMB, hasLoaded);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      <Navbar.GameNavbar
        currency={currency}
        title={title}
        videoName={video.NAME.CLIMB}
      />
      <div className={cn('climb-collections-container', className)}>
        <MenuFrame
          title={title}
          currencyImageUrl={currency.imageUrl}
          currentPageNumber={currentPage}
          pagesCount={pageCount}
          onBack={handlePreviousPage}
          onForward={handleNextPage}
        >
          {pageItems?.map((collection) => (
            <ContentContainer
              variant="small"
              onClick={() => goToLevel(collection.id)}
              isLocked={collection.isLocked}
              showFeedback={collection.showFeedback}
              isFeedbackPositive={collection.isFeedbackPositive}
            >
              <p>{collection.label}</p>
              <Image alt={collection.key} src={collection.imageUrl} />
            </ContentContainer>
          ))}
        </MenuFrame>
      </div>
    </PageLoader>
  );
};

Container.propTypes = {
  className: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    imageUrl: PropTypes.string,
  }).isRequired,
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  levelsPath: PropTypes.string.isRequired,
  student: PropTypes.shape({
    hasPassedClimbEducation: PropTypes.bool.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default memo(Container);
