import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { Redirect } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { navigation, paths, subscriptions } from 'src/constants';
import { selectors } from 'src/store';
import { useIntlMessages } from 'src/hooks';

import { Navigation, Page } from 'src/components/common';

import LastMonthStatistics from './LastMonthStatistics';
import MonthlyStatistics from './MonthlyStatistics';
import TodayStatistics from './TodayStatistics';

const ACTIVE_TAB_ID = 1;

const Statistics = ({ activeSubscriptionKey, hasLoaded }) => {
  const messages = useIntlMessages();
  const [activeTabId, setActiveTabId] = useState(ACTIVE_TAB_ID);

  const tabs = useMemo(
    () => [
      {
        id: navigation.FIRST_TAB_ID,
        label: messages.page.statistics.tab.today,
      },
      {
        id: navigation.SECOND_TAB_ID,
        label: messages.page.statistics.tab.lastMonth,
      },
      {
        id: navigation.THIRD_TAB_ID,
        label: messages.page.statistics.tab.monthly,
      },
    ],
    [messages]
  );

  const handleNavigationClick = useCallback((tabId) => {
    setActiveTabId(tabId);
  }, []);

  if (
    ![
      subscriptions.KEYS.PREMIUM,
      subscriptions.KEYS.KOKOLINGO_1,
      subscriptions.KEYS.KOKOLINGO_5,
      subscriptions.KEYS.KOKOLINGO_10,
      subscriptions.KEYS.KOKOLINGO_25,
    ].includes(activeSubscriptionKey) &&
    hasLoaded
  ) {
    return <Redirect to={paths.MAP} />;
  }

  const content = (
    <Page title={messages.page.statistics.title}>
      <Navigation
        activeTabId={activeTabId}
        tabs={tabs}
        onClick={handleNavigationClick}
      />
      <Page.Body>
        {activeTabId === navigation.FIRST_TAB_ID && <TodayStatistics />}
        {activeTabId === navigation.SECOND_TAB_ID && <LastMonthStatistics />}
        {activeTabId === navigation.THIRD_TAB_ID && <MonthlyStatistics />}
      </Page.Body>
    </Page>
  );

  return content;
};

Statistics.propTypes = {
  activeSubscriptionKey: PropTypes.string.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  activeSubscriptionKey: selectors.authentication.getActiveSubscriptionKey(
    state
  ),
  hasLoaded: selectors.authentication.getHasLoaded(state),
});

export default compose(connect(mapStateToProps), memo)(Statistics);
