import PropTypes from 'prop-types';
import React, { memo } from 'react';
import cn from 'classnames';

import Container from '../../Container';

import './index.scss';

const Body = ({ children, className, noPadding, color, size, align }) => {
  const containerClassNames = cn('page-body', className, {
    'page-body--no-padding': noPadding,
    [`page-body--${color}`]: !!color,
    [`page-body--size-${size}`]: !!size,
    [`page-body--align-${align}`]: !!align,
  });

  return <Container className={containerClassNames}>{children}</Container>;
};

Body.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  noPadding: PropTypes.bool,
  color: PropTypes.oneOf(['light', 'orange', 'transparent']),
  size: PropTypes.oneOf(['sm', 'md']),
  align: PropTypes.oneOf(['center']),
};

Body.defaultProps = {
  className: '',
  align: undefined,
  children: undefined,
  noPadding: undefined,
  color: undefined,
  size: undefined,
};

export default memo(Body);
