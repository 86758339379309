import { useHistory, useParams } from 'react-router';
import { useCallback } from 'react';

import { paths } from 'src/constants';

export default (levelsPath) => {
  const history = useHistory();
  const { groupKey, activityId, groupId } = useParams();

  return useCallback(
    (collectionId) => {
      let finalPath;

      if (groupId) {
        finalPath = paths.build(
          levelsPath,
          groupKey,
          activityId,
          groupId,
          collectionId
        );
      } else if (activityId) {
        finalPath = paths.build(levelsPath, groupKey, activityId, collectionId);
      } else {
        finalPath = paths.build(levelsPath, groupKey, collectionId);
      }

      history.push(finalPath);
    },
    [activityId, groupKey, groupId, history, levelsPath]
  );
};
