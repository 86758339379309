import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { subscriptions as subscriptionsConstants } from 'src/constants';
import { useIntlMessages, useRadioButtons } from 'src/hooks';
import { subscriptions as subscriptionsUtils } from 'src/utils';

import { Badge, Title, Page } from 'src/components/common';

import DurationRadioButton from '../DurationRadioButton';
import ButtonNext from '../ButtonNext';

import './index.scss';

const RECOMMENDED_DURATION =
  subscriptionsConstants.DURATION.KEYS.SIX_MONTHS_FORWARD;

const SubscriptionDurationSelection = ({
  nextStep,
  setStepsState,
  wizardState,
}) => {
  const { subscription } = wizardState;

  const messages = useIntlMessages();
  const { selectedValue, radioButtonProps } = useRadioButtons(
    'duration-select',
    RECOMMENDED_DURATION
  );

  const durations = subscriptionsUtils
    .getSubscriptionsOfferByDuration(messages)
    .filter(
      ({ key }) =>
        // For now not allowing users to select 2 months forward...
        key !== subscriptionsConstants.DURATION.KEYS.TWO_MONTHS_FORWARD &&
        key !== subscriptionsConstants.DURATION.KEYS.TEN_DAYS_FORWARD
    );

  useEffect(() => {
    setStepsState({
      subscriptionDurationKey: selectedValue,
    });
  }, [selectedValue, setStepsState]);

  return (
    <>
      <Page.Body className="page-body-container">
        <Title text={messages.page.subscriptionDurationSelection.title} />
        <div className="page-body-container__content">
          {durations.map((duration) => (
            <div key={duration.key} className="duration-radio-btn-container">
              <DurationRadioButton
                duration={duration}
                radioButtonProps={radioButtonProps}
                selectedValue={selectedValue}
                subscription={subscription}
              />
              {duration.key === RECOMMENDED_DURATION && (
                <div
                  className="duration-radio-btn-container__recommendation-container"
                  onClick={nextStep}
                >
                  <Badge iconName="check" type="success" size="small" />
                  <div className="duration-radio-btn-container__recommendation-container__recommendation">
                    <div className="duration-radio-btn-container__recommendation-container__recommendation__title">
                      {
                        messages.page.subscriptionDurationSelection
                          .recommendation.title
                      }
                    </div>
                    <div className="duration-radio-btn-container__recommendation-container__recommendation__description">
                      {
                        messages.page.subscriptionDurationSelection
                          .recommendation.description
                      }
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </Page.Body>
      <Page.Footer>
        <ButtonNext onClick={nextStep} />
      </Page.Footer>
    </>
  );
};

SubscriptionDurationSelection.propTypes = {
  nextStep: PropTypes.func.isRequired,
  setStepsState: PropTypes.func.isRequired,
  wizardState: PropTypes.shape({
    subscription: PropTypes.shape({
      price: PropTypes.number,
    }),
    subscriptionDurationKey: PropTypes.string,
  }).isRequired,
};

export default SubscriptionDurationSelection;
