import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import classNames from 'classnames';

import Coin from '../Coin';

import './index.scss';

const GameCoins = ({
  className,
  totalCoinsCount,
  collectedCoinsCount,
  type,
  coinSize,
}) => {
  const gameCoinsClassNames = useMemo(
    () => classNames('game-coins', className),
    [className]
  );

  return (
    <div className={gameCoinsClassNames}>
      {[...Array(totalCoinsCount)].map((_, i) => (
        <Coin
          key={i}
          type={type}
          ghost={i >= collectedCoinsCount}
          size={coinSize}
        />
      ))}
    </div>
  );
};

GameCoins.propTypes = {
  className: PropTypes.string,
  totalCoinsCount: PropTypes.number.isRequired,
  collectedCoinsCount: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  coinSize: Coin.propTypes.size,
};

GameCoins.defaultProps = {
  className: '',
  coinSize: undefined,
};

export default memo(GameCoins);
