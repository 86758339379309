import { actions as actionConstants } from 'src/constants';
import { actions, selectors } from 'src/store';

const middlewareActions = {
  [actionConstants.PLAYED_VIDEO]: true,
  [actionConstants.USER_CHECKPOINT]: true,
};

export default () => (store) => (next) => (action) => {
  if (!middlewareActions[action.type]) {
    return next(action);
  }

  const user = selectors.authentication.getUser(store.getState());

  let userMeta;

  if (actionConstants.PLAYED_VIDEO === action.type) {
    userMeta = {
      meta: {
        ...user.meta,
        playedVideos: {
          ...user.meta?.playedVideos,
          ...action.payload?.playedVideos,
        },
      },
    };
  }

  if (action.type === actionConstants.USER_CHECKPOINT) {
    const checkpoint = action.payload;

    const passedCheckpoints = selectors.user.getCheckpoints(store.getState());

    if (!passedCheckpoints?.[checkpoint]) {
      userMeta = {
        meta: {
          ...user.meta,
          checkpoints: {
            ...user.meta?.checkpoints,
            [checkpoint]: true,
          },
        },
      };
    }
  }

  if (userMeta) {
    store.dispatch(actions.users.patchUser(userMeta, user.id));
  }

  return next(action);
};
