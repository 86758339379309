import React from 'react';
import { Localize } from '@kokolingo/localisation';
import { object, ref, string } from 'yup';

import { regex } from 'src/constants';

export default object().shape({
  currentPassword: string(),
  password: string()
    .required(() => <Localize id="error.passwordRequired" />)
    .min(8, () => <Localize id="error.passwordMinimumCharacters" />)
    .max(20, () => <Localize id="error.passwordMaximumCharacters" />),
  passwordConfirmation: string().when('currentPassword', {
    is: (currentPasswordField) => currentPasswordField.trim().length > 0,
    then: string().oneOf([ref('password'), null], () => (
      <Localize id="error.passwordsMustMatch" />
    )),
    otherwise: string().matches(regex.emptyString, () => (
      <Localize id='error.enterCurrentPassword"' />
    )),
  }),
});
