import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';

import {
  Button,
  UserRoleModal,
  PasswordInputModal,
  Localize,
} from 'src/components/common';

import Icon from '../Icon';

const FacebookLogin = ({ facebookLogin, login, patchUser, isLoading }) => {
  const [showPasswordInputModal, setShowPasswordInputModal] = useState(false);
  const [showUserRoleModal, setShowUserRoleModal] = useState(false);
  const [token, setToken] = useState();

  const checkLoginState = useCallback(() => {
    window.FB.getLoginStatus((FBresponse) => {
      if (FBresponse.status === 'connected') {
        setToken(FBresponse.authResponse.accessToken);
        facebookLogin({
          accessToken: FBresponse.authResponse.accessToken,
        }).catch((err) => {
          if (err.response.data.message === 'error.feedback.emailInUse') {
            setShowPasswordInputModal(true);
          }
          if (err.response.data.message === 'error.feedback.fieldRequired') {
            setShowUserRoleModal(true);
          }
        });
      }
    }, true);
  }, [facebookLogin]);

  const handleFBLogin = useCallback(() => {
    window.FB.login(
      () => {
        checkLoginState();
      },
      {
        scope: 'email',
      }
    );
  }, [checkLoginState]);

  const loginFacebookUser = (data) => {
    const loginData = {
      ...data,
      accessToken: token,
    };
    facebookLogin(loginData);
  };

  const loginUser = (data) => {
    window.FB.api('/me', { fields: 'email' }, (response) => {
      const { email } = response;
      const loginData = {
        ...data,
        email,
      };

      login(loginData).then((res) => {
        const userId = res.result.data.id;
        const facebookId = response.id;
        patchUser({ facebookId }, userId);
      });
    });
  };

  const closeModal = useCallback(() => {
    setShowPasswordInputModal(false);
    setShowUserRoleModal(false);
  }, [setShowPasswordInputModal, setShowUserRoleModal]);

  return (
    <>
      <UserRoleModal
        show={showUserRoleModal}
        close={closeModal}
        onSubmit={loginFacebookUser}
        isLoading={isLoading}
      />
      <PasswordInputModal
        show={showPasswordInputModal}
        close={closeModal}
        onSubmit={loginUser}
        isLoading={isLoading}
      />
      <Button onClick={handleFBLogin}>
        <Localize id="button.fbLogin" />
        <Icon name="facebook" size={30} />
      </Button>
    </>
  );
};

FacebookLogin.propTypes = {
  facebookLogin: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  patchUser: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: selectors.authentication.getIsLoading(state),
});

const mapDispatchToProps = {
  ...actions.authentication,
  ...actions.users,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(FacebookLogin);
