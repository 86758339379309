import PropTypes from 'prop-types';
import React, { memo } from 'react';

import GroupCard from './GroupCard';

const GroupsSection = ({ collectionsPath, items }) => {
  return items.map((group) => (
    <GroupCard collectionsPath={collectionsPath} group={group} key={group.id} />
  ));
};

GroupsSection.propTypes = {
  collectionsPath: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
};

GroupsSection.defaultProps = {
  items: [],
};

export default memo(GroupsSection);
