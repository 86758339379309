import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Page from 'src/components/common/Page';
import ListenAgainBadge from '../ListenAgainBadge';

import './index.scss';

const FeedbackPreviewSound = ({ children, handleListenAgain }) => {
  return (
    <Page.Body className="feedback-sound-container">
      {children}
      <ListenAgainBadge handleListenAgain={handleListenAgain} />
    </Page.Body>
  );
};

FeedbackPreviewSound.propTypes = {
  children: PropTypes.node.isRequired,
  handleListenAgain: PropTypes.func.isRequired,
};

export default memo(FeedbackPreviewSound);
