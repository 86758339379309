import { createSelector } from 'reselect';

const getRouteState = createSelector(
  (state) => state.routing.locationBeforeTransitions,
  (routeState) => routeState
);

export default {
  getRouteState,
};
