import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { notifications, paths } from 'src/constants';
import { actions } from 'src/store';

import useIntlMessages from './useIntlMessages';

const useArchiveStudent = () => {
  const dispatch = useDispatch();
  const messages = useIntlMessages();
  const history = useHistory();

  const showSuccessNotification = useCallback(
    (message) => {
      dispatch(
        actions.notifications.showNotification(
          message,
          notifications.type.SUCCESS,
          notifications.duration.SHORT
        )
      );
    },
    [dispatch]
  );

  const archiveStudent = useCallback(
    (studentId) => {
      dispatch(
        actions.students.updateStudentById(studentId, {
          isActive: false,
        })
      ).then(() => {
        history.push(paths.SPEECH_THERAPIST);
        showSuccessNotification(messages.notification.studentArchived);
      });
    },
    [
      dispatch,
      history,
      messages.notification.studentArchived,
      showSuccessNotification,
    ]
  );

  const unarchiveStudent = useCallback(
    (studentId) => {
      dispatch(
        actions.students.updateStudentById(studentId, {
          isActive: true,
        })
      ).then(() => {
        showSuccessNotification(messages.notification.studentUnarchived);
      });
    },
    [dispatch, messages.notification.studentUnarchived, showSuccessNotification]
  );

  return { archiveStudent, unarchiveStudent };
};

export default useArchiveStudent;
