import React from 'react';

import { usePaymentModule } from 'src/hooks';
import { Redirect, useParams } from 'react-router';

const PaymentRouter = () => {
  const { paymentProvider } = useParams();

  const { Router } = usePaymentModule(paymentProvider) || {};

  if (!Router) {
    // Handle back to /payment
    return <Redirect path="/map" />;
  }

  return <Router />;
};

export default PaymentRouter;
