import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { common } from 'src/utils';
import { feedback, video } from 'src/constants';
import { useAudio, useIntlMessages } from 'src/hooks';

import {
  Container,
  FeedbackContainer,
  FeedbackPanel,
  FeedbackPreview,
  GameCoins,
  PageLoader,
  Navbar,
  Zoom,
  Topbar,
  Image,
} from 'src/components/common';

import './index.scss';
import LevelCard from './components/LevelCard';

const ORIENTATIONS = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
const ORIENTATION_POSITIONS = [1, 2, 3, 4];
const SHAKE_DURATION = 500;

const ReadTogetherBonusLevelsContainer = ({
  currency,
  handleCollectionEnd,
  handleLevelAnswer,
  hasLoaded,
  items,
  navigation,
  student,
}) => {
  const messages = useIntlMessages();

  const [cleanedTermIds, setCleanedTermIds] = useState([]);
  const [collectedCoins, setCollectedCoins] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [sceneItems, setSceneItems] = useState([]);
  const [numberOfDistractors, setNumberOfDistractors] = useState(0);
  const [selectedTerm, setSelectedTerm] = useState({});
  const [shake, setShake] = useState(false);
  const [playAudio, isAudioPlaying] = useAudio();

  useEffect(() => {
    setNumberOfDistractors(items.filter((item) => item.isDistractor).length);
  }, [items]);

  const scenePositions = useMemo(
    () =>
      ORIENTATIONS.map(
        (orientation) =>
          `${orientation}-${
            ORIENTATION_POSITIONS[
              Math.floor(Math.random() * ORIENTATION_POSITIONS.length)
            ]
          }`
      ),
    []
  );

  const handleAnswer = useCallback(
    (isCorrect) => {
      playAudio(feedback.COIN_COLLECT_SOUND_URL, () => {
        if (
          collectedCoins + 1 >= items.length - numberOfDistractors &&
          items.length > 0
        ) {
          playAudio(feedback.LEVEL_END_SOUND_URL, handleCollectionEnd);
        }
      });

      handleLevelAnswer(selectedTerm.id, isCorrect, currency?.id);

      setCollectedCoins((prevCollectedCoins) => prevCollectedCoins + 1);
      setDisplayModal(false);

      setCleanedTermIds([...cleanedTermIds, selectedTerm.id]);
    },
    [
      playAudio,
      handleLevelAnswer,
      selectedTerm.id,
      currency,
      cleanedTermIds,
      collectedCoins,
      items.length,
      handleCollectionEnd,
      numberOfDistractors,
    ]
  );

  const handleSelect = useCallback(
    (term) => {
      if (cleanedTermIds.includes(term.id) || isAudioPlaying) {
        return;
      }

      if (term.isDistractor) {
        if (student.hasEnabledErrorSound) {
          playAudio(feedback.BAD_SOUND_URL);
        }

        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, SHAKE_DURATION);
      } else {
        playAudio(feedback.HAPPY_SOUND_URL, () => {
          if (term.audioUrl !== '') {
            playAudio(term.audioUrl, () => {
              setDisplayModal(true);
            });
          } else {
            setDisplayModal(true);
          }
        });
      }

      setSelectedTerm(term);
    },
    [cleanedTermIds, isAudioPlaying, playAudio, student.hasEnabledErrorSound]
  );

  useEffect(() => {
    if (hasLoaded) {
      const levelItems = common.shuffle(items);

      setSceneItems(
        scenePositions.map((scenePosition, index) => ({
          scenePosition,
          ...levelItems[index],
        }))
      );
    }
  }, [hasLoaded, items, scenePositions]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      {navigation}
      <Navbar.GameNavbar
        currency={currency}
        title={messages.game.readTogether}
        videoName={video.NAME.READ_TOGETHER}
      />
      <Topbar>
        <GameCoins
          coinSize="small"
          type={currency.key}
          totalCoinsCount={items.length - numberOfDistractors}
          collectedCoinsCount={collectedCoins}
        />
      </Topbar>
      <Container className="read-together-bonus-levels-container">
        <FeedbackContainer isVisible={displayModal}>
          <FeedbackPreview.Sound
            handleListenAgain={() => playAudio(selectedTerm.audioUrl)}
          >
            <Image
              className="read-together-bonus-levels-container__image"
              alt={selectedTerm.key}
              src={selectedTerm.imageUrl}
            />
          </FeedbackPreview.Sound>
          <FeedbackPanel.Grades onClick={handleAnswer} />
        </FeedbackContainer>

        <Zoom
          mobileWidth={321}
          mobileHeight={535}
          mobileRatio="calc(100% - 140px)"
          align="center"
        >
          <div className="read-together-bonus-levels-container__body">
            {sceneItems.map((item, index) => (
              <LevelCard
                key={index}
                level={item}
                cleanedTermIds={cleanedTermIds}
                shake={shake}
                selectedTermId={selectedTerm.id}
                handleSelect={handleSelect}
              />
            ))}
          </div>
        </Zoom>
      </Container>
    </PageLoader>
  );
};

ReadTogetherBonusLevelsContainer.propTypes = {
  currency: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    imageUrl: PropTypes.string,
    placeholderImageUrl: PropTypes.string,
  }),
  handleCollectionEnd: PropTypes.func.isRequired,
  handleLevelAnswer: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      audioUrl: PropTypes.string,
      id: PropTypes.number,
      imageUrl: PropTypes.string,
      isDistractor: PropTypes.bool,
      key: PropTypes.string,
    })
  ),
  navigation: PropTypes.node,
  student: PropTypes.shape({
    hasEnabledErrorSound: PropTypes.bool.isRequired,
  }).isRequired,
};

ReadTogetherBonusLevelsContainer.defaultProps = {
  navigation: null,
  currency: null,
  items: [],
};

export default memo(ReadTogetherBonusLevelsContainer);
