import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { player } from 'src/utils';
import { useIntlMessages } from 'src/hooks';

import {
  Badge,
  Button,
  Icon,
  OverlayLoader,
  Student,
} from 'src/components/common';

import './index.scss';

const AdditionalStudents = ({
  hasLoaded,
  handleInviteNewUser,
  handleInviteUserAgain,
  invitedUsers,
  resolveUsers,
  userExtraStudents,
  userInactiveExtraStudents,
  user,
}) => {
  const messages = useIntlMessages();

  const handleSelectStudent = useCallback(
    (studentId, callback) => {
      player.setSelectedStudentId(studentId);
      resolveUsers().then(() => callback && callback());
    },
    [resolveUsers]
  );

  if (!hasLoaded) {
    return (
      <div className="additional-students-container">
        <OverlayLoader className="additional-students-container__overlay-loader" />
      </div>
    );
  }

  return (
    <div className="additional-students-container">
      <div className="additional-students-container__items">
        <div className="additional-students-container__invite-student-container">
          <Button
            variant="register"
            onClick={handleInviteNewUser}
            size="medium"
          >
            {messages.button.inviteStudent}
            <Icon name="inviteShadow" size={40} />
          </Button>
        </div>
        <div className="additional-students-container__students-section">
          {userExtraStudents.map((student) => (
            <Student
              key={student.id}
              student={student}
              handleOnClick={handleSelectStudent}
              userId={user.id}
            />
          ))}
          {userInactiveExtraStudents.map(({ fullNameForSpeechTherapist }) => (
            <div
              className="additional-students-container__students-section__inactive-user"
              key={fullNameForSpeechTherapist}
            >
              <Button disabled size="medium">
                {fullNameForSpeechTherapist}
              </Button>
              <p className="additional-students-container__students-section__inactive-user__text">
                {messages.label.noActiveSubscription}
              </p>
            </div>
          ))}
          {invitedUsers.map(({ email }) => (
            <div
              className="additional-students-container__students-section__invited-user"
              key={email}
            >
              <Button disabled size="medium">
                {email}
              </Button>
              <Badge
                className="additional-students-container__students-section__invited-user__call-again"
                iconName="redo"
                onClick={() => handleInviteUserAgain(email)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

AdditionalStudents.propTypes = {
  resolveUsers: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  userExtraStudents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userInactiveExtraStudents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  invitedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleInviteNewUser: PropTypes.func.isRequired,
  handleInviteUserAgain: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.students.getHasLoaded(state),
  isSubmitting: selectors.user.getIsSubmitting(state),
  userExtraStudents: selectors.students.getUserExtraStudents(state),
  userInactiveExtraStudents: selectors.students.getUserInactiveExtraStudents(
    state
  ),
  invitedUsers: selectors.students.getInvitedUsers(state),
  user: selectors.authentication.getUser(state),
});

const mapDispatchToProps = {
  ...actions.authentication,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(AdditionalStudents);
