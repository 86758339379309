import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ContentContainer } from 'src/components/common';

import './index.scss';

const LevelCard = ({
  canPaintPairs,
  cleanTerm,
  cleanedTerms,
  isPair,
  level,
  selectTerm,
  selectedTermId,
  shakeTermId,
}) => {
  const cardText = isPair ? level.pairLabel : level.label;

  const currentTerm = cleanedTerms.includes(level.id);

  const levelCardClassNames = useMemo(
    () =>
      cn('discover-level-card', {
        'discover-level-card--hidden': currentTerm,
        'discover-level-card--selected': !isPair && selectedTermId === level.id,
        'discover-level-card--shake': shakeTermId === level.id && isPair,
        [`discover-level-card--${level.pairColor}-border`]:
          canPaintPairs && selectedTermId === level.id,
      }),
    [level, shakeTermId, currentTerm, selectedTermId, isPair, canPaintPairs]
  );

  const levelCardTextClassNames = useMemo(
    () =>
      cn(
        'discover-level-card__text',
        `discover-level-card__text--${isPair ? level.pairColor : level.color}`
      ),
    [level, isPair]
  );

  return (
    <ContentContainer
      className={levelCardClassNames}
      onClick={() => (isPair ? cleanTerm(level) : selectTerm(level))}
    >
      <p className={levelCardTextClassNames}>{cardText.toUpperCase()}</p>
    </ContentContainer>
  );
};

LevelCard.propTypes = {
  cleanTerm: PropTypes.func.isRequired,
  cleanedTerms: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  isPair: PropTypes.bool.isRequired,
  level: PropTypes.shape({
    color: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    pairColor: PropTypes.string.isRequired,
    pairLabel: PropTypes.string.isRequired,
  }).isRequired,
  canPaintPairs: PropTypes.bool,
  selectTerm: PropTypes.func.isRequired,
  selectedTermId: PropTypes.number,
  shakeTermId: PropTypes.number,
};

LevelCard.defaultProps = {
  canPaintPairs: false,
  selectedTermId: null,
  shakeTermId: null,
};

export default memo(LevelCard);
