import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Page from '../Page';
import Zoom from '../Zoom';

import './index.scss';

const FeedbackContainer = ({ children, isVisible }) => {
  if (!isVisible) {
    return null;
  }
  return (
    <>
      <div className="feedback-container__background" />
      <Zoom
        className="feedback-container__zoom"
        mobileWidth={320}
        mobileHeight={640}
        mobileRatio="calc(100% - 80px)"
      >
        <Page className="feedback-container">
          <Page.Section vertical>{children}</Page.Section>
        </Page>
      </Zoom>
    </>
  );
};

FeedbackContainer.propTypes = {
  children: PropTypes.node,
  isVisible: PropTypes.bool,
};

FeedbackContainer.defaultProps = {
  children: null,
  isVisible: false,
};

export default memo(FeedbackContainer);
