import _cloneDeep from 'lodash/cloneDeep';

import { jwt, player } from 'src/utils';
import { actions, statusCodes } from 'src/constants';

const initialState = {
  data: {},
  hasLoaded: false,
  hasSubmitted: false,
  isAuthenticated: false,
  isLoading: false,
  isSubmitting: false,
  hasSubmittedForgotPassword: false,
};

const actionMap = {
  [actions.AUTHENTICATE_USER_REQUEST]: (state) => ({
    ...state,
    hasLoaded: false,
    isAuthenticated: false,
    isLoading: true,
  }),
  [actions.AUTHENTICATE_USER_SUCCESS]: (state, { result }) => ({
    ...state,
    data: result.data,
    hasLoaded: true,
    isAuthenticated: true,
    isLoading: false,
  }),
  [actions.AUTHENTICATE_USER_FAILURE]: (state, { error }) => {
    player.clearSelectedStudentId();

    return {
      ...state,
      data:
        error.response.status === statusCodes.PAYMENT_REQUIRED
          ? { user: error.response.data.user }
          : {},
      hasLoaded: true,
      isAuthenticated: error.response.status === statusCodes.PAYMENT_REQUIRED,
      isLoading: false,
    };
  },

  [actions.FACEBOOK_LOGIN_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [actions.FACEBOOK_LOGIN_SUCCESS]: (state, { result }) => {
    jwt.setToken(result.data.token);

    return {
      ...state,
      data: { user: result.data },
      hasLoaded: true,
      hasSubmitted: true,
      isAuthenticated: true,
      isLoading: false,
    };
  },
  [actions.FACEBOOK_LOGIN_FAILURE]: (state) => ({
    ...state,
    hasLoaded: true,
    hasSubmitted: true,
    isAuthenticated: false,
    isLoading: false,
  }),

  [actions.GOOGLE_LOGIN_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [actions.GOOGLE_LOGIN_SUCCESS]: (state, { result }) => {
    jwt.setToken(result.data.token);

    return {
      ...state,
      data: { user: result.data },
      hasLoaded: true,
      hasSubmitted: true,
      isAuthenticated: true,
      isLoading: false,
    };
  },
  [actions.GOOGLE_LOGIN_FAILURE]: (state) => ({
    ...state,
    hasLoaded: true,
    hasSubmitted: true,
    isAuthenticated: false,
    isLoading: false,
  }),

  [actions.REGISTER_CLIENT_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [actions.REGISTER_CLIENT_SUCCESS]: (state, { result }) => {
    jwt.setToken(result.data.token);

    return {
      ...state,
      data: { user: result.data },
      hasLoaded: true,
      hasSubmitted: true,
      isAuthenticated: true,
      isLoading: false,
    };
  },
  [actions.REGISTER_CLIENT_FAILURE]: (state) => ({
    ...state,
    hasLoaded: true,
    hasSubmitted: true,
    isAuthenticated: false,
    isLoading: false,
  }),

  [actions.REGISTER_SPEECH_THERAPIST_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [actions.REGISTER_SPEECH_THERAPIST_SUCCESS]: (state, { result }) => {
    jwt.setToken(result.data.token);

    return {
      ...state,
      data: { user: result.data },
      hasLoaded: true,
      hasSubmitted: true,
      isAuthenticated: true,
      isLoading: false,
    };
  },
  [actions.REGISTER_SPEECH_THERAPIST_FAILURE]: (state) => ({
    ...state,
    hasLoaded: true,
    hasSubmitted: true,
    isAuthenticated: false,
    isLoading: false,
  }),

  [actions.LOGIN_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [actions.LOGIN_SUCCESS]: (state, { result }) => {
    jwt.setToken(result.data.token);

    return {
      ...state,
      data: { user: result.data },
      hasLoaded: true,
      isAuthenticated: true,
      isLoading: false,
    };
  },
  [actions.LOGIN_FAILURE]: (state) => ({
    ...state,
    hasLoaded: true,
    isAuthenticated: false,
    isLoading: false,
  }),

  [actions.FORGOT_PASSWORD_REQUEST]: (state) => ({
    ...state,
    hasSubmittedForgotPassword: false,
  }),
  [actions.FORGOT_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    hasSubmittedForgotPassword: true,
  }),
  [actions.FORGOT_PASSWORD_FAILURE]: (state) => ({
    ...state,
    hasSubmittedForgotPassword: true,
  }),

  [actions.STUDENT_UPDATE_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [actions.STUDENT_UPDATE_SUCCESS]: (state, { result }) => {
    const dataCopy = _cloneDeep(state.data);

    if (dataCopy.student?.id !== result.data.id) {
      return state;
    }

    dataCopy.student = {
      ...dataCopy.student,
      ...result.data,
    };

    return {
      ...state,
      data: dataCopy,
      isLoading: false,
    };
  },

  [actions.SPEECH_SOUND_CREATE_SUCCESS]: (state, { result }) => {
    const dataCopy = _cloneDeep(state.data);

    if (dataCopy.student?.id !== result.data.id) {
      return state;
    }

    dataCopy.student.SpeechSoundId = result.data.SpeechSoundId;
    return {
      ...state,
      data: dataCopy,
    };
  },

  [actions.USER_UPDATE_REQUEST]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: true,
  }),
  [actions.USER_UPDATE_FAILURE]: (state) => {
    return {
      ...state,
      hasLoaded: true,
      isLoading: false,
    };
  },
  [actions.USER_UPDATE_SUCCESS]: (state, { result }) => {
    const dataCopy = _cloneDeep(state.data);

    dataCopy.user = result.data;

    return {
      ...state,
      data: dataCopy,
      hasLoaded: true,
      isLoading: false,
    };
  },

  [actions.SUBSCRIPTION_SELECT_SUCCESS]: (state, { result }) => ({
    ...state,
    data: {
      ...state.data,
      user: {
        ...state.data.user,
        SubscriptionId: result.data.SubscriptionId,
      },
    },
  }),

  [actions.DISMISS_TRIAL_EXPIRATION_ALERT_SUCCESS]: (state) => {
    const dataCopy = _cloneDeep(state.data);

    dataCopy.user.hasReceivedTrialExpirationNotification = true;

    return {
      ...state,
      data: dataCopy,
    };
  },

  [actions.DISMISS_UPDATE_NOTIFICATION_ALERT_REQUEST]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [actions.DISMISS_UPDATE_NOTIFICATION_ALERT_SUCCESS]: (state) => {
    const dataCopy = _cloneDeep(state.data);

    dataCopy.user.hasReceivedUpdateNotification = true;

    return {
      ...state,
      data: dataCopy,
      isSubmitting: false,
    };
  },
  [actions.DISMISS_UPDATE_NOTIFICATION_ALERT_FAILURE]: (state) => ({
    ...state,
    isSubmitting: false,
  }),

  [actions.SUBSCRIPTION_CANCEL_SUCCESS]: (state) => {
    const dataCopy = _cloneDeep(state.data);

    dataCopy.user.recurringRefId = null;

    return {
      ...state,
      data: dataCopy,
      isSubmitting: false,
    };
  },

  [actions.RESOLVE_USERS_REQUEST]: (state) => ({
    ...state,
    hasLoaded: false,
  }),
  [actions.RESOLVE_USERS_SUCCESS]: (state, { result }) => ({
    ...state,
    data: result.data,
    hasLoaded: true,
  }),
  [actions.RESOLVE_USERS_FAILURE]: (state) => ({
    ...state,
    hasLoaded: true,
  }),

  [actions.STATE_RESET]: (state) => ({
    ...state,
    hasSubmitted: false,
  }),

  [actions.PATCH_USER_SUCCESS]: (state, { result }) => ({
    ...state,
    data: { ...state.data, user: { ...state.data.user, ...result.data } },
  }),
};

export default (state = initialState, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
