import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const LevelContentText = ({ label, size }) => {
  return <p className={cn('level-content-text', size)}>{label}</p>;
};

LevelContentText.defaultProps = {
  label: '',
  size: undefined,
};

LevelContentText.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['small']),
};

export default memo(LevelContentText);
