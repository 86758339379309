import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const FlashCardsStepThreeExtraCollections = ({
  flashCardsStepThreeExtraCollections,
  flashCardsStepThreeExtraCurrency,
  getCollections,
  hasLoaded,
  pageCount,
  student,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="flash-cards-collections-container--step-three-extra"
      collectionsPath={paths.FLASH_CARDS_STEP_THREE_EXTRA}
      currency={flashCardsStepThreeExtraCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={flashCardsStepThreeExtraCollections}
      levelsPath={paths.FLASH_CARDS_STEP_THREE_EXTRA_LEVELS}
      pageCount={pageCount}
      student={student}
      title={messages.game.flashCards}
    />
  );
};

FlashCardsStepThreeExtraCollections.propTypes = {
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  flashCardsStepThreeExtraCollections: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  flashCardsStepThreeExtraCurrency: PropTypes.shape({}).isRequired,
  pageCount: PropTypes.number.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.flashCardsStepThreeExtraCollections.getHasLoaded(state),
  flashCardsStepThreeExtraCurrency: selectors.flashCardsStepThreeExtraCollections.getCurrencyData(
    state
  ),
  flashCardsStepThreeExtraCollections: selectors.flashCardsStepThreeExtraCollections.getCollectionsData(
    state
  ),
  pageCount: selectors.flashCardsStepThreeExtraCollections.getPageCount(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.flashCardsStepThreeExtra,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(FlashCardsStepThreeExtraCollections);
