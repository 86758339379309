import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';

import { Container } from './components';

const PaintingCollections = ({
  getCollectionsByGroupId,
  hasLoaded,
  pageCount,
  paintingCollections,
  paintingCurrency,
  paintingGroupData,
}) => {
  return (
    <Container
      collectionsPath={paths.PAINTING_COLLECTIONS}
      currency={paintingCurrency}
      getCollectionsByGroupId={getCollectionsByGroupId}
      groupData={paintingGroupData}
      hasLoaded={hasLoaded}
      items={paintingCollections}
      levelsPath={paths.PAINTING_LEVELS}
      pageCount={pageCount}
    />
  );
};

PaintingCollections.propTypes = {
  getCollectionsByGroupId: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  paintingCollections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  paintingCurrency: PropTypes.shape({}).isRequired,
  paintingGroupData: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.paintingCollections.getHasLoaded(state),
  pageCount: selectors.paintingCollections.getPageCount(state),
  paintingCurrency: selectors.paintingCollections.getCurrencyData(state),
  paintingGroupData: selectors.paintingCollections.getGroupData(state),
  paintingCollections: selectors.paintingCollections.getCollectionsData(state),
});

const mapDispatchToProps = {
  ...actions.painting,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(PaintingCollections);
