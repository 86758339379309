import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { useIntlMessages } from 'src/hooks';

import {
  Container,
  Wallet,
  OverlayLoader,
  FeedbackComponent,
} from 'src/components/common';

import { PieChartContainer } from '../components';

import './index.scss';

const TodayStatistics = ({
  feedbackStatisticsData,
  getFeedbackStatistics,
  hasLoaded,
}) => {
  const messages = useIntlMessages();

  useEffect(() => {
    getFeedbackStatistics();
  }, [getFeedbackStatistics]);

  if (!hasLoaded) {
    return <OverlayLoader />;
  }

  return (
    <Container className="today-statistics-container">
      <div>
        <p className="today-statistics-container__description">
          {messages.page.statistics.todayEarned}
        </p>
        <div className="feedback-grade-sum">
          <FeedbackComponent isFeedbackPositive collapsed hideText />
          {feedbackStatisticsData.correctAnswers}
        </div>
        <div className="feedback-grade-sum">
          <FeedbackComponent collapsed hideText />
          {feedbackStatisticsData.wrongAnswers}
        </div>
      </div>
      <div>
        <PieChartContainer
          className="today-statistics-container__pie-chart"
          statisticsData={feedbackStatisticsData}
          description={messages.page.statistics.todayActivityReview}
          outerRadius={180}
          aspect={500 / 490}
        />
      </div>
      <div>
        <p className="today-statistics-container__description">
          {messages.page.statistics.currentKokolingoWallet}
        </p>
        <Wallet vertical />
      </div>
    </Container>
  );
};

TodayStatistics.propTypes = {
  getFeedbackStatistics: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  feedbackStatisticsData: PropTypes.shape({
    allTimeEarnedKokolingoPoints: PropTypes.number,
    earnedKokolingoPoints: PropTypes.number,
    correctAnswers: PropTypes.number,
    wrongAnswers: PropTypes.number,
  }),
};

TodayStatistics.defaultProps = {
  feedbackStatisticsData: PropTypes.shape({
    allTimeEarnedKokolingoPoints: 0,
    earnedKokolingoPoints: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  }),
};

const mapStateToProps = (state) => ({
  feedbackStatisticsData: selectors.feedback.getFeedbackData(state),
  hasLoaded: selectors.feedback.getHasLoaded(state),
});

const mapDispatchToProps = {
  ...actions.feedback,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(TodayStatistics);
