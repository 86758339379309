import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { useIntlMessages } from 'src/hooks';

import { Form, Button, Input, Modal, Icon } from 'src/components/common';

import validationSchema from './validationSchema';

import './index.scss';

const AddStudent = ({ isDisabled, onSubmit }) => {
  const messages = useIntlMessages();
  const [showModal, setShowModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  return (
    <>
      <div className="add-student-container">
        <Button
          onClick={() => {
            setShowModal(true);
          }}
          disabled={isDisabled}
          size="medium"
        >
          {messages.label.addStudent}
          <Icon name="addStudent" size={40} />
        </Button>
      </div>
      {showModal && (
        <Modal
          className="add-student-modal"
          show={showModal}
          onClose={() => setShowModal(false)}
          onOutsideClick={() => setShowModal(false)}
        >
          <Icon
            className="add-student-modal__student-icon"
            name="addStudent"
            size={isMobile ? 70 : 150}
          />
          <Modal.Title>{messages.label.addNewStudent}</Modal.Title>
          <Modal.Description>
            {
              messages.modal.speechTherapistsHome.packageStudents.addStudent
                .description
            }
          </Modal.Description>
          <div className="add-student-modal__input-description">
            {messages.input.addNewStudentDescription}
          </div>
          <Form
            formClassName="add-student-modal__form"
            buttonClassName="add-student-modal__form__button collapsed"
            onSubmit={(data) => {
              onSubmit(data);
              setSubmitted(true);
            }}
            validationSchema={validationSchema}
            submitBtnLabel={messages.button.addToList}
            submittable={submitted}
          >
            <Input
              className="add-student-modal__form__input"
              name="fullName"
              placeholder={messages.input.addNewStudentPlaceholder}
              disabled={isDisabled}
              required
            />
          </Form>
        </Modal>
      )}
    </>
  );
};

AddStudent.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(AddStudent);
