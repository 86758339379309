import { actions as actionConstants } from 'src/constants';

export default () => (store) => (next) => (action) => {
  if (
    action.type === actionConstants.MAGIC_LINK_SUCCESS ||
    action.type === actionConstants.MAGIC_LINK_SUCCESS
  ) {
    store.dispatch({
      type: actionConstants.LOGIN_SUCCESS,
      result: {
        data: action.result.data.user,
        redirectUrl: action.result.data.redirectUrl,
      },
    });
  }

  if (
    action.type === actionConstants.MAGIC_LINK_FAILURE ||
    action.type === actionConstants.MAGIC_LINK_FAILURE
  ) {
    window.location.href = '/';
  }

  const nextAction = next(action);

  if (
    action.type === actionConstants.LOGIN_SUCCESS &&
    action.result?.redirectUrl
  ) {
    window.location.href = action.result?.redirectUrl;
  }

  return nextAction;
};
