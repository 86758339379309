import { actions, paths } from 'src/constants';

export default {
  getDailyMap: (speechSoundId) => ({
    [actions.API_CALL]: {
      types: [
        actions.DAILY_MAP_GET_REQUEST,
        actions.DAILY_MAP_GET_SUCCESS,
        actions.DAILY_MAP_GET_FAILURE,
      ],
      promise: (client) =>
        client.get(paths.api.DAILY_MAP, {
          params: {
            speechSoundId,
          },
        }),
    },
  }),

  getDailyMaps: () => ({
    [actions.API_CALL]: {
      types: [
        actions.DAILY_MAPS_GET_REQUEST,
        actions.DAILY_MAPS_GET_SUCCESS,
        actions.DAILY_MAPS_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.api.DAILY_MAPS),
    },
  }),

  resetDailyMap: () => ({
    type: actions.DAILY_MAP_RESET,
  }),

  getDailyMapTemplates: () => ({
    [actions.API_CALL]: {
      types: [
        actions.DAILY_MAP_TEMPLATES_GET_REQUEST,
        actions.DAILY_MAP_TEMPLATES_GET_SUCCESS,
        actions.DAILY_MAP_TEMPLATES_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.api.DAILY_MAP_TEMPLATES),
    },
  }),

  activateDailyMap: (DailyMapTemplateId, StudentId, SpeechSoundId) => ({
    [actions.API_CALL]: {
      types: [
        actions.DAILY_MAP_POST_REQUEST,
        actions.DAILY_MAP_POST_SUCCESS,
        actions.DAILY_MAP_POST_FAILURE,
      ],
      promise: (client) =>
        client.post(paths.api.DAILY_MAP, {
          startedAt: Date.now(),
          endedAt: null,
          meta: {
            currentActivityIndex: 0,
            progress: {},
            dailyDuration: 'MEDIUM',
          },
          DailyMapTemplateId,
          StudentId,
          SpeechSoundId,
        }),
    },
  }),

  updateDailyMap: (dailyMapId, data) => ({
    [actions.API_CALL]: {
      types: [
        actions.DAILY_MAP_UPDATE_REQUEST,
        actions.DAILY_MAP_UPDATE_SUCCESS,
        actions.DAILY_MAP_UPDATE_FAILURE,
      ],
      promise: (client) =>
        client.put(paths.build(paths.api.DAILY_MAPS_ID, dailyMapId), data),
    },
  }),

  updateFeedback: (dailyMapId, data) => ({
    [actions.API_CALL]: {
      types: [
        actions.FEEDBACK_UPDATE_REQUEST,
        actions.FEEDBACK_UPDATE_SUCCESS,
        actions.FEEDBACK_UPDATE_FAILURE,
      ],
      promise: (client) =>
        client.put(
          paths.build(paths.api.DAILY_MAPS_ID_FEEDBACK, dailyMapId),
          data
        ),
    },
  }),

  getDailyMapFeedback: (dailyMapId, days) => ({
    [actions.API_CALL]: {
      types: [
        actions.DAILY_MAP_FEEDBACK_GET_REQUEST,
        actions.DAILY_MAP_FEEDBACK_GET_SUCCESS,
        actions.DAILY_MAP_FEEDBACK_GET_FAILURE,
      ],
      promise: (client) =>
        client.get(paths.build(paths.api.DAILY_MAPS_ID_FEEDBACK, dailyMapId), {
          params: { days },
        }),
    },
  }),

  getLevels: (params) => ({
    [actions.API_CALL]: {
      types: [
        actions.DAILY_MAP_LEVELS_GET_REQUEST,
        actions.DAILY_MAP_LEVELS_GET_SUCCESS,
        actions.DAILY_MAP_LEVELS_GET_FAILURE,
      ],
      promise: (client) =>
        client.get(paths.buildQuery(paths.api.DAILY_MAPS_LEVELS, params)),
    },
  }),

  verifyCollection: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.DAILY_MAP_COLLECTION_VERIFICATION_POST_REQUEST,
        actions.DAILY_MAP_COLLECTION_VERIFICATION_POST_SUCCESS,
        actions.DAILY_MAP_COLLECTION_VERIFICATION_POST_FAILURE,
      ],
      promise: (client) =>
        client.post(paths.api.DAILY_MAP_COLLECTION_VERIFICATION, data),
    },
  }),

  resetLevels: () => ({
    type: actions.RESET_DAILY_MAP_LEVELS,
  }),

  setShowLastDayModal: (payload) => ({
    type: actions.SET_SHOW_LAST_DAY_MODAL,
    payload,
  }),

  setShowDailyProgressModal: (payload) => ({
    type: actions.SET_SHOW_DAILY_PROGRESS_MODAL,
    payload,
  }),

  setShowFinalModal: (payload) => ({
    type: actions.SET_SHOW_FINAL_MODAL,
    payload,
  }),
};
