import PropTypes from 'prop-types';
import React, { memo } from 'react';

import LevelAwardCoverItem from './LevelAwardCoverItem';

const LevelAwardCoverItemsSection = ({
  discoveredPositions,
  positionNames,
  groupKey,
}) => {
  return positionNames.map((positionName, index) => {
    return (
      <LevelAwardCoverItem
        key={index}
        discoveredPositions={discoveredPositions}
        positionIndex={index + 1}
        positionName={positionName}
        groupKey={groupKey}
      />
    );
  });
};

LevelAwardCoverItemsSection.propTypes = {
  discoveredPositions: PropTypes.arrayOf(PropTypes.number.isRequired),
  positionNames: PropTypes.arrayOf(PropTypes.string.isRequired),
  groupKey: PropTypes.string.isRequired,
};

export default memo(LevelAwardCoverItemsSection);
