import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import cn from 'classnames';

import './index.scss';

const Label = ({ className, label, required, htmlFor }) => {
  const labelClassNames = useMemo(() => cn('label-container', className), [
    className,
  ]);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label htmlFor={htmlFor} className={labelClassNames}>
      <span
        dangerouslySetInnerHTML={{
          __html: label,
        }}
      />
      {required && <span className="label-container__asterisk">*</span>}
    </label>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

Label.defaultProps = {
  className: '',
  required: false,
};

export default memo(Label);
