import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { Button } from 'src/components/common';

const StudentsSection = ({ items, handleStudentSelect }) => {
  return items.map((student) => (
    <Button
      className="students-selection-menu__button"
      key={student.id}
      label={student.fullName}
      variant="tertiary"
      onClick={() => handleStudentSelect(student.id)}
    />
  ));
};

StudentsSection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
};

StudentsSection.defaultProps = {
  items: [],
};

export default memo(StudentsSection);
