import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormContext } from 'react-hook-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { useIntlMessages, useInviteUser, useModalState } from 'src/hooks';
import { actions, selectors } from 'src/store';

import { Button, Dropdown, Icon, Input, Page } from 'src/components/common';

import validationSchema from './validationSchema';
import ArchiveStudentModal from './ArchiveStudentModal';

import './index.scss';

const StudentForm = ({
  datePickerKeyDown,
  datePickerOnChange,
  student,
  genders,
  onSubmit,
  selectedSpeechSound,
  selectedGender,
  speechSounds,
  userOnlyStudents,
  getStudents,
}) => {
  const messages = useIntlMessages();

  useEffect(() => {
    if (!userOnlyStudents.length) {
      getStudents();
    }
  }, [getStudents, userOnlyStudents]);

  const handleInviteUser = useInviteUser();

  const [
    isArchiveModalVisible,
    displayArchiveModal,
    hideArchiveModal,
  ] = useModalState();

  const defaultValues = {
    address: student.address,
    dateOfBirth: student.dateOfBirth,
    parentEmail: student.parentEmail,
    parentName: student.parentName,
    parentPhoneNumber: student.parentPhoneNumber,
    fullNameForSpeechTherapist: student.fullNameForSpeechTherapist,
  };

  const isDisabled = student.id === userOnlyStudents[0]?.id;
  const isSpeechTherapistStudent = !!student.fullNameForSpeechTherapist;

  const methods = useForm({
    defaultValues,
    validationSchema,
  });

  const { dirty } = methods.formState;

  const isStudentPackageStudent = useMemo(
    () => userOnlyStudents.map((user) => user.id).includes(student?.id),
    [userOnlyStudents, student]
  );

  return (
    <FormContext {...methods}>
      <Page.Section
        as="form"
        className="student-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <Page.Body color="light">
          <Input
            className="student-form__input"
            label={messages.label.studentName}
            name="fullNameForSpeechTherapist"
            required
          />
          <Dropdown
            classNames="student-form__dropdown"
            placeholder={messages.label.selectedSpeechSoundPlaceholder}
            defaultValue={selectedSpeechSound}
            label={messages.label.selectedSpeechSound}
            name="SpeechSoundId"
            options={speechSounds}
            required
          />
          <Input
            className="student-form__input"
            label={messages.label.parentName}
            name="parentName"
          />
          <Input
            className="student-form__input"
            label={messages.label.phoneNumber}
            name="parentPhoneNumber"
            required={!isSpeechTherapistStudent}
          />
        </Page.Body>
        <Page.Body color="light">
          <Dropdown
            classNames="student-form__dropdown"
            placeholder={messages.label.genderPlaceholder}
            defaultValue={selectedGender}
            label={messages.label.gender}
            name="gender"
            options={genders}
          />
          <Input
            className="student-form__input"
            label={messages.label.dateOfBirth}
            name="dateOfBirth"
            placeholder="dd.mm.yyyy"
            onChange={datePickerOnChange}
            onKeyDown={datePickerKeyDown}
            maxLength={10}
          />
          <Input
            className="student-form__input"
            label={messages.label.email}
            name="parentEmail"
            required={!isSpeechTherapistStudent}
          />
          <Input
            className="student-form__input"
            label={messages.label.address}
            name="address"
          />
          {isMobile && isStudentPackageStudent && (
            <div className="student-form__archive">
              <Button
                variant="danger"
                onClick={displayArchiveModal}
                className="student-form__archive--btn-mobile"
                disabled={isDisabled}
              >
                {messages.button.archive}
                <Icon name="archive" size={40} />
              </Button>
            </div>
          )}
        </Page.Body>
      </Page.Section>
      <Page.Footer className="student-form__footer">
        {!isMobile && isStudentPackageStudent && (
          <Button
            variant="danger"
            onClick={displayArchiveModal}
            disabled={isDisabled}
          >
            {messages.button.archive}
            <Icon name="archive" size={40} />
          </Button>
        )}
        <div className="student-form__footer__right">
          {isStudentPackageStudent && (
            <Button
              variant="register"
              onClick={() => handleInviteUser(student.parentEmail, student.id)}
              disabled={dirty}
            >
              {messages.button.inviteStudent}
              <Icon name="inviteShadow" size={isMobile ? 20 : 40} />
            </Button>
          )}
          <Button onClick={methods.handleSubmit(onSubmit)}>
            {messages.button.save}
            <Icon name="save" size={isMobile ? 20 : 40} />
          </Button>
        </div>
      </Page.Footer>
      {isArchiveModalVisible && (
        <ArchiveStudentModal
          studentId={student.id}
          handleCloseModal={hideArchiveModal}
        />
      )}
    </FormContext>
  );
};

StudentForm.propTypes = {
  datePickerKeyDown: PropTypes.func.isRequired,
  datePickerOnChange: PropTypes.func.isRequired,
  genders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedGender: PropTypes.string,
  selectedSpeechSound: PropTypes.string,
  speechSounds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  student: PropTypes.shape({
    SpeechSoundId: PropTypes.number,
    address: PropTypes.string,
    dateOfBirth: PropTypes.string,
    fullNameForSpeechTherapist: PropTypes.string,
    gender: PropTypes.string,
    id: PropTypes.number,
    parentEmail: PropTypes.string,
    parentName: PropTypes.string,
    parentPhoneNumber: PropTypes.string,
  }).isRequired,
  userOnlyStudents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  getStudents: PropTypes.func.isRequired,
};

StudentForm.defaultProps = {
  selectedGender: null,
  selectedSpeechSound: null,
};

const mapStateToProps = (state) => ({
  userOnlyStudents: selectors.students.getUserOnlyStudents(state),
});

const mapDispatchToProps = {
  getStudents: actions.students.getStudents,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(StudentForm);
