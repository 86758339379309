import React from 'react';
import { Localize } from '@kokolingo/localisation';
import { boolean, object, string } from 'yup';

export default object().shape({
  fullName: string().required(() => <Localize id="error.fullNameRequired" />),
  email: string()
    .email(() => <Localize id="error.emailFormatInvalid" />)
    .required(() => <Localize id="error.emailRequired" />),
  password: string()
    .required(() => <Localize id="error.passwordRequired" />)
    .min(8, () => <Localize id="error.passwordMinimumCharacters" />)
    .max(20, () => <Localize id="error.passwordMaximumCharacters" />),
  studentFullName: string().required(() => (
    <Localize id="error.childNameRequired" />
  )),
  phoneNumber: string().required(() => (
    <Localize id="error.phoneNumberRequired" />
  )),
  state: string().notOneOf(
    ['select state', 'izaberite županiju', 'wybierz województwo'],
    () => <Localize id="error.stateRequired" />
  ),
  gdpr: boolean().oneOf([true], () => <Localize id="error.fieldRequired" />),
});
