import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

import spacers from 'src/theme/spacers.scss';

import './index.scss';

const DEFAULT_RATION = 1;
const MAX_WIDTH = parseInt(spacers.pageWidth, 10);
const MAX_HEIGHT = parseInt(spacers.pageHeight, 10);

const ViewProvider = ({ children }) => {
  const [ration, setRatio] = useState(DEFAULT_RATION);
  const shouldRotate = false;
  const observer = useMemo(
    () =>
      isMobile
        ? new ResizeObserver(() => {
            setRatio(1);
          })
        : new ResizeObserver(() => {
            const widthRatio = document.body.clientWidth / MAX_WIDTH;
            const heightRatio = document.body.clientHeight / MAX_HEIGHT;

            let nextZoom = DEFAULT_RATION;
            if (widthRatio < DEFAULT_RATION || heightRatio < DEFAULT_RATION) {
              nextZoom = widthRatio < heightRatio ? widthRatio : heightRatio;
            }
            setRatio(nextZoom);
          }),
    []
  );

  useEffect(() => {
    observer.observe(document.body);

    return () => {
      observer.disconnect();
    };
  }, [observer]);

  useEffect(() => {
    if (isMobile) {
      document.body.classList.add('mobile');
    } else {
      document.body.classList.add('desktop');
    }
  }, []);

  return (
    <div
      id="view-provider"
      className="view-provider"
      style={{
        zoom: `${ration * 100}%`,
        transform: shouldRotate && 'rotate(90deg)',
        transformOrigin:
          shouldRotate && `${MAX_HEIGHT / 2}px ${MAX_HEIGHT / 2}px`, // In portrait mode use height as relevant dimension
      }}
    >
      <div className="view-provider__container">{children}</div>
    </div>
  );
};

ViewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(ViewProvider);
