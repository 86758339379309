import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { isMobile } from 'react-device-detect';

import Icon from '../Icon';
import Localize from '../Localize';
import Badge from '../Badge';

import './index.scss';

const FeedbackComponent = ({
  isFeedbackPositive,
  hideText,
  collapsed,
  onClick,
}) => {
  const iconName = useMemo(
    () => (isFeedbackPositive ? 'green-star' : 'yellow-star'),
    [isFeedbackPositive]
  );

  // Don't pass event because of react event synthetic behavior. It throws warnings.
  const handleClick = useCallback(() => onClick(isFeedbackPositive), [
    onClick,
    isFeedbackPositive,
  ]);

  const labelKey = isFeedbackPositive ? 'label.great' : 'label.good';

  return (
    <div className="feedback-grade">
      {!hideText && (
        <p onClick={handleClick}>
          <Localize id={labelKey} />
        </p>
      )}
      {collapsed ? (
        <Icon name={iconName} size={isMobile ? 20 : 40} onClick={handleClick} />
      ) : (
        <Badge
          onClick={handleClick}
          iconName={iconName}
          size="large"
          type={isFeedbackPositive ? 'success' : undefined}
        />
      )}
    </div>
  );
};

FeedbackComponent.propTypes = {
  isFeedbackPositive: PropTypes.bool,
  hideText: PropTypes.bool,
  collapsed: PropTypes.bool,
  onClick: PropTypes.func,
};

FeedbackComponent.defaultProps = {
  isFeedbackPositive: false,
  hideText: false,
  collapsed: false,
  onClick: () => {},
};

export default memo(FeedbackComponent);
