import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';

import cactuses from 'src/images/memory/green.png';

import { Container } from './components';

const MemoryStepTwoGroups = ({
  getGroups,
  hasLoaded,
  memoryStepTwoCurrency,
  memoryStepTwoGroups,
  student,
}) => {
  return (
    <Container
      cardBackgroundImage={cactuses}
      className="memory-groups-container--step-two"
      collectionsPath={paths.MEMORY_STEP_TWO_COLLECTIONS}
      currency={memoryStepTwoCurrency}
      getGroups={getGroups}
      hasLoaded={hasLoaded}
      items={memoryStepTwoGroups}
      student={student}
    />
  );
};

MemoryStepTwoGroups.propTypes = {
  getGroups: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  memoryStepTwoCurrency: PropTypes.shape({}).isRequired,
  memoryStepTwoGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.memoryStepTwoGroups.getHasLoaded(state),
  memoryStepTwoCurrency: selectors.memoryStepTwoGroups.getCurrencyData(state),
  memoryStepTwoGroups: selectors.memoryStepTwoGroups.getGroupsData(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.memoryStepTwo,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(MemoryStepTwoGroups);
