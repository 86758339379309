import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';
import { Image } from 'src/components/common';

const AnswerImage = ({ answer }) => {
  const answerImageClassNames = useMemo(
    () =>
      cn(
        'wheel-of-fortune-answer-image',
        `wheel-of-fortune-answer-image--${answer.position}`
      ),
    [answer.position]
  );

  return (
    <Image
      className={answerImageClassNames}
      alt="answer"
      src={answer.imageUrl}
    />
  );
};

AnswerImage.propTypes = {
  answer: PropTypes.shape({
    imageUrl: PropTypes.string,
    position: PropTypes.string,
  }).isRequired,
};

export default memo(AnswerImage);
