import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';

import Icon from 'src/components/common/Icon';

import './index.scss';

const FIRST_PAGE = 1;

const ArrowPagination = ({
  activePage,
  pageCount,
  next,
  prev,
  children,
  withPageLabel,
  vertical,
  collapse,
}) => {
  const className = useMemo(
    () =>
      cn('pagination-container', {
        'pagination-container--vertical': vertical,
        'pagination-container--collapse': collapse,
      }),
    [vertical, collapse]
  );
  return (
    <div className={className}>
      <div
        className="pagination-container__arrow pagination-container__arrow--left"
        onClick={prev}
        onKeyPress={prev}
        role="button"
        tabIndex={0}
      >
        {activePage !== FIRST_PAGE && (
          <Icon size={isMobile ? 30 : 100} name="move-left" />
        )}
      </div>
      <div className="pagination-container__content">{children}</div>
      <div
        className="pagination-container__arrow pagination-container__arrow--right"
        onClick={next}
        onKeyPress={next}
        role="button"
        tabIndex={0}
      >
        {activePage !== pageCount && pageCount > 1 && (
          <Icon size={isMobile ? 30 : 100} name="move-right" />
        )}
      </div>
      {withPageLabel && pageCount > 1 && (
        <div className="pagination-container__text">{`${activePage}/${pageCount}`}</div>
      )}
    </div>
  );
};

ArrowPagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  next: PropTypes.func,
  prev: PropTypes.func,
  pageCount: PropTypes.number.isRequired,
  withPageLabel: PropTypes.bool,
  vertical: PropTypes.bool,
  collapse: PropTypes.bool,
};

ArrowPagination.defaultProps = {
  withPageLabel: false,
  next: () => {},
  prev: () => {},
  vertical: false,
  collapse: false,
};

export default memo(ArrowPagination);
