import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Card, Image } from 'src/components/common';

import './index.scss';

const AnswerCard = ({ answer, shakeAnswerImageUrl, onClick }) => {
  const answerCardClassNames = useMemo(
    () =>
      cn('read-and-think-answer-card', {
        'read-and-think-answer-card--shake': shakeAnswerImageUrl === answer,
      }),
    [answer, shakeAnswerImageUrl]
  );

  return (
    <Card className={answerCardClassNames} onClick={() => onClick(answer)}>
      <Image
        className="read-and-think-answer-card__image"
        alt="image"
        src={answer}
      />
    </Card>
  );
};

AnswerCard.propTypes = {
  answer: PropTypes.string,
  shakeAnswerImageUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

AnswerCard.defaultProps = {
  answer: null,
};

export default memo(AnswerCard);
