import PropTypes from 'prop-types';
import React, { memo, useMemo, useCallback } from 'react';
import _map from 'lodash/map';
import _without from 'lodash/without';
import cn from 'classnames';
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';

import { gameNames, activityGroupLabels, statistics } from 'src/constants';
import { common } from 'src/utils';
import { useIntlMessages } from 'src/hooks';

import './index.scss';

const PIE_CHART_ANALYSE_CATEGORY = {
  GAMES: 'games',
  ISLANDS: 'islands',
};

const PieChartContainer = ({
  className,
  dataCategory,
  dataCategoryType,
  description,
  aspect,
  hideLabel,
  hideLegend,
  hideTooltip,
  statisticsData,
  width,
  unit,
}) => {
  const messages = useIntlMessages();

  const pieChartClassNames = useMemo(
    () => cn('pie-chart-container', className),
    [className]
  );

  const formatTooltip = useCallback((value) => `${value}${unit}`, [unit]);

  const formatLabel = useCallback((data) => `${data.value}${unit}`, [unit]);

  const pieChartIslandsData = useMemo(
    () =>
      _without(
        _map(statisticsData, (value, key) => {
          if (
            !Object.keys(activityGroupLabels).includes(
              key.toUpperCase().replace('-', '_')
            )
          ) {
            return false;
          }

          return {
            name: messages()[
              activityGroupLabels[
                key.toUpperCase().replace('-', '_')
              ].toLowerCase()
            ],
            value: Math.round((value + Number.EPSILON) * 100),
            fill: statistics.colors[key.toUpperCase().replace('-', '_')],
          };
        }),
        false
      ),
    [messages, statisticsData]
  );

  const pieChartGamesData = useMemo(
    () =>
      _without(
        _map(statisticsData, (_value, key) => {
          if (
            !(key in common.getParsedGameKeys()) ||
            statisticsData[key].points === 0 ||
            (dataCategoryType !== statistics.analyseType.EARNED_POINTS &&
              statisticsData[key].playedPercentage === 0)
          ) {
            return false;
          }

          return {
            name: messages()[gameNames[key]],
            value:
              dataCategoryType === statistics.analyseType.EARNED_POINTS
                ? statisticsData[key].points
                : Math.round(
                    (statisticsData[key].playedPercentage + Number.EPSILON) *
                      100
                  ),
            fill: statistics.colors[key],
          };
        }),
        false
      ),
    [messages, statisticsData, dataCategoryType]
  );

  return (
    <div className={pieChartClassNames}>
      <p>{description}</p>
      <ResponsiveContainer width={width} aspect={aspect}>
        <PieChart>
          <Pie
            dataKey="value"
            data={
              dataCategory === PIE_CHART_ANALYSE_CATEGORY.ISLANDS
                ? pieChartIslandsData
                : pieChartGamesData
            }
            label={!hideLabel && formatLabel}
          />
          {!hideTooltip && <Tooltip formatter={formatTooltip} />}
          {!hideLegend && <Legend />}
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

PieChartContainer.propTypes = {
  className: PropTypes.string,
  dataCategory: PropTypes.string,
  dataCategoryType: PropTypes.string,
  description: PropTypes.string.isRequired,
  aspect: PropTypes.number.isRequired,
  hideLabel: PropTypes.bool,
  hideLegend: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  statisticsData: PropTypes.shape({
    points: PropTypes.number,
  }).isRequired,
  unit: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

PieChartContainer.defaultProps = {
  className: '',
  dataCategory: 'games',
  dataCategoryType: 'earnedPoints',
  hideLabel: false,
  hideLegend: false,
  hideTooltip: false,
  unit: '',
  width: '100%',
};

export default memo(PieChartContainer);
