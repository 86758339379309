import React from 'react';
import { Localize } from '@kokolingo/localisation';
import { object, string } from 'yup';

export default object().shape({
  fullNameForSpeechTherapist: string().required(() => (
    <Localize id="error.fullNameRequired" />
  )),
  parentEmail: string()
    .email(() => <Localize id="error.emailFormatInvalid" />)
    .when('fullNameForSpeechTherapist', (fullNameForSpeechTherapist) => {
      if (!fullNameForSpeechTherapist) {
        return string().required(() => <Localize id="error.emailRequired" />);
      }

      return string();
    }),
  parentPhoneNumber: string().when(
    'fullNameForSpeechTherapist',
    (fullNameForSpeechTherapist) => {
      if (!fullNameForSpeechTherapist) {
        return string().required(() => (
          <Localize id="error.phoneNumberRequired" />
        ));
      }

      return string();
    }
  ),
  SpeechSoundId: string()
    .required(() => <Localize id="error.speechSoundRequired" />)
    .test('error.speechSoundRequired', 'error.speechSoundRequired', (val) => {
      return Number.isInteger(parseFloat(val, 10));
    }),
});
