import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cn from 'classnames';

import { selectors } from 'src/store';

import Image from '../Image';
import './index.scss';

const COINS = ['gold', 'orange', 'green', 'purple', 'red', 'blue', 'bonus'];

const Wallet = ({ walletData, className, vertical }) => {
  const walletClassName = cn('wallet', className, { vertical });

  const currencyItems = useMemo(
    () =>
      COINS.map((coinKey) => {
        const currencyData = walletData.find(
          (dataItem) => dataItem.currency.key === coinKey
        );

        return (
          <div key={coinKey} className="wallet__currency">
            <Image
              className="wallet__currency__icon"
              alt="currency-icon"
              src={currencyData?.currency.imageUrl}
            />
            <div className="wallet__currency__amount">
              {currencyData?.amount}
            </div>
          </div>
        );
      }),
    [walletData]
  );

  return <div className={walletClassName}>{currencyItems}</div>;
};

Wallet.propTypes = {
  className: PropTypes.string,
  vertical: PropTypes.bool,
  walletData: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.shape({ imageUrl: PropTypes.string.isRequired }),
    })
  ).isRequired,
};

Wallet.defaultProps = {
  className: '',
  vertical: false,
};

const mapStateToProps = (state) => ({
  walletData: selectors.wallet.getData(state),
  hasLoaded: selectors.wallet.getHasLoaded(state),
});

export default compose(connect(mapStateToProps, null), memo)(Wallet);
