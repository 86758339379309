import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { video } from 'src/constants';
import { useGoToLevel, usePagination } from 'src/hooks';

import {
  MenuFrame,
  PageLoader,
  ContentContainer,
  Navbar,
  Image,
} from 'src/components/common';

import './index.scss';

const Container = ({
  currency,
  getCollections,
  getSpeechSoundById,
  hasLoaded,
  items,
  levelsPath,
  student,
  studentSpeechSound,
  title,
}) => {
  const goToLevel = useGoToLevel(levelsPath);

  const {
    currentPage,
    pageCount,
    handlePreviousPage,
    handleNextPage,
    pageItems,
  } = usePagination(items);

  const containerClassNames = useMemo(
    () =>
      cn(
        'read-and-think-collections-container',
        `read-and-think-collections-container--background-${studentSpeechSound.label}`
      ),
    [studentSpeechSound.label]
  );

  useEffect(() => {
    getCollections();
  }, [getCollections]);

  useEffect(() => {
    getSpeechSoundById(student.SpeechSoundId);
  }, [getSpeechSoundById, student]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      <Navbar.GameNavbar
        currency={currency}
        title={title}
        videoName={video.NAME.READ_AND_THINK}
      />
      <div className={containerClassNames}>
        <MenuFrame
          title={title}
          currencyImageUrl={currency.imageUrl}
          currentPageNumber={currentPage}
          pagesCount={pageCount}
          onBack={handlePreviousPage}
          onForward={handleNextPage}
        >
          <div className="read-and-think-collections-container__collections">
            {pageItems?.map((collection) => (
              <ContentContainer
                variant="small"
                onClick={() => goToLevel(collection.id)}
                isLocked={collection.isLocked}
                showFeedback={collection.showFeedback}
                isFeedbackPositive={collection.isFeedbackPositive}
              >
                <p>{collection.label}</p>
                <Image alt={collection.key} src={collection.imageUrl} />
              </ContentContainer>
            ))}
          </div>
        </MenuFrame>
      </div>
    </PageLoader>
  );
};

Container.propTypes = {
  currency: PropTypes.shape({
    imageUrl: PropTypes.string,
  }).isRequired,
  getCollections: PropTypes.func.isRequired,
  getSpeechSoundById: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  levelsPath: PropTypes.string.isRequired,
  student: PropTypes.shape({
    hasPassedReadAndThinkEducation: PropTypes.bool.isRequired,
    SpeechSoundId: PropTypes.number.isRequired,
  }).isRequired,
  studentSpeechSound: PropTypes.shape({
    label: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default memo(Container);
