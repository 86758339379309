import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { object, string } from 'yup';
import { isMobile } from 'react-device-detect';

import { Localize } from '@kokolingo/localisation';

import { useIntlMessages } from 'src/hooks';

import { Form, Icon, Input, Modal } from 'src/components/common';

import './index.scss';

const validationSchema = object().shape({
  email: string()
    .email(() => <Localize id="error.emailFormatInvalid" />)
    .required(() => <Localize id="error.emailRequired" />),
});

const InviteUserModal = ({
  student,
  showInviteModal,
  handleCloseModal,
  handleInviteUser,
}) => {
  const messages = useIntlMessages();

  return (
    <Modal
      className="invite-student-modal"
      show={showInviteModal}
      onClose={handleCloseModal}
      onOutsideClick={handleCloseModal}
    >
      <Icon
        className="invite-student-modal__icon"
        name="invite"
        size={isMobile ? 60 : 150}
      />
      <Modal.Title>{messages.label.addNewProfiles}</Modal.Title>
      <Modal.Description>
        {
          messages({
            studentName:
              student?.fullName ??
              messages.modal.speechTherapistsHome.inviteStudents.newStudent,
          }).modal.speechTherapistsHome.inviteStudents.description1
        }
        <br />
        <br />
        {messages.modal.speechTherapistsHome.inviteStudents.description2}
      </Modal.Description>
      <div className="invite-student-modal__form-description">
        {messages.modal.speechTherapistsHome.inviteStudents.inputDescription}
      </div>
      <Form
        formClassName="invite-student-modal__form"
        buttonClassName="invite-student-modal__form__cta"
        onSubmit={({ email }) => {
          handleInviteUser(email, student?.id);
          handleCloseModal();
        }}
        validationSchema={validationSchema}
        initialValues={student?.parentEmail && ['email', student.parentEmail]}
        submitBtnLabel={messages.modal.speechTherapistsHome.inviteStudents.CTA}
      >
        <Input
          className="invite-student-modal__form__input"
          name="email"
          placeholder={
            messages.modal.speechTherapistsHome.inviteStudents.inputPlaceholder
          }
          required
        />
      </Form>
    </Modal>
  );
};

InviteUserModal.propTypes = {
  student: PropTypes.shape({
    id: PropTypes.number.isRequired,
    SpeechSoundId: PropTypes.number,
    fullName: PropTypes.string.isRequired,
    fullNameForSpeechTherapist: PropTypes.string.isRequired,
    speechSoundLabel: PropTypes.string,
    parentEmail: PropTypes.string,
  }),
  showInviteModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleInviteUser: PropTypes.func.isRequired,
};

InviteUserModal.defaultProps = {
  student: null,
};

export default memo(InviteUserModal);
