import { createSelector } from 'reselect';

import { subscriptions } from 'src/utils';

const getHasLoaded = createSelector(
  (state) => state.authentication.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getHasSubmitted = createSelector(
  (state) => state.authentication.hasSubmitted,
  (hasSubmitted) => hasSubmitted
);
const getHasSubmittedForgotPassword = createSelector(
  (state) => state.authentication.hasSubmittedForgotPassword,
  (hasSubmittedForgotPassword) => hasSubmittedForgotPassword
);
const getIsAuthenticated = createSelector(
  (state) => state.authentication.isAuthenticated,
  (isAuthenticated) => isAuthenticated
);
const getIsLoading = createSelector(
  (state) => state.authentication.isLoading,
  (isLoading) => isLoading
);
const getUser = createSelector(
  (state) => state.authentication.data.user,
  (user) => user
);
const getStudent = createSelector(
  (state) => state.authentication.data.student,
  (student = {}) => student
);
const getIsSpeechTherapist = createSelector(
  (state) => !!state.authentication.data?.user?.SpeechTherapistId,
  (isSpeechTherapist) => isSpeechTherapist
);
const getHasActiveSubscription = createSelector(
  (state) =>
    !!state.authentication.data?.user?.SubscriptionId &&
    subscriptions.getIsSubscriptionActive(state.authentication.data?.user),
  (hasActiveSubscription = false) => hasActiveSubscription
);
const getActiveSubscriptionKey = createSelector(
  (state) => state.authentication.data?.user?.subscriptionKey ?? '',
  (activeSubscriptionKey) => activeSubscriptionKey
);
const getUserPaymentMethod = createSelector(
  (state) => state.authentication.data?.user?.selectedPaymentMethod,
  (userPaymentMethod = '') => userPaymentMethod
);
const getHasAcceptedInvitation = createSelector(
  (state) => state.authentication.data?.user?.hasAcceptedInvitation,
  (hasAcceptedInvitation = false) => hasAcceptedInvitation
);
const getHasReceivedTrialExpirationNotification = createSelector(
  (state) =>
    state.authentication.data?.user?.hasReceivedTrialExpirationNotification,
  (hasReceivedTrialExpirationNotification = false) =>
    hasReceivedTrialExpirationNotification
);
const getHasReceivedUpdateNotification = createSelector(
  (state) => state.authentication.data?.user?.hasReceivedUpdateNotification,
  (hasReceivedUpdateNotification = false) => hasReceivedUpdateNotification
);

export default {
  getActiveSubscriptionKey,
  getHasAcceptedInvitation,
  getHasActiveSubscription,
  getHasLoaded,
  getHasReceivedTrialExpirationNotification,
  getHasReceivedUpdateNotification,
  getHasSubmitted,
  getHasSubmittedForgotPassword,
  getIsAuthenticated,
  getIsLoading,
  getIsSpeechTherapist,
  getStudent,
  getUser,
  getUserPaymentMethod,
};
