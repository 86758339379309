import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { SafeRouteValidatorProvider } from 'src/context';
import { paths, payment } from 'src/constants';
import { selectors } from 'src/store';

const SubscriptionValidator = ({
  children,
  hasActiveSubscription,
  isSpeechTherapist,
  subscriptionStatus,
  showTrialEndedModal,
}) => {
  let redirectPath = isSpeechTherapist ? paths.SPEECH_THERAPIST : paths.MAP;

  if (
    subscriptionStatus.lastTransactionStatus ===
    payment.PAYMENT_STATUS.IN_PROGRESS
  ) {
    redirectPath = paths.SUBSCRIPTION_STATUS;
  }

  // showTrialEndedModal - we don't want to redirect user while trial ended modal is show.
  //   That's the moment when we don't want to distract user.
  //   Redirect after the modal is closed if user doesn't proceed to payment.
  const redirect =
    !hasActiveSubscription && !showTrialEndedModal && redirectPath;

  return (
    <SafeRouteValidatorProvider value={redirect}>
      {children}
    </SafeRouteValidatorProvider>
  );
};

SubscriptionValidator.propTypes = {
  children: PropTypes.node.isRequired,
  hasActiveSubscription: PropTypes.bool.isRequired,
  showTrialEndedModal: PropTypes.bool.isRequired,
  isSpeechTherapist: PropTypes.bool.isRequired,
  subscriptionStatus: PropTypes.shape({
    lastTransactionStatus: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  hasActiveSubscription: selectors.subscriptionStatus.getHasActiveSubscription(
    state
  ),
  showTrialEndedModal: selectors.subscriptionStatus.getShowTrialEndedModal(
    state
  ),
  isSpeechTherapist: selectors.authentication.getIsSpeechTherapist(state),
  subscriptionStatus: selectors.subscriptionStatus.getSubscriptionStatus(state),
});

export default compose(
  connect(mapStateToProps),
  withRouter
)(SubscriptionValidator);
