import _cloneDeep from 'lodash/cloneDeep';

import { actions } from 'src/constants';
import { common } from 'src/utils';
import { selectors } from 'src/store';

const initialState = {
  templates: [],
  selectedMap: null,
  hasLoadedTemplates: false,
  hasLoadedMap: false,
  hasLoadedLevels: false,
  hasLoadedDailyMaps: false,
  stats: {},
  collection: null,
  showLastDayModal: false,
  showDailyProgressModal: false,
  showFinalModal: false,
  error: false,
  collectionVerified: false,
  dailyMaps: [],
};

const dailyMapModalsReducer = (state, newState) => {
  const today = common.getDateFromNow().replace(/-/g, '');
  const daysActive = selectors.dailyMap.getDaysActive({ dailyMap: newState });

  return {
    ...newState,
    showLastDayModal:
      !state.selectedMap &&
      newState.selectedMap?.meta.currentActivityIndex === 0 &&
      daysActive === newState.selectedMap?.DailyMapTemplate.durationInDays,
    showDailyProgressModal:
      state.selectedMap?.meta.progress[today] < 1 &&
      newState.selectedMap?.meta.progress[today] === 1 &&
      daysActive < newState.selectedMap?.DailyMapTemplate.durationInDays,
    showFinalModal: state.selectedMap && !newState.selectedMap,
  };
};

const actionMap = {
  [actions.DAILY_MAP_GET_REQUEST]: (state) => ({
    ...state,
    hasLoadedMap: false,
  }),
  [actions.DAILY_MAP_GET_SUCCESS]: (state, { result }) => {
    const newState = {
      ...state,
      selectedMap: result.data,
      hasLoadedMap: true,
    };
    return dailyMapModalsReducer(state, newState);
  },
  [actions.DAILY_MAP_GET_FAILURE]: (state) => ({
    ...state,
    selectedMap: null,
    hasLoadedMap: true,
  }),

  [actions.DAILY_MAP_COLLECTION_VERIFICATION_POST_REQUEST]: (state) => ({
    ...state,
    hasLoadedMap: false,
  }),
  [actions.DAILY_MAP_COLLECTION_VERIFICATION_POST_SUCCESS]: (
    state,
    { result }
  ) => {
    const updatedMap = _cloneDeep(state.selectedMap);
    updatedMap.DailyMapTemplate.DailyMapTemplateCollections[0].collections =
      result.data;

    return {
      ...state,
      selectedMap: updatedMap,
      collectionVerified: true,
      hasLoadedMap: true,
    };
  },
  [actions.DAILY_MAP_COLLECTION_VERIFICATION_POST_FAILURE]: (state) => ({
    ...state,
    hasLoadedMap: true,
  }),

  [actions.DAILY_MAPS_GET_REQUEST]: (state) => ({
    ...state,
  }),
  [actions.DAILY_MAPS_GET_SUCCESS]: (state, { result }) => ({
    ...state,
    dailyMaps: result.data,
    hasLoadedDailyMaps: true,
  }),
  [actions.DAILY_MAPS_GET_FAILURE]: (state) => ({
    ...state,
    dailyMaps: [],
    hasLoadedMap: true,
  }),

  [actions.DAILY_MAP_RESET]: (state) => ({
    ...state,
    selectedMap: null,
    templates: [],
    hasLoadedTemplates: false,
    hasLoadedMap: false,
  }),

  [actions.DAILY_MAP_TEMPLATES_GET_REQUEST]: (state) => ({
    ...state,
    hasLoadedTemplates: false,
  }),
  [actions.DAILY_MAP_TEMPLATES_GET_SUCCESS]: (state, { result }) => ({
    ...state,
    templates: result.data,
    hasLoadedTemplates: true,
  }),
  [actions.DAILY_MAP_TEMPLATES_GET_FAILURE]: (state) => ({
    ...state,
    templates: [],
    hasLoadedTemplates: true,
  }),

  [actions.DAILY_MAP_POST_REQUEST]: (state) => state,
  [actions.DAILY_MAP_POST_SUCCESS]: (state, { result }) => {
    return {
      ...state,
      selectedMap: result.data,
      dailyMaps: [...state.dailyMaps, result.data],
    };
  },
  [actions.DAILY_MAP_POST_FAILURE]: (state) => ({
    ...state,
    selectedMap: null,
  }),

  [actions.DAILY_MAP_UPDATE_REQUEST]: (state) => state,
  [actions.DAILY_MAP_UPDATE_SUCCESS]: (state, { result }) => {
    let selectedMap = { ...state.selectedMap, ...result.data };
    if (selectedMap.endedAt) {
      selectedMap = null;
    }
    const newState = {
      ...state,
      selectedMap,
    };
    return dailyMapModalsReducer(state, newState);
  },
  [actions.DAILY_MAP_UPDATE_FAILURE]: (state) => state,

  [actions.DAILY_MAP_FEEDBACK_GET_REQUEST]: (state) => state,
  [actions.DAILY_MAP_FEEDBACK_GET_SUCCESS]: (state, { result }) => ({
    ...state,
    stats: result.data,
  }),
  [actions.DAILY_MAP_FEEDBACK_GET_FAILURE]: (state) => ({
    ...state,
    stats: {},
  }),

  [actions.DAILY_MAP_LEVELS_GET_REQUEST]: (state) => ({
    ...state,
    error: null,
    collectionVerified: false,
    hasLoadedLevels: false,
  }),
  [actions.DAILY_MAP_LEVELS_GET_SUCCESS]: (state, { result }) => ({
    ...state,
    hasLoadedLevels: true,
    error: false,
    collection: result.data,
  }),
  [actions.DAILY_MAP_LEVELS_GET_FAILURE]: (state) => ({
    ...state,
    hasLoadedLevels: false,
    error: true,
    collection: null,
  }),

  [actions.RESET_DAILY_MAP_LEVELS]: (state) => ({
    ...state,
    hasLoadedLevels: false,
    collection: null,
  }),

  [actions.SET_SHOW_LAST_DAY_MODAL]: (state, { payload }) => ({
    ...state,
    showLastDayModal: payload,
  }),
  [actions.SET_SHOW_DAILY_PROGRESS_MODAL]: (state, { payload }) => ({
    ...state,
    showDailyProgressModal: payload,
  }),
  [actions.SET_SHOW_FINAL_MODAL]: (state, { payload }) => ({
    ...state,
    showFinalModal: payload,
  }),
};

export default (state = initialState, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
