import React, { useCallback, useEffect, useMemo, useState } from 'react';

import _has from 'lodash/has';

const isStepCompleted = (state, step) => {
  // if step doesn't have isCompleted defined, it is always completed
  if (!_has(step, 'isCompleted')) {
    return true;
  }

  return step.isCompleted(state);
};

export default (steps, initialState = {}) => {
  const [state, setState] = useState(initialState);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState(new Set([0]));
  const activeStep = useMemo(() => steps[activeStepIndex], [
    activeStepIndex,
    steps,
  ]);

  const nextStep = useCallback(() => {
    if (
      activeStepIndex >= steps.length - 1 ||
      !isStepCompleted(state, activeStep)
    ) {
      return;
    }

    setActiveStepIndex(activeStepIndex + 1);
  }, [activeStep, activeStepIndex, state, steps]);

  const prevStep = useCallback(() => {
    if (activeStepIndex <= 0) {
      return;
    }

    setActiveStepIndex(activeStepIndex - 1);
  }, [activeStepIndex]);

  const setActiveStep = useCallback(
    (newStep) => {
      const newActiveIndex = steps.findIndex((step) => step === newStep);

      // check if all previous steps are completed
      let prevStepsCompleted = true;
      steps.some((step, index) => {
        if (!isStepCompleted(state, step) && index !== newActiveIndex) {
          prevStepsCompleted = false;
        }

        return index === newActiveIndex;
      });

      if (newActiveIndex !== -1 && prevStepsCompleted) {
        setActiveStepIndex(newActiveIndex);
      }
    },
    [state, steps]
  );

  const setWizardState = useCallback((newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  }, []);

  const resetWizardState = useCallback((newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  }, []);

  const Component = (
    <activeStep.Component
      nextStep={nextStep}
      prevStep={prevStep}
      wizardState={state}
      setStepsState={setWizardState}
    />
  );

  useEffect(() => {
    if (!visitedSteps.has(activeStep)) {
      const newVisited = new Set(visitedSteps);

      newVisited.add(activeStep);
      setVisitedSteps(newVisited);
    }
  }, [setVisitedSteps, visitedSteps, activeStep]);

  return {
    activeStep,
    activeStepIndex,
    Component,
    nextStep,
    prevStep,
    resetWizardState,
    setActiveStep,
    setWizardState,
    steps,
    visitedSteps,
  };
};
