import { Localize } from '@kokolingo/localisation';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { object, string } from 'yup';

import { Button, Icon, Input, Modal } from 'src/components/common';
import { useArchiveStudent, useIntlMessages } from 'src/hooks';

import './index.scss';

const ArchiveStudentModal = ({ studentId, handleCloseModal }) => {
  const messages = useIntlMessages();

  const ARCHIVE_CHECK = messages.button.archive.toUpperCase();

  const validationSchema = useMemo(
    () =>
      object().shape({
        archiveCheck: string()
          .required(() => <Localize id="error.feedback.fieldRequired" />)
          .test(
            'error.archiveCheck',
            messages({ archiveCheck: ARCHIVE_CHECK }).error.archiveError,
            (val) =>
              val?.toUpperCase() === messages.button.archive.toUpperCase()
          ),
      }),
    [ARCHIVE_CHECK, messages]
  );

  const { archiveStudent } = useArchiveStudent();

  const methods = useForm({
    validationSchema,
    mode: 'onChange',
  });

  const { dirty, isValid } = methods.formState;

  return (
    <Modal
      className="archive-student-modal"
      show
      onClose={handleCloseModal}
      onOutsideClick={handleCloseModal}
    >
      <Icon
        className="archive-student-modal__icon"
        name="archive"
        size={isMobile ? 60 : 150}
        color="#fb7b76"
      />
      <Modal.Title>
        {messages.modal.speechTherapistsHome.archiveStudents.title}
      </Modal.Title>
      <Modal.Description>
        {messages.modal.speechTherapistsHome.archiveStudents.description1}
        {/* TODO uncomment when unarchive student is implemented */}
        {/* <br />
        {messages.modal.speechTherapistsHome.archiveStudents.description2}
        <br />
        {messages.modal.speechTherapistsHome.archiveStudents.description3} */}
      </Modal.Description>
      <span className="archive-student-modal__divider" />
      <Modal.Description>
        {
          messages({ archiveCheck: ARCHIVE_CHECK }).modal.speechTherapistsHome
            .archiveStudents.confirm.description
        }
      </Modal.Description>
      <FormContext {...methods}>
        <form
          className="archive-student-modal__form"
          onSubmit={() => archiveStudent(studentId)}
        >
          <Input
            className="archive-student-modal__form__input"
            name="archiveCheck"
            required
          />
          <div className="archive-student-modal__form__cta">
            <Button variant="register" isSubmit disabled={!dirty || !isValid}>
              {messages.button.confirm}
            </Button>
            <Button onClick={handleCloseModal}>{messages.button.cancel}</Button>
          </div>
        </form>
      </FormContext>
    </Modal>
  );
};

ArchiveStudentModal.propTypes = {
  studentId: PropTypes.string.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default memo(ArchiveStudentModal);
