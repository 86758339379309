import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';

import { Container } from './components';

const TreasureHuntCollections = ({
  getCollectionsByGroupId,
  hasLoaded,
  pageCount,
  treasureHuntCollections,
  treasureHuntCurrency,
  treasureHuntGroupData,
}) => {
  return (
    <Container
      collectionsPath={paths.TREASURE_HUNT_COLLECTIONS}
      currency={treasureHuntCurrency}
      getCollectionsByGroupId={getCollectionsByGroupId}
      hasLoaded={hasLoaded}
      items={treasureHuntCollections}
      levelsPath={paths.TREASURE_HUNT_LEVELS}
      pageCount={pageCount}
      groupData={treasureHuntGroupData}
    />
  );
};

TreasureHuntCollections.propTypes = {
  getCollectionsByGroupId: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  treasureHuntCollections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  treasureHuntCurrency: PropTypes.shape({}).isRequired,
  treasureHuntGroupData: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.treasureHuntCollections.getHasLoaded(state),
  treasureHuntCurrency: selectors.treasureHuntCollections.getCurrencyData(
    state
  ),
  treasureHuntGroupData: selectors.treasureHuntCollections.getGroupData(state),
  treasureHuntCollections: selectors.treasureHuntCollections.getCollectionsData(
    state
  ),
  pageCount: selectors.treasureHuntCollections.getPageCount(state),
});

const mapDispatchToProps = {
  ...actions.treasureHunt,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(TreasureHuntCollections);
