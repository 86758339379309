import PropTypes from 'prop-types';
import React, { memo, useCallback, useState, useRef, useMemo } from 'react';
import { useHistory } from 'react-router';
import { isMobile } from 'react-device-detect';

import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Badge, Button, Navbar, Modal } from 'src/components/common';

import './index.scss';

const Student = ({
  student,
  isTestStudent,
  handleInviteStudent,
  handleOnClick,
  userId,
}) => {
  const history = useHistory();
  const menuBadgeRef = useRef();
  const settingsMenuRef = useRef();

  const messages = useIntlMessages();

  const [showStudentMobileMenu, setShowStudentMobileMenu] = useState(false);

  const isSoundSelected = !!student.speechSoundLabel;

  const hideStudentMobileMenu = useCallback(
    () => setShowStudentMobileMenu(false),
    [setShowStudentMobileMenu]
  );

  const handleMapOrSelectSpeechSound = useCallback(
    () =>
      handleOnClick(
        student.id,
        student.SpeechSoundId
          ? () => history.push(paths.buildQuery(paths.MAP, { userId }))
          : () => history.push(paths.SPEECH_SOUND_SELECTION)
      ),
    [student, history, handleOnClick, userId]
  );

  const studentOptions = useMemo(
    () => [
      <Navbar.SettingsMenuItem
        key="map"
        iconName="map"
        type="blue"
        onClick={handleMapOrSelectSpeechSound}
        title={isMobile && messages.label.map}
        size="small"
      />,
      <Navbar.SettingsMenuItem
        key="sound-selection"
        onClick={() =>
          handleOnClick(student.id, () =>
            history.push(paths.SPEECH_SOUND_SELECTION)
          )
        }
        type={isSoundSelected ? 'green' : 'primary'}
        iconName={isSoundSelected ? undefined : 'sound-selection'}
        badgeChildren={isSoundSelected && student.speechSoundLabel}
        title={isMobile && messages.label.speechSoundSelection}
        size="small"
      />,
      <React.Fragment key="testStudent">
        {!isTestStudent && (
          <>
            <Navbar.SettingsMenuItem
              iconName="edit"
              onClick={() =>
                handleOnClick(student.id, () =>
                  history.push(
                    paths.SETTINGS_STUDENT.replace(':studentId', student.id)
                  )
                )
              }
              title={isMobile && messages.page.studentSettings.tab.file}
              size="small"
            />
            <Navbar.SettingsMenuItem
              iconName="statistic"
              onClick={() =>
                handleOnClick(student.id, () => history.push(paths.STATISTICS))
              }
              title={isMobile && messages.label.statistics}
              size="small"
            />
          </>
        )}
      </React.Fragment>,
      <React.Fragment key="inviteShadow">
        {!isTestStudent && handleInviteStudent && (
          <Navbar.SettingsMenuItem
            iconName="inviteShadow"
            onClick={() => handleOnClick(student.id, handleInviteStudent)}
            type="green"
            title={isMobile && messages.button.sendInvitation}
            className={isMobile ? 'student__badge--invite' : ''}
            size="small"
          />
        )}
      </React.Fragment>,
      <React.Fragment key="questionmark">
        {/* TODO uncomment when tooltips ready */}
        {isTestStudent && (
          <Navbar.SettingsMenuItem
            iconName="questionmark"
            title={isMobile && student.fullNameForSpeechTherapist}
            // TODO onClick show tooltip
            onClick={() => {}}
            size="small"
          />
        )}
      </React.Fragment>,
    ],
    [
      handleInviteStudent,
      handleMapOrSelectSpeechSound,
      handleOnClick,
      history,
      isSoundSelected,
      isTestStudent,
      student.id,
      student.speechSoundLabel,
      student.fullNameForSpeechTherapist,
      messages,
    ]
  );

  return (
    <div className="student">
      <Button
        className="student__button"
        onClick={handleMapOrSelectSpeechSound}
        size="medium"
      >
        {student.fullNameForSpeechTherapist}
      </Button>
      {!isMobile && studentOptions}
      {isMobile && (
        <>
          <Badge
            className="student-badge--additional"
            onClick={() => setShowStudentMobileMenu(true)}
            type="gold"
            iconName="threeDots"
            ref={menuBadgeRef}
          />
          <div className="student-menu" ref={settingsMenuRef}>
            {showStudentMobileMenu && (
              <Modal
                show={showStudentMobileMenu}
                onClose={hideStudentMobileMenu}
                onOutsideClick={hideStudentMobileMenu}
                className="student-mobile-menu"
              >
                <Modal.Title>{student.fullName}</Modal.Title>
                <Modal.Description>
                  <Navbar.SettingsMenu>{studentOptions}</Navbar.SettingsMenu>
                </Modal.Description>
              </Modal>
            )}
          </div>
        </>
      )}
    </div>
  );
};

Student.propTypes = {
  isTestStudent: PropTypes.bool,
  student: PropTypes.shape({
    id: PropTypes.number.isRequired,
    SpeechSoundId: PropTypes.number,
    fullNameForSpeechTherapist: PropTypes.string.isRequired,
    speechSoundLabel: PropTypes.string,
    parentEmail: PropTypes.string,
    fullName: PropTypes.string,
  }).isRequired,
  handleInviteStudent: PropTypes.func,
  handleOnClick: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};

Student.defaultProps = {
  isTestStudent: false,
  handleInviteStudent: null,
};

export default memo(Student);
