import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import cn from 'classnames';

import { useCheckpoint, useGoToLevel, usePagination } from 'src/hooks';
import { checkpoints, video } from 'src/constants';

import {
  MenuFrame,
  PageLoader,
  ContentContainer,
  Navbar,
  Image,
} from 'src/components/common';

import './index.scss';

const Container = ({
  className,
  currency,
  getCollectionsByGroupId,
  hasLoaded,
  items,
  levelsPath,
  title,
}) => {
  const goToLevel = useGoToLevel(levelsPath);
  const { groupId } = useParams();

  const {
    currentPage,
    pageCount,
    handlePreviousPage,
    handleNextPage,
    pageItems,
  } = usePagination(items);

  useEffect(() => {
    getCollectionsByGroupId(groupId);
  }, [getCollectionsByGroupId, groupId]);

  useCheckpoint(checkpoints.FIRST_TIME_ON_MEMORY, hasLoaded);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      <Navbar.GameNavbar
        currency={currency}
        title={title}
        videoName={video.NAME.MEMORY}
      />
      <div className={cn('memory-collections-container', className)}>
        <MenuFrame
          title={title}
          currencyImageUrl={currency.imageUrl}
          currentPageNumber={currentPage}
          pagesCount={pageCount}
          onBack={handlePreviousPage}
          onForward={handleNextPage}
        >
          <div className="memory-collections-container__collections">
            {pageItems?.map((collection) => (
              <ContentContainer
                variant="small"
                onClick={() => goToLevel(collection.id)}
                isLocked={collection.isLocked}
                showFeedback={collection.showFeedback}
                isFeedbackPositive={collection.isFeedbackPositive}
              >
                <p>{collection.label}</p>
                <Image alt={collection.key} src={collection.imageUrl} />
              </ContentContainer>
            ))}
          </div>
        </MenuFrame>
      </div>
    </PageLoader>
  );
};

Container.propTypes = {
  className: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    imageUrl: PropTypes.string,
  }).isRequired,
  getCollectionsByGroupId: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  levelsPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default memo(Container);
