import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const MemoryStepThreeCollections = ({
  getCollectionsByGroupId,
  hasLoaded,
  memoryStepThreeCollections,
  memoryStepThreeCurrency,
  pageCount,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="memory-collections-container--step-three"
      collectionsPath={paths.MEMORY_STEP_THREE_COLLECTIONS}
      currency={memoryStepThreeCurrency}
      getCollectionsByGroupId={getCollectionsByGroupId}
      hasLoaded={hasLoaded}
      items={memoryStepThreeCollections}
      levelsPath={paths.MEMORY_STEP_THREE_LEVELS}
      pageCount={pageCount}
      title={messages.game.memory}
    />
  );
};

MemoryStepThreeCollections.propTypes = {
  getCollectionsByGroupId: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  memoryStepThreeCollections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  memoryStepThreeCurrency: PropTypes.shape({}).isRequired,
  pageCount: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.memoryStepThreeCollections.getHasLoaded(state),
  memoryStepThreeCurrency: selectors.memoryStepThreeCollections.getCurrencyData(
    state
  ),
  memoryStepThreeCollections: selectors.memoryStepThreeCollections.getCollectionsData(
    state
  ),
  pageCount: selectors.memoryStepThreeCollections.getPageCount(state),
});

const mapDispatchToProps = {
  ...actions.memoryStepThree,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(MemoryStepThreeCollections);
