import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import _get from 'lodash/get';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import { Label } from 'src/components/common';

import './index.scss';

const Input = ({
  className,
  defaultValue,
  disabled,
  id,
  label,
  labelClassName,
  maxLength,
  name,
  onChange,
  onKeyDown,
  placeholder,
  required,
  type,
}) => {
  const { errors, register } = useFormContext();
  const error = _get(errors, `${name}.message`);

  const containerClassNames = useMemo(
    () =>
      classNames('input-container', {
        'input-container__checkbox-container': type === 'checkbox',
      }),
    [type]
  );

  const inputClassNames = useMemo(
    () =>
      classNames(
        'input-container__input',
        {
          'input-container__input--error': error,
          'input-container__input--disabled': disabled,
        },
        className
      ),
    [className, error, disabled]
  );

  const errorMessageClassName = useMemo(
    () =>
      classNames('error-message', {
        'error-message--with-label': label,
      }),
    [label]
  );

  return (
    <div className={containerClassNames}>
      {label && (
        <Label
          className={labelClassName}
          htmlFor={id || name}
          label={label}
          required={required}
        />
      )}
      <input
        id={id || name}
        name={name}
        type={type}
        className={inputClassNames}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        ref={register}
        onChange={onChange}
        onKeyDown={onKeyDown}
        maxLength={maxLength}
      />
      <div className={errorMessageClassName}>{error && error}</div>
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
};

Input.defaultProps = {
  className: '',
  defaultValue: '',
  disabled: false,
  id: null,
  label: '',
  labelClassName: '',
  maxLength: 40,
  onChange: null,
  onKeyDown: null,
  placeholder: '',
  required: false,
  type: 'text',
};

export default memo(Input);
