import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const LevelContentSentence = ({ children }) => {
  return <span className="level-content-sentence">{children}</span>;
};

LevelContentSentence.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(LevelContentSentence);
