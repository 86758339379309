import { actions, paths } from 'src/constants';

export default {
  getStudentById: (id) => ({
    [actions.API_CALL]: {
      types: [
        actions.STUDENT_GET_REQUEST,
        actions.STUDENT_GET_SUCCESS,
        actions.STUDENT_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.build(paths.api.STUDENTS_ID, id)),
    },
  }),

  getStudents: () => ({
    [actions.API_CALL]: {
      types: [
        actions.STUDENTS_GET_REQUEST,
        actions.STUDENTS_GET_SUCCESS,
        actions.STUDENTS_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.api.STUDENTS),
    },
  }),

  getNotes: (id) => ({
    [actions.API_CALL]: {
      types: [
        actions.NOTES_GET_REQUEST,
        actions.NOTES_GET_SUCCESS,
        actions.NOTES_GET_FAILURE,
      ],
      promise: (client) =>
        client.get(paths.build(paths.api.STUDENTS_ID_NOTES, id)),
    },
  }),

  addNoteToStudent: (id, data) => ({
    [actions.API_CALL]: {
      types: [
        actions.ADD_NOTE_TO_STUDENT_REQUEST,
        actions.ADD_NOTE_TO_STUDENT_SUCCESS,
        actions.ADD_NOTE_TO_STUDENT_FAILURE,
      ],
      promise: (client) =>
        client.post(paths.build(paths.api.STUDENTS_ID_NOTES, id), data),
    },
  }),

  updateStudentNoteById: (studentId, noteId, data) => ({
    [actions.API_CALL]: {
      types: [
        actions.NOTE_UPDATE_REQUEST,
        actions.NOTE_UPDATE_SUCCESS,
        actions.NOTE_UPDATE_FAILURE,
      ],
      promise: (client) =>
        client.patch(
          paths.build(paths.api.STUDENTS_ID_NOTES_ID, studentId, noteId),
          data
        ),
    },
  }),

  deleteNoteById: (studentId, noteId) => ({
    [actions.API_CALL]: {
      types: [
        actions.NOTE_DELETE_REQUEST,
        actions.NOTE_DELETE_SUCCESS,
        actions.NOTE_DELETE_FAILURE,
      ],
      promise: (client) =>
        client.delete(
          paths.build(paths.api.STUDENTS_ID_NOTES_ID, studentId, noteId)
        ),
    },
  }),

  addToUser: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.ADD_STUDENT_TO_USER_REQUEST,
        actions.ADD_STUDENT_TO_USER_SUCCESS,
        actions.ADD_STUDENT_TO_USER_FAILURE,
      ],
      promise: (client) => client.post(paths.api.STUDENTS, data),
    },
  }),

  updateStudentById: (id, data) => ({
    [actions.API_CALL]: {
      types: [
        actions.STUDENT_UPDATE_REQUEST,
        actions.STUDENT_UPDATE_SUCCESS,
        actions.STUDENT_UPDATE_FAILURE,
      ],
      promise: (client) =>
        client.patch(paths.build(paths.api.STUDENTS_ID, id), data),
    },
  }),

  unlockCollection: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.STUDENT_COLLECTION_UNLOCK_REQUEST,
        actions.STUDENT_COLLECTION_UNLOCK_SUCCESS,
        actions.STUDENT_COLLECTION_UNLOCK_FAILURE,
      ],
      promise: (client) =>
        client.post(paths.api.STUDENTS_UNLOCK_COLLECTION, {
          ...data,
          collectionId: Number(data.collectionId),
        }),
    },
  }),

  selectStudent: (selectedStudent) => ({
    type: actions.SELECT_STUDENT,
    payload: selectedStudent,
  }),
};
