import React, { memo, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Localize } from '@kokolingo/localisation';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { selectors } from 'src/store';
import { paths } from 'src/constants';

import Badge from '../../../Badge';
import Modal from '../../../Modal';
import Button from '../../../Button';

import './index.scss';

const FREE_TRIAL_MINIMUM_DAYS_SHOWN = 7;

const FreeTrialAction = ({ subscriptionStatus }) => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  let counter;
  let title;
  let description;
  let showCounter = true;

  // Free points trial mode
  if (subscriptionStatus.daysLeft > 0 && subscriptionStatus.isTrial) {
    // Free duration trial mode
    counter = subscriptionStatus.daysLeft;
    showCounter = subscriptionStatus.daysLeft <= FREE_TRIAL_MINIMUM_DAYS_SHOWN;
    title = <Localize id="dialog.trialModeTitle" />;
    description = (
      <Localize
        id="dialog.trialModeDescription"
        values={{
          daysTrialDuration: subscriptionStatus.daysTrialDuration?.toString(),
          daysLeft: subscriptionStatus.daysLeft?.toString(),
        }}
      />
    );
  } else if (
    typeof subscriptionStatus.pointsLeft === 'number' &&
    typeof subscriptionStatus.maxPoints === 'number'
  ) {
    counter = subscriptionStatus.pointsLeft;
    title = <Localize id="dialog.trialPointsModeTitle" />;
    description = (
      <Localize
        id="dialog.trialPointsModeDescription"
        values={{
          maxPoints: subscriptionStatus.maxPoints?.toString(),
          pointsLeft: subscriptionStatus.pointsLeft?.toString(),
        }}
      />
    );
  }

  return title && description ? (
    <>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        onOutsideClick={() => setShowModal(false)}
      >
        <Modal.Title>{title}</Modal.Title>
        <br />
        <br />
        <Modal.Description>{description}</Modal.Description>
        <br />
        <br />
        <Button
          variant="register"
          collapsed
          onClick={() => {
            setShowModal(false);
            history.push(paths.SUBSCRIPTIONS);
          }}
        >
          <Localize id="button.payment" />
        </Button>
      </Modal>
      {subscriptionStatus.isTrial && showCounter && (
        <Badge
          className="free-points-counter"
          onClick={() => setShowModal(true)}
        >{`${counter}`}</Badge>
      )}
    </>
  ) : null;
};

FreeTrialAction.propTypes = {
  subscriptionStatus: PropTypes.shape({
    isTrial: PropTypes.bool,
    daysLeft: PropTypes.number,
    daysTrialDuration: PropTypes.number,
    pointsLeft: PropTypes.number,
    maxPoints: PropTypes.number,
    subscriptionExpirationDate: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  subscriptionStatus: selectors.subscriptionStatus.getSubscriptionStatus(state),
});

export default compose(connect(mapStateToProps), memo)(FreeTrialAction);
