import { actions } from 'src/constants';

const initialState = {
  data: {},
  hasLoaded: false,
  isSubmitting: false,
};

const actionMap = {
  [actions.COUPONS_ACTIVATE_REQUEST]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [actions.COUPONS_ACTIVATE_SUCCESS]: (state, { result: { data } }) => ({
    ...state,
    data,
    isSubmitting: false,
  }),
  [actions.COUPONS_ACTIVATE_FAILURE]: (state) => ({
    ...state,
    isSubmitting: false,
  }),

  [actions.COUPONS_STATUS_GET_REQUEST]: (state) => ({
    ...state,
    data: initialState.data,
    hasLoaded: false,
  }),
  [actions.COUPONS_STATUS_GET_SUCCESS]: (state, { result }) => ({
    ...state,
    data: result.data,
    hasLoaded: true,
  }),
  [actions.COUPONS_STATUS_GET_FAILURE]: (state) => ({
    ...state,
    hasLoaded: false,
  }),
};

export default (state = initialState, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
