import { createSelector } from 'reselect';
import { common } from 'src/utils';

const getDailyMapTemplates = createSelector(
  (state) => state.dailyMap.templates,
  (dailyMapTemplates) => dailyMapTemplates
);

const getSelectedDailyMap = createSelector(
  (state) => state.dailyMap.selectedMap,
  (selectedDailyMap) => selectedDailyMap
);

const getDailyMaps = createSelector(
  (state) => state.dailyMap.dailyMaps,
  (dailyMaps) => dailyMaps
);

const getDaysActive = createSelector(
  (state) => {
    if (!state.dailyMap.selectedMap) {
      return 0;
    }
    const dateStarted = state.dailyMap.selectedMap.startedAt.split('T')[0];
    return Math.abs(common.getDaysFromNow(dateStarted)) + 1;
  },
  (daysActive) => daysActive
);

const getBackground = createSelector(
  (state) => {
    if (!state.dailyMap.selectedMap) {
      return null;
    }
    const entries = Object.entries(
      state.dailyMap.selectedMap.DailyMapTemplate.meta.backgrounds
    );
    const daysActive = getDaysActive(state);
    return entries.reduce((prev, curr) => {
      return daysActive >= curr[0] ? curr[1] : prev;
    }, null);
  },
  (background) => background
);

const getHasLoadedTemplates = createSelector(
  (state) => state.dailyMap.hasLoadedTemplates,
  (hasLoaded) => hasLoaded
);

const getHasLoadedMap = createSelector(
  (state) => state.dailyMap.hasLoadedMap,
  (hasLoaded) => hasLoaded
);

const getHasLoadedDailyMaps = createSelector(
  (state) => state.dailyMap.hasLoadedDailyMaps,
  (hasLoadedDailyMaps) => hasLoadedDailyMaps
);

const getDailyMapFeedback = createSelector(
  (state) => state.dailyMap.stats,
  (stats) => stats
);

const getDailyMapCollection = createSelector(
  (state) => state.dailyMap.collection,
  (collection) => collection
);

const getHasLoadedLevels = createSelector(
  (state) => state.dailyMap.hasLoadedLevels,
  (hasLoaded) => hasLoaded
);

const getHasLoadingLevelsError = createSelector(
  (state) => state.dailyMap.error,
  (error) => error
);

const getShowLastDayModal = createSelector(
  (state) => state.dailyMap.showLastDayModal,
  (showLastDayModal) => showLastDayModal
);

const getShowDailyProgressModal = createSelector(
  (state) => state.dailyMap.showDailyProgressModal,
  (showDailyProgressModal) => showDailyProgressModal
);

const getShowFinalModal = createSelector(
  (state) => state.dailyMap.showFinalModal,
  (showFinalModal) => showFinalModal
);

const getVerificationStatus = createSelector(
  (state) => state.dailyMap.collectionVerified,
  (collectionVerified) => collectionVerified
);

export default {
  getDaysActive,
  getBackground,
  getDailyMapTemplates,
  getSelectedDailyMap,
  getDailyMaps,
  getHasLoadedTemplates,
  getHasLoadedMap,
  getHasLoadedDailyMaps,
  getDailyMapFeedback,
  getDailyMapCollection,
  getHasLoadedLevels,
  getHasLoadingLevelsError,
  getShowLastDayModal,
  getShowDailyProgressModal,
  getShowFinalModal,
  getVerificationStatus,
};
