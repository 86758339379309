import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.notes.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getNotes = createSelector(
  (state) => state.notes.data,
  (notes = []) => notes
);
const getIsSubmitting = createSelector(
  (state) => state.notes.isSubmitting,
  (isSubmitting) => isSubmitting
);

export default {
  getHasLoaded,
  getIsSubmitting,
  getNotes,
};
