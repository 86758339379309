import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { Modal, Button } from 'src/components/common';
import { paths } from 'src/constants';
import { actions, selectors } from 'src/store';
import { useIntlMessages } from 'src/hooks';
import ModalScore from './ScoreModal';

import './index.scss';

const ModalDailyMap = ({
  dailyMap,
  daysActive,
  getDailyMap,
  getDailyMapFeedback,
  hasLoadedMap,
  setShowLastDayModal,
  setShowFinalModal,
  showLastDayModal,
  showFinalModal,
  updateDailyMap,
  student,
}) => {
  const messages = useIntlMessages();
  const history = useHistory();

  useEffect(() => {
    if (!hasLoadedMap) {
      getDailyMap(student.SpeechSoundId);
    }
  }, [getDailyMap, hasLoadedMap, student.SpeechSoundId]);

  useEffect(() => {
    if (dailyMap && daysActive > dailyMap.DailyMapTemplate.durationInDays) {
      updateDailyMap(dailyMap.id, { endedAt: Date.now() });
      getDailyMapFeedback(dailyMap.id, daysActive);
    }
  }, [dailyMap, daysActive, getDailyMapFeedback, updateDailyMap]);

  return (
    <>
      <Modal
        show={showLastDayModal}
        className="daily-map-modal"
        onClose={() => setShowLastDayModal(false)}
        onOutsideClick={() => setShowLastDayModal(false)}
      >
        <Modal.Title>{dailyMap?.DailyMapTemplate.title}</Modal.Title>
        <Modal.Description>
          <div>{messages.modal.dailyMap.lastDay}</div>
          <Button
            onClick={() => {
              setShowLastDayModal(false);
              history.replace(paths.DAILY_MAP);
            }}
            className="daily-map-modal__button"
          >
            {messages.button.goToDailyMap}
          </Button>
        </Modal.Description>
      </Modal>

      <ModalScore
        show={showFinalModal}
        title={messages.modal.dailyMap.titleOverallResults}
        close={() => setShowFinalModal(false)}
      >
        <Button
          className="daily-map-modal__button"
          onClick={() => {
            setShowFinalModal(false);
            history.replace(paths.SETTINGS_DAILY_MAP);
          }}
        >
          {messages.button.newDailyMap}
        </Button>
      </ModalScore>
    </>
  );
};

ModalDailyMap.propTypes = {
  dailyMap: PropTypes.shape({
    id: PropTypes.number,
    meta: PropTypes.object,
    DailyMapTemplate: PropTypes.object,
  }),
  daysActive: PropTypes.number.isRequired,
  getDailyMap: PropTypes.func.isRequired,
  getDailyMapFeedback: PropTypes.func.isRequired,
  hasLoadedMap: PropTypes.bool.isRequired,
  setShowLastDayModal: PropTypes.func.isRequired,
  setShowFinalModal: PropTypes.func.isRequired,
  showLastDayModal: PropTypes.bool.isRequired,
  showFinalModal: PropTypes.bool,
  updateDailyMap: PropTypes.func.isRequired,
  student: PropTypes.shape({
    SpeechSoundId: PropTypes.number.isRequired,
  }).isRequired,
};

ModalDailyMap.defaultProps = {
  dailyMap: null,
  showFinalModal: null,
};

const mapStateToProps = (state) => ({
  dailyMap: selectors.dailyMap.getSelectedDailyMap(state),
  daysActive: selectors.dailyMap.getDaysActive(state),
  hasLoadedMap: selectors.dailyMap.getHasLoadedMap(state),
  showLastDayModal: selectors.dailyMap.getShowLastDayModal(state),
  showFinalModal: selectors.dailyMap.getShowFinalModal(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.dailyMap,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ModalDailyMap);
