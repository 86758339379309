import React, { memo, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import { useIntlMessages } from 'src/hooks';
import { actions, selectors } from 'src/store';
import { paths, subscriptions as subscriptionsConstants } from 'src/constants';

import { Button, Page, Title } from 'src/components/common';

import SubscriptionCard from '../SubscriptionCard';

import './index.scss';

const CARDS_MOBILE_ORDER = [
  subscriptionsConstants.KEYS.PREMIUM,
  subscriptionsConstants.KEYS.SMART,
  subscriptionsConstants.KEYS.MINI,
];

const SubscriptionSelection = ({
  nextStep,
  subscriptions,
  setStepsState,
  hasLoaded,
  wizardState,
  specialOfferSubscriptions,
}) => {
  const history = useHistory();
  const messages = useIntlMessages();

  const handleClick = (subscription) => {
    setStepsState({ subscription });
    nextStep();
  };

  useEffect(() => {
    if (!wizardState.subscription && hasLoaded) {
      const premiumSubscription = subscriptions.find(
        (subscription) =>
          subscription.key === subscriptionsConstants.KEYS.PREMIUM
      );
      setStepsState({ subscription: premiumSubscription });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLoaded]);

  const sortedSubscriptions = isMobile
    ? CARDS_MOBILE_ORDER.map((key) =>
        subscriptions.find((subscription) => subscription.key === key)
      )
    : subscriptions;

  return (
    <>
      <Page.Body>
        <div className="subscription-selection">
          <Title text={messages.page.subscriptionSelection.title} />
          {specialOfferSubscriptions.length > 0 && (
            <Button
              className="subscription-selection__promo"
              onClick={() => history.push(paths.SUBSCRIPTIONS)}
              variant="register"
              collapsed
            >
              {messages.page.subscriptionSelection.promotion}
            </Button>
          )}
          <div className="subscription-selection__subscription-container">
            {hasLoaded &&
              sortedSubscriptions.map((subscription) => (
                <SubscriptionCard
                  className="subscription-selection__subscription-container__subscription-card"
                  key={subscription.id}
                  isHighlighted={
                    subscription.key === subscriptionsConstants.KEYS.PREMIUM
                  }
                  subscription={subscription}
                  onClick={() => handleClick(subscription)}
                />
              ))}
          </div>
        </div>
      </Page.Body>
    </>
  );
};

SubscriptionSelection.propTypes = {
  nextStep: PropTypes.func.isRequired,
  setStepsState: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  specialOfferSubscriptions: PropTypes.arrayOf(PropTypes.shape({})),
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      key: PropTypes.string,
      label: PropTypes.string,
      description: PropTypes.string,
      price: PropTypes.number,
    })
  ).isRequired,
  wizardState: PropTypes.shape({
    subscription: PropTypes.shape({
      key: PropTypes.string,
    }),
  }).isRequired,
};

SubscriptionSelection.defaultProps = {
  specialOfferSubscriptions: [],
};

const mapStateToProps = (state) => ({
  subscriptions: selectors.subscriptions.getSubscriptions(state),
  specialOfferSubscriptions: selectors.subscriptions.getSpecialOfferSubscriptions(
    state
  ),
  hasLoaded: selectors.subscriptions.getHasLoaded(state),
});

const mapDispatchToProps = {
  ...actions.notifications,
  ...actions.subscriptions,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(SubscriptionSelection);
