import React, { memo } from 'react';
import PropTypes from 'prop-types';

import FeedbackComponent from 'src/components/common/FeedbackComponent';
import Localize from 'src/components/common/Localize';
import Page from 'src/components/common/Page';

import '../index.scss';

const FeedbackGrades = ({ label, onClick }) => (
  <Page.Body className="feedback-panel-container" size="md">
    <div className="feedback-panel-container__label">{label}</div>
    <div className="feedback-panel-container__feedback">
      <FeedbackComponent isFeedbackPositive onClick={onClick} />
      <FeedbackComponent onClick={onClick} />
    </div>
  </Page.Body>
);

FeedbackGrades.propTypes = {
  label: PropTypes.element,
  onClick: PropTypes.func.isRequired,
};

FeedbackGrades.defaultProps = {
  label: <Localize id="label.feedbackQuestion" />,
};

export default memo(FeedbackGrades);
