import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import Container from 'src/components/pages/Activities/ReadTogether/Levels/Container';

const ReadTogetherLevels = ({
  currency,
  getLevelsByCollectionId,
  getSpeechSoundById,
  hasLoaded,
  collectionData,
  levels,
  student,
  studentSpeechSound,
  ...props
}) => {
  useEffect(() => {
    getLevelsByCollectionId();
  }, [getLevelsByCollectionId]);

  useEffect(() => {
    getSpeechSoundById(student.SpeechSoundId);
  }, [getSpeechSoundById, student]);

  return (
    <Container
      collection={collectionData}
      hasLoaded={hasLoaded}
      items={levels}
      currency={currency}
      studentSpeechSound={studentSpeechSound}
      {...props}
    />
  );
};

ReadTogetherLevels.propTypes = {
  currency: PropTypes.shape({}),
  getLevelsByCollectionId: PropTypes.func.isRequired,
  getSpeechSoundById: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  collectionData: PropTypes.shape({}),
  levels: PropTypes.arrayOf(PropTypes.shape({})),
  student: PropTypes.shape({
    SpeechSoundId: PropTypes.number.isRequired,
  }).isRequired,
  studentSpeechSound: PropTypes.shape({}).isRequired,
};

ReadTogetherLevels.defaultProps = {
  currency: null,
  collectionData: null,
  levels: [],
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.dailyMap.getHasLoadedLevels(state),
  collectionData: selectors.dailyMap.getDailyMapCollection(state)
    ?.collectionData,
  currency: selectors.dailyMap.getDailyMapCollection(state)?.currency,
  levels: selectors.dailyMap.getDailyMapCollection(state)?.levels,
  student: selectors.authentication.getStudent(state),
  studentSpeechSound: selectors.speechSounds.getSpeechSound(state),
});

const mapDispatchToProps = {
  ...actions.speechSounds,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ReadTogetherLevels);
