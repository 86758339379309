import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.findWordsStepThreeLevels.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getCurrencyData = createSelector(
  (state) => state.findWordsStepThreeLevels.data.currency,
  (currency = {}) => currency
);
const getLevelsData = createSelector(
  (state) => state.findWordsStepThreeLevels.data.findWordsStepThreeLevels,
  (levels = []) => levels
);

export default {
  getCurrencyData,
  getHasLoaded,
  getLevelsData,
};
