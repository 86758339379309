import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleOAuthProvider } from '@react-oauth/google';

const GoogleContext = React.createContext();

const GoogleProvider = ({ children }) => {
  const [googleAuthLoaded, setGoogleAuthLoaded] = useState();

  return (
    <GoogleContext.Provider value={googleAuthLoaded}>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        onScriptLoadSuccess={() => setGoogleAuthLoaded(true)}
        onScriptLoadError={() => setGoogleAuthLoaded(false)}
      >
        {children}
      </GoogleOAuthProvider>
    </GoogleContext.Provider>
  );
};

GoogleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { GoogleProvider, GoogleContext };
