import { useCallback, useState, useRef } from 'react';

import { audio } from 'src/utils';

const useAudio = () => {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const audioRef = useRef();

  const playAudio = useCallback((url, onAudioEndCallback) => {
    const handleAudioEndCallBack = () => {
      setIsAudioPlaying(false);

      if (onAudioEndCallback) {
        onAudioEndCallback();
      }
    };

    audioRef.current = audio.triggerAudio(url, handleAudioEndCallBack);
    setIsAudioPlaying(!audioRef.current.paused);
  }, []);

  return [playAudio, isAudioPlaying, setIsAudioPlaying];
};

export default useAudio;
