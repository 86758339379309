import { memo, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Actions = ({ children }) => {
  const [actionsContainer, setActionsContainer] = useState(
    document.getElementById('navbar-container-right')
  );

  useEffect(() => {
    if (!actionsContainer) {
      setActionsContainer(document.getElementById('navbar-container-right'));
    }
  }, [actionsContainer, setActionsContainer]);

  return actionsContainer
    ? ReactDOM.createPortal(children, actionsContainer)
    : null;
};

Actions.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(Actions);
