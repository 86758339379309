import { actions, paths } from 'src/constants';
import { MAX_PAGE_SIZE } from 'src/constants/common';

export default {
  getGroups: () => ({
    [actions.API_CALL]: {
      types: [
        actions.MEMORY_STEP_TWO_EXTRA_GROUPS_GET_REQUEST,
        actions.MEMORY_STEP_TWO_EXTRA_GROUPS_GET_SUCCESS,
        actions.MEMORY_STEP_TWO_EXTRA_GROUPS_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.api.MEMORY_STEP_TWO_EXTRA),
    },
  }),
  getCollectionsByGroupId: (groupId, page, pageSize = MAX_PAGE_SIZE) => ({
    [actions.API_CALL]: {
      types: [
        actions.MEMORY_STEP_TWO_EXTRA_COLLECTIONS_GET_REQUEST,
        actions.MEMORY_STEP_TWO_EXTRA_COLLECTIONS_GET_SUCCESS,
        actions.MEMORY_STEP_TWO_EXTRA_COLLECTIONS_GET_FAILURE,
      ],
      promise: (client) =>
        client.get(
          paths.build(paths.api.MEMORY_STEP_TWO_EXTRA_ID_COLLECTIONS, groupId),
          {
            params: {
              page,
              pageSize,
            },
          }
        ),
    },
  }),
  getLevelsByCollectionId: (collectionId) => ({
    [actions.API_CALL]: {
      types: [
        actions.MEMORY_STEP_TWO_EXTRA_LEVELS_GET_REQUEST,
        actions.MEMORY_STEP_TWO_EXTRA_LEVELS_GET_SUCCESS,
        actions.MEMORY_STEP_TWO_EXTRA_LEVELS_GET_FAILURE,
      ],
      promise: (client) =>
        client.get(
          paths.build(
            paths.api.MEMORY_STEP_TWO_EXTRA_COLLECTIONS_ID_LEVELS,
            collectionId
          )
        ),
    },
  }),
};
