import PropTypes from 'prop-types';
import React, { memo } from 'react';
import cn from 'classnames';

import './index.scss';

const Footer = ({ className, children }) => {
  const footerClassName = cn('page-footer', className);

  return <div className={footerClassName}>{children}</div>;
};

Footer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

Footer.defaultProps = {
  className: '',
  children: undefined,
};

export default memo(Footer);
