import React from 'react';
import { Localize } from '@kokolingo/localisation';
import { object, string } from 'yup';

export default object().shape({
  childName: string().required(() => <Localize id="error.childNameRequired" />),
  fullName: string().required(() => <Localize id="error.fullNameRequired" />),
  email: string()
    .email(() => <Localize id="error.emailFormatInvalid" />)
    .required(() => <Localize id="error.emailRequired" />),
  phoneNumber: string().required(() => (
    <Localize id="error.phoneNumberRequired" />
  )),
});
