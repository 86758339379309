import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const FlashCardsStepTwoExtraCollections = ({
  flashCardsStepTwoExtraCollections,
  flashCardsStepTwoExtraCurrency,
  getCollections,
  hasLoaded,
  pageCount,
  student,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="flash-cards-collections-container--step-two-extra"
      collectionsPath={paths.FLASH_CARDS_STEP_TWO_EXTRA}
      currency={flashCardsStepTwoExtraCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={flashCardsStepTwoExtraCollections}
      levelsPath={paths.FLASH_CARDS_STEP_TWO_EXTRA_LEVELS}
      pageCount={pageCount}
      student={student}
      title={messages.game.flashCards}
    />
  );
};

FlashCardsStepTwoExtraCollections.propTypes = {
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  flashCardsStepTwoExtraCollections: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  flashCardsStepTwoExtraCurrency: PropTypes.shape({}).isRequired,
  pageCount: PropTypes.number.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.flashCardsStepTwoExtraCollections.getHasLoaded(state),
  flashCardsStepTwoExtraCurrency: selectors.flashCardsStepTwoExtraCollections.getCurrencyData(
    state
  ),
  flashCardsStepTwoExtraCollections: selectors.flashCardsStepTwoExtraCollections.getCollectionsData(
    state
  ),
  pageCount: selectors.flashCardsStepTwoExtraCollections.getPageCount(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.flashCardsStepTwoExtra,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(FlashCardsStepTwoExtraCollections);
