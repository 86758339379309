import React, { memo } from 'react';
import PropTypes from 'prop-types';

import SentenceCard from '../SentenceCard';

const SENTENCES_PER_SCREEN = 3;

const Sentences = ({ level, onClick, screenSentences, sentencesCount }) => {
  const screenSentencesCount =
    sentencesCount - level > SENTENCES_PER_SCREEN
      ? SENTENCES_PER_SCREEN
      : sentencesCount - level;

  return (
    <>
      {screenSentences.map((sentence) => (
        <SentenceCard key={sentence.id} sentence={sentence} onClick={onClick} />
      ))}
      {Array.from(
        {
          length: screenSentencesCount - screenSentences.length,
        },
        (dummy, index) => (
          <SentenceCard key={index} />
        )
      )}
    </>
  );
};

Sentences.propTypes = {
  sentencesCount: PropTypes.number.isRequired,
  level: PropTypes.number.isRequired,
  screenSentences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      createdSentence: PropTypes.shape({}),
    })
  ).isRequired,
  onClick: PropTypes.func,
};

Sentences.defaultProps = {
  onClick: () => {},
};

export default memo(Sentences);
