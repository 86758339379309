import PropTypes from 'prop-types';
import React from 'react';

import Page from '../../../Page';
import Stepper from '../../../Stepper';

import './index.scss';

const Wizard = ({ children, ...wizardProps }) => {
  const content = (
    <Page>
      <Page.Header className="wizard__header">
        <Stepper {...wizardProps} />
      </Page.Header>
      {children}
    </Page>
  );

  return content;
};

Wizard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Wizard;
