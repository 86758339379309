import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

function calculateRatio(width, height, ref) {
  if (!ref.current) {
    return 1;
  }

  const widthRatio = ref.current.clientWidth / width;
  const heightRatio = ref.current.clientHeight / height;

  return widthRatio < heightRatio ? widthRatio : heightRatio;
}

export default (width, height) => {
  const ref = useRef();
  const [ratio, setRatio] = useState(1);

  const setRef = useCallback(
    (newRef) => {
      if (ref.current || !newRef) {
        return;
      }
      ref.current = newRef;
      setRatio(calculateRatio(width, height, ref));
    },
    [setRatio, ref, width, height]
  );

  const observer = useMemo(
    () =>
      new ResizeObserver(() => {
        if (!ref.current) {
          return;
        }
        setRatio(calculateRatio(width, height, ref));
      }),
    [setRatio, width, height]
  );

  useEffect(() => {
    observer.observe(document.body);

    return () => {
      observer.disconnect();
    };
  }, [observer]);

  return useMemo(
    () => ({ style: { zoom: ratio, width, height }, ref: setRef }),
    [ratio, width, height, setRef]
  );
};
