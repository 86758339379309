import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const DiscoverStepOneCollections = ({
  discoverStepOneCollections,
  discoverStepOneCurrency,
  getCollections,
  hasLoaded,
  student,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="discover-collections-container--step-one"
      currency={discoverStepOneCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={discoverStepOneCollections}
      levelsPath={paths.DISCOVER_STEP_ONE_LEVELS}
      student={student}
      title={messages.game.discover}
    />
  );
};

DiscoverStepOneCollections.propTypes = {
  discoverStepOneCollections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  discoverStepOneCurrency: PropTypes.shape({}).isRequired,
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.discoverStepOneCollections.getHasLoaded(state),
  discoverStepOneCollections: selectors.discoverStepOneCollections.getCollectionsData(
    state
  ),
  discoverStepOneCurrency: selectors.discoverStepOneCollections.getCurrencyData(
    state
  ),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.discoverStepOne,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(DiscoverStepOneCollections);
