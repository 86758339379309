import { actions, paths } from 'src/constants';

export default {
  getActivitiesByGroupId: (activityGroupId) => ({
    [actions.API_CALL]: {
      types: [
        actions.ACTIVITIES_GET_REQUEST,
        actions.ACTIVITIES_GET_SUCCESS,
        actions.ACTIVITIES_GET_FAILURE,
      ],
      promise: (client) =>
        client.get(paths.build(paths.api.ACTIVITIES, activityGroupId)),
    },
  }),
  resetState: () => ({
    type: actions.ACTIVITIES_RESET,
  }),
};
