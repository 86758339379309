/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo, useState, useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { PageLoader, Page, Video, Modal } from 'src/components/common';
import { actions, selectors } from 'src/store';
import { useQuery } from 'src/hooks';

import { Menu } from './components';

import './index.scss';

const Help = ({ hasLoaded, getHelp, help, isSpeechTherapist }) => {
  const query = useQuery();

  const [selectedItem, setSelectedItem] = useState({
    parentId: null,
    childId: null,
  });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getHelp();
  }, [getHelp]);

  const selectedHelp = useMemo(() => {
    if (hasLoaded) {
      if (!selectedItem.parentId) {
        let resolvedHelp = help.sort((a, b) => a.id - b.id)[0];

        const helpKey = query.get('key');

        if (helpKey) {
          const foundHelp = help.find(({ key }) => key === helpKey);
          const parent = help.find(({ id }) => id === foundHelp.HelpId);

          if (foundHelp) {
            resolvedHelp = { ...foundHelp, parentId: parent?.id };
          }
        }

        setSelectedItem({
          ...selectedItem,
          parentId: resolvedHelp?.parentId ?? resolvedHelp.id,
          childId: resolvedHelp.id,
        });

        return resolvedHelp;
      }

      if (selectedItem.childId) {
        return help.find(({ id }) => id === selectedItem.childId);
      }

      return help.find(({ id }) => id === selectedItem.parentId);
    }

    return {};
  }, [query, hasLoaded, selectedItem, help]);

  const handleItemClick = useCallback(
    (id, isChild) => {
      if (selectedItem.parentId === id) {
        setSelectedItem({ ...selectedItem, parentId: null });
      } else if (selectedItem.childId === id) {
        setSelectedItem({ ...selectedItem, childId: null });
      } else if (isChild) {
        setSelectedItem({ ...selectedItem, childId: id });
      } else {
        setSelectedItem({ ...selectedItem, parentId: id, childId: null });
      }

      setShowModal(true);
    },
    [selectedItem]
  );

  if (!hasLoaded) {
    return <PageLoader />;
  }

  const mainContent = (
    <>
      <span
        className="help-container__content-container__title"
        dangerouslySetInnerHTML={{
          __html: selectedHelp.title,
        }}
      />
      {selectedHelp.videoUrl && (
        <Video
          className="help-container__content-container__video"
          src={selectedHelp.videoUrl}
        />
      )}
      <div
        className="help-container__content-container__description"
        dangerouslySetInnerHTML={{
          __html: selectedHelp.description,
        }}
      />
    </>
  );

  const content = (
    <>
      {isMobile && (
        <Modal show={showModal} onClose={() => setShowModal(false)}>
          {mainContent}
        </Modal>
      )}
      <Page>
        <Page.Section>
          <Page.Body className="help-container" color="light" size="sm">
            <div className="help-container__menu-container">
              <Menu
                isSpeechTherapist={isSpeechTherapist}
                items={help}
                onItemClick={handleItemClick}
                selectedItem={selectedItem}
              />
            </div>
          </Page.Body>
          {!isMobile && (
            <Page.Body className="help-container__content-container">
              {mainContent}
            </Page.Body>
          )}
        </Page.Section>
      </Page>
    </>
  );

  return content;
};

Help.propTypes = {
  getHelp: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  help: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSpeechTherapist: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  help: selectors.help.getHelpData(state),
  hasLoaded: selectors.help.getHasLoaded(state),
  isSpeechTherapist: selectors.authentication.getIsSpeechTherapist(state),
});

const mapDispatchToProps = {
  ...actions.help,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(Help);
