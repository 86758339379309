import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { isMobile } from 'react-device-detect';

import { actions, selectors } from 'src/store';
import { useIntlMessages } from 'src/hooks';

import { Button, Icon, OverlayLoader, Page } from 'src/components/common';

import { NoteFormModal, NoteCard } from './components';

import './index.scss';

const Notes = ({
  addNoteToStudent,
  getNotes,
  hasLoaded,
  isSubmitting = true,
  notes,
  updateStudentNoteById,
  deleteNoteById,
}) => {
  const { studentId } = useParams();
  const messages = useIntlMessages();

  const [selectedNote, setSelectedNote] = useState(null);
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);

  useEffect(() => {
    getNotes(studentId);
  }, [getNotes, studentId]);

  const selectNote = useCallback((note) => {
    setSelectedNote(note);
  }, []);

  const handleSubmitNewNote = useCallback(
    (data) => {
      addNoteToStudent(studentId, data).then(() => {
        setShowAddNoteModal(false);
        getNotes(studentId);
      });
    },
    [addNoteToStudent, getNotes, studentId]
  );
  const handleDeleteNoteById = useCallback(() => {
    if (selectedNote) {
      deleteNoteById(selectedNote.StudentId, selectedNote.id).then(() => {
        getNotes(studentId);
        setSelectedNote(null);
      });
    }
  }, [deleteNoteById, getNotes, studentId, selectedNote]);

  const handleSubmitUpdatedNote = useCallback(
    (data) => {
      updateStudentNoteById(studentId, selectedNote?.id, data).then(() => {
        getNotes(studentId);
        setSelectedNote(null);
      });
    },
    [updateStudentNoteById, getNotes, studentId, selectedNote]
  );

  if (!hasLoaded) {
    return <OverlayLoader className="notes-container__overlay-loader" />;
  }

  return (
    <>
      <Page.Body color="light" className="page-body--notes">
        {isSubmitting && (
          <OverlayLoader className="notes-container__overlay-loader" />
        )}
        <div className="notes-container">
          {notes.map((note) => (
            <NoteCard note={note} onClick={selectNote} key={note.id} />
          ))}
          {showAddNoteModal && (
            <NoteFormModal
              onSubmit={handleSubmitNewNote}
              onClose={() => setShowAddNoteModal(false)}
            >
              <div className="notes-container__buttons-container notes-container__buttons-container--center">
                <Button isSubmit>
                  {messages.button.addNote}
                  <Icon name="note" size={isMobile ? 20 : 40} />
                </Button>
              </div>
            </NoteFormModal>
          )}
          {selectedNote && (
            <NoteFormModal
              note={selectedNote}
              onSubmit={handleSubmitUpdatedNote}
              onClose={() => setSelectedNote(null)}
            >
              <div className="notes-container__buttons-container">
                <Button variant="danger" onClick={handleDeleteNoteById}>
                  {messages.button.delete}
                  <Icon name="delete" size={isMobile ? 20 : 40} />
                </Button>
                <Button isSubmit variant="register">
                  {messages.button.saveChanges}
                  <Icon name="save" size={isMobile ? 20 : 40} />
                </Button>
              </div>
            </NoteFormModal>
          )}
        </div>
      </Page.Body>
      <Page.Footer className="notes-footer">
        <Button isSubmit onClick={() => setShowAddNoteModal(true)}>
          {messages.button.addNewNote}
          <Icon name="note" size={isMobile ? 20 : 40} />
        </Button>
      </Page.Footer>
    </>
  );
};

Notes.propTypes = {
  addNoteToStudent: PropTypes.func.isRequired,
  updateStudentNoteById: PropTypes.func.isRequired,
  deleteNoteById: PropTypes.func.isRequired,
  getNotes: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  notes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.notes.getHasLoaded(state),
  isSubmitting: selectors.notes.getIsSubmitting(state),
  notes: selectors.notes.getNotes(state),
});

const mapDispatchToProps = {
  ...actions.students,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(Notes);
