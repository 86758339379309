import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Badge from 'src/components/common/Badge';
import Localize from 'src/components/common/Localize';

import './index.scss';

const ListenAgainBadge = ({ handleListenAgain }) => {
  return (
    <div className="listen-again-container">
      <Badge
        size="medium"
        iconName="repeat"
        type="neutral"
        onClick={handleListenAgain}
      />
      <p className="listen-again-container__label">
        <Localize id="label.listenAgain" />
      </p>
    </div>
  );
};

ListenAgainBadge.propTypes = {
  handleListenAgain: PropTypes.func.isRequired,
};

export default memo(ListenAgainBadge);
