import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Image } from 'src/components/common';
import './index.scss';

const LevelImage = ({
  coverImage,
  level,
  isCoverImage,
  isHidden,
  shake,
  onClick,
}) => {
  const levelImageContainerClassNames = useMemo(
    () =>
      cn('find-words-level-image-container', {
        'find-words-level-image-container--hidden': isHidden,
      }),
    [isHidden]
  );

  const levelImageClassNames = useMemo(
    () =>
      cn({
        'find-words-level-image-container__image': !isCoverImage,
        'find-words-level-image-container__image--shake': shake,
        'find-words-level-image-container__cover-image': isCoverImage,
      }),
    [isCoverImage, shake]
  );

  return (
    <div
      className={levelImageContainerClassNames}
      onClick={() => onClick(level)}
      onKeyPress={() => onClick(level)}
      tabIndex={0}
      role="button"
    >
      <Image
        className={levelImageClassNames}
        alt={level.key}
        src={isCoverImage ? coverImage : level.imageUrl}
      />
    </div>
  );
};

LevelImage.propTypes = {
  level: PropTypes.shape({
    key: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
  }).isRequired,
  coverImage: PropTypes.string,
  isCoverImage: PropTypes.bool,
  isHidden: PropTypes.bool,
  shake: PropTypes.bool,
  onClick: PropTypes.func,
};

LevelImage.defaultProps = {
  coverImage: null,
  isCoverImage: false,
  isHidden: false,
  onClick: () => {},
  shake: false,
};

export default memo(LevelImage);
