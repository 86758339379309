import PropTypes from 'prop-types';
import React, { memo } from 'react';

import LevelCard from './LevelCard';

const LevelSection = ({
  canPaintPairs,
  cleanTerm,
  cleanedTerms,
  items,
  selectTerm,
  selectedTermId,
  shakeTermId,
}) => {
  return items.map((item, index) => (
    <LevelCard
      canPaintPairs={canPaintPairs}
      cleanTerm={cleanTerm}
      cleanedTerms={cleanedTerms}
      isPair={index % 2 !== 0}
      key={index}
      level={item}
      selectTerm={selectTerm}
      selectedTermId={selectedTermId}
      shakeTermId={shakeTermId}
    />
  ));
};

LevelSection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

LevelSection.defaultProps = {
  items: [],
};

export default memo(LevelSection);
