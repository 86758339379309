import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import cn from 'classnames';

import { useIntlMessages, usePaymentModule } from 'src/hooks';

import { InfoField } from 'src/components/common';

import './index.scss';

const InfoFields = ({
  cancelTrial,
  showSubscriptionCancelLink,
  subscriptionStatusData,
  lastTransactionStatus,
}) => {
  const messages = useIntlMessages();
  const paymentModule = usePaymentModule(subscriptionStatusData.paymentMethod);

  const InfoComponent = paymentModule?.InfoComponent || (() => null);

  const subscriptionCancelLinkClassName = useMemo(
    () =>
      cn('info-fields-container__cancel-link', {
        'info-fields-container__cancel-link--hidden': !showSubscriptionCancelLink,
      }),
    [showSubscriptionCancelLink]
  );

  return (
    <div className="info-fields-container">
      <div className="info-fields-container__subscription-info">
        <InfoField
          className="info-fields-container__info-field"
          label={messages.label.currentSubscription}
          value={subscriptionStatusData.currentPackage}
        />
        <span
          className={subscriptionCancelLinkClassName}
          onClick={cancelTrial}
          onKeyPress={cancelTrial}
          role="button"
          tabIndex={0}
        >
          {messages.page.subscriptionStatus.button.subscriptionCancellation}
        </span>
      </div>
      <InfoField
        className="info-fields-container__info-field"
        label={messages.label.subscriptionStatus}
        value={subscriptionStatusData.state}
        info={<InfoComponent lastTransactionStatus={lastTransactionStatus} />}
      />
      {subscriptionStatusData.description !== '-' && (
        <InfoField
          className="info-fields-container__info-field"
          label={messages.label.subscriptionEndDate}
          value={subscriptionStatusData.description}
        />
      )}
    </div>
  );
};

InfoFields.propTypes = {
  cancelTrial: PropTypes.func.isRequired,
  subscriptionStatusData: PropTypes.shape({
    currentPackage: PropTypes.string,
    state: PropTypes.string,
    description: PropTypes.node,
    paymentMethod: PropTypes.string,
  }).isRequired,
  showSubscriptionCancelLink: PropTypes.bool.isRequired,
  lastTransactionStatus: PropTypes.string.isRequired,
};

export default memo(InfoFields);
