import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import { common } from 'src/utils';

import ContentContainer from 'src/components/common/ContentContainer';

import './index.scss';

const NoteCard = ({ note, onClick }) => {
  const formattedNoteDate = useMemo(
    () =>
      common.formatDate(
        common.getDateFromNow(common.getDaysFromNow(note.createdAt))
      ),
    [note.createdAt]
  );

  return (
    <ContentContainer
      className="note-card-container"
      onClick={() => onClick(note)}
    >
      <span>
        <b>
          {note.label}, {formattedNoteDate}
        </b>
      </span>
      <span>{note.description}</span>
    </ContentContainer>
  );
};

NoteCard.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    createdAt: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(NoteCard);
