import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import './index.scss';

const RadioButton = ({
  checked,
  className,
  name,
  children,
  onChange,
  value,
  imgSrc,
}) => {
  const id = useMemo(() => Math.random().toString(36).substr(2, 5), []);
  const radioButtonClassName = cn('radio-button-container', className);

  const formContext = useFormContext();

  return (
    <label htmlFor={id} className={radioButtonClassName}>
      <input
        className="radio-button-container__input"
        checked={checked}
        id={id}
        name={name}
        onChange={onChange}
        ref={formContext?.register}
        type="radio"
        value={value}
      />
      {imgSrc && (
        <img
          className="radio-button-container__img"
          src={imgSrc}
          alt="select"
        />
      )}
      {checked && <div className="radio-button-container__input__checkmark" />}
      <div
        className={cn('radio-button-container__label', {
          'radio-button-container__label--selected': checked,
        })}
      >
        {children}
      </div>
    </label>
  );
};

RadioButton.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

RadioButton.defaultProps = {
  checked: false,
  children: undefined,
  className: '',
  imgSrc: '',
};

export default memo(RadioButton);
