import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const DiscoverStepOneExtraCollections = ({
  discoverStepOneExtraCollections,
  discoverStepOneExtraCurrency,
  getCollections,
  hasLoaded,
  student,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="discover-collections-container--step-one-extra"
      currency={discoverStepOneExtraCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={discoverStepOneExtraCollections}
      levelsPath={paths.DISCOVER_STEP_ONE_EXTRA_LEVELS}
      student={student}
      title={messages.game.discover}
    />
  );
};

DiscoverStepOneExtraCollections.propTypes = {
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  discoverStepOneExtraCollections: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  discoverStepOneExtraCurrency: PropTypes.shape({}).isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.discoverStepOneExtraCollections.getHasLoaded(state),
  discoverStepOneExtraCollections: selectors.discoverStepOneExtraCollections.getCollectionsData(
    state
  ),
  discoverStepOneExtraCurrency: selectors.discoverStepOneExtraCollections.getCurrencyData(
    state
  ),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.discoverStepOneExtra,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(DiscoverStepOneExtraCollections);
