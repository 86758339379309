import { actions as actionConstants } from 'src/constants';
import { actions } from 'src/store';

export default () => (store) => (next) => (action) => {
  if (action.type === actionConstants.PARROT_SELECTED_PARTS_GET_SUCCESS) {
    store.dispatch(actions.parrot.getCheapParts());
  }

  return next(action);
};
