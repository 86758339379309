import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { paths } from 'src/constants';

const useMakePayment = (
  subscriptionId,
  subscriptionDuration,
  paymentLabel,
  subscriptionKey
) => {
  const history = useHistory();
  return useCallback(
    (paymentProvider, paymentMethod) => {
      if (!subscriptionId || !subscriptionDuration) {
        console.warn('Missing subscriptionId or subscriptionDuration');
        return;
      }
      history.push(
        `${paths.SUBSCRIPTION_PAYMENT}?paymentProvider=${paymentProvider}`,
        {
          subscriptionData: {
            subscriptionId,
            subscriptionDuration,
            subscriptionKey,
          },
          paymentMethod,
          paymentLabel,
        }
      );
    },
    [
      history,
      subscriptionId,
      subscriptionDuration,
      paymentLabel,
      subscriptionKey,
    ]
  );
};

export default useMakePayment;
