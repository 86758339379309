import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Form, Input } from 'src/components/common';

import validationSchema from './validationSchema';

import './index.scss';

const FormContainer = ({ sendResetPasswordLink }) => {
  const messages = useIntlMessages();
  return (
    <div className="forgot-password-container">
      <span className="forgot-password-container__info-text">
        {messages.page.forgotPassword.title}
      </span>
      <Form
        submitBtnLabel={messages.button.send}
        onSubmit={sendResetPasswordLink}
        validationSchema={validationSchema}
        formClassName="forgot-password-container__form"
      >
        <Input
          name="resetPasswordEmail"
          type="email"
          label={messages.label.email}
          required
        />
      </Form>
      <div className="forgot-password-container__register-redirect">
        <span>
          {messages.label.dontHaveAccount}{' '}
          <Link to={paths.LOGIN}>{messages.button.register}</Link>
        </span>
      </div>
    </div>
  );
};

FormContainer.propTypes = {
  sendResetPasswordLink: PropTypes.func.isRequired,
};

export default memo(FormContainer);
