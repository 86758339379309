import { actions, paths } from 'src/constants';

export default {
  getStudentSelectedParts: () => ({
    [actions.API_CALL]: {
      types: [
        actions.PARROT_SELECTED_PARTS_GET_REQUEST,
        actions.PARROT_SELECTED_PARTS_GET_SUCCESS,
        actions.PARROT_SELECTED_PARTS_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.api.PARROT_PARTS),
    },
  }),
  updateStudentSelectedParts: (parrotPartId) => ({
    [actions.API_CALL]: {
      types: [
        actions.PARROT_SELECTED_PARTS_UPDATE_REQUEST,
        actions.PARROT_SELECTED_PARTS_UPDATE_SUCCESS,
        actions.PARROT_SELECTED_PARTS_UPDATE_FAILURE,
      ],
      promise: (client) =>
        client.patch(paths.api.PARROT_PARTS, { parrotPartId }),
    },
  }),
  removeStudentSelectedParts: (parrotPartId) => ({
    [actions.API_CALL]: {
      types: [
        actions.PARROT_SELECTED_PARTS_REMOVE_REQUEST,
        actions.PARROT_SELECTED_PARTS_REMOVE_SUCCESS,
        actions.PARROT_SELECTED_PARTS_REMOVE_FAILURE,
      ],
      promise: (client) =>
        client.patch(
          paths.build(
            paths.api.PARROT_SELECTED_PARTS_REMOVE_BY_ID,
            parrotPartId
          )
        ),
    },
  }),
  getCheapParts: () => ({
    [actions.API_CALL]: {
      types: [
        actions.PARROT_PARTS_CHEAP_GET_REQUEST,
        actions.PARROT_PARTS_CHEAP_GET_SUCCESS,
        actions.PARROT_PARTS_CHEAP_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.api.PARROT_PARTS_CHEAP),
    },
  }),
  getPartsByCategoryLabel: (categoryLabel, pageNumber, pageSize) => ({
    [actions.API_CALL]: {
      types: [
        actions.PARROT_PARTS_BY_CATEGORY_LABEL_GET_REQUEST,
        actions.PARROT_PARTS_BY_CATEGORY_LABEL_GET_SUCCESS,
        actions.PARROT_PARTS_BY_CATEGORY_LABEL_GET_FAILURE,
      ],
      promise: (client) =>
        client.get(
          paths.build(paths.api.PARROT_PARTS_BY_CATEGORY_LABEL, categoryLabel),
          {
            params: {
              page: pageNumber,
              pageSize,
            },
          }
        ),
    },
  }),
  getOwnedParts: (pageNumber, pageSize) => ({
    [actions.API_CALL]: {
      types: [
        actions.PARROT_PARTS_OWNED_GET_REQUEST,
        actions.PARROT_PARTS_OWNED_GET_SUCCESS,
        actions.PARROT_PARTS_OWNED_GET_FAILURE,
      ],
      promise: (client) =>
        client.get(paths.api.PARROT_PARTS_OWNED, {
          params: {
            page: pageNumber,
            pageSize,
          },
        }),
    },
  }),
  buyParrotPart: (parrotPartId) => ({
    [actions.API_CALL]: {
      types: [
        actions.PARROT_PARTS_BUY_POST_REQUEST,
        actions.PARROT_PARTS_BUY_POST_SUCCESS,
        actions.PARROT_PARTS_BUY_POST_FAILURE,
      ],
      promise: (client) =>
        client.post(
          paths.build(paths.api.PARROT_PARTS_BUY_BY_ID, parrotPartId)
        ),
    },
  }),
};
