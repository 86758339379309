import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';

import { useZoom } from 'src/hooks';

import ArrowPagination from '../ArrowPagination';

import './index.scss';

const MenuFrame = ({
  children,
  currentPageNumber,
  pagesCount,
  onBack,
  onForward,
}) => {
  const { style, ref } = useZoom(360, 640);

  return (
    <div className="menu-frame" ref={ref}>
      <div className="menu-frame__body" style={isMobile ? style : undefined}>
        <ArrowPagination
          activePage={currentPageNumber}
          pageCount={pagesCount}
          next={onForward}
          prev={onBack}
          withPageLabel
          vertical
        >
          {children}
        </ArrowPagination>
      </div>
    </div>
  );
};

MenuFrame.propTypes = {
  children: PropTypes.node,
  currentPageNumber: PropTypes.number,
  pagesCount: PropTypes.number,
  onBack: PropTypes.func,
  onForward: PropTypes.func,
  // title: PropTypes.string,
  // currencyImageUrl: PropTypes.string,
};

MenuFrame.defaultProps = {
  children: null,
  // title: '',
  // currencyImageUrl: '',
  currentPageNumber: 1,
  pagesCount: 1,
  onBack: null,
  onForward: null,
};

export default memo(MenuFrame);
