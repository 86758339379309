import { useHistory, useParams } from 'react-router';
import { useCallback } from 'react';

import { paths } from 'src/constants';

export default () => {
  const history = useHistory();
  const params = useParams();

  return useCallback(
    (subactivity) => {
      history.push(
        paths.build(paths[subactivity.key], ...Object.values(params))
      );
    },
    [history, params]
  );
};
