export default {
  ARTICULATION_GYMNASTICS: 'game.articulationGymnastics',
  TREASURE_HUNT: 'game.treasureHunt',
  PAINTING: 'game.painting',
  CLIMB: 'game.climb',
  DISCOVER: 'game.discover',
  FLASH_CARDS: 'game.flashCards',
  FIND_WORDS: 'game.findWords',
  MEMORY: 'game.memory',
  WHEEL_OF_FORTUNE: 'game.wheelOfFortune',
  REPLENISHMENT: 'game.articulationGymnastics',
  READ_TOGETHER: 'game.replenishment',
  READ_AND_THINK: 'game.readAndThink',
};
