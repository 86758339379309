import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useIntlMessages } from 'src/hooks';
import { subscriptions } from 'src/constants';
import subscriptionsUtils from 'src/utils/subscriptions';
import { Icon, Localize } from 'src/components/common';

import './index.scss';

const SUBSCRIPTION_DURATIONS = [
  subscriptions.DURATION.KEYS.TEN_DAYS_FORWARD,
  subscriptions.DURATION.KEYS.ONE_MONTH_FORWARD,
  subscriptions.DURATION.KEYS.TWO_MONTHS_FORWARD,
];

const SubscriptionCard = ({
  specialOfferSubscriptions,
  selectedSubscriptionId,
  selectPackage,
}) => {
  const messages = useIntlMessages();

  return (
    specialOfferSubscriptions
      // Only support 3 options for now :/
      // For more options more durations is needed
      .slice(0, 3)
      .map((subscription, index) => {
        const { description = '', label, price, id } = subscription;
        const selected = selectedSubscriptionId === id;
        const className = cn('subscriptions-offer', {
          selected,
        });

        const durationKey = SUBSCRIPTION_DURATIONS[index];
        const durationMessageKey = subscriptions.DURATION[durationKey];
        const formattedPrice = subscriptionsUtils.getPriceForDuration(
          durationKey,
          price
        );
        const subscriptionDuration =
          subscriptions.DURATION_VALUES[durationKey] < 1 ? 10 : 30;

        return (
          <div
            className={className}
            onClick={() => selectPackage({ subscriptionId: id, durationKey })}
            key={id}
          >
            <div className="subscriptions-offer__checkmark">
              <Icon name="check" />
            </div>
            <div className="subscriptions-offer__title">
              {label} <br />
              {messages[durationMessageKey]}
            </div>
            <div className="subscriptions-offer__prices">
              <div className="subscriptions-offer__price-container">
                <div className="subscriptions-offer__price-container__label">
                  {messages.page.subscriptionSelection.card.discountedPrice}
                  <span className="subscriptions-offer__price-container__asterisk">
                    *
                  </span>
                </div>
                <div>
                  <span className="subscriptions-offer__price-container__price--highlighted">
                    <Localize.Currency
                      id="meta.currency"
                      value={formattedPrice}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="subscriptions-offer__discount">
              {
                messages({
                  price: (
                    <span>
                      {subscriptionsUtils.getDailyPrice(
                        price,
                        subscriptionDuration
                      )}
                    </span>
                  ),
                }).page.subscriptions.promo.dailyPrice
              }
            </div>

            <div className="subscriptions-offer__description">
              *{description}
            </div>
          </div>
        );
      })
  );
};

const SubscriptionType = PropTypes.shape({
  id: PropTypes.number,
  key: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  fullPrice: PropTypes.number,
  validTo: PropTypes.string,
});

SubscriptionCard.propTypes = {
  selectedSubscriptionId: PropTypes.number.isRequired,
  selectPackage: PropTypes.func.isRequired,
  specialOfferSubscriptions: PropTypes.arrayOf(SubscriptionType).isRequired,
};

export default SubscriptionCard;
