import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';
import { Image } from 'src/components/common';

const ShopCategoriesMenuItem = ({ img, onClick, selected }) => {
  const className = cn('store-menu-item', {
    'store-menu-item--selected': selected,
  });

  return (
    <div
      className={className}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      <Image className="store-menu-item__img" src={img} alt="Menu item" />
    </div>
  );
};

ShopCategoriesMenuItem.propTypes = {
  selected: PropTypes.bool,
  img: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

ShopCategoriesMenuItem.defaultProps = {
  selected: false,
};

export default memo(ShopCategoriesMenuItem);
