import PropTypes from 'prop-types';
import React, { memo } from 'react';

const Image = ({ alt, ...otherProps }) => (
  <img
    onContextMenu={(event) => event.preventDefault()}
    alt={alt}
    {...otherProps}
  />
);

Image.propTypes = {
  alt: PropTypes.string.isRequired,
};

export default memo(Image);
