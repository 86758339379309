import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths, checkpoints } from 'src/constants';
import { useIntlMessages, useCheckpoint } from 'src/hooks';

import { Container } from './components';

const ArticulationGymnasticsCollections = ({
  articulationGymnasticsCollections,
  articulationGymnasticsCurrency,
  getCollections,
  hasLoaded,
  pageCount,
  student,
}) => {
  const messages = useIntlMessages();

  useCheckpoint(checkpoints.FIRST_TIME_ON_ARTICULATION_GYMNASTICS, hasLoaded);

  return (
    <Container
      collectionsPath={paths.ARTICULATION_GYMNASTICS}
      currency={articulationGymnasticsCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={articulationGymnasticsCollections}
      levelsPath={paths.ARTICULATION_GYMNASTICS_LEVELS}
      pageCount={pageCount}
      student={student}
      title={messages.activity.articulationGymnastics}
    />
  );
};

ArticulationGymnasticsCollections.propTypes = {
  articulationGymnasticsCollections: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  articulationGymnasticsCurrency: PropTypes.shape({}).isRequired,
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.articulationGymnasticsCollections.getHasLoaded(state),
  articulationGymnasticsCollections: selectors.articulationGymnasticsCollections.getCollections(
    state
  ),
  articulationGymnasticsCurrency: selectors.articulationGymnasticsCollections.getCurrency(
    state
  ),
  pageCount: selectors.articulationGymnasticsCollections.getPageCount(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.articulationGymnastics,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ArticulationGymnasticsCollections);
