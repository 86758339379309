import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Image from '../Image';
import Icon from '../Icon';

import './index.scss';

const ValueDisplay = ({
  currencyIcon,
  feedbackIcon,
  amount,
  className,
  vertical,
}) => {
  const valueContainerClassNames = useMemo(
    () => cn('value-container', className, { vertical }),
    [className, vertical]
  );

  const feedbackIconContainerClassNames = useMemo(
    () =>
      cn(
        'value-container__feedback-icon-container',
        `value-container__feedback-icon-container--${feedbackIcon}`
      ),
    [feedbackIcon]
  );

  return (
    <div className={valueContainerClassNames}>
      {currencyIcon && (
        <Image
          className="value-container__icon"
          alt="currency-icon"
          src={currencyIcon}
        />
      )}
      {feedbackIcon && (
        <div className={feedbackIconContainerClassNames}>
          <Icon name={feedbackIcon} size={50} />
        </div>
      )}
      <div className="value-container__amount">{amount}</div>
    </div>
  );
};

ValueDisplay.propTypes = {
  vertical: PropTypes.bool,
  amount: PropTypes.number,
  className: PropTypes.string,
  currencyIcon: PropTypes.string,
  feedbackIcon: PropTypes.string,
};

ValueDisplay.defaultProps = {
  vertical: false,
  amount: 0,
  className: '',
  currencyIcon: '',
  feedbackIcon: '',
};

export default memo(ValueDisplay);
