import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from 'src/store';

const depsAchieved = (dependencies, passedCheckpoints) => {
  return dependencies.every((dependency) => !!passedCheckpoints?.[dependency]);
};

const useCheckpoint = (checkpoint, achieved, dependencies = []) => {
  const dispatch = useDispatch();
  const passedCheckpoints = useSelector(selectors.user.getCheckpoints);
  const passed = passedCheckpoints?.[checkpoint];
  const dependenciesAchieved = useRef(
    depsAchieved(dependencies, passedCheckpoints)
  );

  useEffect(() => {
    if (achieved && !passed && dependenciesAchieved.current) {
      dispatch(actions.users.userCheckpoint(checkpoint));
    }
  }, [achieved, checkpoint, dispatch, passed]);

  return passed;
};

export default useCheckpoint;
