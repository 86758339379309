import { createSelector } from 'reselect';

const getIsSubmitting = createSelector(
  (state) => state.coupons.isSubmitting,
  (isSubmitting) => isSubmitting
);

const getHasLoaded = createSelector(
  (state) => state.coupons.hasLoaded,
  (hasLoaded) => hasLoaded
);

const getCouponData = createSelector(
  (state) => state.coupons.data,
  (couponData = {}) => couponData
);

export default {
  getCouponData,
  getHasLoaded,
  getIsSubmitting,
};
