import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.paintingLevels.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getCurrencyData = createSelector(
  (state) => state.paintingLevels.data.currency,
  (currency = {}) => currency
);
const getLevelsData = createSelector(
  (state) => state.paintingLevels.data.paintingLevels,
  (levels = []) => levels
);
const getGroupOrderNumber = createSelector(
  (state) => state.paintingLevels.data.paintingGroupOrderNumber,
  (groupOrderNumber = 1) => groupOrderNumber
);

export default {
  getCurrencyData,
  getGroupOrderNumber,
  getHasLoaded,
  getLevelsData,
};
