import { createSelector } from 'reselect';

const getIsLoading = createSelector(
  (state) => state.parrot.isLoading,
  (isLoading) => isLoading
);

const getHasLoaded = createSelector(
  (state) => state.parrot.hasLoaded,
  (hasLoaded) => hasLoaded
);

const getStudentSelectedParts = createSelector(
  (state) => state.parrot.studentSelectedParts,
  (studentSelectedParts = {}) => studentSelectedParts
);

const getParrotParts = createSelector(
  (state) => state.parrot.parts,
  (parts = {}) => parts
);

const getCheapParrotParts = createSelector(
  (state) => state.parrot.cheapParts,
  (cheapParts = {}) => cheapParts
);

const getPartsPageCount = createSelector(
  (state) => state.parrot.pageCount,
  (pageCount) => pageCount
);

export default {
  getIsLoading,
  getHasLoaded,
  getParrotParts,
  getPartsPageCount,
  getCheapParrotParts,
  getStudentSelectedParts,
};
