import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Badge from '../Badge';

import './index.scss';

const SpeechSoundLabel = ({ speechSound }) => {
  const className = cn({
    'speech-sound-badge-extended': speechSound.label?.length > 3,
  });

  return (
    <Badge type="success" className={className}>
      {speechSound.label}
    </Badge>
  );
};

SpeechSoundLabel.propTypes = {
  speechSound: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

export default SpeechSoundLabel;
