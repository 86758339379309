import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { useIntlMessages } from 'src/hooks';

import { Form, Input } from 'src/components/common';

import validationSchema from './validationSchema';

import './index.scss';

const CouponForm = ({ initialValues, onSubmit, disabled }) => {
  const messages = useIntlMessages();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      submitBtnLabel={messages.button.activateCoupon}
      formClassName="coupon-form"
    >
      <Input
        label={messages.label.enterCoupon}
        name="code"
        disabled={disabled}
      />
    </Form>
  );
};

CouponForm.propTypes = {
  initialValues: PropTypes.shape([]),
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

CouponForm.defaultProps = {
  initialValues: [],
  disabled: false,
};

export default memo(CouponForm);
