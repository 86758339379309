import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const MemoryStepOneCollections = ({
  getCollectionsByGroupId,
  hasLoaded,
  memoryStepOneCollections,
  memoryStepOneCurrency,
  pageCount,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="memory-collections-container--step-one"
      collectionsPath={paths.MEMORY_STEP_ONE_COLLECTIONS}
      currency={memoryStepOneCurrency}
      getCollectionsByGroupId={getCollectionsByGroupId}
      hasLoaded={hasLoaded}
      items={memoryStepOneCollections}
      levelsPath={paths.MEMORY_STEP_ONE_LEVELS}
      pageCount={pageCount}
      title={messages.game.memory}
    />
  );
};

MemoryStepOneCollections.propTypes = {
  getCollectionsByGroupId: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  memoryStepOneCollections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  memoryStepOneCurrency: PropTypes.shape({}).isRequired,
  pageCount: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.memoryStepOneCollections.getHasLoaded(state),
  memoryStepOneCurrency: selectors.memoryStepOneCollections.getCurrencyData(
    state
  ),
  memoryStepOneCollections: selectors.memoryStepOneCollections.getCollectionsData(
    state
  ),
  pageCount: selectors.memoryStepOneCollections.getPageCount(state),
});

const mapDispatchToProps = {
  ...actions.memoryStepOne,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(MemoryStepOneCollections);
