import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { compose } from 'redux';
import cn from 'classnames';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Localize } from '@kokolingo/localisation';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import _isEmpty from 'lodash/isEmpty';

import { actions, selectors } from 'src/store';
import { notifications, paths, checkpoints } from 'src/constants';
import { player, common } from 'src/utils';
import { useQuery, useIntlMessages, useCheckpoint } from 'src/hooks';
import config from 'src/config.json';

import {
  Badge,
  Container,
  PageLoader,
  Parrot,
  Navbar,
  Zoom,
  Topbar,
  Button,
  Tooltip,
  ModalShop,
  SpeechSoundBadge,
  Label,
} from 'src/components/common';

import './index.scss';
import './mapPoints.scss';

const USER_POINTS_CHECKPOINT = 20;

const pointPriorities = {
  10: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  9: [1, 2, 3, 4, 5, 6, 7, 9, 10],
  8: [1, 2, 3, 4, 6, 7, 9, 10],
  7: [1, 2, 4, 5, 7, 9, 10],
  6: [1, 3, 4, 5, 8, 10],
  5: [1, 3, 5, 8, 10],
  4: [1, 4, 8, 10],
};

const Map = ({
  activityGroupsData,
  getActivityGroupsData,
  getSpeechSoundById,
  hasLoaded,
  showNotification,
  student,
  studentSpeechSound,
  updateStudentById,
  hasActiveSubscription,
  setShowTrialEndedModal,
  user,
  subscriptionStatus,
  getStatus,
  isSpeechTherapist,
  setTooltipVisibility,
  userTotalPoints,
  subscriptions,
}) => {
  const history = useHistory();
  const query = useQuery();
  const messages = useIntlMessages();

  useCheckpoint(checkpoints.FIRST_TIME_ON_MAP, hasLoaded);
  useCheckpoint(checkpoints.SECOND_TIME_ON_MAP, hasLoaded, [
    checkpoints.FIRST_TIME_ON_MAP,
  ]);
  useCheckpoint(
    checkpoints.FIRST_TIME_OVER_20_POINTS,
    hasLoaded && userTotalPoints > USER_POINTS_CHECKPOINT,
    [checkpoints.FIRST_TIME_ON_MAP, checkpoints.SECOND_TIME_ON_MAP]
  );

  const mapClassNames = useMemo(
    () =>
      cn(`map-container`, {
        [`map-container--special`]: studentSpeechSound.isSpecial,
      }),
    [studentSpeechSound]
  );

  useEffect(() => {
    if (query.has('email-verified')) {
      showNotification(
        messages.notification.emailConfirmed,
        notifications.type.SUCCESS,
        notifications.duration.SHORT
      );

      history.push(paths.buildQuery(paths.MAP, { userId: user.id }));
    }

    if (!query.get('userId')) {
      history.replace(paths.buildQuery(paths.MAP, { userId: user.id }));
    }
  }, [history, query, showNotification, user.id, messages]);

  const handleVideoEnded = useCallback(
    (educationFlag) => {
      updateStudentById(player.getSelectedStudentId(), {
        [educationFlag]: true,
      });
    },
    [updateStudentById]
  );

  useEffect(() => {
    if (activityGroupsData.length === 0) {
      getActivityGroupsData();
    }
  }, [getActivityGroupsData, activityGroupsData]);

  useEffect(() => {
    getSpeechSoundById(student.SpeechSoundId);
  }, [getSpeechSoundById, student]);

  useEffect(() => {
    if (_isEmpty(subscriptionStatus)) {
      getStatus();
    }
  }, [getStatus, subscriptionStatus]);

  const createActivityRedirect = useCallback(
    (activityGroupKey, tooltipId) => () => {
      setTooltipVisibility(tooltipId, false);
      if (!hasActiveSubscription) {
        setShowTrialEndedModal(true);
        return;
      }
      history.push(paths.build(paths.ACTIVITY, activityGroupKey));
    },
    [
      setTooltipVisibility,
      hasActiveSubscription,
      history,
      setShowTrialEndedModal,
    ]
  );

  if (!hasLoaded) {
    return <PageLoader />;
  }

  const tooltip = ['firstIsland'];

  return (
    <PageLoader isFadingOut>
      {/* modal is here because we don't want to break playing flow */}
      <ModalShop />
      <Container className={mapClassNames}>
        <Navbar.Title
          title={
            !isSpeechTherapist
              ? student.fullName
              : student.fullNameForSpeechTherapist
          }
          icon={<SpeechSoundBadge speechSound={studentSpeechSound} />}
        />
        <Navbar.Actions>
          <Navbar.FreeTrialAction />
          {process.env.REACT_APP_DAILY_MAP === 'true' && (
            <Link to={paths.DAILY_MAP}>
              <Badge iconName="map" />
            </Link>
          )}
          <Navbar.EducationAction
            videos={config.videos.map}
            handleVideoEnded={handleVideoEnded}
          />
        </Navbar.Actions>
        {!subscriptionStatus.isSubscription &&
          !isSpeechTherapist &&
          subscriptions.length > 0 && (
            <Topbar>
              <Button
                collapsed
                variant="register"
                className="map-container__action"
                onClick={() => history.push(paths.SUBSCRIPTIONS)}
              >
                <b>
                  <Localize id="page.subscriptionSelection.promotion" />
                </b>
              </Button>
            </Topbar>
          )}
        <Zoom mobileWidth={360} mobileHeight={640} align="center">
          <Container className="map-container__filled">
            <>
              <Tooltip id="firstIsland">
                <Localize id="tooltip.firstIsland" />
              </Tooltip>
              {activityGroupsData.map((activityGroupData, index) => {
                const positions = pointPriorities[activityGroupsData.length];

                return (
                  <>
                    <Badge
                      id={`point-${positions[index]}`}
                      className={`point point-${positions[index]}`}
                      size="regular"
                      type={common.getActivityIconBackground(
                        activityGroupData.key
                      )}
                      key={activityGroupData.key}
                      onClick={createActivityRedirect(
                        `${activityGroupData.key}-${activityGroupData.id}`,
                        tooltip[index]
                      )}
                      tooltip={tooltip[index]}
                    >
                      {(index + 1).toString()}
                    </Badge>
                    {activityGroupData.label && (
                      <Label
                        className={`point point-${positions[index]} point-label`}
                        htmlFor={`point-${positions[index]}`}
                        label={activityGroupData.label}
                      />
                    )}
                  </>
                );
              })}
            </>

            <Link to={paths.SHOP}>
              <Parrot
                className="map-container__parrot"
                height={isMobile ? '100px' : '300px'}
              />
            </Link>
          </Container>
        </Zoom>
      </Container>
    </PageLoader>
  );
};

Map.propTypes = {
  getActivityGroupsData: PropTypes.func.isRequired,
  getSpeechSoundById: PropTypes.func.isRequired,
  updateStudentById: PropTypes.func.isRequired,
  activityGroupsData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
    })
  ),
  hasLoaded: PropTypes.bool.isRequired,
  hasActiveSubscription: PropTypes.bool.isRequired,
  showNotification: PropTypes.func.isRequired,
  setShowTrialEndedModal: PropTypes.func.isRequired,
  student: PropTypes.shape({
    SpeechSoundId: PropTypes.number,
    fullName: PropTypes.string,
    fullNameForSpeechTherapist: PropTypes.string,
    hasPassedActivityGroupEducation: PropTypes.bool,
    hasPassedMapIconsEducation: PropTypes.bool,
    hasPassedWelcomeEducation: PropTypes.bool,
  }).isRequired,
  studentSpeechSound: PropTypes.shape({
    label: PropTypes.string,
    isSpecial: PropTypes.bool,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    totalPoints: PropTypes.number,
    meta: PropTypes.object,
  }).isRequired,
  subscriptions: PropTypes.arrayOf({}).isRequired,
  subscriptionStatus: PropTypes.shape({
    isTrial: PropTypes.bool,
    isSubscription: PropTypes.bool,
    daysLeft: PropTypes.number,
    pointsLeft: PropTypes.number,
    subscriptionExpirationDate: PropTypes.string,
  }).isRequired,
  getStatus: PropTypes.func.isRequired,
  isSpeechTherapist: PropTypes.bool.isRequired,
  setTooltipVisibility: PropTypes.func.isRequired,
  userTotalPoints: PropTypes.number.isRequired,
};

Map.defaultProps = {
  activityGroupsData: [],
};

const mapStateToProps = (state) => ({
  activityGroupsData: selectors.activityGroups.getActivityGroupsData(state),
  hasLoaded: selectors.activityGroups.getHasLoaded(state),
  hasReceivedTrialExpirationNotification: selectors.authentication.getHasReceivedTrialExpirationNotification(
    state
  ),
  hasReceivedUpdateNotification: selectors.authentication.getHasReceivedUpdateNotification(
    state
  ),
  isSpeechTherapist: selectors.authentication.getIsSpeechTherapist(state),
  student: selectors.authentication.getStudent(state),
  studentSpeechSound: selectors.speechSounds.getSpeechSound(state),
  hasActiveSubscription: selectors.subscriptionStatus.getHasActiveSubscription(
    state
  ),
  user: selectors.authentication.getUser(state),
  subscriptionStatus: selectors.subscriptionStatus.getSubscriptionStatus(state),
  subscriptions: selectors.subscriptions.getSubscriptions(state),
  userTotalPoints: selectors.user.getTotalPoints(state),
});

const mapDispatchToProps = {
  ...actions.activityGroups,
  ...actions.authentication,
  ...actions.notifications,
  ...actions.speechSounds,
  ...actions.students,
  ...actions.subscriptions,
  ...actions.tooltips,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), memo)(Map);
