import React, { memo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { gameKeys, paths } from 'src/constants';
import Container from 'src/components/pages/Activities/ReadTogether/Levels/Container';

const TIMEOUT_DURATION = 500;

const ReadTogetherLevels = ({
  getLevelsByCollectionId,
  getSpeechSoundById,
  hasLoaded,
  collectionData,
  currency,
  levels,
  student,
  studentSpeechSound,
  updateFeedback,
}) => {
  const history = useHistory();
  const gameKey = gameKeys.READ_TOGETHER;
  const { groupKey, collectionId } = useParams();

  const handleLevelAnswer = (levelId, isCorrect, currencyId) => {
    updateFeedback({ currencyId, gameKey, levelId, isCorrect });
  };

  const handleCollectionEnd = () => {
    setTimeout(() => {
      history.push(
        paths.build(paths.READ_TOGETHER_BONUS_LEVELS, groupKey, collectionId)
      );
    }, TIMEOUT_DURATION);
  };

  useEffect(() => {
    getLevelsByCollectionId(collectionId);
  }, [getLevelsByCollectionId, collectionId]);

  useEffect(() => {
    getSpeechSoundById(student.SpeechSoundId);
  }, [getSpeechSoundById, student]);

  return (
    <Container
      collection={collectionData}
      collectionId={collectionId}
      currency={currency}
      handleLevelAnswer={handleLevelAnswer}
      handleCollectionEnd={handleCollectionEnd}
      hasLoaded={hasLoaded}
      items={levels}
      studentSpeechSound={studentSpeechSound}
    />
  );
};

ReadTogetherLevels.propTypes = {
  getLevelsByCollectionId: PropTypes.func.isRequired,
  getSpeechSoundById: PropTypes.func.isRequired,
  updateFeedback: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  collectionData: PropTypes.shape({}).isRequired,
  currency: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    imageUrl: PropTypes.string,
    placeholderImageUrl: PropTypes.string,
  }).isRequired,
  levels: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number.isRequired })
  ).isRequired,
  student: PropTypes.shape({
    SpeechSoundId: PropTypes.number.isRequired,
  }).isRequired,
  studentSpeechSound: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.readTogetherLevels.getHasLoaded(state),
  collectionData: selectors.readTogetherLevels.getCollectionData(state),
  currency: selectors.readTogetherLevels.getCurrencyData(state),
  levels: selectors.readTogetherLevels.getLevelsData(state),
  student: selectors.authentication.getStudent(state),
  studentSpeechSound: selectors.speechSounds.getSpeechSound(state),
});

const mapDispatchToProps = {
  ...actions.feedback,
  ...actions.readTogether,
  ...actions.speechSounds,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ReadTogetherLevels);
