import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Badge from '../Badge';

import './index.scss';

const Stepper = ({ activeStep, setActiveStep, steps, visitedSteps }) => {
  return (
    <div className="stepper">
      {steps.map((step, index) => {
        const active = activeStep === step;
        const visited = visitedSteps.has(step);
        let badgeType = visited ? 'success' : 'neutral';

        if (active) {
          badgeType = 'primary';
        }

        return (
          <div
            key={index}
            className={cn('stepper__step', {
              active,
              visited,
            })}
            onClick={() => setActiveStep(step)}
            onKeyPress={() => setActiveStep(step)}
            role="button"
            tabIndex={0}
          >
            {steps.length > 1 && <Badge type={badgeType} size="small" />}
            <p>{step.label}</p>
          </div>
        );
      })}
    </div>
  );
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      Component: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
    })
  ).isRequired,
  activeStep: PropTypes.shape({}).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  visitedSteps: PropTypes.shape({}).isRequired,
};

export default Stepper;
