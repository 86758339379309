import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import { Localize } from '@kokolingo/localisation';

import { paths, payment } from 'src/constants';
import { subscriptions as subscriptionsUtil } from 'src/utils';
import { actions, selectors } from 'src/store';
import { useQuery } from 'src/hooks';

import Modal from '../Modal/Modal';
import Button from '../Button';
import Parrot from '../Parrot';

function getTitle(user, subscriptionStatus) {
  if (subscriptionsUtil.getIsPaymentInProgress(subscriptionStatus)) {
    return <Localize id="dialog.paymentInProgress" />;
  }
  if (user.SpeechTherapistId) {
    return <Localize id="dialog.trialExpired" />;
  }
  if (user.firstPaymentDate) {
    return <Localize id="dialog.subscriptionExpired" />;
  }
  if (user.freeTrialExpirationDate) {
    return <Localize id="dialog.trialExpired" />;
  }
  return <Localize id="dialog.trialPointsUsed" />;
}

function getDescription(user, subscriptionStatus) {
  if (subscriptionsUtil.getIsPaymentInProgress(subscriptionStatus)) {
    return <Localize id="dialog.paymentInProgressDescription" />;
  }
  if (user.SpeechTherapistId) {
    return <Localize id="dialog.therapistTrialExpiredDescription" />;
  }
  if (user.firstPaymentDate) {
    return <Localize id="dialog.renewSubscriptionDescription" />;
  }
  if (user.freeTrialExpirationDate) {
    return <Localize id="dialog.freeTrialExpiredDescription" />;
  }
  return <Localize id="dialog.trialPointsUsedDescription" />;
}

const ModalExpiredSubscription = ({
  subscriptionStatus,
  showTrialEndedModal,
  setShowTrialEndedModal,
  user,
  student,
}) => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const close = useCallback(() => {
    setShowTrialEndedModal(false);
  }, [setShowTrialEndedModal]);

  useEffect(() => {
    if (!query.get('promoModal') && showTrialEndedModal) {
      window.location.hash = 'trialEndedModal';
    }
  }, [location, showTrialEndedModal, query]);

  if (!user || !student) {
    // Works only when user is logged in
    // Student is need to get parrots parts
    return null;
  }

  return (
    <Modal show={showTrialEndedModal} onClose={close} onOutsideClick={close}>
      <Modal.Title>{getTitle(user, subscriptionStatus)}</Modal.Title>
      <Parrot centered height={isMobile ? '200px' : '400px'} />
      <br />
      <Modal.Description>
        {getDescription(user, subscriptionStatus)}
      </Modal.Description>
      <br />
      <Button
        variant="register"
        collapsed
        onClick={() => {
          close();
          history.push(paths.SUBSCRIPTIONS);
        }}
      >
        {user.SpeechTherapistId && <Localize id="label.dashboard" />}
        {!user.SpeechTherapistId &&
          (subscriptionsUtil.getIsPaymentInProgress(subscriptionStatus) ? (
            <Localize id="button.finalizePayment" />
          ) : (
            <Localize id="button.payment" />
          ))}
      </Button>
    </Modal>
  );
};

ModalExpiredSubscription.propTypes = {
  subscriptionStatus: PropTypes.shape({
    lastTransactionStatus: PropTypes.oneOf(
      Object.values(payment.PAYMENT_STATUS)
    ),
  }).isRequired,
  showTrialEndedModal: PropTypes.bool.isRequired,
  setShowTrialEndedModal: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstPaymentDate: PropTypes.string,
    freeTrialExpirationDate: PropTypes.string,
    SpeechTherapistId: PropTypes.bool,
  }),
  student: PropTypes.shape({}).isRequired,
};

ModalExpiredSubscription.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  subscriptionStatus: selectors.subscriptionStatus.getSubscriptionStatus(state),
  user: selectors.authentication.getUser(state),
  student: selectors.authentication.getStudent(state),
  showTrialEndedModal: selectors.subscriptionStatus.getShowTrialEndedModal(
    state
  ),
});
const mapDispatchToProps = {
  ...actions.subscriptions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalExpiredSubscription);
