import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.activities.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getActivitiesData = createSelector(
  (state) => state.activities.data,
  (activitiesData) => activitiesData
);

export default {
  getHasLoaded,
  getActivitiesData,
};
