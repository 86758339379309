import PropTypes from 'prop-types';
import React, { memo } from 'react';

import AnswerImage from './AnswerImage';

const AnswersSection = ({ items }) => {
  return items.map((answer, index) => (
    <AnswerImage key={index} answer={answer} />
  ));
};

AnswersSection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

AnswersSection.defaultProps = {
  items: [],
};

export default memo(AnswersSection);
