import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';

import { Badge } from 'src/components/common';

import './index.scss';

const SpeechSound = ({
  speechSound,
  isLeft,
  shake,
  onClick,
  label,
  mark,
  type,
}) => {
  const speechSoundClassNames = useMemo(
    () =>
      cn('speech-sound-card', {
        'speech-sound-card--left': isLeft,
        'speech-sound-card--right': !isLeft,
        'speech-sound-card--shake': shake,
      }),
    [isLeft, shake]
  );

  return (
    <div className={speechSoundClassNames}>
      {label && <p className="speech-sound-card__message">{label}</p>}
      <Badge
        onClick={() => onClick(speechSound, isLeft)}
        size={isMobile ? 'extra-large' : 'large'}
        type={type || (isLeft ? 'orange' : 'success')}
      >
        {mark}
      </Badge>
    </div>
  );
};

SpeechSound.propTypes = {
  speechSound: PropTypes.string.isRequired,
  isLeft: PropTypes.bool,
  shake: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.node,
  mark: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    'danger',
    'neutral',
    'primary',
    'secondary',
    'success',
    'purple',
    'blue',
    'gold',
    'orange',
    'green',
    'red',
  ]),
};

SpeechSound.defaultProps = {
  isLeft: false,
  shake: false,
  label: null,
  type: null,
};

export default memo(SpeechSound);
