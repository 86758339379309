import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { common } from 'src/utils';
import { useIntlMessages } from 'src/hooks';

import { Container, OverlayLoader } from 'src/components/common';

import { BarChartContainer, PieChartContainer } from '../components';

import './index.scss';

const KOKOLINGO_DATE_OF_CREATION = '09-01-2020';

const MonthlyStatistics = ({
  feedbackStatisticsData,
  getFeedbackStatistics,
  hasLoaded,
}) => {
  const messages = useIntlMessages();

  useEffect(() => {
    getFeedbackStatistics(common.getDaysFromNow(KOKOLINGO_DATE_OF_CREATION));
  }, [getFeedbackStatistics]);

  if (!hasLoaded) {
    return <OverlayLoader />;
  }

  return (
    <Container className="monthly-statistics-container">
      <BarChartContainer
        className="monthly-statistics-container__activity-line"
        dataType="gamesPlayed"
        description={messages.page.statistics.allTimeProgress}
        hideLegend={false}
        statisticsData={feedbackStatisticsData}
        aspect={810 / 400}
        xAxisData={common.getLastNDates(feedbackStatisticsData.daysOfUsage)}
        xTickFormatter={common.shortenDate}
      />
      <PieChartContainer
        className="monthly-statistics-container__days-pie"
        dataCategoryType="gamesPlayed"
        description={messages.page.statistics.allTimeActivityShare}
        hideLegend
        statisticsData={feedbackStatisticsData}
        unit="%"
        aspect={320 / 300}
      />
      <PieChartContainer
        className="monthly-statistics-container__island-pie"
        dataCategory="islands"
        description={messages.page.statistics.allTimeActivitySharePerIsland}
        hideLegend
        statisticsData={feedbackStatisticsData}
        unit="%"
        aspect={320 / 300}
      />
    </Container>
  );
};

MonthlyStatistics.propTypes = {
  getFeedbackStatistics: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  feedbackStatisticsData: PropTypes.shape({
    allTimeEarnedKokolingoPoints: PropTypes.number,
    daysOfUsage: PropTypes.number,
    todayEarnedKokolingoPoints: PropTypes.number,
    todayCorrectAnswers: PropTypes.number,
    todayWrongAnswers: PropTypes.number,
  }),
};

MonthlyStatistics.defaultProps = {
  feedbackStatisticsData: PropTypes.shape({
    allTimeEarnedKokolingoPoints: 0,
    daysOfUsage: 1,
    todayEarnedKokolingoPoints: 0,
    todayCorrectAnswers: 0,
    todayWrongAnswers: 0,
  }),
};

const mapStateToProps = (state) => ({
  feedbackStatisticsData: selectors.feedback.getFeedbackData(state),
  hasLoaded: selectors.feedback.getHasLoaded(state),
});

const mapDispatchToProps = {
  ...actions.feedback,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(MonthlyStatistics);
