import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';

import ice from 'src/images/memory/ice.png';

import { Container } from './components';

const MemoryStepThreeGroups = ({
  getGroups,
  hasLoaded,
  memoryStepThreeCurrency,
  memoryStepThreeGroups,
  student,
}) => {
  return (
    <Container
      cardBackgroundImage={ice}
      cardClassName="memory-level-card-container--step-three"
      className="memory-groups-container--step-three"
      collectionsPath={paths.MEMORY_STEP_THREE_COLLECTIONS}
      currency={memoryStepThreeCurrency}
      getGroups={getGroups}
      hasLoaded={hasLoaded}
      items={memoryStepThreeGroups}
      student={student}
    />
  );
};

MemoryStepThreeGroups.propTypes = {
  getGroups: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  memoryStepThreeCurrency: PropTypes.shape({}).isRequired,
  memoryStepThreeGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.memoryStepThreeGroups.getHasLoaded(state),
  memoryStepThreeCurrency: selectors.memoryStepThreeGroups.getCurrencyData(
    state
  ),
  memoryStepThreeGroups: selectors.memoryStepThreeGroups.getGroupsData(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.memoryStepThree,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(MemoryStepThreeGroups);
