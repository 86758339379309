import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.readAndThinkLevels.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getCollectionData = createSelector(
  (state) => state.readAndThinkLevels.data.readAndThinkCollectionData,
  (collection = {}) => collection
);
const getCurrencyData = createSelector(
  (state) => state.readAndThinkLevels.data.currency,
  (currency = {}) => currency
);
const getLevelsData = createSelector(
  (state) => state.readAndThinkLevels.data.readAndThinkLevels,
  (levels = []) => levels
);

export default {
  getCollectionData,
  getCurrencyData,
  getHasLoaded,
  getLevelsData,
};
