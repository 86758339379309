import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths, checkpoints } from 'src/constants';
import { useIntlMessages, useCheckpoint } from 'src/hooks';

import { Container } from './components';

const ReadAndThinkCollections = ({
  getCollections,
  getSpeechSoundById,
  hasLoaded,
  pageCount,
  readAndThinkCollections,
  readAndThinkCurrency,
  student,
  studentSpeechSound,
}) => {
  const messages = useIntlMessages();

  useCheckpoint(checkpoints.FIRST_TIME_ON_READ_AND_THINK, hasLoaded);

  return (
    <Container
      collectionsPath={paths.READ_AND_THINK}
      currency={readAndThinkCurrency}
      getCollections={getCollections}
      getSpeechSoundById={getSpeechSoundById}
      hasLoaded={hasLoaded}
      items={readAndThinkCollections}
      levelsPath={paths.READ_AND_THINK_LEVELS}
      pageCount={pageCount}
      student={student}
      studentSpeechSound={studentSpeechSound}
      title={messages.game.readAndThink}
    />
  );
};

ReadAndThinkCollections.propTypes = {
  getCollections: PropTypes.func.isRequired,
  getSpeechSoundById: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  readAndThinkCollections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  readAndThinkCurrency: PropTypes.shape({}).isRequired,
  student: PropTypes.shape({}).isRequired,
  studentSpeechSound: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.readAndThinkCollections.getHasLoaded(state),
  readAndThinkCurrency: selectors.readAndThinkCollections.getCurrencyData(
    state
  ),
  readAndThinkCollections: selectors.readAndThinkCollections.getCollectionsData(
    state
  ),
  pageCount: selectors.readAndThinkCollections.getPageCount(state),
  student: selectors.authentication.getStudent(state),
  studentSpeechSound: selectors.speechSounds.getSpeechSound(state),
});

const mapDispatchToProps = {
  ...actions.readAndThink,
  ...actions.speechSounds,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ReadAndThinkCollections);
