import React, { memo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { gameKeys, paths } from 'src/constants';
import Container from 'src/components/pages/Activities/Climb/Levels/Container';

const TIMEOUT_DURATION = 500;

const ClimbStepOneExtraLevels = ({
  currency,
  levels,
  getLevelsByCollectionId,
  hasLoaded,
  unlockCollection,
  updateFeedback,
}) => {
  const history = useHistory();
  const gameKey = gameKeys.CLIMB_STEP_ONE_EXTRA;
  const { groupKey, activityId, collectionId } = useParams();

  const handleLevelAnswer = (levelId, isCorrect, currencyId) => {
    updateFeedback({ currencyId, gameKey, levelId, isCorrect });
  };

  const handleCollectionEnd = () => {
    unlockCollection({ gameKey, collectionId });
    setTimeout(() => {
      history.push(
        paths.build(paths.CLIMB_STEP_ONE_EXTRA, groupKey, activityId)
      );
    }, TIMEOUT_DURATION);
  };

  useEffect(() => {
    getLevelsByCollectionId(collectionId);
  }, [getLevelsByCollectionId, collectionId]);

  return (
    <Container
      awardClassName="climb-level-scene-award--step-one-extra"
      className="climb-levels-container--step-one-extra"
      subactivityName="climbStepOneExtra"
      currency={currency}
      handleCollectionEnd={handleCollectionEnd}
      handleLevelAnswer={handleLevelAnswer}
      hasLoaded={hasLoaded}
      items={levels}
    />
  );
};

ClimbStepOneExtraLevels.propTypes = {
  currency: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getLevelsByCollectionId: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  unlockCollection: PropTypes.func.isRequired,
  updateFeedback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.climbStepOneExtraLevels.getHasLoaded(state),
  currency: selectors.climbStepOneExtraLevels.getCurrencyData(state),
  levels: selectors.climbStepOneExtraLevels.getLevelsData(state),
});

const mapDispatchToProps = {
  ...actions.climbStepOneExtra,
  ...actions.feedback,
  ...actions.students,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ClimbStepOneExtraLevels);
