import PropTypes from 'prop-types';
import React, { memo } from 'react';

import './index.scss';

const SettingsMenu = ({ children, className, innerRef }) => {
  return (
    <div className={`settings-menu-container ${className}`} ref={innerRef}>
      {children}
    </div>
  );
};

SettingsMenu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  innerRef: PropTypes.shape({}),
};

SettingsMenu.defaultProps = {
  children: undefined,
  className: undefined,
  innerRef: undefined,
};

export default memo(SettingsMenu);
