import React from 'react';
import { Localize } from '@kokolingo/localisation';
import { boolean, object, string } from 'yup';

export default object().shape({
  'user-role-select': string()
    .oneOf(['parent', 'therapist'], () => <Localize id="error.selectRole" />)
    .required(() => <Localize id="error.selectRole" />),
  gdpr: boolean()
    .oneOf([true], () => <Localize id="error.fieldRequired" />)
    .required(() => <Localize id="error.fieldRequired" />),
});
