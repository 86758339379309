import { useEffect, useState } from 'react';

// https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx
const useScript = (attributes) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');

    Object.keys(attributes).forEach((key) => {
      script.setAttribute(key, attributes[key]);
    });

    script.async = true;
    script.addEventListener('load', () => setScriptLoaded(true));

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [attributes]);

  return { scriptLoaded };
};

export default useScript;
