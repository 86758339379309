import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { SafeRouteValidatorProvider } from 'src/context';
import { paths } from 'src/constants';
import { selectors } from 'src/store';

const SpeechTherapistValidator = ({ children, isSpeechTherapist }) => {
  const redirect = isSpeechTherapist && paths.SPEECH_THERAPIST;

  return (
    <SafeRouteValidatorProvider value={redirect}>
      {children}
    </SafeRouteValidatorProvider>
  );
};

SpeechTherapistValidator.propTypes = {
  children: PropTypes.node.isRequired,
  isSpeechTherapist: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isSpeechTherapist: selectors.authentication.getIsSpeechTherapist(state),
});

export default compose(
  connect(mapStateToProps),
  withRouter
)(SpeechTherapistValidator);
