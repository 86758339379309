export default {
  GOLD: 'activity.gold',
  ORANGE: 'activity.orange',
  ORANGE_SPECIAL: 'activity.orangeSpecial',
  GREEN: 'activity.green',
  GREEN_SPECIAL: 'activity.greenSpecial',
  PURPLE: 'activity.purple',
  PURPLE_SPECIAL: 'activity.purpleSpecial',
  RED: 'activity.red',
  BLUE: 'activity.blue',
};
