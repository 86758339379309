const hasEnoughFunds = (selectedItem, walletData) => {
  return selectedItem.ParrotPartCostsCurrencies.every((partCost) => {
    const walletCurrencyItem = walletData.find(
      (walletItem) => walletItem.currency.key === partCost.currency.key
    );

    return walletCurrencyItem?.amount >= partCost.amount;
  });
};

export default {
  hasEnoughFunds,
};
