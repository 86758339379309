import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Localize from 'src/components/common/Localize';
import Page from 'src/components/common/Page';
import SpeechSound from '../SpeechSound';

import '../../index.scss';

const FeedbackSounds = ({
  onClick,
  label,
  selectedTerm,
  shakeLeft,
  shakeRight,
}) => (
  <Page.Body className="feedback-panel-container" size="md">
    <div className="feedback-panel-container__label">{label}</div>
    <div className="feedback-panel-container__feedback">
      <SpeechSound
        isLeft
        onClick={onClick}
        shake={shakeLeft}
        speechSound={selectedTerm.leftSpeechSound}
        mark={selectedTerm.leftSpeechSound}
        label={
          <Localize
            id="label.sound"
            values={{ sound: selectedTerm.leftSpeechSound }}
          />
        }
      />
      <SpeechSound
        onClick={onClick}
        shake={shakeRight}
        speechSound={selectedTerm.rightSpeechSound}
        mark={selectedTerm.rightSpeechSound}
        label={
          <Localize
            id="label.sound"
            values={{ sound: selectedTerm.rightSpeechSound }}
          />
        }
      />
    </div>
  </Page.Body>
);

FeedbackSounds.propTypes = {
  label: PropTypes.element,
  onClick: PropTypes.func.isRequired,
  selectedTerm: PropTypes.shape({
    leftSpeechSound: PropTypes.string.isRequired,
    rightSpeechSound: PropTypes.string.isRequired,
    correctSpeechSound: PropTypes.string.isRequired,
  }).isRequired,
  shakeLeft: PropTypes.bool.isRequired,
  shakeRight: PropTypes.bool.isRequired,
};

FeedbackSounds.defaultProps = {
  label: <Localize id="label.whatDidWeHear" />,
};

export default memo(FeedbackSounds);
