import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { selectors } from 'src/store';

// Used to render component only if the user is a parent!
const ParentContainer = ({
  children,
  isSpeechTherapist,
  componentChildren,
  isAuthenticated,
}) => (isSpeechTherapist || !isAuthenticated ? componentChildren : children);

ParentContainer.propTypes = {
  isSpeechTherapist: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  componentChildren: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  isSpeechTherapist: selectors.authentication.getIsSpeechTherapist(state),
  isAuthenticated: selectors.authentication.getIsAuthenticated(state),
});

const ConnectedParentContainer = compose(
  connect(mapStateToProps),
  memo
)(ParentContainer);

export default (WrappedComponent) => (props) => (
  <ConnectedParentContainer componentChildren={props.children}>
    <WrappedComponent {...props} />
  </ConnectedParentContainer>
);
