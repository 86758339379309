import { actions, paths } from 'src/constants';

export default {
  getHelp: () => ({
    [actions.API_CALL]: {
      types: [
        actions.HELP_GET_REQUEST,
        actions.HELP_GET_SUCCESS,
        actions.HELP_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.api.HELP),
    },
  }),
};
