import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { video } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { MenuFrame, PageLoader, Navbar } from 'src/components/common';

import GroupsSection from './GroupsSection';

import './index.scss';

const Container = ({
  cardBackgroundImage,
  className,
  collectionsPath,
  currency,
  getGroups,
  hasLoaded,
  items,
}) => {
  const messages = useIntlMessages();

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      <Navbar.GameNavbar
        currency={currency}
        title={messages.game.memory}
        videoName={video.NAME.MEMORY}
      />
      <div className={cn('memory-groups-container', className)}>
        <MenuFrame
          title={messages.game.memory}
          currencyImageUrl={currency.imageUrl}
        >
          <div className="memory-groups-container__groups-container">
            <GroupsSection
              cardBackgroundImage={cardBackgroundImage}
              collectionsPath={collectionsPath}
              items={items}
            />
          </div>
        </MenuFrame>
      </div>
    </PageLoader>
  );
};

Container.propTypes = {
  cardBackgroundImage: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  collectionsPath: PropTypes.string.isRequired,
  getGroups: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  currency: PropTypes.shape({
    imageUrl: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  student: PropTypes.shape({
    hasPassedMemoryEducation: PropTypes.bool.isRequired,
  }).isRequired,
};

export default memo(Container);
