import React, { memo, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';

import { gameKeys, paths } from 'src/constants';
import { selectors, actions } from 'src/store';
import Container from 'src/components/pages/Activities/Painting/Levels/Container';

const TIMEOUT_DURATION = 500;

const PaintingLevels = ({
  getLevelsByCollectionId,
  hasLoaded,
  currency,
  paintingGroupOrderNumber,
  levels,
  student,
  unlockCollection,
  updateFeedback,
}) => {
  const history = useHistory();
  const gameKey = gameKeys.PAINTING;
  const { groupKey, activityId, groupId, collectionId } = useParams();

  const handleLevelAnswer = (levelId, isCorrect, currencyId) => {
    updateFeedback({ currencyId, gameKey, levelId, isCorrect });
  };

  const handleCollectionEnd = () => {
    unlockCollection({ gameKey, collectionId });
    setTimeout(() => {
      history.push(
        paths.build(paths.PAINTING_COLLECTIONS, groupKey, activityId, groupId)
      );
    }, TIMEOUT_DURATION);
  };

  useEffect(() => {
    getLevelsByCollectionId(collectionId);
  }, [collectionId, getLevelsByCollectionId]);

  return (
    <Container
      groupOrderNumber={paintingGroupOrderNumber}
      hasLoaded={hasLoaded}
      items={levels}
      currency={currency}
      student={student}
      handleLevelAnswer={handleLevelAnswer}
      handleCollectionEnd={handleCollectionEnd}
      navigation={<div />}
    />
  );
};

PaintingLevels.propTypes = {
  getLevelsByCollectionId: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  currency: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  paintingGroupOrderNumber: PropTypes.number.isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  student: PropTypes.shape({}).isRequired,
  unlockCollection: PropTypes.func.isRequired,
  updateFeedback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.paintingLevels.getHasLoaded(state),
  currency: selectors.paintingLevels.getCurrencyData(state),
  paintingGroupOrderNumber: selectors.paintingLevels.getGroupOrderNumber(state),
  levels: selectors.paintingLevels.getLevelsData(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.painting,
  ...actions.feedback,
  ...actions.students,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(PaintingLevels);
