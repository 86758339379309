import ActivitiesValidator from './ActivitiesValidator';
import AuthValidator from './AuthValidator';
import UnauthorizedValidator from './UnauthorizedValidator';
import BaseValidator from './BaseValidator';
import SubscriptionValidator from './SubscriptionValidator';
import SpeechTherapistValidator from './SpeechTherapistValidator';

export default {
  ActivitiesValidator,
  AuthValidator,
  UnauthorizedValidator,
  BaseValidator,
  SubscriptionValidator,
  SpeechTherapistValidator,
};
