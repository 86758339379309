import ArticulationGymnastics from './ArticulationGymnastics';
import Climb from './Climb';
import Discover from './Discover';
import FindWords from './FindWords';
import FlashCards from './FlashCards';
import Memory from './Memory';
import Painting from './Painting';
import ReadAndThink from './ReadAndThink';
import ReadTogether from './ReadTogether';
import Replenishment from './Replenishment';
import SelectActivity from './SelectActivity';
import SelectSubactivity from './SelectSubactivity';
import TreasureHunt from './TreasureHunt';
import WheelOfFortune from './WheelOfFortune';

export default {
  ArticulationGymnastics,
  Climb,
  Discover,
  FindWords,
  FlashCards,
  Memory,
  Painting,
  ReadAndThink,
  ReadTogether,
  Replenishment,
  SelectActivity,
  SelectSubactivity,
  TreasureHunt,
  WheelOfFortune,
};
