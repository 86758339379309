import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import classNames from 'classnames';
import _get from 'lodash/get';
import { useFormContext } from 'react-hook-form';

import Label from '../Label';

import './index.scss';

const Textarea = ({
  className,
  defaultValue,
  disabled,
  id,
  label,
  labelClassName,
  maxLength,
  name,
  onChange,
  placeholder,
  required,
  rows,
}) => {
  const { errors, register } = useFormContext();
  const error = _get(errors, `${name}.message`);

  const textareaClassNames = useMemo(
    () =>
      classNames(
        'textarea-container__textarea',
        {
          'textarea-container__textarea--error': error,
          'textarea-container__textarea--disabled': disabled,
        },
        className
      ),
    [className, error, disabled]
  );

  const errorMessageClassName = useMemo(
    () =>
      classNames('error-message', {
        'error-message--with-label': label,
      }),
    [label]
  );

  return (
    <>
      <div className="textarea-container">
        {label && (
          <Label
            className={labelClassName}
            htmlFor={id || name}
            label={label}
            required={required}
          />
        )}
        <textarea
          id={id || name}
          name={name}
          className={textareaClassNames}
          placeholder={placeholder}
          rows={rows}
          disabled={disabled}
          defaultValue={defaultValue}
          ref={register}
          onChange={onChange}
          maxLength={maxLength}
        />
      </div>
      <div className={errorMessageClassName}>{error && error}</div>
    </>
  );
};

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
};

Textarea.defaultProps = {
  className: '',
  defaultValue: '',
  disabled: false,
  id: null,
  label: '',
  labelClassName: '',
  maxLength: 10000,
  onChange: null,
  placeholder: '',
  required: false,
  rows: 5,
};

export default memo(Textarea);
