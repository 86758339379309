import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.discoverStepOneCollections.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getCurrencyData = createSelector(
  (state) => state.discoverStepOneCollections.data.currency,
  (currency = {}) => currency
);
const getCollectionsData = createSelector(
  (state) => state.discoverStepOneCollections.data.discoverStepOneCollections,
  (collections = []) => collections
);
const getPageCount = createSelector(
  (state) => state.discoverStepOneCollections.data.pageCount,
  (pageCount) => pageCount || 0
);

export default {
  getCollectionsData,
  getCurrencyData,
  getHasLoaded,
  getPageCount,
};
