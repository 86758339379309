import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import TagManager from 'react-gtm-module';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';

import { actions, selectors } from 'src/store';
import { paths, regex } from 'src/constants';
import { tagManager } from 'src/utils';
import { useQuery } from 'src/hooks';

import {
  LogoTitle,
  Page,
  RegistrationProgressDialog,
} from 'src/components/common';

import { FormContainer } from './components';

import './index.scss';

const SpeechTherapistRegistration = ({
  isLoading,
  registerSpeechTherapist,
}) => {
  const history = useHistory();
  const query = useQuery();

  const token = query.get('token');
  const email = window.location.search.match(regex.extractEmail);

  const handleSubmit = useCallback(
    (data) => {
      TagManager.dataLayer({
        dataLayer: tagManager.getRegistrationArgs('Terapeut'),
      });
      registerSpeechTherapist(data, token);
    },
    [registerSpeechTherapist, token]
  );

  const handleContinue = useCallback(() => history.push(paths.BASE), [history]);

  const content = (
    <Page className="therapist-registration-page" noNavigation>
      <RegistrationProgressDialog
        handleContinue={handleContinue}
        isLoading={isLoading}
      />
      <Page.Section>
        {!isMobile && (
          <Page.Body color="transparent" noPadding>
            <LogoTitle />
          </Page.Body>
        )}
        <Page.Body size="sm" color="light">
          <FormContainer onSubmit={handleSubmit} defaultValues={{ email }} />
        </Page.Body>
      </Page.Section>
    </Page>
  );

  return content;
};

SpeechTherapistRegistration.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  registerSpeechTherapist: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  ...actions.authentication,
};

const mapStateToProps = (state) => ({
  isLoading: selectors.authentication.getIsLoading(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(SpeechTherapistRegistration);
