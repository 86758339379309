import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.memoryStepOneExtraGroups.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getGroupsData = createSelector(
  (state) => state.memoryStepOneExtraGroups.data.memoryStepOneExtraGroups,
  (groups = []) => groups
);
const getCurrencyData = createSelector(
  (state) => state.memoryStepOneExtraGroups.data.currency,
  (currency = {}) => currency
);

export default {
  getCurrencyData,
  getGroupsData,
  getHasLoaded,
};
