import Section from './Section';
import Body from './Body';
import Header from './Header';
import Footer from './Footer';
import Page from './Page';

Page.Body = Body;
Page.Header = Header;
Page.Section = Section;
Page.Footer = Footer;

export default Page;
