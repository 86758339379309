export default {
  API_CALL: 'API_CALL',

  LOCATION_CHANGED: '@@router/LOCATION_CHANGE',

  SET_SHOW_TRIAL_ENDED_MODAL: 'SET_SHOW_TRIAL_ENDED_MODAL',
  SET_SHOW_PROMO_MODAL: 'SET_SHOW_PROMO_MODAL',
  SET_SHOW_LAST_DAY_MODAL: 'SET_SHOW_LAST_DAY_MODAL',
  SET_SHOW_DAILY_PROGRESS_MODAL: 'SET_SHOW_DAILY_PROGRESS_MODAL',
  SET_SHOW_FINAL_MODAL: 'SET_SHOW_FINAL_MODAL',
  SET_SHOW_SHOP_MODAL: 'SET_SHOW_SHOP_MODAL',

  SELECT_STUDENT: 'SELECT_STUDENT',

  ADD_NOTE_TO_STUDENT_FAILURE: 'ADD_NOTE_TO_STUDENT_FAILURE',
  ADD_NOTE_TO_STUDENT_REQUEST: 'ADD_NOTE_TO_STUDENT_REQUEST',
  ADD_NOTE_TO_STUDENT_SUCCESS: 'ADD_NOTE_TO_STUDENT_SUCCESS',
  ADD_STUDENT_TO_USER_FAILURE: 'ADD_STUDENT_TO_USER_FAILURE',
  ADD_STUDENT_TO_USER_REQUEST: 'ADD_STUDENT_TO_USER_REQUEST',
  ADD_STUDENT_TO_USER_SUCCESS: 'ADD_STUDENT_TO_USER_SUCCESS',
  ACTIVITIES_GET_FAILURE: 'ACTIVITIES_GET_FAILURE',
  ACTIVITIES_GET_REQUEST: 'ACTIVITIES_GET_REQUEST',
  ACTIVITIES_GET_SUCCESS: 'ACTIVITIES_GET_SUCCESS',
  ACTIVITIES_RESET: 'ACTIVITIES_RESET',
  ACTIVITY_GROUPS_GET_FAILURE: 'ACTIVITY_GROUPS_GET_FAILURE',
  ACTIVITY_GROUPS_GET_REQUEST: 'ACTIVITY_GROUPS_GET_REQUEST',
  ACTIVITY_GROUPS_GET_SUCCESS: 'ACTIVITY_GROUPS_GET_SUCCESS',
  ACTIVITY_GROUPS_RESET: 'ACTIVITY_GROUPS_RESET',
  ARTICULATION_GYMNASTICS_COLLECTIONS_GET_FAILURE:
    'ARTICULATION_GYMNASTICS_COLLECTIONS_GET_FAILURE',
  ARTICULATION_GYMNASTICS_COLLECTIONS_GET_REQUEST:
    'ARTICULATION_GYMNASTICS_COLLECTIONS_GET_REQUEST',
  ARTICULATION_GYMNASTICS_COLLECTIONS_GET_SUCCESS:
    'ARTICULATION_GYMNASTICS_COLLECTIONS_GET_SUCCESS',
  ARTICULATION_GYMNASTICS_LEVELS_GET_FAILURE:
    'ARTICULATION_GYMNASTICS_LEVELS_GET_FAILURE',
  ARTICULATION_GYMNASTICS_LEVELS_GET_REQUEST:
    'ARTICULATION_GYMNASTICS_LEVELS_GET_REQUEST',
  ARTICULATION_GYMNASTICS_LEVELS_GET_SUCCESS:
    'ARTICULATION_GYMNASTICS_LEVELS_GET_SUCCESS',
  AUTHENTICATE_USER_FAILURE: 'AUTHENTICATE_USER_FAILURE',
  AUTHENTICATE_USER_REQUEST: 'AUTHENTICATE_USER_REQUEST',
  AUTHENTICATE_USER_SUCCESS: 'AUTHENTICATE_USER_SUCCESS',
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
  CLIMB_STEP_THREE_COLLECTIONS_GET_FAILURE:
    'CLIMB_STEP_THREE_COLLECTIONS_GET_FAILURE',
  CLIMB_STEP_THREE_COLLECTIONS_GET_REQUEST:
    'CLIMB_STEP_THREE_COLLECTIONS_GET_REQUEST',
  CLIMB_STEP_THREE_COLLECTIONS_GET_SUCCESS:
    'CLIMB_STEP_THREE_COLLECTIONS_GET_SUCCESS',
  CLIMB_STEP_THREE_LEVELS_GET_FAILURE: 'CLIMB_STEP_THREE_LEVELS_GET_FAILURE',
  CLIMB_STEP_THREE_LEVELS_GET_REQUEST: 'CLIMB_STEP_THREE_LEVELS_GET_REQUEST',
  CLIMB_STEP_THREE_LEVELS_GET_SUCCESS: 'CLIMB_STEP_THREE_LEVELS_GET_SUCCESS',
  CLIMB_STEP_THREE_EXTRA_COLLECTIONS_GET_FAILURE:
    'CLIMB_STEP_THREE_EXTRA_COLLECTIONS_GET_FAILURE',
  CLIMB_STEP_THREE_EXTRA_COLLECTIONS_GET_REQUEST:
    'CLIMB_STEP_THREE_EXTRA_COLLECTIONS_GET_REQUEST',
  CLIMB_STEP_THREE_EXTRA_COLLECTIONS_GET_SUCCESS:
    'CLIMB_STEP_THREE_EXTRA_COLLECTIONS_GET_SUCCESS',
  CLIMB_STEP_THREE_EXTRA_LEVELS_GET_FAILURE:
    'CLIMB_STEP_THREE_EXTRA_LEVELS_GET_FAILURE',
  CLIMB_STEP_THREE_EXTRA_LEVELS_GET_REQUEST:
    'CLIMB_STEP_THREE_EXTRA_LEVELS_GET_REQUEST',
  CLIMB_STEP_THREE_EXTRA_LEVELS_GET_SUCCESS:
    'CLIMB_STEP_THREE_EXTRA_LEVELS_GET_SUCCESS',
  CLIMB_STEP_TWO_COLLECTIONS_GET_FAILURE:
    'CLIMB_STEP_TWO_COLLECTIONS_GET_FAILURE',
  CLIMB_STEP_TWO_COLLECTIONS_GET_REQUEST:
    'CLIMB_STEP_TWO_COLLECTIONS_GET_REQUEST',
  CLIMB_STEP_TWO_COLLECTIONS_GET_SUCCESS:
    'CLIMB_STEP_TWO_COLLECTIONS_GET_SUCCESS',
  CLIMB_STEP_TWO_LEVELS_GET_FAILURE: 'CLIMB_STEP_TWO_LEVELS_GET_FAILURE',
  CLIMB_STEP_TWO_LEVELS_GET_REQUEST: 'CLIMB_STEP_TWO_LEVELS_GET_REQUEST',
  CLIMB_STEP_TWO_LEVELS_GET_SUCCESS: 'CLIMB_STEP_TWO_LEVELS_GET_SUCCESS',
  CLIMB_STEP_TWO_EXTRA_COLLECTIONS_GET_FAILURE:
    'CLIMB_STEP_TWO_EXTRA_COLLECTIONS_GET_FAILURE',
  CLIMB_STEP_TWO_EXTRA_COLLECTIONS_GET_REQUEST:
    'CLIMB_STEP_TWO_EXTRA_COLLECTIONS_GET_REQUEST',
  CLIMB_STEP_TWO_EXTRA_COLLECTIONS_GET_SUCCESS:
    'CLIMB_STEP_TWO_EXTRA_COLLECTIONS_GET_SUCCESS',
  CLIMB_STEP_TWO_EXTRA_LEVELS_GET_FAILURE:
    'CLIMB_STEP_TWO_EXTRA_LEVELS_GET_FAILURE',
  CLIMB_STEP_TWO_EXTRA_LEVELS_GET_REQUEST:
    'CLIMB_STEP_TWO_EXTRA_LEVELS_GET_REQUEST',
  CLIMB_STEP_TWO_EXTRA_LEVELS_GET_SUCCESS:
    'CLIMB_STEP_TWO_EXTRA_LEVELS_GET_SUCCESS',
  CLIMB_STEP_ONE_COLLECTIONS_GET_FAILURE:
    'CLIMB_STEP_ONE_COLLECTIONS_GET_FAILURE',
  CLIMB_STEP_ONE_COLLECTIONS_GET_REQUEST:
    'CLIMB_STEP_ONE_COLLECTIONS_GET_REQUEST',
  CLIMB_STEP_ONE_COLLECTIONS_GET_SUCCESS:
    'CLIMB_STEP_ONE_COLLECTIONS_GET_SUCCESS',
  CLIMB_STEP_ONE_LEVELS_GET_FAILURE: 'CLIMB_STEP_ONE_LEVELS_GET_FAILURE',
  CLIMB_STEP_ONE_LEVELS_GET_REQUEST: 'CLIMB_STEP_ONE_LEVELS_GET_REQUEST',
  CLIMB_STEP_ONE_LEVELS_GET_SUCCESS: 'CLIMB_STEP_ONE_LEVELS_GET_SUCCESS',
  CLIMB_STEP_ONE_EXTRA_COLLECTIONS_GET_FAILURE:
    'CLIMB_STEP_ONE_EXTRA_COLLECTIONS_GET_FAILURE',
  CLIMB_STEP_ONE_EXTRA_COLLECTIONS_GET_REQUEST:
    'CLIMB_STEP_ONE_EXTRA_COLLECTIONS_GET_REQUEST',
  CLIMB_STEP_ONE_EXTRA_COLLECTIONS_GET_SUCCESS:
    'CLIMB_STEP_ONE_EXTRA_COLLECTIONS_GET_SUCCESS',
  CLIMB_STEP_ONE_EXTRA_LEVELS_GET_FAILURE:
    'CLIMB_STEP_ONE_EXTRA_LEVELS_GET_FAILURE',
  CLIMB_STEP_ONE_EXTRA_LEVELS_GET_REQUEST:
    'CLIMB_STEP_ONE_EXTRA_LEVELS_GET_REQUEST',
  CLIMB_STEP_ONE_EXTRA_LEVELS_GET_SUCCESS:
    'CLIMB_STEP_ONE_EXTRA_LEVELS_GET_SUCCESS',
  CLEAR_USER_STORE_DATA: 'CLEAR_USER_STORE_DATA',
  COUPONS_ACTIVATE_FAILURE: 'COUPONS_ACTIVATE_FAILURE',
  COUPONS_ACTIVATE_REQUEST: 'COUPONS_ACTIVATE_REQUEST',
  COUPONS_ACTIVATE_SUCCESS: 'COUPONS_ACTIVATE_SUCCESS',
  COUPONS_STATUS_GET_FAILURE: 'COUPONS_STATUS_GET_FAILURE',
  COUPONS_STATUS_GET_REQUEST: 'COUPONS_STATUS_GET_REQUEST',
  COUPONS_STATUS_GET_SUCCESS: 'COUPONS_STATUS_GET_SUCCESS',
  DAILY_MAP_TEMPLATES_GET_FAILURE: 'DAILY_MAP_TEMPLATES_GET_FAILURE',
  DAILY_MAP_TEMPLATES_GET_REQUEST: 'DAILY_MAP_TEMPLATES_GET_REQUEST',
  DAILY_MAP_TEMPLATES_GET_SUCCESS: 'DAILY_MAP_TEMPLATES_GET_SUCCESS',
  DAILY_MAP_GET_FAILURE: 'DAILY_MAP_GET_FAILURE',
  DAILY_MAP_GET_REQUEST: 'DAILY_MAP_GET_REQUEST',
  DAILY_MAP_GET_SUCCESS: 'DAILY_MAP_GET_SUCCESS',
  DAILY_MAPS_GET_FAILURE: 'DAILY_MAPS_GET_FAILURE',
  DAILY_MAPS_GET_REQUEST: 'DAILY_MAPS_GET_REQUEST',
  DAILY_MAPS_GET_SUCCESS: 'DAILY_MAPS_GET_SUCCESS',
  DAILY_MAP_POST_FAILURE: 'DAILY_MAP_POST_FAILURE',
  DAILY_MAP_POST_REQUEST: 'DAILY_MAP_POST_REQUEST',
  DAILY_MAP_POST_SUCCESS: 'DAILY_MAP_POST_SUCCESS',
  DAILY_MAP_UPDATE_FAILURE: 'DAILY_MAP_UPDATE_FAILURE',
  DAILY_MAP_UPDATE_REQUEST: 'DAILY_MAP_UPDATE_REQUEST',
  DAILY_MAP_UPDATE_SUCCESS: 'DAILY_MAP_UPDATE_SUCCESS',
  DAILY_MAP_FEEDBACK_GET_FAILURE: 'DAILY_MAP_FEEDBACK_GET_FAILURE',
  DAILY_MAP_FEEDBACK_GET_REQUEST: 'DAILY_MAP_FEEDBACK_GET_REQUEST',
  DAILY_MAP_FEEDBACK_GET_SUCCESS: 'DAILY_MAP_FEEDBACK_GET_SUCCESS',
  DAILY_MAP_LEVELS_GET_FAILURE: 'DAILY_MAP_LEVELS_GET_FAILURE',
  DAILY_MAP_LEVELS_GET_REQUEST: 'DAILY_MAP_LEVELS_GET_REQUEST',
  DAILY_MAP_LEVELS_GET_SUCCESS: 'DAILY_MAP_LEVELS_GET_SUCCESS',
  DAILY_MAP_COLLECTION_VERIFICATION_POST_FAILURE:
    'DAILY_MAP_COLLECTION_VERIFICATION_POST_FAILURE',
  DAILY_MAP_COLLECTION_VERIFICATION_POST_REQUEST:
    'DAILY_MAP_COLLECTION_VERIFICATION_POST_REQUEST',
  DAILY_MAP_COLLECTION_VERIFICATION_POST_SUCCESS:
    'DAILY_MAP_COLLECTION_VERIFICATION_POST_SUCCESS',
  DAILY_MAP_RESET: 'DAILY_MAP_RESET',
  RESET_DAILY_MAP_LEVELS: 'RESET_DAILY_MAP_LEVELS',
  DISCOVER_STEP_THREE_COLLECTIONS_GET_FAILURE:
    'DISCOVER_STEP_THREE_COLLECTIONS_GET_FAILURE',
  DISCOVER_STEP_THREE_COLLECTIONS_GET_REQUEST:
    'DISCOVER_STEP_THREE_COLLECTIONS_GET_REQUEST',
  DISCOVER_STEP_THREE_COLLECTIONS_GET_SUCCESS:
    'DISCOVER_STEP_THREE_COLLECTIONS_GET_SUCCESS',
  DISCOVER_STEP_THREE_LEVELS_GET_FAILURE:
    'DISCOVER_STEP_THREE_LEVELS_GET_FAILURE',
  DISCOVER_STEP_THREE_LEVELS_GET_REQUEST:
    'DISCOVER_STEP_THREE_LEVELS_GET_REQUEST',
  DISCOVER_STEP_THREE_LEVELS_GET_SUCCESS:
    'DISCOVER_STEP_THREE_LEVELS_GET_SUCCESS',
  DISCOVER_STEP_THREE_EXTRA_COLLECTIONS_GET_FAILURE:
    'DISCOVER_STEP_THREE_EXTRA_COLLECTIONS_GET_FAILURE',
  DISCOVER_STEP_THREE_EXTRA_COLLECTIONS_GET_REQUEST:
    'DISCOVER_STEP_THREE_EXTRA_COLLECTIONS_GET_REQUEST',
  DISCOVER_STEP_THREE_EXTRA_COLLECTIONS_GET_SUCCESS:
    'DISCOVER_STEP_THREE_EXTRA_COLLECTIONS_GET_SUCCESS',
  DISCOVER_STEP_THREE_EXTRA_LEVELS_GET_FAILURE:
    'DISCOVER_STEP_THREE_EXTRA_LEVELS_GET_FAILURE',
  DISCOVER_STEP_THREE_EXTRA_LEVELS_GET_REQUEST:
    'DISCOVER_STEP_THREE_EXTRA_LEVELS_GET_REQUEST',
  DISCOVER_STEP_THREE_EXTRA_LEVELS_GET_SUCCESS:
    'DISCOVER_STEP_THREE_EXTRA_LEVELS_GET_SUCCESS',
  DISCOVER_STEP_TWO_COLLECTIONS_GET_FAILURE:
    'DISCOVER_STEP_TWO_COLLECTIONS_GET_FAILURE',
  DISCOVER_STEP_TWO_COLLECTIONS_GET_REQUEST:
    'DISCOVER_STEP_TWO_COLLECTIONS_GET_REQUEST',
  DISCOVER_STEP_TWO_COLLECTIONS_GET_SUCCESS:
    'DISCOVER_STEP_TWO_COLLECTIONS_GET_SUCCESS',
  DISCOVER_STEP_TWO_LEVELS_GET_FAILURE: 'DISCOVER_STEP_TWO_LEVELS_GET_FAILURE',
  DISCOVER_STEP_TWO_LEVELS_GET_REQUEST: 'DISCOVER_STEP_TWO_LEVELS_GET_REQUEST',
  DISCOVER_STEP_TWO_LEVELS_GET_SUCCESS: 'DISCOVER_STEP_TWO_LEVELS_GET_SUCCESS',
  DISCOVER_STEP_ONE_COLLECTIONS_GET_FAILURE:
    'DISCOVER_STEP_ONE_COLLECTIONS_GET_FAILURE',
  DISCOVER_STEP_ONE_COLLECTIONS_GET_REQUEST:
    'DISCOVER_STEP_ONE_COLLECTIONS_GET_REQUEST',
  DISCOVER_STEP_ONE_COLLECTIONS_GET_SUCCESS:
    'DISCOVER_STEP_ONE_COLLECTIONS_GET_SUCCESS',
  DISCOVER_STEP_ONE_LEVELS_GET_FAILURE: 'DISCOVER_STEP_ONE_LEVELS_GET_FAILURE',
  DISCOVER_STEP_ONE_LEVELS_GET_REQUEST: 'DISCOVER_STEP_ONE_LEVELS_GET_REQUEST',
  DISCOVER_STEP_ONE_LEVELS_GET_SUCCESS: 'DISCOVER_STEP_ONE_LEVELS_GET_SUCCESS',
  DISCOVER_STEP_ONE_EXTRA_COLLECTIONS_GET_FAILURE:
    'DISCOVER_STEP_ONE_EXTRA_COLLECTIONS_GET_FAILURE',
  DISCOVER_STEP_ONE_EXTRA_COLLECTIONS_GET_REQUEST:
    'DISCOVER_STEP_ONE_EXTRA_COLLECTIONS_GET_REQUEST',
  DISCOVER_STEP_ONE_EXTRA_COLLECTIONS_GET_SUCCESS:
    'DISCOVER_STEP_ONE_EXTRA_COLLECTIONS_GET_SUCCESS',
  DISCOVER_STEP_ONE_EXTRA_LEVELS_GET_FAILURE:
    'DISCOVER_STEP_ONE_EXTRA_LEVELS_GET_FAILURE',
  DISCOVER_STEP_ONE_EXTRA_LEVELS_GET_REQUEST:
    'DISCOVER_STEP_ONE_EXTRA_LEVELS_GET_REQUEST',
  DISCOVER_STEP_ONE_EXTRA_LEVELS_GET_SUCCESS:
    'DISCOVER_STEP_ONE_EXTRA_LEVELS_GET_SUCCESS',
  DISCOVER_STEP_TWO_EXTRA_COLLECTIONS_GET_FAILURE:
    'DISCOVER_STEP_TWO_EXTRA_COLLECTIONS_GET_FAILURE',
  DISCOVER_STEP_TWO_EXTRA_COLLECTIONS_GET_REQUEST:
    'DISCOVER_STEP_TWO_EXTRA_COLLECTIONS_GET_REQUEST',
  DISCOVER_STEP_TWO_EXTRA_COLLECTIONS_GET_SUCCESS:
    'DISCOVER_STEP_TWO_EXTRA_COLLECTIONS_GET_SUCCESS',
  DISCOVER_STEP_TWO_EXTRA_LEVELS_GET_FAILURE:
    'DISCOVER_STEP_TWO_EXTRA_LEVELS_GET_FAILURE',
  DISCOVER_STEP_TWO_EXTRA_LEVELS_GET_REQUEST:
    'DISCOVER_STEP_TWO_EXTRA_LEVELS_GET_REQUEST',
  DISCOVER_STEP_TWO_EXTRA_LEVELS_GET_SUCCESS:
    'DISCOVER_STEP_TWO_EXTRA_LEVELS_GET_SUCCESS',
  DISMISS_TRIAL_EXPIRATION_ALERT_FAILURE:
    'DISMISS_TRIAL_EXPIRATION_ALERT_FAILURE',
  DISMISS_TRIAL_EXPIRATION_ALERT_REQUEST:
    'DISMISS_TRIAL_EXPIRATION_ALERT_REQUEST',
  DISMISS_TRIAL_EXPIRATION_ALERT_SUCCESS:
    'DISMISS_TRIAL_EXPIRATION_ALERT_SUCCESS',
  DISMISS_UPDATE_NOTIFICATION_ALERT_FAILURE:
    'DISMISS_UPDATE_NOTIFICATION_ALERT_FAILURE',
  DISMISS_UPDATE_NOTIFICATION_ALERT_REQUEST:
    'DISMISS_UPDATE_NOTIFICATION_ALERT_REQUEST',
  DISMISS_UPDATE_NOTIFICATION_ALERT_SUCCESS:
    'DISMISS_UPDATE_NOTIFICATION_ALERT_SUCCESS',
  FACEBOOK_LOGIN_FAILURE: 'FACEBOOK_LOGIN_FAILURE',
  FACEBOOK_LOGIN_REQUEST: 'FACEBOOK_LOGIN_REQUEST',
  FACEBOOK_LOGIN_SUCCESS: 'FACEBOOK_LOGIN_SUCCESS',
  FEEDBACK_STATISTICS_GET_FAILURE: 'FEEDBACK_STATISTICS_GET_FAILURE',
  FEEDBACK_STATISTICS_GET_REQUEST: 'FEEDBACK_STATISTICS_GET_REQUEST',
  FEEDBACK_STATISTICS_GET_SUCCESS: 'FEEDBACK_STATISTICS_GET_SUCCESS',
  FEEDBACK_UPDATE_FAILURE: 'FEEDBACK_UPDATE_FAILURE',
  FEEDBACK_UPDATE_REQUEST: 'FEEDBACK_UPDATE_REQUEST',
  FEEDBACK_UPDATE_SUCCESS: 'FEEDBACK_UPDATE_SUCCESS',
  FIND_WORDS_STEP_THREE_COLLECTIONS_GET_FAILURE:
    'FIND_WORDS_STEP_THREE_COLLECTIONS_GET_FAILURE',
  FIND_WORDS_STEP_THREE_COLLECTIONS_GET_REQUEST:
    'FIND_WORDS_STEP_THREE_COLLECTIONS_GET_REQUEST',
  FIND_WORDS_STEP_THREE_COLLECTIONS_GET_SUCCESS:
    'FIND_WORDS_STEP_THREE_COLLECTIONS_GET_SUCCESS',
  FIND_WORDS_STEP_THREE_LEVELS_GET_FAILURE:
    'FIND_WORDS_STEP_THREE_LEVELS_GET_FAILURE',
  FIND_WORDS_STEP_THREE_LEVELS_GET_REQUEST:
    'FIND_WORDS_STEP_THREE_LEVELS_GET_REQUEST',
  FIND_WORDS_STEP_THREE_LEVELS_GET_SUCCESS:
    'FIND_WORDS_STEP_THREE_LEVELS_GET_SUCCESS',
  FIND_WORDS_STEP_THREE_EXTRA_COLLECTIONS_GET_FAILURE:
    'FIND_WORDS_STEP_THREE_EXTRA_COLLECTIONS_GET_FAILURE',
  FIND_WORDS_STEP_THREE_EXTRA_COLLECTIONS_GET_REQUEST:
    'FIND_WORDS_STEP_THREE_EXTRA_COLLECTIONS_GET_REQUEST',
  FIND_WORDS_STEP_THREE_EXTRA_COLLECTIONS_GET_SUCCESS:
    'FIND_WORDS_STEP_THREE_EXTRA_COLLECTIONS_GET_SUCCESS',
  FIND_WORDS_STEP_THREE_EXTRA_LEVELS_GET_FAILURE:
    'FIND_WORDS_STEP_THREE_EXTRA_LEVELS_GET_FAILURE',
  FIND_WORDS_STEP_THREE_EXTRA_LEVELS_GET_REQUEST:
    'FIND_WORDS_STEP_THREE_EXTRA_LEVELS_GET_REQUEST',
  FIND_WORDS_STEP_THREE_EXTRA_LEVELS_GET_SUCCESS:
    'FIND_WORDS_STEP_THREE_EXTRA_LEVELS_GET_SUCCESS',
  FIND_WORDS_STEP_TWO_COLLECTIONS_GET_FAILURE:
    'FIND_WORDS_STEP_TWO_COLLECTIONS_GET_FAILURE',
  FIND_WORDS_STEP_TWO_COLLECTIONS_GET_REQUEST:
    'FIND_WORDS_STEP_TWO_COLLECTIONS_GET_REQUEST',
  FIND_WORDS_STEP_TWO_COLLECTIONS_GET_SUCCESS:
    'FIND_WORDS_STEP_TWO_COLLECTIONS_GET_SUCCESS',
  FIND_WORDS_STEP_TWO_LEVELS_GET_FAILURE:
    'FIND_WORDS_STEP_TWO_LEVELS_GET_FAILURE',
  FIND_WORDS_STEP_TWO_LEVELS_GET_REQUEST:
    'FIND_WORDS_STEP_TWO_LEVELS_GET_REQUEST',
  FIND_WORDS_STEP_TWO_LEVELS_GET_SUCCESS:
    'FIND_WORDS_STEP_TWO_LEVELS_GET_SUCCESS',
  FIND_WORDS_STEP_TWO_EXTRA_COLLECTIONS_GET_FAILURE:
    'FIND_WORDS_STEP_TWO_EXTRA_COLLECTIONS_GET_FAILURE',
  FIND_WORDS_STEP_TWO_EXTRA_COLLECTIONS_GET_REQUEST:
    'FIND_WORDS_STEP_TWO_EXTRA_COLLECTIONS_GET_REQUEST',
  FIND_WORDS_STEP_TWO_EXTRA_COLLECTIONS_GET_SUCCESS:
    'FIND_WORDS_STEP_TWO_EXTRA_COLLECTIONS_GET_SUCCESS',
  FIND_WORDS_STEP_TWO_EXTRA_LEVELS_GET_FAILURE:
    'FIND_WORDS_STEP_TWO_EXTRA_LEVELS_GET_FAILURE',
  FIND_WORDS_STEP_TWO_EXTRA_LEVELS_GET_REQUEST:
    'FIND_WORDS_STEP_TWO_EXTRA_LEVELS_GET_REQUEST',
  FIND_WORDS_STEP_TWO_EXTRA_LEVELS_GET_SUCCESS:
    'FIND_WORDS_STEP_TWO_EXTRA_LEVELS_GET_SUCCESS',
  FIND_WORDS_STEP_ONE_COLLECTIONS_GET_FAILURE:
    'FIND_WORDS_STEP_ONE_COLLECTIONS_GET_FAILURE',
  FIND_WORDS_STEP_ONE_COLLECTIONS_GET_REQUEST:
    'FIND_WORDS_STEP_ONE_COLLECTIONS_GET_REQUEST',
  FIND_WORDS_STEP_ONE_COLLECTIONS_GET_SUCCESS:
    'FIND_WORDS_STEP_ONE_COLLECTIONS_GET_SUCCESS',
  FIND_WORDS_STEP_ONE_LEVELS_GET_FAILURE:
    'FIND_WORDS_STEP_ONE_LEVELS_GET_FAILURE',
  FIND_WORDS_STEP_ONE_LEVELS_GET_REQUEST:
    'FIND_WORDS_STEP_ONE_LEVELS_GET_REQUEST',
  FIND_WORDS_STEP_ONE_LEVELS_GET_SUCCESS:
    'FIND_WORDS_STEP_ONE_LEVELS_GET_SUCCESS',
  FIND_WORDS_STEP_ONE_EXTRA_COLLECTIONS_GET_FAILURE:
    'FIND_WORDS_STEP_ONE_EXTRA_COLLECTIONS_GET_FAILURE',
  FIND_WORDS_STEP_ONE_EXTRA_COLLECTIONS_GET_REQUEST:
    'FIND_WORDS_STEP_ONE_EXTRA_COLLECTIONS_GET_REQUEST',
  FIND_WORDS_STEP_ONE_EXTRA_COLLECTIONS_GET_SUCCESS:
    'FIND_WORDS_STEP_ONE_EXTRA_COLLECTIONS_GET_SUCCESS',
  FIND_WORDS_STEP_ONE_EXTRA_LEVELS_GET_FAILURE:
    'FIND_WORDS_STEP_ONE_EXTRA_LEVELS_GET_FAILURE',
  FIND_WORDS_STEP_ONE_EXTRA_LEVELS_GET_REQUEST:
    'FIND_WORDS_STEP_ONE_EXTRA_LEVELS_GET_REQUEST',
  FIND_WORDS_STEP_ONE_EXTRA_LEVELS_GET_SUCCESS:
    'FIND_WORDS_STEP_ONE_EXTRA_LEVELS_GET_SUCCESS',
  FLASH_CARDS_STEP_THREE_COLLECTIONS_GET_FAILURE:
    'FLASH_CARDS_STEP_THREE_COLLECTIONS_GET_FAILURE',
  FLASH_CARDS_STEP_THREE_COLLECTIONS_GET_REQUEST:
    'FLASH_CARDS_STEP_THREE_COLLECTIONS_GET_REQUEST',
  FLASH_CARDS_STEP_THREE_COLLECTIONS_GET_SUCCESS:
    'FLASH_CARDS_STEP_THREE_COLLECTIONS_GET_SUCCESS',
  FLASH_CARDS_STEP_THREE_LEVELS_GET_FAILURE:
    'FLASH_CARDS_STEP_THREE_LEVELS_GET_FAILURE',
  FLASH_CARDS_STEP_THREE_LEVELS_GET_REQUEST:
    'FLASH_CARDS_STEP_THREE_LEVELS_GET_REQUEST',
  FLASH_CARDS_STEP_THREE_LEVELS_GET_SUCCESS:
    'FLASH_CARDS_STEP_THREE_LEVELS_GET_SUCCESS',
  FLASH_CARDS_STEP_THREE_EXTRA_COLLECTIONS_GET_FAILURE:
    'FLASH_CARDS_STEP_THREE_EXTRA_COLLECTIONS_GET_FAILURE',
  FLASH_CARDS_STEP_THREE_EXTRA_COLLECTIONS_GET_REQUEST:
    'FLASH_CARDS_STEP_THREE_EXTRA_COLLECTIONS_GET_REQUEST',
  FLASH_CARDS_STEP_THREE_EXTRA_COLLECTIONS_GET_SUCCESS:
    'FLASH_CARDS_STEP_THREE_EXTRA_COLLECTIONS_GET_SUCCESS',
  FLASH_CARDS_STEP_THREE_EXTRA_LEVELS_GET_FAILURE:
    'FLASH_CARDS_STEP_THREE_EXTRA_LEVELS_GET_FAILURE',
  FLASH_CARDS_STEP_THREE_EXTRA_LEVELS_GET_REQUEST:
    'FLASH_CARDS_STEP_THREE_EXTRA_LEVELS_GET_REQUEST',
  FLASH_CARDS_STEP_THREE_EXTRA_LEVELS_GET_SUCCESS:
    'FLASH_CARDS_STEP_THREE_LEVELS_GET_SUCCESS',
  FLASH_CARDS_STEP_TWO_COLLECTIONS_GET_FAILURE:
    'FLASH_CARDS_STEP_TWO_COLLECTIONS_GET_FAILURE',
  FLASH_CARDS_STEP_TWO_COLLECTIONS_GET_REQUEST:
    'FLASH_CARDS_STEP_TWO_COLLECTIONS_GET_REQUEST',
  FLASH_CARDS_STEP_TWO_COLLECTIONS_GET_SUCCESS:
    'FLASH_CARDS_STEP_TWO_COLLECTIONS_GET_SUCCESS',
  FLASH_CARDS_STEP_TWO_LEVELS_GET_FAILURE:
    'FLASH_CARDS_STEP_TWO_LEVELS_GET_FAILURE',
  FLASH_CARDS_STEP_TWO_LEVELS_GET_REQUEST:
    'FLASH_CARDS_STEP_TWO_LEVELS_GET_REQUEST',
  FLASH_CARDS_STEP_TWO_LEVELS_GET_SUCCESS:
    'FLASH_CARDS_STEP_TWO_LEVELS_GET_SUCCESS',
  FLASH_CARDS_STEP_ONE_COLLECTIONS_GET_FAILURE:
    'FLASH_CARDS_STEP_ONE_COLLECTIONS_GET_FAILURE',
  FLASH_CARDS_STEP_ONE_COLLECTIONS_GET_REQUEST:
    'FLASH_CARDS_STEP_ONE_COLLECTIONS_GET_REQUEST',
  FLASH_CARDS_STEP_ONE_COLLECTIONS_GET_SUCCESS:
    'FLASH_CARDS_STEP_ONE_COLLECTIONS_GET_SUCCESS',
  FLASH_CARDS_STEP_ONE_LEVELS_GET_FAILURE:
    'FLASH_CARDS_STEP_ONE_LEVELS_GET_FAILURE',
  FLASH_CARDS_STEP_ONE_LEVELS_GET_REQUEST:
    'FLASH_CARDS_STEP_ONE_LEVELS_GET_REQUEST',
  FLASH_CARDS_STEP_ONE_LEVELS_GET_SUCCESS:
    'FLASH_CARDS_STEP_ONE_LEVELS_GET_SUCCESS',
  FLASH_CARDS_STEP_ONE_EXTRA_COLLECTIONS_GET_FAILURE:
    'FLASH_CARDS_STEP_ONE_EXTRA_COLLECTIONS_GET_FAILURE',
  FLASH_CARDS_STEP_ONE_EXTRA_COLLECTIONS_GET_REQUEST:
    'FLASH_CARDS_STEP_ONE_EXTRA_COLLECTIONS_GET_REQUEST',
  FLASH_CARDS_STEP_ONE_EXTRA_COLLECTIONS_GET_SUCCESS:
    'FLASH_CARDS_STEP_ONE_EXTRA_COLLECTIONS_GET_SUCCESS',
  FLASH_CARDS_STEP_ONE_EXTRA_LEVELS_GET_FAILURE:
    'FLASH_CARDS_STEP_ONE_EXTRA_LEVELS_GET_FAILURE',
  FLASH_CARDS_STEP_ONE_EXTRA_LEVELS_GET_REQUEST:
    'FLASH_CARDS_STEP_ONE_EXTRA_LEVELS_GET_REQUEST',
  FLASH_CARDS_STEP_ONE_EXTRA_LEVELS_GET_SUCCESS:
    'FLASH_CARDS_STEP_ONE_EXTRA_LEVELS_GET_SUCCESS',
  FLASH_CARDS_STEP_TWO_EXTRA_COLLECTIONS_GET_FAILURE:
    'FLASH_CARDS_STEP_TWO_EXTRA_COLLECTIONS_GET_FAILURE',
  FLASH_CARDS_STEP_TWO_EXTRA_COLLECTIONS_GET_REQUEST:
    'FLASH_CARDS_STEP_TWO_EXTRA_COLLECTIONS_GET_REQUEST',
  FLASH_CARDS_STEP_TWO_EXTRA_COLLECTIONS_GET_SUCCESS:
    'FLASH_CARDS_STEP_TWO_EXTRA_COLLECTIONS_GET_SUCCESS',
  FLASH_CARDS_STEP_TWO_EXTRA_LEVELS_GET_FAILURE:
    'FLASH_CARDS_STEP_TWO_EXTRA_LEVELS_GET_FAILURE',
  FLASH_CARDS_STEP_TWO_EXTRA_LEVELS_GET_REQUEST:
    'FLASH_CARDS_STEP_TWO_EXTRA_LEVELS_GET_REQUEST',
  FLASH_CARDS_STEP_TWO_EXTRA_LEVELS_GET_SUCCESS:
    'FLASH_CARDS_STEP_TWO_EXTRA_LEVELS_GET_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  GENERAL_API_FAILURE: 'GENERAL_API_FAILURE',
  GOOGLE_LOGIN_FAILURE: 'GOOGLE_LOGIN_FAILURE',
  GOOGLE_LOGIN_REQUEST: 'GOOGLE_LOGIN_REQUEST',
  GOOGLE_LOGIN_SUCCESS: 'GOOGLE_LOGIN_SUCCESS',
  HELP_GET_FAILURE: 'HELP_GET_FAILURE',
  HELP_GET_REQUEST: 'HELP_GET_REQUEST',
  HELP_GET_SUCCESS: 'HELP_GET_SUCCESS',
  INVITE_USER_FAILURE: 'INVITE_USER_FAILURE',
  INVITE_USER_REQUEST: 'INVITE_USER_REQUEST',
  INVITE_USER_SUCCESS: 'INVITE_USER_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  MAGIC_LINK_FAILURE: 'MAGIC_LINK_FAILURE',
  MAGIC_LINK_REQUEST: 'MAGIC_LINK_REQUEST',
  MAGIC_LINK_SUCCESS: 'MAGIC_LINK_SUCCESS',
  MEMORY_STEP_THREE_COLLECTIONS_GET_FAILURE:
    'MEMORY_STEP_THREE_COLLECTIONS_GET_FAILURE',
  MEMORY_STEP_THREE_COLLECTIONS_GET_REQUEST:
    'MEMORY_STEP_THREE_COLLECTIONS_GET_REQUEST',
  MEMORY_STEP_THREE_COLLECTIONS_GET_SUCCESS:
    'MEMORY_STEP_THREE_COLLECTIONS_GET_SUCCESS',
  MEMORY_STEP_THREE_GROUPS_GET_FAILURE: 'MEMORY_STEP_THREE_GROUPS_GET_FAILURE',
  MEMORY_STEP_THREE_GROUPS_GET_REQUEST: 'MEMORY_STEP_THREE_GROUPS_GET_REQUEST',
  MEMORY_STEP_THREE_GROUPS_GET_SUCCESS: 'MEMORY_STEP_THREE_GROUPS_GET_SUCCESS',
  MEMORY_STEP_THREE_LEVELS_GET_FAILURE: 'MEMORY_STEP_THREE_LEVELS_GET_FAILURE',
  MEMORY_STEP_THREE_LEVELS_GET_REQUEST: 'MEMORY_STEP_THREE_LEVELS_GET_REQUEST',
  MEMORY_STEP_THREE_LEVELS_GET_SUCCESS: 'MEMORY_STEP_THREE_LEVELS_GET_SUCCESS',
  MEMORY_STEP_THREE_EXTRA_COLLECTIONS_GET_FAILURE:
    'MEMORY_STEP_THREE_EXTRA_COLLECTIONS_GET_FAILURE',
  MEMORY_STEP_THREE_EXTRA_COLLECTIONS_GET_REQUEST:
    'MEMORY_STEP_THREE_EXTRA_COLLECTIONS_GET_REQUEST',
  MEMORY_STEP_THREE_EXTRA_COLLECTIONS_GET_SUCCESS:
    'MEMORY_STEP_THREE_EXTRA_COLLECTIONS_GET_SUCCESS',
  MEMORY_STEP_THREE_EXTRA_GROUPS_GET_FAILURE:
    'MEMORY_STEP_THREE_EXTRA_GROUPS_GET_FAILURE',
  MEMORY_STEP_THREE_EXTRA_GROUPS_GET_REQUEST:
    'MEMORY_STEP_THREE_EXTRA_GROUPS_GET_REQUEST',
  MEMORY_STEP_THREE_EXTRA_GROUPS_GET_SUCCESS:
    'MEMORY_STEP_THREE_EXTRA_GROUPS_GET_SUCCESS',
  MEMORY_STEP_THREE_EXTRA_LEVELS_GET_FAILURE:
    'MEMORY_STEP_THREE_EXTRA_LEVELS_GET_FAILURE',
  MEMORY_STEP_THREE_EXTRA_LEVELS_GET_REQUEST:
    'MEMORY_STEP_THREE_EXTRA_LEVELS_GET_REQUEST',
  MEMORY_STEP_THREE_EXTRA_LEVELS_GET_SUCCESS:
    'MEMORY_STEP_THREE_EXTRA_LEVELS_GET_SUCCESS',
  MEMORY_STEP_TWO_COLLECTIONS_GET_FAILURE:
    'MEMORY_STEP_TWO_COLLECTIONS_GET_FAILURE',
  MEMORY_STEP_TWO_COLLECTIONS_GET_REQUEST:
    'MEMORY_STEP_TWO_COLLECTIONS_GET_REQUEST',
  MEMORY_STEP_TWO_COLLECTIONS_GET_SUCCESS:
    'MEMORY_STEP_TWO_COLLECTIONS_GET_SUCCESS',
  MEMORY_STEP_TWO_GROUPS_GET_FAILURE: 'MEMORY_STEP_TWO_GROUPS_GET_FAILURE',
  MEMORY_STEP_TWO_GROUPS_GET_REQUEST: 'MEMORY_STEP_TWO_GROUPS_GET_REQUEST',
  MEMORY_STEP_TWO_GROUPS_GET_SUCCESS: 'MEMORY_STEP_TWO_GROUPS_GET_SUCCESS',
  MEMORY_STEP_TWO_LEVELS_GET_FAILURE: 'MEMORY_STEP_TWO_LEVELS_GET_FAILURE',
  MEMORY_STEP_TWO_LEVELS_GET_REQUEST: 'MEMORY_STEP_TWO_LEVELS_GET_REQUEST',
  MEMORY_STEP_TWO_LEVELS_GET_SUCCESS: 'MEMORY_STEP_TWO_LEVELS_GET_SUCCESS',
  MEMORY_STEP_TWO_EXTRA_COLLECTIONS_GET_FAILURE:
    'MEMORY_STEP_TWO_EXTRA_COLLECTIONS_GET_FAILURE',
  MEMORY_STEP_TWO_EXTRA_COLLECTIONS_GET_REQUEST:
    'MEMORY_STEP_TWO_EXTRA_COLLECTIONS_GET_REQUEST',
  MEMORY_STEP_TWO_EXTRA_COLLECTIONS_GET_SUCCESS:
    'MEMORY_STEP_TWO_EXTRA_COLLECTIONS_GET_SUCCESS',
  MEMORY_STEP_TWO_EXTRA_GROUPS_GET_FAILURE:
    'MEMORY_STEP_TWO_EXTRA_GROUPS_GET_FAILURE',
  MEMORY_STEP_TWO_EXTRA_GROUPS_GET_REQUEST:
    'MEMORY_STEP_TWO_EXTRA_GROUPS_GET_REQUEST',
  MEMORY_STEP_TWO_EXTRA_GROUPS_GET_SUCCESS:
    'MEMORY_STEP_TWO_EXTRA_GROUPS_GET_SUCCESS',
  MEMORY_STEP_TWO_EXTRA_LEVELS_GET_FAILURE:
    'MEMORY_STEP_TWO_EXTRA_LEVELS_GET_FAILURE',
  MEMORY_STEP_TWO_EXTRA_LEVELS_GET_REQUEST:
    'MEMORY_STEP_TWO_EXTRA_LEVELS_GET_REQUEST',
  MEMORY_STEP_TWO_EXTRA_LEVELS_GET_SUCCESS:
    'MEMORY_STEP_TWO_EXTRA_LEVELS_GET_SUCCESS',
  MEMORY_STEP_ONE_COLLECTIONS_GET_FAILURE:
    'MEMORY_STEP_ONE_COLLECTIONS_GET_FAILURE',
  MEMORY_STEP_ONE_COLLECTIONS_GET_REQUEST:
    'MEMORY_STEP_ONE_COLLECTIONS_GET_REQUEST',
  MEMORY_STEP_ONE_COLLECTIONS_GET_SUCCESS:
    'MEMORY_STEP_ONE_COLLECTIONS_GET_SUCCESS',
  MEMORY_STEP_ONE_GROUPS_GET_FAILURE: 'MEMORY_STEP_ONE_GROUPS_GET_FAILURE',
  MEMORY_STEP_ONE_GROUPS_GET_REQUEST: 'MEMORY_STEP_ONE_GROUPS_GET_REQUEST',
  MEMORY_STEP_ONE_GROUPS_GET_SUCCESS: 'MEMORY_STEP_ONE_GROUPS_GET_SUCCESS',
  MEMORY_STEP_ONE_LEVELS_GET_FAILURE: 'MEMORY_STEP_ONE_LEVELS_GET_FAILURE',
  MEMORY_STEP_ONE_LEVELS_GET_REQUEST: 'MEMORY_STEP_ONE_LEVELS_GET_REQUEST',
  MEMORY_STEP_ONE_LEVELS_GET_SUCCESS: 'MEMORY_STEP_ONE_LEVELS_GET_SUCCESS',
  MEMORY_STEP_ONE_EXTRA_COLLECTIONS_GET_FAILURE:
    'MEMORY_STEP_ONE_EXTRA_COLLECTIONS_GET_FAILURE',
  MEMORY_STEP_ONE_EXTRA_COLLECTIONS_GET_REQUEST:
    'MEMORY_STEP_ONE_EXTRA_COLLECTIONS_GET_REQUEST',
  MEMORY_STEP_ONE_EXTRA_COLLECTIONS_GET_SUCCESS:
    'MEMORY_STEP_ONE_EXTRA_COLLECTIONS_GET_SUCCESS',
  MEMORY_STEP_ONE_EXTRA_GROUPS_GET_FAILURE:
    'MEMORY_STEP_ONE_EXTRA_GROUPS_GET_FAILURE',
  MEMORY_STEP_ONE_EXTRA_GROUPS_GET_REQUEST:
    'MEMORY_STEP_ONE_EXTRA_GROUPS_GET_REQUEST',
  MEMORY_STEP_ONE_EXTRA_GROUPS_GET_SUCCESS:
    'MEMORY_STEP_ONE_EXTRA_GROUPS_GET_SUCCESS',
  MEMORY_STEP_ONE_EXTRA_LEVELS_GET_FAILURE:
    'MEMORY_STEP_ONE_EXTRA_LEVELS_GET_FAILURE',
  MEMORY_STEP_ONE_EXTRA_LEVELS_GET_REQUEST:
    'MEMORY_STEP_ONE_EXTRA_LEVELS_GET_REQUEST',
  MEMORY_STEP_ONE_EXTRA_LEVELS_GET_SUCCESS:
    'MEMORY_STEP_ONE_EXTRA_LEVELS_GET_SUCCESS',
  NOTES_GET_FAILURE: 'NOTES_GET_FAILURE',
  NOTES_GET_REQUEST: 'NOTES_GET_REQUEST',
  NOTES_GET_SUCCESS: 'NOTES_GET_SUCCESS',
  NOTE_UPDATE_FAILURE: 'NOTE_UPDATE_FAILURE',
  NOTE_UPDATE_REQUEST: 'NOTE_UPDATE_REQUEST',
  NOTE_UPDATE_SUCCESS: 'NOTE_UPDATE_SUCCESS',
  NOTE_DELETE_REQUEST: 'NOTE_DELETE_REQUEST',
  NOTE_DELETE_SUCCESS: 'NOTE_DELETE_SUCCESS',
  NOTE_DELETE_FAILURE: 'NOTE_DELETE_FAILURE',
  PAINTING_COLLECTIONS_GET_FAILURE: 'PAINTING_COLLECTIONS_GET_FAILURE',
  PAINTING_COLLECTIONS_GET_REQUEST: 'PAINTING_COLLECTIONS_GET_REQUEST',
  PAINTING_COLLECTIONS_GET_SUCCESS: 'PAINTING_COLLECTIONS_GET_SUCCESS',
  PAINTING_GROUPS_GET_FAILURE: 'PAINTING_GROUPS_GET_FAILURE',
  PAINTING_GROUPS_GET_REQUEST: 'PAINTING_GROUPS_GET_REQUEST',
  PAINTING_GROUPS_GET_SUCCESS: 'PAINTING_GROUPS_GET_SUCCESS',
  PAINTING_LEVELS_GET_FAILURE: 'PAINTING_LEVELS_GET_FAILURE',
  PAINTING_LEVELS_GET_REQUEST: 'PAINTING_LEVELS_GET_REQUEST',
  PAINTING_LEVELS_GET_SUCCESS: 'PAINTING_LEVELS_GET_SUCCESS',
  PARROT_MOTIVATION_HIDE: 'PARROT_MOTIVATION_HIDE',
  PARROT_SELECTED_PARTS_GET_FAILURE: 'PARROT_SELECTED_PARTS_GET_FAILURE',
  PARROT_SELECTED_PARTS_GET_REQUEST: 'PARROT_SELECTED_PARTS_GET_REQUEST',
  PARROT_SELECTED_PARTS_GET_SUCCESS: 'PARROT_SELECTED_PARTS_GET_SUCCESS',
  PARROT_PARTS_BUY_POST_FAILURE: 'PARROT_PARTS_BUY_POST_FAILURE',
  PARROT_PARTS_BUY_POST_REQUEST: 'PARROT_PARTS_BUY_POST_REQUEST',
  PARROT_PARTS_BUY_POST_SUCCESS: 'PARROT_PARTS_BUY_POST_SUCCESS',
  PARROT_SELECTED_PARTS_REMOVE_REQUEST: 'PARROT_SELECTED_PARTS_REMOVE_REQUEST',
  PARROT_SELECTED_PARTS_REMOVE_SUCCESS: 'PARROT_SELECTED_PARTS_REMOVE_SUCCESS',
  PARROT_SELECTED_PARTS_REMOVE_FAILURE: 'PARROT_SELECTED_PARTS_REMOVE_FAILURE',
  PARROT_SELECTED_PARTS_UPDATE_FAILURE: 'PARROT_SELECTED_PARTS_UPDATE_FAILURE',
  PARROT_SELECTED_PARTS_UPDATE_REQUEST: 'PARROT_SELECTED_PARTS_UPDATE_REQUEST',
  PARROT_SELECTED_PARTS_UPDATE_SUCCESS: 'PARROT_SELECTED_PARTS_UPDATE_SUCCESS',
  PARROT_PARTS_BY_CATEGORY_LABEL_GET_FAILURE:
    'PARROT_PARTS_BY_CATEGORY_LABEL_GET_FAILURE',
  PARROT_PARTS_BY_CATEGORY_LABEL_GET_REQUEST:
    'PARROT_PARTS_BY_CATEGORY_LABEL_GET_REQUEST',
  PARROT_PARTS_BY_CATEGORY_LABEL_GET_SUCCESS:
    'PARROT_PARTS_BY_CATEGORY_LABEL_GET_SUCCESS',
  PARROT_PARTS_OWNED_GET_FAILURE: 'PARROT_PARTS_OWNED_GET_FAILURE',
  PARROT_PARTS_OWNED_GET_REQUEST: 'PARROT_PARTS_OWNED_GET_REQUEST',
  PARROT_PARTS_OWNED_GET_SUCCESS: 'PARROT_PARTS_OWNED_GET_SUCCESS',
  PARROT_PARTS_CHEAP_GET_REQUEST: 'PARROT_PARTS_CHEAP_GET_REQUEST',
  PARROT_PARTS_CHEAP_GET_SUCCESS: 'PARROT_PARTS_CHEAP_GET_SUCCESS',
  PARROT_PARTS_CHEAP_GET_FAILURE: 'PARROT_PARTS_CHEAP_GET_FAILURE',
  PATCH_USER_REQUEST: 'PATCH_USER_REQUEST',
  PATCH_USER_SUCCESS: 'PATCH_USER_SUCCESS',
  PATCH_USER_FAILURE: 'PATCH_USER_FAILURE',
  PAYMENT_INFO_FAILURE: 'PAYMENT_INFO_FAILURE',
  PAYMENT_INFO_REQUEST: 'PAYMENT_INFO_REQUEST',
  PAYMENT_INFO_SUCCESS: 'PAYMENT_INFO_SUCCESS',
  PAYMENT_BANK_FAILURE: 'PAYMENT_BANK_FAILURE',
  PAYMENT_BANK_REQUEST: 'PAYMENT_BANK_REQUEST',
  PAYMENT_BANK_SUCCESS: 'PAYMENT_BANK_SUCCESS',
  PAYMENT_CANCEL_FAILURE: 'PAYMENT_CANCEL_FAILURE',
  PAYMENT_CANCEL_REQUEST: 'PAYMENT_CANCEL_REQUEST',
  PAYMENT_CANCEL_SUCCESS: 'PAYMENT_CANCEL_SUCCESS',
  PAYMENT_PROVIDERS_GET_REQUEST: 'PAYMENT_PROVIDERS_GET_REQUEST',
  PAYMENT_PROVIDERS_GET_SUCCESS: 'PAYMENT_PROVIDERS_GET_SUCCESS',
  PAYMENT_PROVIDERS_GET_FAILURE: 'PAYMENT_PROVIDERS_GET_FAILURE',
  READ_AND_THINK_BONUS_LEVELS_GET_FAILURE:
    'READ_AND_THINK_BONUS_LEVELS_GET_FAILURE',
  READ_AND_THINK_BONUS_LEVELS_GET_REQUEST:
    'READ_AND_THINK_BONUS_LEVELS_GET_REQUEST',
  READ_AND_THINK_BONUS_LEVELS_GET_SUCCESS:
    'READ_AND_THINK_BONUS_LEVELS_GET_SUCCESS',
  READ_AND_THINK_LEVELS_GET_FAILURE: 'READ_AND_THINK_LEVELS_GET_FAILURE',
  READ_AND_THINK_LEVELS_GET_REQUEST: 'READ_AND_THINK_LEVELS_GET_REQUEST',
  READ_AND_THINK_LEVELS_GET_SUCCESS: 'READ_AND_THINK_LEVELS_GET_SUCCESS',
  READ_AND_THINK_LEVELS_RESET: 'READ_AND_THINK_LEVELS_RESET',
  READ_AND_THINK_COLLECTIONS_GET_FAILURE:
    'READ_AND_THINK_COLLECTIONS_GET_FAILURE',
  READ_AND_THINK_COLLECTIONS_GET_REQUEST:
    'READ_AND_THINK_COLLECTIONS_GET_REQUEST',
  READ_AND_THINK_COLLECTIONS_GET_SUCCESS:
    'READ_AND_THINK_COLLECTIONS_GET_SUCCESS',
  READ_TOGETHER_COLLECTIONS_GET_FAILURE:
    'READ_TOGETHER_COLLECTIONS_GET_FAILURE',
  READ_TOGETHER_COLLECTIONS_GET_REQUEST:
    'READ_TOGETHER_COLLECTIONS_GET_REQUEST',
  READ_TOGETHER_COLLECTIONS_GET_SUCCESS:
    'READ_TOGETHER_COLLECTIONS_GET_SUCCESS',
  READ_TOGETHER_BONUS_LEVELS_GET_FAILURE:
    'READ_TOGETHER_BONUS_LEVELS_GET_FAILURE',
  READ_TOGETHER_BONUS_LEVELS_GET_REQUEST:
    'READ_TOGETHER_BONUS_LEVELS_GET_REQUEST',
  READ_TOGETHER_BONUS_LEVELS_GET_SUCCESS:
    'READ_TOGETHER_BONUS_LEVELS_GET_SUCCESS',
  READ_TOGETHER_LEVELS_GET_FAILURE: 'READ_TOGETHER_LEVELS_GET_FAILURE',
  READ_TOGETHER_LEVELS_GET_REQUEST: 'READ_TOGETHER_LEVELS_GET_REQUEST',
  READ_TOGETHER_LEVELS_GET_SUCCESS: 'READ_TOGETHER_LEVELS_GET_SUCCESS',
  READ_TOGETHER_LEVELS_RESET: 'READ_TOGETHER_LEVELS_RESET',
  REGISTER_CLIENT_FAILURE: 'REGISTER_CLIENT_FAILURE',
  REGISTER_CLIENT_REQUEST: 'REGISTER_CLIENT_REQUEST',
  REGISTER_CLIENT_SUCCESS: 'REGISTER_CLIENT_SUCCESS',
  REPLENISHMENT_COLLECTIONS_GET_FAILURE:
    'REPLENISHMENT_COLLECTIONS_GET_FAILURE',
  REPLENISHMENT_COLLECTIONS_GET_REQUEST:
    'REPLENISHMENT_COLLECTIONS_GET_REQUEST',
  REPLENISHMENT_COLLECTIONS_GET_SUCCESS:
    'REPLENISHMENT_COLLECTIONS_GET_SUCCESS',
  REPLENISHMENT_LEVELS_GET_FAILURE: 'REPLENISHMENT_LEVELS_GET_FAILURE',
  REPLENISHMENT_LEVELS_GET_REQUEST: 'REPLENISHMENT_LEVELS_GET_REQUEST',
  REPLENISHMENT_LEVELS_GET_SUCCESS: 'REPLENISHMENT_LEVELS_GET_SUCCESS',
  REGISTER_SPEECH_THERAPIST_FAILURE: 'REGISTER_SPEECH_THERAPIST_FAILURE',
  REGISTER_SPEECH_THERAPIST_REQUEST: 'REGISTER_SPEECH_THERAPIST_REQUEST',
  REGISTER_SPEECH_THERAPIST_SUCCESS: 'REGISTER_SPEECH_THERAPIST_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESOLVE_USERS_FAILURE: 'RESOLVE_USERS_FAILURE',
  RESOLVE_USERS_REQUEST: 'RESOLVE_USERS_REQUEST',
  RESOLVE_USERS_SUCCESS: 'RESOLVE_USERS_SUCCESS',
  SEND_MOBILE_USER_INVITE_FAILURE: 'SEND_MOBILE_USER_INVITE_FAILURE',
  SEND_MOBILE_USER_INVITE_REQUEST: 'SEND_MOBILE_USER_INVITE_REQUEST',
  SEND_MOBILE_USER_INVITE_SUCCESS: 'SEND_MOBILE_USER_INVITE_SUCCESS',
  SET_TOOLTIP_VISIBILITY: 'SET_TOOLTIP_VISIBILITY',
  PLAYED_VIDEO: 'PLAYED_VIDEO',
  SPEECH_SOUNDS_GET_FAILURE: 'SPEECH_SOUNDS_GET_FAILURE',
  SPEECH_SOUNDS_GET_REQUEST: 'SPEECH_SOUNDS_GET_REQUEST',
  SPEECH_SOUNDS_GET_SUCCESS: 'SPEECH_SOUNDS_GET_SUCCESS',
  SPEECH_SOUND_CREATE_FAILURE: 'SPEECH_SOUND_CREATE_FAILURE',
  SPEECH_SOUND_CREATE_REQUEST: 'SPEECH_SOUND_CREATE_REQUEST',
  SPEECH_SOUND_CREATE_SUCCESS: 'SPEECH_SOUND_CREATE_SUCCESS',
  SPEECH_SOUND_GET_FAILURE: 'SPEECH_SOUND_GET_FAILURE',
  SPEECH_SOUND_GET_REQUEST: 'SPEECH_SOUND_GET_REQUEST',
  SPEECH_SOUND_GET_SUCCESS: 'SPEECH_SOUND_GET_SUCCESS',
  STATE_RESET: 'STATE_RESET',
  STUDENTS_GET_FAILURE: 'STUDENTS_GET_FAILURE',
  STUDENTS_GET_REQUEST: 'STUDENTS_GET_REQUEST',
  STUDENTS_GET_SUCCESS: 'STUDENTS_GET_SUCCESS',
  STUDENT_COLLECTION_UNLOCK_FAILURE: 'STUDENT_COLLECTION_UNLOCK_FAILURE',
  STUDENT_COLLECTION_UNLOCK_REQUEST: 'STUDENT_COLLECTION_UNLOCK_REQUEST',
  STUDENT_COLLECTION_UNLOCK_SUCCESS: 'STUDENT_COLLECTION_UNLOCK_SUCCESS',
  STUDENT_GET_FAILURE: 'STUDENT_GET_FAILURE',
  STUDENT_GET_REQUEST: 'STUDENT_GET_REQUEST',
  STUDENT_GET_SUCCESS: 'STUDENT_GET_SUCCESS',
  STUDENT_UPDATE_FAILURE: 'STUDENT_UPDATE_FAILURE',
  STUDENT_UPDATE_REQUEST: 'STUDENT_UPDATE_REQUEST',
  STUDENT_UPDATE_SUCCESS: 'STUDENT_UPDATE_SUCCESS',
  SUBACTIVITIES_GET_FAILURE: 'SUBACTIVITIES_GET_FAILURE',
  SUBACTIVITIES_GET_REQUEST: 'SUBACTIVITIES_GET_REQUEST',
  SUBACTIVITIES_GET_SUCCESS: 'SUBACTIVITIES_GET_SUCCESS',
  SUBSCRIPTIONS_GET_FAILURE: 'SUBSCRIPTIONS_GET_FAILURE',
  SUBSCRIPTIONS_GET_REQUEST: 'SUBSCRIPTIONS_GET_REQUEST',
  SUBSCRIPTIONS_GET_SUCCESS: 'SUBSCRIPTIONS_GET_SUCCESS',
  SUBSCRIPTION_CANCEL_FAILURE: 'SUBSCRIPTION_CANCEL_FAILURE',
  SUBSCRIPTION_CANCEL_REQUEST: 'SUBSCRIPTION_CANCEL_REQUEST',
  SUBSCRIPTION_CANCEL_SUCCESS: 'SUBSCRIPTION_CANCEL_SUCCESS',
  SUBSCRIPTION_CHECK_FAILURE: 'SUBSCRIPTION_CHECK_FAILURE',
  SUBSCRIPTION_CHECK_REQUEST: 'SUBSCRIPTION_CHECK_REQUEST',
  SUBSCRIPTION_CHECK_SUCCESS: 'SUBSCRIPTION_CHECK_SUCCESS',
  SUBSCRIPTION_SELECT_FAILURE: 'SUBSCRIPTION_SELECT_FAILURE',
  SUBSCRIPTION_SELECT_REQUEST: 'SUBSCRIPTION_SELECT_REQUEST',
  SUBSCRIPTION_SELECT_SUCCESS: 'SUBSCRIPTION_SELECT_SUCCESS',
  SUBSCRIPTION_STATUS_GET_FAILURE: 'SUBSCRIPTION_STATUS_GET_FAILURE',
  SUBSCRIPTION_STATUS_GET_REQUEST: 'SUBSCRIPTION_STATUS_GET_REQUEST',
  SUBSCRIPTION_STATUS_GET_SUCCESS: 'SUBSCRIPTION_STATUS_GET_SUCCESS',
  TREASURE_HUNT_COLLECTIONS_GET_FAILURE:
    'TREASURE_HUNT_COLLECTIONS_GET_FAILURE',
  TREASURE_HUNT_COLLECTIONS_GET_REQUEST:
    'TREASURE_HUNT_COLLECTIONS_GET_REQUEST',
  TREASURE_HUNT_COLLECTIONS_GET_SUCCESS:
    'TREASURE_HUNT_COLLECTIONS_GET_SUCCESS',
  TREASURE_HUNT_GROUPS_GET_FAILURE: 'TREASURE_HUNT_GROUPS_GET_FAILURE',
  TREASURE_HUNT_GROUPS_GET_REQUEST: 'TREASURE_HUNT_GROUPS_GET_REQUEST',
  TREASURE_HUNT_GROUPS_GET_SUCCESS: 'TREASURE_HUNT_GROUPS_GET_SUCCESS',
  TREASURE_HUNT_LEVELS_GET_FAILURE: 'TREASURE_HUNT_LEVELS_GET_FAILURE',
  TREASURE_HUNT_LEVELS_GET_REQUEST: 'TREASURE_HUNT_LEVELS_GET_REQUEST',
  TREASURE_HUNT_LEVELS_GET_SUCCESS: 'TREASURE_HUNT_LEVELS_GET_SUCCESS',
  USER_CHECKPOINT: 'USER_CHECKPOINT',
  USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',
  USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  WALLET_GET_FAILURE: 'WALLET_GET_FAILURE',
  WALLET_GET_REQUEST: 'WALLET_GET_REQUEST',
  WALLET_GET_SUCCESS: 'WALLET_GET_SUCCESS',
  WHEEL_OF_FORTUNE_COLLECTIONS_GET_FAILURE:
    'WHEEL_OF_FORTUNE_COLLECTIONS_GET_FAILURE',
  WHEEL_OF_FORTUNE_COLLECTIONS_GET_REQUEST:
    'WHEEL_OF_FORTUNE_COLLECTIONS_GET_REQUEST',
  WHEEL_OF_FORTUNE_COLLECTIONS_GET_SUCCESS:
    'WHEEL_OF_FORTUNE_COLLECTIONS_GET_SUCCESS',
  WHEEL_OF_FORTUNE_LEVELS_GET_FAILURE: 'WHEEL_OF_FORTUNE_LEVELS_GET_FAILURE',
  WHEEL_OF_FORTUNE_LEVELS_GET_REQUEST: 'WHEEL_OF_FORTUNE_LEVELS_GET_REQUEST',
  WHEEL_OF_FORTUNE_LEVELS_GET_SUCCESS: 'WHEEL_OF_FORTUNE_LEVELS_GET_SUCCESS',
};
