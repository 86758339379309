import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { actions, selectors } from 'src/store';
import { datePicker } from 'src/utils';
import { navigation } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import {
  Badge,
  Navbar,
  Navigation,
  OverlayLoader,
  Page,
  PageLoader,
} from 'src/components/common';

import { Notes, StudentForm } from './components';

const ACTIVE_TAB_ID = 1;

const StudentSettings = ({
  getSpeechSounds,
  getStudentById,
  hasLoaded,
  isSubmitting,
  speechSounds,
  student,
  updateStudentById,
}) => {
  const { studentId } = useParams();
  const messages = useIntlMessages();

  const [activeTabId, setActiveTabId] = useState(ACTIVE_TAB_ID);
  const [dateLength, setDateLength] = useState(0);
  const [key, setKey] = useState(0);

  const tabs = useMemo(
    () => [
      {
        id: navigation.FIRST_TAB_ID,
        label: messages.page.studentSettings.tab.file,
      },
      {
        id: navigation.THIRD_TAB_ID,
        label: messages.page.studentSettings.tab.notes,
      },
    ],
    [messages]
  );

  const speechSoundsLabels = useMemo(
    () =>
      hasLoaded &&
      speechSounds.map((speechSound) => ({
        key: String(speechSound.id),
        value: speechSound.label,
      })),
    [hasLoaded, speechSounds]
  );

  const genders = useMemo(
    () => [
      { key: 'male', value: messages.label.male },
      { key: 'female', value: messages.label.female },
    ],
    [messages]
  );

  const handleNavigationClick = useCallback((tabId) => {
    setActiveTabId(tabId);
  }, []);

  const handleSave = useCallback(
    (data) => {
      const dataCopy = { ...data };

      if (dataCopy.SpeechSoundId === 'speechSound') {
        delete dataCopy.SpeechSoundId;
      }

      if (dataCopy.gender === 'gender') {
        delete dataCopy.gender;
      }

      updateStudentById(student.id, data);
    },
    [student.id, updateStudentById]
  );

  useEffect(() => {
    getStudentById(studentId);
  }, [getStudentById, studentId]);

  useEffect(() => {
    getSpeechSounds();
  }, [getSpeechSounds]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <>
      {isSubmitting && <OverlayLoader />}
      <Page title={student.fullNameForSpeechTherapist}>
        <Navbar.Title
          title={student.fullNameForSpeechTherapist}
          icon={<Badge iconName="student" type="neutral" />}
        />
        <Navigation
          activeTabId={activeTabId}
          tabs={tabs}
          onClick={handleNavigationClick}
        />
        {activeTabId === navigation.FIRST_TAB_ID && (
          <StudentForm
            student={student}
            datePickerOnChange={(e) =>
              setDateLength(datePicker.onChange(e, key, dateLength))
            }
            datePickerKeyDown={(e) =>
              setKey(datePicker.onKeyDown(e, dateLength))
            }
            genders={genders}
            onSubmit={handleSave}
            selectedSpeechSound={
              speechSounds.find(
                (speechSound) => speechSound.id === student.SpeechSoundId
              )?.label
            }
            selectedGender={
              genders.find((gender) => gender.key === student.gender)?.value
            }
            speechSounds={speechSoundsLabels}
          />
        )}
        {activeTabId === navigation.THIRD_TAB_ID && <Notes />}
      </Page>
    </>
  );
};

StudentSettings.propTypes = {
  updateStudentById: PropTypes.func.isRequired,
  getStudentById: PropTypes.func.isRequired,
  getSpeechSounds: PropTypes.func.isRequired,
  speechSounds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ).isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  student: PropTypes.shape({
    SpeechSoundId: PropTypes.number,
    address: PropTypes.string,
    dateOfBirth: PropTypes.string,
    fullNameForSpeechTherapist: PropTypes.string,
    gender: PropTypes.string,
    id: PropTypes.number,
    parentEmail: PropTypes.string,
    parentName: PropTypes.string,
    parentPhoneNumber: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.student.getHasLoaded(state),
  student: selectors.student.getStudent(state),
  isSubmitting: selectors.student.getIsSubmitting(state),
  speechSounds: selectors.speechSounds.getSpeechSoundsData(state),
});

const mapDispatchToProps = {
  ...actions.students,
  ...actions.speechSounds,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(StudentSettings);
