import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const FlashCardsStepOneCollections = ({
  flashCardsStepOneCollections,
  flashCardsStepOneCurrency,
  getCollections,
  hasLoaded,
  pageCount,
  student,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="flash-cards-collections-container--step-one"
      collectionsPath={paths.FLASH_CARDS_STEP_ONE}
      currency={flashCardsStepOneCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={flashCardsStepOneCollections}
      levelsPath={paths.FLASH_CARDS_STEP_ONE_LEVELS}
      pageCount={pageCount}
      student={student}
      title={messages.game.flashCards}
    />
  );
};

FlashCardsStepOneCollections.propTypes = {
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  flashCardsStepOneCollections: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  flashCardsStepOneCurrency: PropTypes.shape({}).isRequired,
  pageCount: PropTypes.number.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.flashCardsStepOneCollections.getHasLoaded(state),
  flashCardsStepOneCurrency: selectors.flashCardsStepOneCollections.getCurrencyData(
    state
  ),
  flashCardsStepOneCollections: selectors.flashCardsStepOneCollections.getCollectionsData(
    state
  ),
  pageCount: selectors.flashCardsStepOneCollections.getPageCount(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.flashCardsStepOne,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(FlashCardsStepOneCollections);
