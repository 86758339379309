import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import {
  Button,
  Form,
  Input,
  LogoTitle,
  PasswordInput,
  LanguageDropdown,
} from 'src/components/common';

import validationSchema from '../../validationSchema';

import './index.scss';

const FormContainer = ({ login }) => {
  const history = useHistory();
  const messages = useIntlMessages();

  const trimValue = useCallback((e) => {
    e.target.value = e.target.value.trim();
  }, []);

  const loginUser = (data) => {
    login(data).then(() => history.push(paths.BASE));
  };

  return (
    <div className="login-form-container">
      {isMobile && <LogoTitle showBack={false} />}
      <p className="login-form-container__title">
        {messages.label.freeRegistration}
      </p>
      <LanguageDropdown />
      <Button
        className="login-form-container__button"
        variant="register"
        label={messages.label.parentFirstRegistration}
        onClick={() => history.push(paths.REGISTER_CLIENT)}
      />
      <Button
        className="login-form-container__button"
        variant="register"
        label={messages.label.therapistFirstRegistration}
        onClick={() => history.push(paths.REGISTER_SPEECH_THERAPIST)}
      />
      <h2 className="login-form-container__line-text">
        <span className="login-form-container__line-text__text">
          {messages.label.haveAccount}
        </span>
      </h2>
      <Form
        submitBtnLabel={messages.button.login}
        onSubmit={loginUser}
        validationSchema={validationSchema}
        formClassName="login-form-container__form"
      >
        <Input
          name="email"
          type="email"
          placeholder={messages.input.placeholder.email}
          className="login-form-container__form__input"
          onChange={trimValue}
        />
        <PasswordInput
          name="password"
          placeholder={messages.input.placeholder.password}
          className="login-form-container__form__input"
        />
      </Form>
      <Button
        className="login-form-container__button--forgot-password"
        onClick={() => history.push(paths.FORGOT_PASSWORD)}
        label={messages.button.forgotPassword}
      />
    </div>
  );
};

FormContainer.propTypes = {
  login: PropTypes.func.isRequired,
};

export default memo(FormContainer);
