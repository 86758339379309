import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { useIntlMessages } from 'src/hooks';
import loader from 'src/videos/loader.gif';

import Localize from '../Localize';
import Image from '../Image';
import Button from '../Button';
import Modal from '../Modal';

const RegistrationProgressDialog = ({ handleContinue, isLoading }) => {
  const messages = useIntlMessages();
  return (
    <Modal show={isLoading}>
      <Modal.Title>
        <Localize id="dialog.registration.processing.title" />
      </Modal.Title>
      <Modal.Description>
        <Localize id="dialog.registration.processing.description" />
      </Modal.Description>
      <div className="modal-container__buttons">
        {isLoading ? (
          <Image src={loader} alt="Loader" />
        ) : (
          <Button
            className="modal-container__buttons"
            onClick={handleContinue}
            label={messages.button.forward}
          />
        )}
      </div>
    </Modal>
  );
};

RegistrationProgressDialog.propTypes = {
  handleContinue: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default memo(RegistrationProgressDialog);
