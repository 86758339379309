import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';

import { useZoom } from 'src/hooks';

import './index.scss';

// Works only on mobile for now
const Zoom = ({
  className,
  children,
  mobileHeight,
  mobileWidth,
  mobileRatio,
  align,
}) => {
  const classNames = useMemo(
    () =>
      cn('level-content', className, {
        [`level-content--${align}`]: !!align,
      }),
    [className, align]
  );
  const { style, ref } = useZoom(mobileWidth, mobileHeight);

  const containerStyle = useMemo(() => ({ height: mobileRatio }), [
    mobileRatio,
  ]);

  return (
    <div
      ref={ref}
      className={classNames}
      style={isMobile ? containerStyle : undefined}
    >
      <div style={isMobile ? style : undefined}>{children}</div>
    </div>
  );
};

Zoom.propTypes = {
  children: PropTypes.node.isRequired,
  mobileRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mobileWidth: PropTypes.number.isRequired,
  mobileHeight: PropTypes.number.isRequired,
  className: PropTypes.string,
  align: PropTypes.oneOf(['center']),
};

Zoom.defaultProps = {
  className: '',
  align: undefined,
  mobileRatio: '100%',
};

export default memo(Zoom);
