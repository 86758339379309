import { useState } from 'react';

const useModalState = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const displayModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  return [isModalVisible, displayModal, hideModal];
};

export default useModalState;
