import React, { memo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import * as Backgrounds from './backgrounds';

import './index.scss';
import Image from '../Image';
import Icon, { icons } from '../Icon';

const images = {
  actor: `${process.env.REACT_APP_AWS_S3_BASE_URL}common/actor.png`,
};

const Badge = React.forwardRef(
  (
    {
      id,
      type,
      size,
      children,
      onClick,
      onMouseEnter,
      img,
      iconName,
      className,
      tooltip,
      count,
    },
    ref
  ) => {
    const Background = Backgrounds[type];
    const classNames = cn('badge', className, size, {
      'badge--no-count': !count,
    });

    let content;

    if (children) {
      content = typeof children === 'string' ? <p>{children}</p> : children;
    } else if (iconName) {
      content = <Icon name={iconName} />;
    } else if (img) {
      content = <Image src={images[img]} alt={img} />;
    }

    return (
      <div
        id={id}
        className={classNames}
        onClick={onClick}
        onKeyPress={onClick}
        onMouseEnter={onMouseEnter}
        role="button"
        tabIndex={0}
        ref={ref}
        data-tip={tooltip}
        data-for={tooltip}
      >
        <Background />
        <span className="badge__content">{content}</span>
        {!!count && (
          <div className="notification-count">
            <Backgrounds.notification />
            <p>{count}</p>
          </div>
        )}
      </div>
    );
  }
);

Badge.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  size: PropTypes.oneOf([
    'extra-small',
    'small',
    'medium',
    'normal',
    'large',
    'regular',
  ]),
  img: PropTypes.oneOf(Object.keys(images)),
  iconName: PropTypes.oneOf(Object.keys(icons)),
  type: PropTypes.oneOf([
    'danger',
    'neutral',
    'primary',
    'secondary',
    'success',
    'purple',
    'blue',
    'gold',
    'orange',
    'green',
    'red',
    'notification',
  ]),
  tooltip: PropTypes.string,
  count: PropTypes.node,
};

Badge.defaultProps = {
  id: null,
  className: '',
  children: null,
  onClick: () => {},
  onMouseEnter: () => {},
  size: 'regular',
  type: 'primary',
  img: null,
  iconName: null,
  tooltip: null,
  count: null,
};

export default memo(Badge);
