import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { useIntlMessages } from 'src/hooks';

import loader from 'src/videos/loader.gif';

import { Image, Video } from 'src/components/common';

import './index.scss';

const SpeechSoundInfo = ({
  isLoading,
  selectedSpeechSoundId,
  speechSound,
  autoPlay,
}) => {
  const messages = useIntlMessages();

  return (
    <div className="speech-sounds-info">
      {isLoading ? (
        <div className="speech-sounds-info__loader">
          <Image src={loader} alt="Loader" />
        </div>
      ) : (
        <>
          <p className="speech-sounds-info__title">
            {selectedSpeechSoundId
              ? messages({ sound: speechSound.label }).label.sound
              : messages.page.soundSelection.description}
          </p>
          <Video
            autoPlay={autoPlay}
            width="100%"
            className="speech-sounds-info__video"
            src={
              selectedSpeechSoundId
                ? speechSound.videoUrl
                : messages.url.video.speechSoundSelection
            }
          />
          <p
            className="speech-sounds-info__description"
            dangerouslySetInnerHTML={{
              __html: selectedSpeechSoundId
                ? speechSound.introDescription
                : messages.page.soundSelection.instructions,
            }}
          />
        </>
      )}
    </div>
  );
};

SpeechSoundInfo.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  autoPlay: PropTypes.bool,
  selectedSpeechSoundId: PropTypes.number,
  speechSound: PropTypes.shape({
    introDescription: PropTypes.string,
    label: PropTypes.string,
    videoUrl: PropTypes.string,
  }).isRequired,
  student: PropTypes.shape({}).isRequired,
};

SpeechSoundInfo.defaultProps = {
  selectedSpeechSoundId: null,
  autoPlay: false,
};

const mapStateToProps = (state, props) => ({
  hasLoaded: selectors.speechSounds.getHasLoaded(state),
  speechSound: selectors.speechSounds.getSpeechSoundDataById(
    state,
    props.selectedSpeechSoundId
  ),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.students,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(SpeechSoundInfo);
