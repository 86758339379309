import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import LevelImage from '../LevelImage';

import './index.scss';

const LevelCard = ({
  level,
  cleanedTermIds,
  selectedTermId,
  shake,
  handleSelect,
}) => {
  const isCleaned = useMemo(() => cleanedTermIds.includes(level.id), [
    cleanedTermIds,
    level,
  ]);

  const levelCardContainerClassNames = useMemo(
    () =>
      cn(`read-together-bonus-level-card-container--${level.scenePosition}`),
    [level.scenePosition]
  );

  return (
    <div className={levelCardContainerClassNames}>
      <LevelImage
        level={level}
        isHidden={isCleaned}
        shake={selectedTermId === level.id && level.isDistractor && shake}
        onClick={handleSelect}
      />
    </div>
  );
};

LevelCard.propTypes = {
  level: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isDistractor: PropTypes.bool,
    scenePosition: PropTypes.string.isRequired,
  }).isRequired,
  cleanedTermIds: PropTypes.arrayOf(PropTypes.number.isRequired),
  shake: PropTypes.bool.isRequired,
  selectedTermId: PropTypes.number,
  handleSelect: PropTypes.func.isRequired,
};

LevelCard.defaultProps = {
  cleanedTermIds: [],
  selectedTermId: null,
};

export default memo(LevelCard);
