import { actions, paths } from 'src/constants';

export default {
  getSubactivitiesByActivityId: (activityId) => ({
    [actions.API_CALL]: {
      types: [
        actions.SUBACTIVITIES_GET_REQUEST,
        actions.SUBACTIVITIES_GET_SUCCESS,
        actions.SUBACTIVITIES_GET_FAILURE,
      ],
      promise: (client) =>
        client.get(
          paths.build(paths.api.SUBACTIVITIES_ACTIVITY_ID, activityId)
        ),
    },
  }),
};
