import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { notifications } from 'src/constants';
import { subscriptions as subscriptionsUtil } from 'src/utils';
import { useIntlMessages } from 'src/hooks';

import lock from 'src/images/lock.svg';

import Image from '../Image';
import Card from '../Card';
import FeedbackComponent from '../FeedbackComponent';

import './index.scss';

const ContentContainer = ({
  allowedSubscriptionKeys,
  children,
  className,
  isAllowed,
  isFeedbackPositive,
  isLocked,
  onClick,
  showFeedback,
  showNotification,
  subscriptions,
  variant,
}) => {
  const messages = useIntlMessages();

  const contentContainerClassNames = useMemo(
    () =>
      classNames('content-container', className, {
        'content-container--locked': isLocked && isAllowed,
        [`content-container--${variant}`]: !!variant,
      }),
    [className, isAllowed, isLocked, variant]
  );

  const handleOnClick = () => {
    if (!isAllowed) {
      showNotification(
        subscriptionsUtil.getNotAllowedMessage(
          subscriptions.filter((sub) =>
            allowedSubscriptionKeys.includes(sub.key)
          ),
          messages
        ),
        notifications.type.INFO,
        notifications.duration.SHORT
      );
      return;
    }

    if (isLocked || !onClick) {
      return;
    }

    onClick();
  };

  return (
    <Card
      className={contentContainerClassNames}
      onClick={handleOnClick}
      onKeyPress={handleOnClick}
      role="button"
      tabIndex={0}
      disabled={!isAllowed}
    >
      {(isLocked || !isAllowed) && (
        <div className="content-container__lock-container">
          {isLocked && isAllowed && (
            <Image
              className="content-container__lock-container__lock"
              src={lock}
              height={180}
              width={180}
              alt="lock"
            />
          )}
        </div>
      )}
      {showFeedback && (
        <FeedbackComponent
          isFeedbackPositive={isFeedbackPositive}
          hideText
          collapsed
        />
      )}
      {!isLocked && children}
    </Card>
  );
};

ContentContainer.propTypes = {
  allowedSubscriptionKeys: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  className: PropTypes.string,
  isAllowed: PropTypes.bool,
  isFeedbackPositive: PropTypes.bool,
  isLocked: PropTypes.bool,
  onClick: PropTypes.func,
  showNotification: PropTypes.func.isRequired,
  subscriptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showFeedback: PropTypes.bool,
  variant: PropTypes.oneOf(['small', 'vertical']),
};

ContentContainer.defaultProps = {
  children: null,
  variant: null,
  allowedSubscriptionKeys: [],
  className: '',
  isAllowed: true,
  isFeedbackPositive: false,
  isLocked: false,
  onClick: null,
  showFeedback: null,
};

const mapStateToProps = (state) => ({
  subscriptions: selectors.subscriptions.getSubscriptions(state),
});

const mapDispatchToProps = {
  ...actions.notifications,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ContentContainer);
