import cn from 'classnames';
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Page = ({ children, className, noNavigation, style }) => {
  const pageClassName = cn(
    'page',
    { 'page--no-navigation': noNavigation },
    className
  );

  return (
    <div className={pageClassName} style={style}>
      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  noNavigation: PropTypes.bool,
  style: PropTypes.shape({}),
};

Page.defaultProps = {
  className: '',
  noNavigation: false,
  style: undefined,
};

export default memo(Page);
