import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const FindWordsStepTwoExtraCollections = ({
  findWordsStepTwoExtraCollections,
  findWordsStepTwoExtraCurrency,
  getCollections,
  hasLoaded,
  pageCount,
  student,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="find-words-collections-container--step-two-extra"
      collectionsPath={paths.FIND_WORDS_STEP_TWO_EXTRA}
      currency={findWordsStepTwoExtraCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={findWordsStepTwoExtraCollections}
      levelsPath={paths.FIND_WORDS_STEP_TWO_EXTRA_LEVELS}
      pageCount={pageCount}
      student={student}
      title={messages.game.findWords}
    />
  );
};

FindWordsStepTwoExtraCollections.propTypes = {
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  findWordsStepTwoExtraCollections: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  findWordsStepTwoExtraCurrency: PropTypes.shape({}).isRequired,
  pageCount: PropTypes.number.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.findWordsStepTwoExtraCollections.getHasLoaded(state),
  findWordsStepTwoExtraCurrency: selectors.findWordsStepTwoExtraCollections.getCurrencyData(
    state
  ),
  findWordsStepTwoExtraCollections: selectors.findWordsStepTwoExtraCollections.getCollectionsData(
    state
  ),
  pageCount: selectors.findWordsStepTwoExtraCollections.getPageCount(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.findWordsStepTwoExtra,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(FindWordsStepTwoExtraCollections);
