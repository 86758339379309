import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { actions, selectors } from 'src/store';
import { subscriptions } from 'src/utils';
import { notifications } from 'src/constants';
import { useIntlMessages, useModalState, useQuery } from 'src/hooks';

import {
  Button,
  InfoField,
  Localize,
  Modal,
  OverlayLoader,
  Page,
  PageLoader,
  Parrot,
  Title,
} from 'src/components/common';

import CouponForm from './components/CouponForm';

import './index.scss';

const CouponSettings = ({
  activateCoupon,
  couponData,
  getStatus,
  hasLoaded,
  isSubmitting,
  showNotification,
  user,
}) => {
  const [isModalVisible, displayModal, hideModal] = useModalState();
  const messages = useIntlMessages();
  const query = useQuery();

  const modalDisplayed = useRef();

  const couponCode = query.get('code');

  const handleCouponFormSubmit = useCallback(
    (data) => {
      activateCoupon(data).then(() =>
        showNotification(
          messages.notification.couponActivatedSuccessfully,
          notifications.type.SUCCESS,
          notifications.duration.SHORT
        )
      );
    },
    [activateCoupon, messages, showNotification]
  );

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  useEffect(() => {
    if (couponCode && !modalDisplayed.current) {
      modalDisplayed.current = true;
      displayModal();
    }
  }, [couponCode, displayModal, query]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <Page containerClassName="coupon-settings-container">
      <Page.Header>
        <Title text={messages.page.coupon.title} />
      </Page.Header>

      <Modal
        className="payment-successful-modal"
        show={isModalVisible}
        onClose={hideModal}
        onOutsideClick={hideModal}
        style={{ 'user-select': 'unset' }}
      >
        <Modal.Title>
          <Localize id="modal.paymentSuccessful.title" />
        </Modal.Title>
        <Parrot centered height={isMobile ? '200px' : '400px'} />
        <Modal.Description>
          {
            messages({
              code: couponCode,
            }).modal.paymentSuccessful.description
          }
        </Modal.Description>
        <Button
          className="button-container__button"
          variant="register"
          label={messages.button.ok}
          onClick={hideModal}
        />
      </Modal>

      <Page.Body>
        {isSubmitting && <OverlayLoader />}
        {couponData && !couponData?.isSubscriptionExpired ? (
          <>
            <InfoField
              label={messages.label.yourCoupon}
              value={couponData.code}
              valueClassName="coupon-settings-container--highlighted"
            />
            <InfoField
              label={messages.label.status}
              value={messages.label.couponActivated}
            />
          </>
        ) : (
          <CouponForm
            initialValues={couponCode ? ['code', couponCode] : undefined}
            onSubmit={handleCouponFormSubmit}
            disabled={!subscriptions.getHasSubscriptionExpired(user)}
          />
        )}
      </Page.Body>
    </Page>
  );
};

CouponSettings.propTypes = {
  activateCoupon: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  couponData: PropTypes.shape({
    isActive: PropTypes.bool,
    isReusable: PropTypes.bool,
    code: PropTypes.string,
    isSubscriptionExpired: PropTypes.bool,
  }).isRequired,
  showNotification: PropTypes.func.isRequired,
  user: PropTypes.shape({ subscriptionExpirationDate: PropTypes.string })
    .isRequired,
};

const mapStateToProps = (state) => ({
  couponData: selectors.coupons.getCouponData(state),
  hasLoaded: selectors.coupons.getHasLoaded(state),
  isSubmitting: selectors.coupons.getIsSubmitting(state),
  user: selectors.authentication.getUser(state),
});

const mapDispatchToProps = {
  ...actions.coupons,
  ...actions.notifications,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(CouponSettings);
