import { actions } from 'src/constants';

const initialState = {
  data: {},
  hasLoaded: false,
  isLoading: false,
  isSubmitting: false,
};

const actionMap = {
  [actions.PAYMENT_INFO_REQUEST]: (state) => ({
    ...state,
    data: initialState.data,
    hasLoaded: false,
    isLoading: true,
  }),
  [actions.PAYMENT_INFO_SUCCESS]: (state, { result }) => ({
    ...state,
    data: result.data,
    hasLoaded: true,
    isLoading: false,
  }),
  [actions.PAYMENT_INFO_FAILURE]: (state) => ({
    ...state,
    hasLoaded: true,
    isLoading: false,
  }),

  [actions.PAYMENT_BANK_REQUEST]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [actions.PAYMENT_BANK_SUCCESS]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
  [actions.PAYMENT_BANK_FAILURE]: (state) => ({
    ...state,
    isSubmitting: false,
  }),

  [actions.PAYMENT_CANCEL_REQUEST]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [actions.PAYMENT_CANCEL_SUCCESS]: (state) => ({
    ...state,
    isSubmitting: false,
    paymentCanceled: true,
  }),
  [actions.PAYMENT_CANCEL_FAILURE]: (state) => ({
    ...state,
    isSubmitting: false,
    paymentCanceled: false,
  }),

  [actions.PAYMENT_PROVIDERS_GET_REQUEST]: (state) => ({
    ...state,
    data: initialState.data,
    hasLoaded: false,
    isLoading: true,
  }),
  [actions.PAYMENT_PROVIDERS_GET_SUCCESS]: (state, { result }) => ({
    ...state,
    data: Object.entries(result.data)
      .filter(([, paymentMethods]) => paymentMethods)
      .map(([providerKey, paymentMethods]) =>
        paymentMethods?.map((paymentMethod) => ({
          ...paymentMethod,
          providerKey,
        }))
      )
      .flat()
      .sort(
        (previousValue, currentValue) =>
          previousValue.orderNumber - currentValue.orderNumber
      ),
    hasLoaded: true,
    isLoading: false,
  }),
  [actions.PAYMENT_PROVIDERS_GET_FAILURE]: (state) => ({
    ...state,
    hasLoaded: true,
    isLoading: false,
  }),
};

export default (state = initialState, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
