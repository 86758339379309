import React from 'react';
import _endsWith from 'lodash/endsWith';

import { actions } from 'src/store';
import { notifications, paths } from 'src/constants';
import { Localize } from 'src/components/common';

export default () => (store) => (next) => (action) => {
  if (
    window.location.pathname !== paths.BASE &&
    _endsWith(action.type, '_FAILURE') &&
    action.error?.response?.status &&
    ![401, 402, 403].includes(action.error.response.status)
  ) {
    // Handle generic actions
    store.dispatch(
      actions.notifications.showNotification(
        action.error.response?.data?.message ? (
          <Localize id={action.error.response?.data?.message} />
        ) : (
          <Localize id="error.feedback.somethingWentWrong" />
        ),
        notifications.type.ERROR,
        notifications.duration.LONG
      )
    );
  }

  return next(action);
};
