import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths, checkpoints } from 'src/constants';
import { useIntlMessages, useCheckpoint } from 'src/hooks';

import { Container } from './components';

const ReplenishmentCollections = ({
  getCollections,
  hasLoaded,
  pageCount,
  replenishmentCollections,
  replenishmentCurrency,
  student,
}) => {
  const messages = useIntlMessages();

  useCheckpoint(checkpoints.FIRST_TIME_ON_REPLENISHMENT, hasLoaded);

  return (
    <Container
      collectionsPath={paths.REPLENISHMENT}
      currency={replenishmentCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={replenishmentCollections}
      levelsPath={paths.REPLENISHMENT_LEVELS}
      pageCount={pageCount}
      student={student}
      title={messages.game.replenishment}
    />
  );
};

ReplenishmentCollections.propTypes = {
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  replenishmentCollections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  replenishmentCurrency: PropTypes.shape({}).isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.replenishmentCollections.getHasLoaded(state),
  replenishmentCurrency: selectors.replenishmentCollections.getCurrencyData(
    state
  ),
  replenishmentCollections: selectors.replenishmentCollections.getCollectionsData(
    state
  ),
  pageCount: selectors.replenishmentCollections.getPageCount(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.replenishment,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ReplenishmentCollections);
