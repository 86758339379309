import { object, string, ref } from 'yup';

export default object().shape({
  password: string()
    .required('Lozinka je obvezna')
    .min(8, 'Lozinka mora imati 8 znakova minimalno')
    .max(20, 'Lozinka mora imati 20 znakova maksimalno'),
  repeatPassword: string().oneOf(
    [ref('password'), null],
    'Lozinke se moraju podudarati'
  ),
});
