import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useIntlMessages } from 'src/hooks';

import { Badge, Button, Image, Modal } from 'src/components/common';
import paths from 'src/constants/paths';

import './index.scss';

const InsufficientFundsModal = ({ selectedItem, onCancel }) => {
  const messages = useIntlMessages();
  const history = useHistory();

  return (
    <div id="cannot-buy-modal" className="cannot-buy-modal">
      <Modal
        className="cannot-buy-modal__modal"
        getRootDOMNode={() => document.getElementById('cannot-buy-modal')}
        show
      >
        <Badge
          className="cannot-buy-modal__badge"
          iconName="cart"
          type="danger"
        />
        <Modal.Title className="cannot-buy-modal__title">
          {messages.page.shop.cannotBuyDialog.title}
        </Modal.Title>
        <Modal.Description className="cannot-buy-modal__description">
          {messages.page.shop.cannotBuyDialog.description}
        </Modal.Description>
        <Image src={selectedItem.thumbnailUrl} alt={selectedItem.label} />
        <div className="cannot-buy-modal__costs">
          {selectedItem.ParrotPartCostsCurrencies.map((cost) => (
            <div
              key={cost.currency.key}
              className="cannot-buy-modal__costs__display"
            >
              <Image
                className="cannot-buy-modal__costs__display__currency-img"
                alt={cost.currency.key}
                src={cost.currency.imageUrl}
              />
              <div className="cannot-buy-modal__costs__display__amount">
                {cost.amount}
              </div>
            </div>
          ))}
        </div>
        <div className="cannot-buy-modal__buttons">
          <Button
            className="cannot-buy-modal__buttons__btn"
            onClick={() => history.push(paths.MAP)}
          >
            {messages.page.shop.cannotBuyDialog.practiceButton}
          </Button>
          <Button
            className="cannot-buy-modal__buttons__btn"
            variant="tertiary"
            onClick={onCancel}
          >
            {messages.page.shop.cannotBuyDialog.cancelButton}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

InsufficientFundsModal.propTypes = {
  selectedItem: PropTypes.shape({
    label: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    ParrotPartCostsCurrencies: PropTypes.arrayOf(PropTypes.shape({}))
      .isRequired,
  }),
  onCancel: PropTypes.func.isRequired,
};

InsufficientFundsModal.defaultProps = {
  selectedItem: null,
};

export default InsufficientFundsModal;
