import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ContentContainer, Video } from 'src/components/common';

import './index.scss';
import { isMobile } from 'react-device-detect';

const LevelCard = ({ level, handleVideoEnded }) => {
  return (
    <div className="articulation-gymnastics-level-card-container">
      <ContentContainer className="articulation-gymnastics-level-card-container__card">
        {level.title && (
          <h1 className="articulation-gymnastics-level-card-container__card__title">
            {level.title}
          </h1>
        )}
        <Video
          autoPlay
          onEnded={handleVideoEnded}
          className="articulation-gymnastics-level-card-container__card__video"
          src={level.videoUrl}
          height={isMobile ? 'auto' : '70%'}
          width={isMobile ? 'auto' : undefined}
        />
        {level.description && (
          <p className="articulation-gymnastics-level-card-container__card__description">
            {level.description}
          </p>
        )}
      </ContentContainer>
    </div>
  );
};

LevelCard.propTypes = {
  level: PropTypes.shape({
    videoUrl: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  handleVideoEnded: PropTypes.func.isRequired,
};

export default memo(LevelCard);
