import { actions } from 'src/constants';

const initialState = {
  data: [],
  hasLoaded: false,
  isLoading: false,
  isSubmitting: false,
};

const actionMap = {
  [actions.SPEECH_SOUNDS_GET_REQUEST]: (state) => ({
    ...state,
    data: initialState.data,
    hasLoaded: false,
    isLoading: true,
  }),
  [actions.SPEECH_SOUNDS_GET_SUCCESS]: (state, { result }) => ({
    ...state,
    data: result.data,
    hasLoaded: true,
    isLoading: false,
  }),
  [actions.SPEECH_SOUNDS_GET_FAILURE]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: false,
  }),

  [actions.SPEECH_SOUND_GET_REQUEST]: (state) => ({
    ...state,
    data: initialState.data,
    hasLoaded: false,
    isLoading: true,
  }),
  [actions.SPEECH_SOUND_GET_SUCCESS]: (state, { result }) => ({
    ...state,
    data: [result.data],
    hasLoaded: true,
    isLoading: false,
  }),
  [actions.SPEECH_SOUND_GET_FAILURE]: (state) => ({
    ...state,
    hasLoaded: false,
    isLoading: false,
  }),

  [actions.SPEECH_SOUND_CREATE_REQUEST]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [actions.SPEECH_SOUND_CREATE_SUCCESS]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
  [actions.SPEECH_SOUND_CREATE_FAILURE]: (state) => ({
    ...state,
    isSubmitting: false,
  }),
};

export default (state = initialState, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
