import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const DiscoverStepTwoCollections = ({
  discoverStepTwoCollections,
  discoverStepTwoCurrency,
  getCollections,
  hasLoaded,
  student,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="discover-collections-container--step-two"
      currency={discoverStepTwoCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={discoverStepTwoCollections}
      levelsPath={paths.DISCOVER_STEP_TWO_LEVELS}
      student={student}
      title={messages.game.discover}
    />
  );
};

DiscoverStepTwoCollections.propTypes = {
  discoverStepTwoCollections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  discoverStepTwoCurrency: PropTypes.shape({}).isRequired,
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.discoverStepTwoCollections.getHasLoaded(state),
  discoverStepTwoCollections: selectors.discoverStepTwoCollections.getCollectionsData(
    state
  ),
  discoverStepTwoCurrency: selectors.discoverStepTwoCollections.getCurrencyData(
    state
  ),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.discoverStepTwo,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(DiscoverStepTwoCollections);
