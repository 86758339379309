import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.activityGroups.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getActivityGroupsData = createSelector(
  (state) => state.activityGroups.data,
  (activityGroupsData) => activityGroupsData
);

export default {
  getHasLoaded,
  getActivityGroupsData,
};
