import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import _map from 'lodash/map';

import { actions, selectors } from 'src/store';
import { player, shop } from 'src/utils';
import { useQuery, useIntlMessages } from 'src/hooks';
import config from 'src/config.json';

import {
  ArrowPagination,
  Page,
  Parrot,
  Wallet,
  Zoom,
  Navbar,
} from 'src/components/common';

import {
  ConfirmBuyModal,
  InsufficientFundsModal,
  ShopCategoriesMenu,
  ShopItem,
} from './components';

import './index.scss';

const INITIAL_PAGE = 1;
const MOBILE_PAGE_SIZE = 6;
const DESKTOP_PAGE_SIZE = 9;

const Shop = ({
  getOwnedParts,
  getPartsByCategoryLabel,
  pageCount,
  parrotParts,
  updateStudentById,
  buyParrotPart,
  getStudentSelectedParts,
  updateStudentSelectedParts,
  removeStudentSelectedParts,
  walletData,
  hasActiveSubscription,
  setShowTrialEndedModal,
}) => {
  const query = useQuery();
  const messages = useIntlMessages();

  const category = query.get('category') || 'hats';

  const [pageNumber, setPageNumber] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showConfirmBuyDialog, setShowConfirmBuyDialog] = useState(false);

  const pageSize = isMobile ? MOBILE_PAGE_SIZE : DESKTOP_PAGE_SIZE;

  const fetchItems = useCallback(() => {
    if (category === 'wardrobe') {
      getOwnedParts(pageNumber, pageSize);
    } else {
      getPartsByCategoryLabel(category, pageNumber, pageSize);
    }
  }, [category, getOwnedParts, getPartsByCategoryLabel, pageNumber, pageSize]);

  const handleVideoEnded = useCallback(() => {
    updateStudentById(player.getSelectedStudentId(), {
      hasPassedShopEducation: true,
    });
  }, [updateStudentById]);

  const handleShopItemClick = useCallback(
    (item) => {
      if (!item.isBought) {
        return setSelectedItem(item);
      }

      return updateStudentSelectedParts(item.id).then(() => {
        // method for updating everything once user dresses something
        // @TODO should be done in redux, not via API calls
        setSelectedItem(item);
        fetchItems();
        getStudentSelectedParts();
      });
    },
    [fetchItems, getStudentSelectedParts, updateStudentSelectedParts]
  );

  const handleShopItemCancelClick = useCallback((event) => {
    event.stopPropagation();
    setSelectedItem(null);
  }, []);

  const handleShopItemBuyClick = useCallback(
    (event) => {
      event.stopPropagation();
      buyParrotPart(selectedItem.id).then(() => {
        setShowConfirmBuyDialog(false);
        setSelectedItem(null);
      });
    },
    [buyParrotPart, selectedItem]
  );

  const handleShopItemUndress = useCallback(
    (event, itemId) => {
      event.stopPropagation();
      removeStudentSelectedParts(itemId).then(() => {
        // method for updating everything once user dresses something
        // @TODO should be done in redux, not via API calls
        setSelectedItem(null);
        fetchItems();
        getStudentSelectedParts();
      });
    },
    [fetchItems, getStudentSelectedParts, removeStudentSelectedParts]
  );

  const handleShowBuyDialog = useCallback((event) => {
    event.stopPropagation();
    setShowConfirmBuyDialog(true);
  }, []);

  const handleNextPage = () => {
    setPageNumber((number) => number + 1);
  };

  const handlePrevPage = () => {
    setPageNumber((number) => number - 1);
  };

  const isItemBuyable = useMemo(() => {
    if (!selectedItem) {
      return false;
    }

    return shop.hasEnoughFunds(selectedItem, walletData);
  }, [selectedItem, walletData]);

  const shopDressedItems = useMemo(
    () =>
      selectedItem
        ? {
            [selectedItem.ParrotPartType.label]: selectedItem.canvasImageUrl,
          }
        : {},
    [selectedItem]
  );

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  useEffect(() => {
    setPageNumber(INITIAL_PAGE);
    setSelectedItem(null);
  }, [category]);

  const createSafeShopAction = (cb) => (...args) => {
    if (!hasActiveSubscription) {
      setShowTrialEndedModal(true);
      return;
    }
    cb(...args);
  };
  const content = (
    <Page className="shop-page">
      <Navbar.Actions>
        <Navbar.EducationAction
          videos={config.videos.shop}
          handleVideoEnded={handleVideoEnded}
        />
      </Navbar.Actions>
      <Zoom mobileWidth={320} mobileHeight={565}>
        <Page.Section className="shop-page__section">
          <div className="shop-page__container">
            {showConfirmBuyDialog && isItemBuyable && (
              <ConfirmBuyModal
                onBuy={handleShopItemBuyClick}
                onCancel={() => setShowConfirmBuyDialog(false)}
                selectedItem={selectedItem}
              />
            )}
            {showConfirmBuyDialog && !isItemBuyable && (
              <InsufficientFundsModal
                onBuy={handleShopItemBuyClick}
                onCancel={() => setShowConfirmBuyDialog(false)}
                selectedItem={selectedItem}
              />
            )}
            <div className="shop-page__container__header">
              {/* @TODO Create and use Tab component here */}
              <ShopCategoriesMenu activeCategory={category} />
            </div>
            <div className="shop-page__container__body">
              <ArrowPagination
                activePage={pageNumber}
                pageCount={pageCount}
                next={handleNextPage}
                prev={handlePrevPage}
              >
                <div className="shop-page__container__body__items">
                  {Object.keys(parrotParts).length > 0 ? (
                    _map(parrotParts, (item) => (
                      <ShopItem
                        costs={item.ParrotPartCostsCurrencies}
                        isBought={item.isBought}
                        isSelected={selectedItem?.id === item.id}
                        key={item.id}
                        label={item.label}
                        onBuy={createSafeShopAction(handleShowBuyDialog)}
                        onCancel={createSafeShopAction(
                          handleShopItemCancelClick
                        )}
                        onClick={createSafeShopAction(() =>
                          handleShopItemClick(item)
                        )}
                        onUndress={createSafeShopAction((event) =>
                          handleShopItemUndress(event, item.id)
                        )}
                        thumbnailUrl={item.thumbnailUrl}
                        isDressed={item.isDressed}
                      />
                    ))
                  ) : (
                    <p>
                      {category === 'wardrobe' &&
                        messages.page.shop.wardrobe.placeholder}
                    </p>
                  )}
                </div>
              </ArrowPagination>
            </div>
            <div className="shop-page__container__footer">
              <Wallet />
            </div>
          </div>
          <Parrot
            className="shop-page__parrot"
            height={isMobile ? '45%' : '870px'}
            shopDressedParrotParts={shopDressedItems}
          />
        </Page.Section>
      </Zoom>
    </Page>
  );

  return content;
};

Shop.propTypes = {
  parrotParts: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  buyParrotPart: PropTypes.func.isRequired,
  updateStudentSelectedParts: PropTypes.func.isRequired,
  removeStudentSelectedParts: PropTypes.func.isRequired,
  getOwnedParts: PropTypes.func.isRequired,
  getPartsByCategoryLabel: PropTypes.func.isRequired,
  getStudentSelectedParts: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  student: PropTypes.shape({
    hasPassedShopEducation: PropTypes.bool.isRequired,
  }).isRequired,
  updateStudentById: PropTypes.func.isRequired,
  walletData: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.shape({ imageUrl: PropTypes.string.isRequired }),
    })
  ).isRequired,
};

const mapStateToProps = (state) => ({
  pageCount: selectors.parrot.getPartsPageCount(state),
  parrotParts: selectors.parrot.getParrotParts(state),
  student: selectors.authentication.getStudent(state),
  walletData: selectors.wallet.getData(state),
  hasActiveSubscription: selectors.subscriptionStatus.getHasActiveSubscription(
    state
  ),
});

const mapDispatchToProps = {
  ...actions.parrot,
  ...actions.students,
  ...actions.wallet,
  ...actions.subscriptions,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(Shop);
