import PropTypes from 'prop-types';
import React, { memo } from 'react';
import cn from 'classnames';

import './index.scss';

const Section = ({ children, className, vertical, as }) => {
  const classNames = cn(className, 'page-section', {
    'page-section--vertical': vertical,
  });

  const Component = as;

  return <Component className={classNames}>{children}</Component>;
};

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  vertical: PropTypes.bool,
  as: PropTypes.elementType,
};

Section.defaultProps = {
  children: undefined,
  className: '',
  vertical: false,
  as: 'div',
};

export default memo(Section);
