export default {
  FIRST_TIME_ON_GOLD: 'firstTimeOnGold',
  FIRST_TIME_ON_ORANGE: 'firstTimeOnOrange',
  FIRST_TIME_ON_ORANGE_SPECIAL: 'firstTimeOnOrangeSpecial',
  FIRST_TIME_ON_GREEN: 'firstTimeOnGreen',
  FIRST_TIME_ON_GREEN_SPECIAL: 'firstTimeOnGreenSpecial',
  FIRST_TIME_ON_PURPLE: 'firstTimeOnPurple',
  FIRST_TIME_ON_PURPLE_SPECIAL: 'firstTimeOnPurpleSpecial',
  FIRST_TIME_ON_RED: 'firstTimeOnRed',
  FIRST_TIME_ON_BLUE: 'firstTimeOnBlue',
  SECOND_TIME_ON_MAP: 'secondTimeOnMap',
  FIRST_TIME_ON_ARTICULATION_GYMNASTICS: 'firstTimeOnArticulationGymnastics',
  FIRST_TIME_ON_TREASURE_HUNT: 'firstTimeOnTreasureHunt',
  FIRST_TIME_ON_PAINTING: 'firstTimeOnPainting',
  FIRST_TIME_ON_CLIMB: 'firstTimeOnClimb',
  FIRST_TIME_ON_DISCOVER: 'firstTimeOnDiscover',
  FIRST_TIME_ON_FLASH_CARDS: 'firstTimeOnFlashCards',
  FIRST_TIME_ON_FIND_WORDS: 'firstTimeOnFindWords',
  FIRST_TIME_ON_MEMORY: 'firstTimeOnMemory',
  FIRST_TIME_ON_WHEEL_OF_FORTUNE: 'firstTimeOnwheelOfFortune',
  FIRST_TIME_ON_REPLENISHMENT: 'firstTimeOnReplenishment',
  FIRST_TIME_ON_READ_TOGETHER: 'firstTimeOnReadTogether',
  FIRST_TIME_ON_READ_AND_THINK: 'firstTimeOnReadAndThink',
  FIRST_TIME_ON_LOGATOMS: 'firstTimeOnLogatoms',
  FIRST_TIME_ON_WORDS: 'firstTimeOnWords',
  FIRST_TIME_ON_MAP: 'firstTimeOnMap',
  FIRST_TIME_OVER_20_POINTS: 'firstTimeOver20Points',
  FIRST_TIME_CAN_BUY: 'firstTimeCanBuy',
};
