/* eslint-disable */
export default {
  addStudent: require('@svgr/webpack?-svgo!!./add-student.svg').ReactComponent,
  'arrow-right': require('@svgr/webpack?-svgo!!./arrow-right.svg')
    .ReactComponent,
  back: require('@svgr/webpack?-svgo!!./back.svg').ReactComponent,
  card: require('@svgr/webpack?-svgo!!./card.svg').ReactComponent,
  cart: require('@svgr/webpack?-svgo!!./cart.svg').ReactComponent,
  close: require('@svgr/webpack?-svgo!!./close.svg').ReactComponent,
  delete: require('@svgr/webpack?-svgo!!./delete.svg').ReactComponent,
  edit: require('@svgr/webpack?-svgo!!./edit.svg').ReactComponent,
  eye: require('@svgr/webpack?-svgo!!./eye.svg').ReactComponent,
  'eye-invisible': require('@svgr/webpack?-svgo!!./eye-invisible.svg')
    .ReactComponent,
  facebook: require('@svgr/webpack?-svgo!!./facebook.svg').ReactComponent,
  google: require('@svgr/webpack?-svgo!!./google.svg').ReactComponent,
  gift: require('@svgr/webpack?-svgo!!./gift.svg').ReactComponent,
  'icon-down': require('@svgr/webpack?-svgo!!./icon-down.svg').ReactComponent,
  'icon-up': require('@svgr/webpack?-svgo!!./icon-up.svg').ReactComponent,
  info: require('@svgr/webpack?-svgo!!./info.svg').ReactComponent,
  invite: require('@svgr/webpack?-svgo!!./invite.svg').ReactComponent,
  inviteShadow: require('@svgr/webpack?-svgo!!./invite-shadow.svg')
    .ReactComponent,
  repeat: require('@svgr/webpack?-svgo!!./repeat.svg').ReactComponent,
  logout: require('@svgr/webpack?-svgo!!./logout.svg').ReactComponent,
  map: require('@svgr/webpack?-svgo!!./map.svg').ReactComponent,
  more: require('@svgr/webpack?-svgo!!./more.svg').ReactComponent,
  'move-left': require('@svgr/webpack?-svgo!!./move-left.svg').ReactComponent,
  'move-right': require('@svgr/webpack?-svgo!!./move-right.svg').ReactComponent,
  next: require('@svgr/webpack?-svgo!!./next.svg').ReactComponent,
  note: require('@svgr/webpack?-svgo!!./note.svg').ReactComponent,
  pause: require('@svgr/webpack?-svgo!!./pause.svg').ReactComponent,
  play: require('@svgr/webpack?-svgo!!./play.svg').ReactComponent,
  questionmark: require('@svgr/webpack?-svgo!!./questionmark.svg')
    .ReactComponent,
  reward: require('@svgr/webpack?-svgo!!./reward.svg').ReactComponent,
  redo: require('@svgr/webpack?-svgo!!./redo.svg').ReactComponent,
  settings: require('@svgr/webpack?-svgo!./settings.svg').ReactComponent,
  shop: require('@svgr/webpack?-svgo!!./shop.svg').ReactComponent,
  sound: require('@svgr/webpack?-svgo!!./sound.svg').ReactComponent,
  'sound-selection': require('@svgr/webpack?-svgo!!./sound-selection.svg')
    .ReactComponent,
  statistic: require('@svgr/webpack?-svgo!!./statistic.svg').ReactComponent,
  student: require('@svgr/webpack?-svgo!!./student.svg').ReactComponent,
  check: require('@svgr/webpack?-svgo!!./check.svg').ReactComponent,
  user: require('@svgr/webpack?-svgo!!./user.svg').ReactComponent,
  exclamation: require('@svgr/webpack?-svgo!!./exclamation.svg').ReactComponent,
  'yellow-star': require('@svgr/webpack?-svgo!!./yellow-star.svg')
    .ReactComponent,
  wardrobe: require('@svgr/webpack?-svgo!!./wardrobe.svg').ReactComponent,
  'green-star': require('@svgr/webpack?-svgo!!./green-star.svg').ReactComponent,
  archive: require('@svgr/webpack?-svgo!!./archive.svg').ReactComponent,
  save: require('@svgr/webpack?-svgo!!./save.svg').ReactComponent,
  threeDots: require('@svgr/webpack?-svgo!!./3dots.svg').ReactComponent,
  us: require('@svgr/webpack?-svgo!!flag-icons/flags/4x3/us.svg')
    .ReactComponent,
  uk: require('@svgr/webpack?-svgo!!flag-icons/flags/4x3/gb.svg')
    .ReactComponent,
  hr: require('@svgr/webpack?-svgo!!flag-icons/flags/4x3/hr.svg')
    .ReactComponent,
  pl: require('@svgr/webpack?-svgo!!flag-icons/flags/4x3/pl.svg')
    .ReactComponent,
};
