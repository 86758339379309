import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';

import { useIntlMessages } from 'src/hooks';

import { KokolingoLogo } from 'src/images';
import { Badge } from 'src/components/common';
import { useHistory } from 'react-router-dom';

import './index.scss';

const LogoTitle = ({ className, showBack }) => {
  const history = useHistory();

  const messages = useIntlMessages();

  const titleClassName = cn('title-header', className);

  return (
    <div className={titleClassName}>
      {isMobile && showBack && (
        // TODO - refactor; implement pragmatic back functionality
        <Badge iconName="back" onClick={() => history.goBack()} />
      )}
      <KokolingoLogo
        width={912}
        height={412}
        onContextMenu={(event) => event.preventDefault()}
      />
      <p className="title-header__welcome">{messages.label.welcomeTo}</p>
      {!isMobile && (
        <p className="title-header__about">{messages.label.aboutKokolingo}</p>
      )}
    </div>
  );
};

LogoTitle.propTypes = {
  className: PropTypes.string,
  showBack: PropTypes.bool,
};

LogoTitle.defaultProps = {
  className: '',
  showBack: true,
};

export default memo(LogoTitle);
