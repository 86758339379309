import React from 'react';
import { Localize } from '@kokolingo/localisation';
import { object, string } from 'yup';

export default object().shape({
  email: string()
    .email(() => <Localize id="error.emailFormatInvalid" />)
    .required(() => <Localize id="error.emailRequired" />),
  password: string().required(() => <Localize id="error.passwordRequired" />),
});
