import PropTypes from 'prop-types';
import React, { memo } from 'react';
import cn from 'classnames';

import colors from 'src/theme/colors.scss';

import icons from './icons';

import './index.scss';

function Icon({ name, size, color, onClick, className }) {
  const IconComponent = icons[name];

  if (!IconComponent) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(
        `Icon ${name} not ported to the new Icon set, please add the missing icon or check if it has different key.`
      );
    }
    return null;
  }

  const classNames = cn('icon-container', className);

  return (
    <div
      className={classNames}
      style={{ width: `${size}px`, height: `${size}px`, color }}
      onClick={onClick}
    >
      <IconComponent />
    </div>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  className: '',
  size: 24,
  color: colors.iconColor,
  onClick: undefined,
};

export { icons };
export default memo(Icon);
