import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { actions, selectors } from 'src/store';
import { gameKeys, paths } from 'src/constants';
import Container from 'src/components/pages/Activities/TreasureHunt/Levels/Container';

const TIMEOUT_DURATION = 500;

const TreasureHuntLevels = ({
  getLevelsByCollectionId,
  hasLoaded,
  student,
  currency,
  treasureHuntGroupOrderNumber,
  levels,
  updateFeedback,
  unlockCollection,
}) => {
  const history = useHistory();
  const gameKey = gameKeys.TREASURE_HUNT;
  const { groupKey, activityId, groupId, collectionId } = useParams();

  const handleLevelAnswer = (levelId, isCorrect, currencyId) => {
    updateFeedback({ currencyId, gameKey, levelId, isCorrect });
  };

  const handleCollectionEnd = () => {
    unlockCollection({
      gameKey,
      collectionId,
    });
    setTimeout(() => {
      history.push(
        paths.build(
          paths.TREASURE_HUNT_COLLECTIONS,
          groupKey,
          activityId,
          groupId
        )
      );
    }, TIMEOUT_DURATION);
  };

  useEffect(() => {
    getLevelsByCollectionId(collectionId);
  }, [collectionId, getLevelsByCollectionId]);

  return (
    <Container
      groupOrderNumber={treasureHuntGroupOrderNumber}
      handleCollectionEnd={handleCollectionEnd}
      handleLevelAnswer={handleLevelAnswer}
      hasLoaded={hasLoaded}
      items={levels}
      currency={currency}
      student={student}
    />
  );
};

TreasureHuntLevels.propTypes = {
  getLevelsByCollectionId: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  student: PropTypes.shape({}).isRequired,
  currency: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  treasureHuntGroupOrderNumber: PropTypes.number.isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateFeedback: PropTypes.func.isRequired,
  unlockCollection: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.treasureHuntLevels.getHasLoaded(state),
  student: selectors.authentication.getStudent(state),
  currency: selectors.treasureHuntLevels.getCurrencyData(state),
  treasureHuntGroupOrderNumber: selectors.treasureHuntLevels.getGroupOrderNumber(
    state
  ),
  levels: selectors.treasureHuntLevels.getLevelsData(state),
});

const mapDispatchToProps = {
  ...actions.treasureHunt,
  ...actions.feedback,
  ...actions.students,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(TreasureHuntLevels);
