const triggerAudio = (url, onAudioEndCallback) => {
  const audio = new Audio(url);

  // Some audio don't have catch - needed for safari to prevent crashing
  // eslint-disable-next-line no-unused-expressions
  audio.play()?.catch(() => {});
  audio.addEventListener('ended', onAudioEndCallback);
  audio.addEventListener('paused', onAudioEndCallback);
  audio.addEventListener('error', () => {
    console.log(`Audio error, can't play ${url} `);
    onAudioEndCallback();
  });

  return audio;
};

export default {
  triggerAudio,
};
