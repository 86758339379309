/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import cn from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

import { paths } from 'src/constants';
import { selectors } from 'src/store';

import Badge from '../../../Badge';
import Localize from '../../../Localize';
import Tooltip from '../../../Tooltip';
import { Title } from '../Title';
import navbarConfiguration from './configuration';
import navbarUtils from './utils';

import './index.scss';

const Navbar = ({ className, isSpeechTherapist }) => {
  const location = useLocation();

  const navbarClassName = cn(
    'navbar-container',
    {
      'navbar-container-auth':
        window.location.pathname === paths.REGISTER_CLIENT ||
        window.location.pathname === paths.REGISTER_SPEECH_THERAPIST ||
        window.location.pathname === paths.FORGOT_PASSWORD,
    },
    className
  );

  const { navbarItem } = navbarUtils.getNavbarItems(location.pathname);

  if (isSpeechTherapist && navbarItem) {
    navbarItem.navigation.forEach((value, key) => {
      if (navbarItem[key] === 'payment') {
        delete navbarItem[key];
      }
    });
  }

  if (!navbarItem) {
    // No items - no navigation
    return null;
  }

  return (
    <div id="navbar" className={navbarClassName}>
      <div id="navbar-container-left" className="navbar-container--left">
        {navbarItem.title && (
          <Title
            title={navbarItem.title}
            icon={
              <Badge
                iconName={navbarItem.badgeIcon}
                img={navbarItem.badgeImg}
                type="neutral"
              />
            }
          />
        )}
      </div>
      <div id="navbar-container-right" className="navbar-container--right">
        <Tooltip id="settings">
          <Localize id="tooltip.settings" />
        </Tooltip>
        {navbarItem.navigation
          .filter((item) => item in navbarConfiguration.NAVBAR_BUTTONS)
          .map((item) => {
            const NavbarButton = navbarConfiguration.NAVBAR_BUTTONS[item];
            return <NavbarButton key={item} />;
          })}
      </div>
    </div>
  );
};

Navbar.propTypes = {
  className: PropTypes.string,
  isSpeechTherapist: PropTypes.bool.isRequired,
};

Navbar.defaultProps = {
  className: 'navbar',
};

const mapStateToProps = (state) => ({
  isSpeechTherapist: selectors.authentication.getIsSpeechTherapist(state),
});

export default compose(connect(mapStateToProps), memo)(Navbar);
