import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const ClimbStepOneCollections = ({
  climbStepOneCollections,
  climbStepOneCurrency,
  getCollections,
  hasLoaded,
  student,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="climb-collections-container--step-one"
      currency={climbStepOneCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={climbStepOneCollections}
      levelsPath={paths.CLIMB_STEP_ONE_LEVELS}
      student={student}
      title={messages.subactivity.climbStepOne}
    />
  );
};

ClimbStepOneCollections.propTypes = {
  climbStepOneCollections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  climbStepOneCurrency: PropTypes.shape({}).isRequired,
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.climbStepOneCollections.getHasLoaded(state),
  climbStepOneCollections: selectors.climbStepOneCollections.getCollectionsData(
    state
  ),
  climbStepOneCurrency: selectors.climbStepOneCollections.getCurrencyData(
    state
  ),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.climbStepOne,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ClimbStepOneCollections);
