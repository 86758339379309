import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.wallet.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getData = createSelector(
  (state) => state.wallet.data,
  (data) => data
);

export default {
  getHasLoaded,
  getData,
};
