import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { common } from 'src/utils';
import { useIntlMessages } from 'src/hooks';

import { Container, OverlayLoader } from 'src/components/common';

import { PieChartContainer, LineChartContainer } from '../components';

import './index.scss';

const THIRTY_DAYS_FROM_NOW = -30;

const LastMonthStatistics = ({
  feedbackStatisticsData,
  getFeedbackStatistics,
  hasLoaded,
}) => {
  const messages = useIntlMessages();

  useEffect(() => {
    getFeedbackStatistics(THIRTY_DAYS_FROM_NOW);
  }, [getFeedbackStatistics]);

  if (!hasLoaded) {
    // @TODO: Replace with designed overlay loader
    return <OverlayLoader />;
  }

  return (
    <Container className="last-month-statistics-container">
      <LineChartContainer
        hideLegend
        className="last-month-statistics-container__points-line"
        description={messages.page.statistics.lastMonthPoints}
        statisticsData={feedbackStatisticsData}
        aspect={810 / 300}
        xAxisData={common.getLastNDates(Math.abs(THIRTY_DAYS_FROM_NOW))}
        xTickFormatter={common.shortenDate}
      />
      <PieChartContainer
        className="last-month-statistics-container__days-pie"
        dataCategoryType="gamesPlayed"
        description={messages.page.statistics.lastMonthActivityShare}
        hideLegend
        unit="%"
        statisticsData={feedbackStatisticsData}
        aspect={320 / 310}
      />
      <PieChartContainer
        className="last-month-statistics-container__island-pie"
        dataCategory="islands"
        description={messages.page.statistics.lastMonthActivitySharePerIsland}
        aspect={320 / 310}
        hideLegend
        unit="%"
        statisticsData={feedbackStatisticsData}
      />
    </Container>
  );
};

LastMonthStatistics.propTypes = {
  getFeedbackStatistics: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  feedbackStatisticsData: PropTypes.shape({
    allTimeEarnedKokolingoPoints: PropTypes.number,
    todayEarnedKokolingoPoints: PropTypes.number,
    todayCorrectAnswers: PropTypes.number,
    todayWrongAnswers: PropTypes.number,
  }),
};

LastMonthStatistics.defaultProps = {
  feedbackStatisticsData: PropTypes.shape({
    allTimeEarnedKokolingoPoints: 0,
    todayEarnedKokolingoPoints: 0,
    todayCorrectAnswers: 0,
    todayWrongAnswers: 0,
  }),
};

const mapStateToProps = (state) => ({
  feedbackStatisticsData: selectors.feedback.getFeedbackData(state),
  hasLoaded: selectors.feedback.getHasLoaded(state),
});

const mapDispatchToProps = {
  ...actions.feedback,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(LastMonthStatistics);
