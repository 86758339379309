import React, { memo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { gameKeys, paths } from 'src/constants';
import Container from 'src/components/pages/Activities/FindWords/Levels/Container';

import coverImage from 'src/images/findWords/icebergCover.svg';

const TIMEOUT_DURATION = 500;

const FindWordsStepThreeLevels = ({
  currency,
  levels,
  getLevelsByCollectionId,
  hasLoaded,
  unlockCollection,
  updateFeedback,
  student,
}) => {
  const history = useHistory();
  const gameKey = gameKeys.FIND_WORDS_STEP_THREE;
  const { groupKey, activityId, collectionId } = useParams();

  const handleLevelAnswer = (levelId, isCorrect, currencyId) => {
    updateFeedback({ currencyId, gameKey, levelId, isCorrect });
  };

  const handleCollectionEnd = () => {
    unlockCollection({ gameKey, collectionId });
    setTimeout(() => {
      history.push(
        paths.build(paths.FIND_WORDS_STEP_THREE, groupKey, activityId)
      );
    }, TIMEOUT_DURATION);
  };

  useEffect(() => {
    getLevelsByCollectionId(collectionId);
  }, [getLevelsByCollectionId, collectionId]);

  return (
    <Container
      className="find-words-levels-container--step-three"
      coverImage={coverImage}
      currency={currency}
      handleCollectionEnd={handleCollectionEnd}
      handleLevelAnswer={handleLevelAnswer}
      hasLoaded={hasLoaded}
      items={levels}
      student={student}
    />
  );
};

FindWordsStepThreeLevels.propTypes = {
  currency: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getLevelsByCollectionId: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  unlockCollection: PropTypes.func.isRequired,
  updateFeedback: PropTypes.func.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.findWordsStepThreeLevels.getHasLoaded(state),
  currency: selectors.findWordsStepThreeLevels.getCurrencyData(state),
  levels: selectors.findWordsStepThreeLevels.getLevelsData(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.findWordsStepThree,
  ...actions.feedback,
  ...actions.students,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(FindWordsStepThreeLevels);
