const MINI = 'MINI';
const SMART = 'SMART';
const PREMIUM = 'PREMIUM';
const KOKOLINGO_1 = 'KOKOLINGO_1';
const KOKOLINGO_5 = 'KOKOLINGO_5';
const KOKOLINGO_10 = 'KOKOLINGO_10';
const KOKOLINGO_25 = 'KOKOLINGO_25';

const TEN_DAYS_FORWARD = 0.33;
const ONE_MONTH_FORWARD = 1;
const TWO_MONTHS_FORWARD = 2;
const THREE_MONTHS_FORWARD = 3;
const SIX_MONTHS_FORWARD = 6;
const NINE_MONTHS_FORWARD = 9;

/**
 *
 * NOTE: THE SAME CODE EXISTS IN models/constants/subscriptions
 * PROBABLY NEED TO EDIT BOTH !!!!!!!!!!!!!!!
 */

const pl = {
  KEYS: {
    MINI,
    SMART,
    PREMIUM,
    KOKOLINGO_1,
    KOKOLINGO_5,
    KOKOLINGO_10,
    KOKOLINGO_25,
  },
  DURATION_VALUES: {
    TEN_DAYS_FORWARD,
    ONE_MONTH_FORWARD,
    TWO_MONTHS_FORWARD,
    THREE_MONTHS_FORWARD,
    SIX_MONTHS_FORWARD,
    NINE_MONTHS_FORWARD,
  },
  DURATION_DISCOUNTS: {
    ONE_MONTH_FORWARD: 0,
    TWO_MONTHS_FORWARD: 0,
    THREE_MONTHS_FORWARD: 0.05,
    SIX_MONTHS_FORWARD: 0.1,
    NINE_MONTHS_FORWARD: 0.15,
  },
  DURATION: {
    KEYS: {
      TEN_DAYS_FORWARD: 'TEN_DAYS_FORWARD',
      ONE_MONTH_FORWARD: 'ONE_MONTH_FORWARD',
      TWO_MONTHS_FORWARD: 'TWO_MONTHS_FORWARD',
      THREE_MONTHS_FORWARD: 'THREE_MONTHS_FORWARD',
      SIX_MONTHS_FORWARD: 'SIX_MONTHS_FORWARD',
      NINE_MONTHS_FORWARD: 'NINE_MONTHS_FORWARD',
    },
    TEN_DAYS_FORWARD: 'label.subscription.duration.tenDaysForward',
    ONE_MONTH_FORWARD: 'label.subscription.duration.oneMonthForward',
    TWO_MONTHS_FORWARD: 'label.subscription.duration.twoMonthsForward',
    THREE_MONTHS_FORWARD: 'label.subscription.duration.threeMonthsForward',
    SIX_MONTHS_FORWARD: 'label.subscription.duration.sixMonthsForward',
    NINE_MONTHS_FORWARD: 'label.subscription.duration.nineMonthsForward',
  },
  PAYMENT_PROVIDER: {
    KEYS: {
      PRZELEWY24: 'PRZELEWY24',
      BANK: 'BANK',
    },
    PATHS: { PRZELEWY24: '/Przelewy24', BANK: '/Bank' },
  },
  PAYMENT_METHODS: {},
  [MINI]: {
    LABEL: 'Mini',
  },
  [SMART]: {
    LABEL: 'Smart',
  },
  [PREMIUM]: {
    LABEL: 'Premium',
  },
  [KOKOLINGO_1]: {
    LABEL: 'Kokolingo 1',
  },
  [KOKOLINGO_5]: {
    LABEL: 'Kokolingo 5',
  },
  [KOKOLINGO_10]: {
    LABEL: 'Kokolingo 10',
  },
  [KOKOLINGO_25]: {
    LABEL: 'Kokolingo 25',
  },
};

const hr = {
  KEYS: {
    MINI,
    SMART,
    PREMIUM,
    KOKOLINGO_1,
    KOKOLINGO_5,
    KOKOLINGO_10,
    KOKOLINGO_25,
  },
  DURATION_VALUES: {
    TEN_DAYS_FORWARD,
    ONE_MONTH_FORWARD,
    TWO_MONTHS_FORWARD,
    THREE_MONTHS_FORWARD,
    SIX_MONTHS_FORWARD,
    NINE_MONTHS_FORWARD,
  },
  DURATION_DISCOUNTS: {
    TEN_DAYS_FORWARD: 0.6,
    ONE_MONTH_FORWARD: 0,
    TWO_MONTHS_FORWARD: 0,
    THREE_MONTHS_FORWARD: 0.05,
    SIX_MONTHS_FORWARD: 0.1,
    NINE_MONTHS_FORWARD: 0.15,
  },
  DURATION: {
    KEYS: {
      TEN_DAYS_FORWARD: 'TEN_DAYS_FORWARD',
      ONE_MONTH_FORWARD: 'ONE_MONTH_FORWARD',
      TWO_MONTHS_FORWARD: 'TWO_MONTHS_FORWARD',
      THREE_MONTHS_FORWARD: 'THREE_MONTHS_FORWARD',
      SIX_MONTHS_FORWARD: 'SIX_MONTHS_FORWARD',
      NINE_MONTHS_FORWARD: 'NINE_MONTHS_FORWARD',
    },
    TEN_DAYS_FORWARD: 'label.subscription.duration.tenDaysForward',
    ONE_MONTH_FORWARD: 'label.subscription.duration.oneMonthForward',
    TWO_MONTHS_FORWARD: 'label.subscription.duration.twoMonthsForward',
    THREE_MONTHS_FORWARD: 'label.subscription.duration.threeMonthsForward',
    SIX_MONTHS_FORWARD: 'label.subscription.duration.sixMonthsForward',
    NINE_MONTHS_FORWARD: 'label.subscription.duration.nineMonthsForward',
  },
  PAYMENT_PROVIDER: {
    KEYS: {
      CORVUS: 'CORVUS',
      BANK: 'BANK',
    },
    PATHS: { CORVUS: '/Corvus', BANK: '/Bank' },
  },
  PAYMENT_METHODS: {
    CORVUS: {
      CREDIT_CARD: 'CREDIT_CARD',
    },
  },
  [MINI]: {
    LABEL: 'Mini',
  },
  [SMART]: {
    LABEL: 'Smart',
  },
  [PREMIUM]: {
    LABEL: 'Premium',
  },
  [KOKOLINGO_1]: {
    LABEL: 'Kokolingo 1',
  },
  [KOKOLINGO_5]: {
    LABEL: 'Kokolingo 5',
  },
  [KOKOLINGO_10]: {
    LABEL: 'Kokolingo 10',
  },
  [KOKOLINGO_25]: {
    LABEL: 'Kokolingo 25',
  },
};

const enUs = {
  KEYS: {
    MINI,
    SMART,
    PREMIUM,
    KOKOLINGO_1,
    KOKOLINGO_5,
    KOKOLINGO_10,
    KOKOLINGO_25,
  },
  DURATION_VALUES: {
    ONE_MONTH_FORWARD,
    SIX_MONTHS_FORWARD,
  },
  DURATION_DISCOUNTS: {
    ONE_MONTH_FORWARD: 0,
    SIX_MONTHS_FORWARD: 0.5,
  },
  DURATION: {
    KEYS: {
      ONE_MONTH_FORWARD: 'ONE_MONTH_FORWARD',
      SIX_MONTHS_FORWARD: 'SIX_MONTHS_FORWARD',
    },
    ONE_MONTH_FORWARD: 'label.subscription.duration.oneMonthForward',
    SIX_MONTHS_FORWARD: 'label.subscription.duration.sixMonthsForward',
  },
  PAYMENT_PROVIDER: {
    KEYS: {
      FASTSPRING: 'FASTSPRING',
    },
    PATHS: { FASTSPRING: '/FastSpring' },
  },
  PAYMENT_METHODS: {
    FASTSPRING: {
      BANK_CARD: 'BANK_CARD',
    },
  },
  [MINI]: {
    LABEL: 'Mini',
  },
  [SMART]: {
    LABEL: 'Basic',
  },
  [PREMIUM]: {
    LABEL: 'Smart',
  },
  [KOKOLINGO_1]: {
    LABEL: 'Kokolingo 1',
  },
  [KOKOLINGO_5]: {
    LABEL: 'Kokolingo 5',
  },
  [KOKOLINGO_10]: {
    LABEL: 'Kokolingo 10',
  },
  [KOKOLINGO_25]: {
    LABEL: 'Kokolingo 25',
  },
};

let constants;
// @TODO move subscription data into the db so that we don't have to this

switch (process.env.REACT_APP_LANGUAGE) {
  default:
  case 'hr':
    constants = hr;
    break;
  case 'pl':
    constants = pl;
    break;
  case 'en-us':
    constants = enUs;
    break;
}

export default {
  ...constants,
};
