import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import _isEmpty from 'lodash/isEmpty';

import { Button } from 'src/components/common';

import './index.scss';
import cn from 'classnames';

const Form = ({
  buttonClassName,
  children,
  defaultValues,
  initialValues,
  onSubmit,
  submitBtnLabel,
  formClassName,
  validationSchema,
  submittable,
}) => {
  const methods = useForm({
    defaultValues,
    validationSchema,
    mode: 'onChange',
  });
  const className = useMemo(() => cn('form', formClassName), [formClassName]);

  if (initialValues && initialValues.length) {
    methods.setValue(...initialValues, {
      shouldDirty: false,
    });
  }

  return (
    <FormContext {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className={className}>
        {children}
        {submitBtnLabel && (
          <div className="form__button-container">
            <Button
              className={buttonClassName}
              isSubmit
              label={submitBtnLabel}
              disabled={
                !methods.formState.isValid ||
                !_isEmpty(methods.errors) ||
                !methods.formState.dirty ||
                submittable
              }
            />
          </div>
        )}
      </form>
    </FormContext>
  );
};

Form.propTypes = {
  buttonClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  submitBtnLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.objectOf(PropTypes.any),
  formClassName: PropTypes.string,
  validationSchema: PropTypes.objectOf(PropTypes.any),
  initialValues: PropTypes.arrayOf(PropTypes.any),
  submittable: PropTypes.bool,
};

Form.defaultProps = {
  submitBtnLabel: '',
  buttonClassName: '',
  defaultValues: {},
  formClassName: '',
  validationSchema: {},
  onSubmit: () => {},
  initialValues: [],
  submittable: false,
};

export default memo(Form);
