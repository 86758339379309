import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { useIntlMessages } from 'src/hooks';

import { Form, Input } from 'src/components/common';

import validationSchema from './validationSchema';

import './index.scss';

const PersonalData = ({ defaultValues, onSubmit }) => {
  const messages = useIntlMessages();

  return (
    <div className="personal-data-container">
      <span className="personal-data-container__title">
        {messages.label.personalData}
      </span>
      <Form
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        buttonClassName="collapsed"
        submitBtnLabel={messages.button.saveChanges}
        formClassName="personal-data-container__form"
      >
        <Input label={messages.label.childName} name="childName" />
        <Input label={messages.label.fullName} name="fullName" />
        <Input label={messages.label.email} name="email" type="email" />
        <Input label={messages.label.phoneNumber} name="phoneNumber" />
      </Form>
    </div>
  );
};

PersonalData.propTypes = {
  defaultValues: PropTypes.shape({
    childName: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(PersonalData);
