import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import _map from 'lodash/map';
import _without from 'lodash/without';
import cn from 'classnames';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { gameNames, statistics } from 'src/constants';
import { common } from 'src/utils';
import { useIntlMessages } from 'src/hooks';

import './index.scss';

const BarChartContainer = ({
  className,
  dataType,
  description,
  aspect,
  hideLegend,
  hideTooltip,
  statisticsData,
  width,
  xAxisData,
  xTickFormatter,
}) => {
  const messages = useIntlMessages();

  const barChartClassNames = useMemo(
    () => cn('bar-chart-container', className),
    [className]
  );

  const bars = useMemo(
    () =>
      _map(common.getParsedGameKeys(), (_value, key) => {
        if (
          !(key in statisticsData) ||
          (dataType === statistics.analyseType.GAMES_PLAYED &&
            statisticsData[key].played < 1)
        ) {
          return false;
        }

        return (
          <Bar
            key={key}
            dataKey={messages()[gameNames[key]]}
            fill={statistics.colors[key]}
          />
        );
      }),
    [messages, dataType, statisticsData]
  );

  const barChartData = useMemo(
    () =>
      xAxisData.map((days) => {
        const data = _without(
          _map(common.getParsedGameKeys(), (_gameValue, gameKey) => {
            if (!(gameKey in statisticsData)) {
              return false;
            }

            const [gameData] = _without(
              _map(statisticsData[gameKey], (_dateValue, dateKey) => {
                if (days !== dateKey) {
                  return false;
                }

                return dataType === statistics.analyseType.EARNED_POINTS
                  ? statisticsData[gameKey][dateKey].points
                  : statisticsData[gameKey][dateKey].played;
              }),
              false
            );

            if (!gameData) {
              return false;
            }

            return {
              [messages()[gameNames[gameKey]]]: gameData,
            };
          }),
          false
        ).reduce(
          (previousData, currentData) => ({
            ...previousData,
            ...currentData,
          }),
          {}
        );

        return {
          days,
          ...data,
        };
      }),
    [messages, statisticsData, xAxisData, dataType]
  );

  return (
    <div className={barChartClassNames}>
      <div className="bar-chart-container__description">{description}</div>
      <ResponsiveContainer width={width} aspect={aspect}>
        <BarChart data={barChartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="days" tickFormatter={xTickFormatter} />
          <YAxis axisLine={false} />
          {_without(bars, false)}
          {!hideTooltip && <Tooltip cursor={{ fill: 'transparent' }} />}
          {!hideLegend && <Legend />}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

BarChartContainer.propTypes = {
  className: PropTypes.string,
  dataType: PropTypes.string,
  description: PropTypes.string.isRequired,
  aspect: PropTypes.number.isRequired,
  hideLegend: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  statisticsData: PropTypes.shape({}).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xAxisData: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  xTickFormatter: PropTypes.func,
};

BarChartContainer.defaultProps = {
  className: '',
  dataType: 'earnedPoints',
  hideLegend: false,
  hideTooltip: false,
  width: '100%',
  xTickFormatter: undefined,
};

export default memo(BarChartContainer);
