import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import classNames from 'classnames';

import './index.scss';

const Card = ({ children, className, onClick, disabled, ghost }) => {
  const cardClassNames = useMemo(
    () =>
      classNames('card', className, {
        'card--clickable': !!onClick,
        'card--disabled': disabled,
        'card--ghost': ghost,
      }),
    [className, disabled, onClick, ghost]
  );

  return (
    <div
      className={cardClassNames}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  ghost: PropTypes.bool,
};

Card.defaultProps = {
  children: null,
  className: '',
  onClick: null,
  disabled: false,
  ghost: false,
};

export default memo(Card);
