import { actions, paths } from 'src/constants';

export default {
  updateFeedback: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.FEEDBACK_UPDATE_REQUEST,
        actions.FEEDBACK_UPDATE_SUCCESS,
        actions.FEEDBACK_UPDATE_FAILURE,
      ],
      promise: (client) => client.put(paths.api.FEEDBACK, data),
    },
  }),

  getFeedbackStatistics: (days) => ({
    [actions.API_CALL]: {
      types: [
        actions.FEEDBACK_STATISTICS_GET_REQUEST,
        actions.FEEDBACK_STATISTICS_GET_SUCCESS,
        actions.FEEDBACK_STATISTICS_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.api.FEEDBACK, { params: { days } }),
    },
  }),

  hideMotivationParrot: () => ({
    type: actions.PARROT_MOTIVATION_HIDE,
  }),
};
