import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';

import bees from 'src/images/memory/bees.png';

import { Container } from './components';

const MemoryStepTwoExtraGroups = ({
  getGroups,
  hasLoaded,
  memoryStepTwoExtraCurrency,
  memoryStepTwoExtraGroups,
  student,
}) => {
  return (
    <Container
      cardBackgroundImage={bees}
      className="memory-groups-container--step-two-extra"
      collectionsPath={paths.MEMORY_STEP_TWO_EXTRA_COLLECTIONS}
      currency={memoryStepTwoExtraCurrency}
      getGroups={getGroups}
      hasLoaded={hasLoaded}
      items={memoryStepTwoExtraGroups}
      student={student}
    />
  );
};

MemoryStepTwoExtraGroups.propTypes = {
  getGroups: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  memoryStepTwoExtraCurrency: PropTypes.shape({}).isRequired,
  memoryStepTwoExtraGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.memoryStepTwoExtraGroups.getHasLoaded(state),
  memoryStepTwoExtraCurrency: selectors.memoryStepTwoExtraGroups.getCurrencyData(
    state
  ),
  memoryStepTwoExtraGroups: selectors.memoryStepTwoExtraGroups.getGroupsData(
    state
  ),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.memoryStepTwoExtra,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(MemoryStepTwoExtraGroups);
