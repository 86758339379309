import React, { useRef, useState } from 'react';
import {
  getCountry,
  getLanguageApplicationUrl,
  parseLanguages,
} from '@kokolingo/localisation';
import { useOutsideClick } from 'src/hooks';

import SettingsMenu from '../SettingsMenu';
import SettingsMenuItem from '../SettingsMenuItem';

import './index.scss';

const LanguageDropdown = () => {
  const [showLanguages, setShowLanguages] = useState(false);
  const languageContainerRef = useRef();
  useOutsideClick(() => setShowLanguages(false), languageContainerRef);
  const currentCountry = getCountry(process.env.REACT_APP_LANGUAGE);

  if (!process.env.REACT_APP_LANGUAGES) {
    return null;
  }

  return (
    <>
      <SettingsMenu
        className="language-dropdown"
        innerRef={languageContainerRef}
      >
        <SettingsMenuItem
          key={currentCountry}
          iconName={currentCountry}
          size="large"
          onClick={() => setShowLanguages(!showLanguages)}
        />
        {showLanguages &&
          parseLanguages(process.env.REACT_APP_LANGUAGES).map((language) => {
            const appUrl = getLanguageApplicationUrl(language);
            const flagCountry = getCountry(language);

            if (flagCountry === currentCountry || !appUrl) {
              return <></>;
            }

            return (
              <a href={appUrl}>
                <SettingsMenuItem
                  key={flagCountry}
                  iconName={flagCountry}
                  size="large"
                />
              </a>
            );
          })}
      </SettingsMenu>
    </>
  );
};
export default LanguageDropdown;
