import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import Badge from '../../../Badge';
import { VideoWizard } from '../../../Wizard';
import Modal from '../../../Modal/Modal';
import Tooltip from '../../../Tooltip';
import Localize from '../../../Localize';

import useUserVideos from './useUserVideos';

import './index.scss';

const EducationAction = ({ videos, handleVideoEnded }) => {
  const [showEducation, setShowEducation] = useState(false);

  const [unplayedVideosCount, markPlayed] = useUserVideos(videos);

  return (
    <>
      {showEducation && (
        <Modal
          show
          fullscreen
          onClose={() => setShowEducation(false)}
          className="education-video-modal"
        >
          <VideoWizard
            videos={videos}
            markPlayed={markPlayed}
            handleVideoEnded={handleVideoEnded}
          />
        </Modal>
      )}
      {videos.length > 0 && (
        <>
          <Tooltip id="actor">
            <Localize id="tooltip.actor" />
          </Tooltip>
          <Badge
            img="actor"
            onClick={() => setShowEducation(true)}
            tooltip="actor"
            count={unplayedVideosCount}
          />
        </>
      )}
    </>
  );
};

EducationAction.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      videoName: PropTypes.string,
      description: PropTypes.node,
    })
  ).isRequired,
  handleVideoEnded: PropTypes.func,
};

EducationAction.defaultProps = {
  handleVideoEnded: () => {},
};

export default memo(EducationAction);
