import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { SafeRouteValidatorProvider } from 'src/context';
import { paths } from 'src/constants';
import { player } from 'src/utils';
import { selectors } from 'src/store';

const ActivitiesValidator = ({ children, isSpeechTherapist, student }) => {
  let redirect = false;

  if (isSpeechTherapist && !player.getSelectedStudentId()) {
    redirect = paths.SPEECH_THERAPIST;
  } else if (!student.SpeechSoundId) {
    redirect = paths.SPEECH_SOUND_SELECTION;
  }

  return (
    <SafeRouteValidatorProvider value={redirect}>
      {children}
    </SafeRouteValidatorProvider>
  );
};

ActivitiesValidator.propTypes = {
  children: PropTypes.node.isRequired,
  isSpeechTherapist: PropTypes.bool.isRequired,
  student: PropTypes.shape({
    SpeechSoundId: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  isSpeechTherapist: selectors.authentication.getIsSpeechTherapist(state),
  student: selectors.authentication.getStudent(state),
});

export default compose(
  connect(mapStateToProps),
  withRouter
)(ActivitiesValidator);
