import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Image from './Image';

const SENTENCE_TERM_ANSWER_LABEL = '_';
const SENTENCE_TERM_ANSWER_PLACEHOLDER = '______';
const SENTENCE_TERM_ANSWER_LABEL_MODIFIED = '*_*';
const SENTENCE_TERM_COMMON_LABEL = '*';

const SentenceCreator = ({
  correctImageUrls,
  imageClassNames,
  initialSentence,
  isCorrect,
  sentenceImages,
  textClassNames,
}) => {
  const modifiedSentence = isCorrect
    ? initialSentence.replace(
        SENTENCE_TERM_ANSWER_LABEL,
        SENTENCE_TERM_ANSWER_LABEL_MODIFIED
      )
    : initialSentence;

  const sentenceParts = modifiedSentence.split(/(\*[^0-9*]+\*)/gi);

  let correctImageUrlIndex = -1;

  return sentenceParts.map((sentencePart, index) => {
    if (
      sentencePart.includes(SENTENCE_TERM_COMMON_LABEL) &&
      !sentencePart.includes(SENTENCE_TERM_ANSWER_LABEL)
    ) {
      const sentenceImageData = sentenceImages.find((sentenceImage) =>
        sentencePart.toLowerCase().includes(sentenceImage.key.toLowerCase())
      );

      if (sentenceImageData) {
        return (
          <Image
            key={index}
            className={imageClassNames}
            alt="image"
            src={sentenceImageData.imageUrl}
          />
        );
      }
    }

    if (sentencePart.includes(SENTENCE_TERM_ANSWER_LABEL) && isCorrect) {
      correctImageUrlIndex += 1;
      return (
        <Image
          key={index}
          className={imageClassNames}
          alt="image"
          src={correctImageUrls[correctImageUrlIndex]}
        />
      );
    }

    return (
      <span key={index} className={textClassNames}>
        {` ${sentencePart.replace(
          SENTENCE_TERM_ANSWER_LABEL,
          SENTENCE_TERM_ANSWER_PLACEHOLDER
        )} `}
      </span>
    );
  });
};

SentenceCreator.propTypes = {
  correctImageUrls: PropTypes.arrayOf(PropTypes.string),
  imageClassNames: PropTypes.string,
  initialSentence: PropTypes.string,
  isCorrect: PropTypes.bool,
  sentenceImages: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      imageUrl: PropTypes.string,
    })
  ).isRequired,
  textClassNames: PropTypes.string,
};

SentenceCreator.defaultProps = {
  correctImageUrls: [],
  imageClassNames: '',
  isCorrect: false,
  textClassNames: '',
  initialSentence: '',
};

export default memo(SentenceCreator);
