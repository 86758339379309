import { actions, paths } from 'src/constants';

export default {
  getSpeechSoundById: (speechSoundId) => ({
    [actions.API_CALL]: {
      types: [
        actions.SPEECH_SOUND_GET_REQUEST,
        actions.SPEECH_SOUND_GET_SUCCESS,
        actions.SPEECH_SOUND_GET_FAILURE,
      ],
      promise: (client) =>
        client.get(paths.build(paths.api.SPEECH_SOUNDS_ID, speechSoundId)),
    },
  }),

  enableSpeechSound: (speechSoundId) => ({
    [actions.API_CALL]: {
      types: [
        actions.SPEECH_SOUND_CREATE_REQUEST,
        actions.SPEECH_SOUND_CREATE_SUCCESS,
        actions.SPEECH_SOUND_CREATE_FAILURE,
      ],
      promise: (client) =>
        client.post(paths.build(paths.api.SPEECH_SOUNDS_ID, speechSoundId)),
    },
  }),

  getSpeechSounds: () => ({
    [actions.API_CALL]: {
      types: [
        actions.SPEECH_SOUNDS_GET_REQUEST,
        actions.SPEECH_SOUNDS_GET_SUCCESS,
        actions.SPEECH_SOUNDS_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.api.SPEECH_SOUNDS),
    },
  }),
};
