import React, { memo, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import _startCase from 'lodash/startCase';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { player } from 'src/utils';

import {
  Container,
  PageLoader,
  MenuFrame,
  ContentContainer,
  Coin,
  Navbar,
  Image,
} from 'src/components/common';

import { useGoToSubactivity, useIntlMessages, useCheckpoint } from 'src/hooks';
import config from 'src/config.json';

import './index.scss';

const SelectGameCategory = ({
  activitiesData,
  getActivitiesByGroupId,
  getSpeechSoundById,
  hasLoaded,
  match,
  student,
  studentSpeechSound,
  updateStudentById,
}) => {
  const history = useHistory();
  const delimiterIndex = match.params.groupKey.lastIndexOf('-');
  const groupKey = match.params.groupKey.slice(
    0,
    delimiterIndex > -1 ? delimiterIndex : undefined
  );
  const groupId = match.params.groupKey.slice(delimiterIndex + 1);
  const messages = useIntlMessages();

  const goToSubactivity = useGoToSubactivity();

  const groupKeyName = useMemo(
    () => _startCase(groupKey.replace('-', ' ')).replace(' ', ''),
    [groupKey]
  );

  useCheckpoint(`firstTimeOn${groupKeyName}`, hasLoaded);

  const videoName = useMemo(() => {
    if (!studentSpeechSound.isSpecial) {
      return `activityGroup${groupKeyName}Education`;
    }
    // @TODO refactor, this was before when `isSpecial` was used
    switch (groupKeyName) {
      case 'OrangeSpecial':
        return `activityGroupGreenEducation`;

      case 'Green':
        return `activityGroupPurpleEducation`;

      case 'Purple':
        return `activityGroupOrangeSpecialEducation`;

      default:
        return `activityGroup${groupKeyName}Education`;
    }
  }, [groupKeyName, studentSpeechSound.isSpecial]);

  const handleVideoEnded = useCallback(() => {
    updateStudentById(player.getSelectedStudentId(), {
      [`hasPassedActivityGroup${groupKeyName}Education`]: true,
    });
  }, [groupKeyName, updateStudentById]);

  useEffect(() => {
    getActivitiesByGroupId(groupId).catch(() => history.push(paths.BASE));
  }, [getActivitiesByGroupId, groupId, history]);

  useEffect(() => {
    getSpeechSoundById(student.SpeechSoundId);
  }, [getSpeechSoundById, student]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      <Navbar.Title
        title={messages.activity[groupKeyName.toLowerCase()]}
        icon={<Coin type={groupKey} />}
      />
      <Navbar.Actions>
        <Navbar.EducationAction
          videos={config.videos.activityGroup.filter(
            (video) => video.videoName === videoName
          )}
          handleVideoEnded={handleVideoEnded}
        />
      </Navbar.Actions>
      <Container
        className={`select-activity-container select-activity-container--background-${groupKeyName}`}
      >
        <MenuFrame>
          {activitiesData.map((activity) => (
            <ContentContainer
              key={activity.id}
              isAllowed={activity.isAllowed}
              allowedSubscriptionKeys={activity.allowedSubscriptionKeys}
              onClick={() => goToSubactivity(activity)}
            >
              <p>{activity.label}</p>
              <Image alt={activity.key} src={activity.imageUrl} />
            </ContentContainer>
          ))}
        </MenuFrame>
      </Container>
    </PageLoader>
  );
};

SelectGameCategory.propTypes = {
  getActivitiesByGroupId: PropTypes.func.isRequired,
  getSpeechSoundById: PropTypes.func.isRequired,
  updateStudentById: PropTypes.func.isRequired,
  activitiesData: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.shape({
        key: PropTypes.string.isRequired,
      }),
    })
  ),
  hasLoaded: PropTypes.bool.isRequired,
  student: PropTypes.shape({
    SpeechSoundId: PropTypes.number,
  }).isRequired,
  studentSpeechSound: PropTypes.shape({
    isSpecial: PropTypes.bool,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupKey: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

SelectGameCategory.defaultProps = {
  activitiesData: [],
};

const mapStateToProps = (state) => ({
  activitiesData: selectors.activities.getActivitiesData(state),
  hasLoaded: selectors.activities.getHasLoaded(state),
  student: selectors.authentication.getStudent(state),
  studentSpeechSound: selectors.speechSounds.getSpeechSound(state),
});

const mapDispatchToProps = {
  ...actions.activities,
  ...actions.speechSounds,
  ...actions.students,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(SelectGameCategory);
