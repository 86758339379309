import { useHistory, useParams } from 'react-router';
import { useCallback } from 'react';

import { activityKeys, paths } from 'src/constants';

export default () => {
  const history = useHistory();
  const params = useParams();

  return useCallback(
    (activity) => {
      switch (activity.key) {
        case activityKeys.ARTICULATION_GYMNASTICS:
        case activityKeys.WHEEL_OF_FORTUNE:
        case activityKeys.REPLENISHMENT:
        case activityKeys.READ_TOGETHER:
        case activityKeys.READ_AND_THINK:
          history.push(
            paths.build(paths[activity.key], ...Object.values(params))
          );
          break;

        default:
          history.push(
            paths.build(
              paths.SUBACTIVITY,
              ...Object.values(params),
              activity.id
            )
          );
          break;
      }
    },
    [history, params]
  );
};
