import { helpKeys, paths } from 'src/constants';

export default {
  [paths.ARTICULATION_GYMNASTICS]: helpKeys.ARTICULATION_GYMNASTICS,
  [paths.CLIMB_STEP_ONE_EXTRA]: helpKeys.CLIMB,
  [paths.CLIMB_STEP_ONE]: helpKeys.CLIMB,
  [paths.CLIMB_STEP_TWO]: helpKeys.CLIMB,
  [paths.CLIMB_STEP_THREE]: helpKeys.CLIMB,
  [paths.DISCOVER_STEP_ONE_EXTRA]: helpKeys.DISCOVER,
  [paths.DISCOVER_STEP_ONE]: helpKeys.DISCOVER,
  [paths.DISCOVER_STEP_TWO]: helpKeys.DISCOVER,
  [paths.DISCOVER_STEP_THREE]: helpKeys.DISCOVER,
  [paths.FIND_WORDS_STEP_ONE_EXTRA]: helpKeys.FIND_WORDS,
  [paths.FIND_WORDS_STEP_ONE]: helpKeys.FIND_WORDS,
  [paths.FIND_WORDS_STEP_TWO]: helpKeys.FIND_WORDS,
  [paths.FIND_WORDS_STEP_THREE]: helpKeys.FIND_WORDS,
  [paths.FLASH_CARDS_STEP_ONE_EXTRA]: helpKeys.FLASH_CARDS,
  [paths.FLASH_CARDS_STEP_ONE]: helpKeys.FLASH_CARDS,
  [paths.FLASH_CARDS_STEP_TWO]: helpKeys.FLASH_CARDS,
  [paths.FLASH_CARDS_STEP_THREE]: helpKeys.FLASH_CARDS,
  [paths.MEMORY_STEP_THREE_COLLECTIONS]: helpKeys.MEMORY,
  [paths.MEMORY_STEP_THREE]: helpKeys.MEMORY,
  [paths.MEMORY_STEP_TWO_COLLECTIONS]: helpKeys.MEMORY,
  [paths.MEMORY_STEP_TWO]: helpKeys.MEMORY,
  [paths.MEMORY_STEP_ONE_EXTRA_COLLECTIONS]: helpKeys.MEMORY,
  [paths.MEMORY_STEP_ONE_EXTRA]: helpKeys.MEMORY,
  [paths.MEMORY_STEP_ONE_COLLECTIONS]: helpKeys.MEMORY,
  [paths.MEMORY_STEP_ONE]: helpKeys.MEMORY,
  [paths.PAINTING_COLLECTIONS]: helpKeys.PAINTING,
  [paths.PAINTING]: helpKeys.PAINTING,
  [paths.READ_AND_THINK]: helpKeys.READ_AND_THINK,
  [paths.READ_TOGETHER]: helpKeys.READ_TOGETHER,
  [paths.REPLENISHMENT]: helpKeys.REPLENISHMENT,
  [paths.SHOP]: helpKeys.SHOP,
  [paths.SHOP_CATEGORY]: helpKeys.SHOP,
  [paths.SPEECH_THERAPIST]: helpKeys.SPEECH_THERAPISTS_HELP,
  [paths.TREASURE_HUNT_COLLECTIONS]: helpKeys.TREASURE_HUNT,
  [paths.TREASURE_HUNT]: helpKeys.TREASURE_HUNT,
  [paths.WHEEL_OF_FORTUNE]: helpKeys.WHEEL_OF_FORTUNE,
};
