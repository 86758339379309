import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useHistory } from 'react-router';
import cn from 'classnames';

import Badge from 'src/components/common/Badge';

import './index.scss';

const SettingsMenuItem = ({
  title,
  iconName,
  type,
  onClick,
  badgeChildren,
  className,
  size,
  to,
}) => {
  const history = useHistory();
  const containerClassName = useMemo(
    () => cn('settings-menu-item-container', className),
    [className]
  );

  const handleClick = onClick || (() => history.push(to));

  return (
    <div
      className={containerClassName}
      onClick={handleClick}
      onKeyPress={handleClick}
      role="button"
      tabIndex={0}
    >
      {title && (
        <div className="settings-menu-item-container__title">{title}</div>
      )}
      <Badge iconName={iconName} type={type} size={size}>
        {badgeChildren}
      </Badge>
    </div>
  );
};

SettingsMenuItem.propTypes = {
  iconName: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  badgeChildren: PropTypes.node,
  to: PropTypes.string,
};

SettingsMenuItem.defaultProps = {
  type: 'primary',
  size: 'regular',
  title: null,
  badgeChildren: null,
  className: '',
  iconName: null,
  onClick: null,
  to: '',
};

export default memo(SettingsMenuItem);
