import React, { memo, useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { gameKeys, paths } from 'src/constants';
import { actions, selectors } from 'src/store';
import {
  ActivityRenderer,
  Badge,
  Button,
  Navbar,
  PageLoader,
} from 'src/components/common';
import ModalScore from 'src/components/common/ModalDailyMap/ScoreModal';
import { dailyMap as dailyMapUtils, common as utils } from 'src/utils';
import { useIntlMessages } from 'src/hooks';

import Map from './Map';

const DailyMap = ({
  daysActive,
  dailyMap,
  getLevels,
  getDailyMapFeedback,
  hasLoadedMap,
  collectionVerified,
  loadingLevelsError,
  showDailyProgressModal,
  setShowDailyProgressModal,
  updateDailyMap,
  updateFeedback,
  resetLevels,
  verifyCollection,
  clearNotification,
}) => {
  const messages = useIntlMessages();
  const history = useHistory();
  const [selectedActivityData, setSelectedActivityData] = useState({});

  const updateProgress = useCallback(
    (currentActivityIndex) => {
      const progress = { ...dailyMap.meta.progress };
      const today = utils.getDateFromNow().replace(/-/g, '');
      progress[today] =
        currentActivityIndex / dailyMap.DailyMapTemplate.meta.activities.length;

      updateDailyMap(dailyMap.id, {
        meta: { currentActivityIndex, progress },
      });

      if (
        progress[today] === 1 &&
        daysActive < dailyMap.DailyMapTemplate.durationInDays
      ) {
        getDailyMapFeedback(dailyMap.id, 0);
      }
    },
    [dailyMap, daysActive, getDailyMapFeedback, updateDailyMap]
  );

  useEffect(() => {
    if (dailyMap && !dailyMapUtils.hasTodayProgress(dailyMap)) {
      updateProgress(0);
    }
  }, [dailyMap, updateProgress]);

  useEffect(() => {
    const today = utils.getDateFromNow().replace(/-/g, '');
    if (
      dailyMap &&
      dailyMap.meta.progress[today] === 1 &&
      daysActive === dailyMap.DailyMapTemplate.durationInDays
    ) {
      history.push(paths.MAP);
      updateDailyMap(dailyMap.id, { endedAt: Date.now() });
      getDailyMapFeedback(dailyMap.id, daysActive);
    }
  }, [
    dailyMap,
    daysActive,
    getDailyMapFeedback,
    history,
    messages,
    updateDailyMap,
  ]);

  const handleLevelAnswer = (levelId, isCorrect, currencyId) => {
    updateFeedback(dailyMap.id, {
      currencyId,
      gameKey: selectedActivityData.activityType,
      levelId,
      isCorrect,
    });
  };

  const handleCollectionEnd = () => {
    if (selectedActivityData.activityType === gameKeys.READ_TOGETHER) {
      setSelectedActivityData({
        ...selectedActivityData,
        activityType: gameKeys.READ_TOGETHER_BONUS,
      });
      return;
    }
    if (selectedActivityData.activityType === gameKeys.READ_AND_THINK) {
      setSelectedActivityData({
        ...selectedActivityData,
        activityType: gameKeys.READ_AND_THINK_BONUS,
      });
      return;
    }
    if (selectedActivityData.index === dailyMap.meta.currentActivityIndex) {
      updateProgress(selectedActivityData.index + 1);
    }
    setSelectedActivityData({});
    resetLevels();
  };

  const setSelectedActivity = useCallback(
    (index = selectedActivityData?.index) => {
      const activityType = dailyMap.DailyMapTemplate.meta.activities[index];
      const collectionId =
        dailyMap.DailyMapTemplate.DailyMapTemplateCollections[0].collections[
          index
        ];
      setSelectedActivityData({ index, collectionId, activityType });
    },
    [dailyMap, selectedActivityData]
  );

  const goToActivity = useCallback(
    (index) => () => {
      setSelectedActivity(index);
    },
    [setSelectedActivity]
  );

  useEffect(() => {
    if (collectionVerified) {
      clearNotification();
      setSelectedActivity();
    }
  }, [
    collectionVerified,
    clearNotification,
    setSelectedActivity,
    loadingLevelsError,
  ]);

  useEffect(() => {
    if (!collectionVerified && loadingLevelsError) {
      verifyCollection({
        ...selectedActivityData,
        dailyMapId: dailyMap.id,
      });
    }
  }, [
    loadingLevelsError,
    verifyCollection,
    selectedActivityData,
    dailyMap,
    collectionVerified,
  ]);

  const getLevelsByCollectionId = useCallback(() => {
    getLevels({
      activityType: selectedActivityData.activityType,
      collectionId: selectedActivityData.collectionId,
      groupId: selectedActivityData.groupId,
    });
  }, [getLevels, selectedActivityData]);

  const activityNavigation = (
    <Navbar.Actions>
      <Badge
        iconName="back"
        onClick={() => {
          setSelectedActivityData({});
          resetLevels();
        }}
      />
    </Navbar.Actions>
  );

  if (!hasLoadedMap) {
    return <PageLoader />;
  }

  if (hasLoadedMap && !dailyMap) {
    return <Redirect to={paths.SETTINGS_DAILY_MAP} />;
  }

  return (
    <PageLoader isFadingOut>
      <ActivityRenderer
        handleLevelAnswer={handleLevelAnswer}
        handleCollectionEnd={handleCollectionEnd}
        {...selectedActivityData}
        navigation={activityNavigation}
        getLevelsByCollectionId={getLevelsByCollectionId}
      >
        <ModalScore
          show={showDailyProgressModal}
          title={messages.modal.dailyMap.titleTodayResults}
          close={() => {
            setShowDailyProgressModal(false);
            history.push(paths.MAP);
          }}
        >
          <Button
            className="daily-map-modal__button"
            onClick={() => {
              setShowDailyProgressModal(false);
              history.push(paths.MAP);
            }}
          >
            {messages.button.ok}
          </Button>
        </ModalScore>
        <Map onSelectActivity={goToActivity} />
      </ActivityRenderer>
    </PageLoader>
  );
};

DailyMap.propTypes = {
  daysActive: PropTypes.number.isRequired,
  dailyMap: PropTypes.shape({
    id: PropTypes.number,
    SpeechSoundId: PropTypes.number,
    meta: PropTypes.object,
    DailyMapTemplate: PropTypes.object,
  }),
  getDailyMap: PropTypes.func.isRequired,
  getLevels: PropTypes.func.isRequired,
  getDailyMapFeedback: PropTypes.func.isRequired,
  getDailyMapTemplates: PropTypes.func.isRequired,
  hasLoadedMap: PropTypes.bool.isRequired,
  getHasLoadingLevelsError: PropTypes.func.isRequired,
  loadingLevelsError: PropTypes.bool.isRequired,
  resetLevels: PropTypes.func.isRequired,
  setShowDailyProgressModal: PropTypes.func.isRequired,
  showDailyProgressModal: PropTypes.bool.isRequired,
  updateDailyMap: PropTypes.func.isRequired,
  updateFeedback: PropTypes.func.isRequired,
  verifyCollection: PropTypes.func.isRequired,
  clearNotification: PropTypes.func.isRequired,
  collectionVerified: PropTypes.bool.isRequired,
};

DailyMap.defaultProps = {
  dailyMap: null,
};

const mapStateToProps = (state) => ({
  dailyMap: selectors.dailyMap.getSelectedDailyMap(state),
  daysActive: selectors.dailyMap.getDaysActive(state),
  hasLoadedMap: selectors.dailyMap.getHasLoadedMap(state),
  loadingLevelsError: selectors.dailyMap.getHasLoadingLevelsError(state),
  showDailyProgressModal: selectors.dailyMap.getShowDailyProgressModal(state),
  collectionVerified: selectors.dailyMap.getVerificationStatus(state),
  clearNotification: PropTypes.func.isRequired,
});

const mapDispatchToProps = {
  ...actions.dailyMap,
  ...actions.notifications,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(DailyMap);
