import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import {
  Badge,
  Container,
  Navbar,
  SpeechSoundBadge,
  Zoom,
} from 'src/components/common';
import { useNavigateBack } from 'src/hooks';

import './index.scss';

const pointPriorities = {
  10: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  9: [1, 2, 3, 4, 5, 6, 7, 9, 10],
  8: [1, 2, 3, 4, 6, 7, 9, 10],
  7: [1, 2, 4, 5, 7, 9, 10],
  6: [1, 3, 4, 5, 8, 10],
  5: [1, 3, 5, 8, 10],
};

const Map = ({
  background,
  dailyMap,
  student,
  getSpeechSoundById,
  studentSpeechSound,
  onSelectActivity,
}) => {
  const navigateBack = useNavigateBack({});

  useEffect(() => {
    getSpeechSoundById(student.SpeechSoundId);
  }, [getSpeechSoundById, student]);

  return (
    <Container
      style={background && { backgroundImage: `url(${background})` }}
      className="daily-map-container"
    >
      <Navbar.Title
        title={student.fullName}
        icon={<SpeechSoundBadge speechSound={studentSpeechSound} />}
      />
      <Navbar.Actions>
        <Badge iconName="back" onClick={navigateBack} />
      </Navbar.Actions>
      <Zoom mobileWidth={360} mobileHeight={640} align="center">
        <Container className="daily-map-container__filled">
          {dailyMap?.DailyMapTemplate.meta.activities.map((activity, index) => {
            const positions =
              pointPriorities[dailyMap.DailyMapTemplate.meta.activities.length];

            return (
              <Badge
                size="regular"
                className={`point-${positions[index]}${
                  index > dailyMap.meta.currentActivityIndex ? ' disabled' : ''
                }`}
                key={index}
                onClick={onSelectActivity(index)}
              >
                {(index + 1).toString()}
              </Badge>
            );
          })}
        </Container>
      </Zoom>
    </Container>
  );
};

Map.propTypes = {
  background: PropTypes.string.isRequired,
  onSelectActivity: PropTypes.func.isRequired,
  dailyMap: PropTypes.shape({
    meta: PropTypes.object,
    DailyMapTemplate: PropTypes.object,
  }).isRequired,
  student: PropTypes.shape({
    SpeechSoundId: PropTypes.number,
    fullName: PropTypes.string,
  }).isRequired,
  studentSpeechSound: PropTypes.shape({
    label: PropTypes.string,
  }).isRequired,
  getSpeechSoundById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  background: selectors.dailyMap.getBackground(state),
  dailyMap: selectors.dailyMap.getSelectedDailyMap(state),
  hasLoaded: selectors.activityGroups.getHasLoaded(state),
  student: selectors.authentication.getStudent(state),
  studentSpeechSound: selectors.speechSounds.getSpeechSound(state),
});

const mapDispatchToProps = {
  ...actions.activityGroups,
  ...actions.authentication,
  ...actions.dailyMap,
  ...actions.speechSounds,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), memo)(Map);
