import React, { memo, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import cn from 'classnames';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { player } from 'src/utils';
import { useGoToActivity } from 'src/hooks';
import config from 'src/config.json';

import {
  ContentContainer,
  MenuFrame,
  PageLoader,
  Coin,
  Navbar,
  Image,
} from 'src/components/common';

import './index.scss';

const SelectSubactivity = ({
  getSubactivitiesByActivityId,
  updateStudentById,
  activityData,
  subactivities,
  hasLoaded,
  match,
}) => {
  const history = useHistory();
  const goToActivity = useGoToActivity();
  const { groupKey } = useParams();

  const selectedActivityId = useMemo(() => match.params.activityId, [
    match.params.activityId,
  ]);

  const createSubactivityKey = (currency) => {
    return currency.concat('-special');
  };

  const containerClassNames = useMemo(
    () =>
      hasLoaded &&
      cn(
        'select-subactivity-container ',
        `select-subactivity-container--background-${
          activityData.key.includes('EXTRA')
            ? createSubactivityKey(activityData.currency.key)
            : activityData.currency.key
        }`
      ),
    [activityData, hasLoaded]
  );

  const handleVideoEnded = useCallback(() => {
    updateStudentById(player.getSelectedStudentId(), {
      [activityData.key.includes('LOGATOMS')
        ? 'hasPassedLogatomsEducation'
        : 'hasPassedWordsEducation']: true,
    });
  }, [updateStudentById, activityData.key]);

  const videos = useMemo(
    () => [
      {
        label: activityData.label,
      },
    ],
    [activityData.label]
  );

  useEffect(() => {
    getSubactivitiesByActivityId(selectedActivityId).catch(() =>
      history.push(paths.BASE)
    );
  }, [getSubactivitiesByActivityId, selectedActivityId, history]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  if (activityData.key.includes('LOGATOMS')) {
    videos[0] = {
      ...videos[0],
      ...config.videos.subactivityGroup.find(
        (video) => video.videoName === 'logatoms'
      ),
    };
  }

  if (activityData.key.includes('WORDS')) {
    videos[0] = {
      ...videos[0],
      ...config.videos.subactivityGroup.find(
        (video) => video.videoName === 'words'
      ),
    };
  }

  const displayIntroVideoIcon =
    (activityData.key.includes('WORDS') && videos[0].videoName === 'words') ||
    (activityData.key.includes('LOGATOMS') &&
      videos[0].videoName === 'logatoms');

  return (
    <PageLoader isFadingOut>
      <Navbar.Title
        title={activityData.label}
        icon={<Coin type={groupKey.split('-')[0]} />}
      />
      {displayIntroVideoIcon && (
        <Navbar.Actions>
          <Navbar.EducationAction
            videos={videos}
            handleVideoEnded={handleVideoEnded}
          />
        </Navbar.Actions>
      )}
      <div className={containerClassNames}>
        <MenuFrame
          title={activityData.label}
          currencyImageUrl={activityData.currency.imageUrl}
        >
          {subactivities.map((activity) => (
            <ContentContainer
              key={activity.id}
              isAllowed={activity.isAllowed}
              allowedSubscriptionKeys={activity.allowedSubscriptionKeys}
              onClick={() => goToActivity(activity)}
            >
              <p>{activity.label}</p>
              <Image alt={activity.key} src={activity.imageUrl} />
            </ContentContainer>
          ))}
        </MenuFrame>
      </div>
    </PageLoader>
  );
};

SelectSubactivity.propTypes = {
  getSubactivitiesByActivityId: PropTypes.func.isRequired,
  activityData: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    currency: PropTypes.shape({
      id: PropTypes.number,
      key: PropTypes.string,
      imageUrl: PropTypes.string,
    }),
  }).isRequired,
  subactivities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  student: PropTypes.shape({
    hasPassedLogatomsEducation: PropTypes.bool.isRequired,
    hasPassedWordsEducation: PropTypes.bool.isRequired,
  }).isRequired,
  updateStudentById: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      activityId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  activityData: selectors.subactivities.getActivityData(state),
  hasLoaded: selectors.subactivities.getHasLoaded(state),
  subactivities: selectors.subactivities.getSubactivitiesData(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.subactivities,
  ...actions.students,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(SelectSubactivity);
