import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import cn from 'classnames';

import clouds from 'src/videos/clouds.webm';

import './index.scss';

const PageLoader = ({ isFadingOut, children }) => {
  const pageLoaderClassNames = useMemo(
    () =>
      cn(`page-loader`, {
        [`page-loader__animation--fade-out`]: isFadingOut,
      }),
    [isFadingOut]
  );

  return (
    <>
      {children}
      <video className={pageLoaderClassNames} autoPlay loop>
        <source src={clouds} type="video/webm" />
      </video>
    </>
  );
};

PageLoader.propTypes = {
  isFadingOut: PropTypes.bool,
  children: PropTypes.node,
};

PageLoader.defaultProps = {
  isFadingOut: false,
  children: null,
};

export default memo(PageLoader);
