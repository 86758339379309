import { createSelector } from 'reselect';

const getTooltipVisibility = (state, id) => {
  return state.tooltips.tooltips[id];
};

const getVisibility = createSelector(getTooltipVisibility, (show) => show);

const getHasLoaded = createSelector(
  (state) => state.tooltips.hasLoaded,
  (hasLoaded) => hasLoaded
);

const getIsTooltipVisible = createSelector(
  (state) => state.tooltips.tooltips,
  (tooltips) => Object.values(tooltips).some((value) => value === true)
);

export default { getVisibility, getHasLoaded, getIsTooltipVisible };
