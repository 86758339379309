import { Localize } from '@kokolingo/localisation';
import React, { memo, useEffect } from 'react';
import { useWizard } from 'src/hooks';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { Wizard } from 'src/components/common';
import {
  paths,
  payment,
  subscriptions as subscriptionsConstants,
} from 'src/constants';
import { actions, selectors } from 'src/store';

import {
  PaymentMethodSelection,
  SubscriptionSelection,
  SubscriptionSelectionReview,
  SubscriptionDurationSelection,
} from './components';

const STEPS = [
  {
    label: <Localize id="page.subscriptionSelection.wizardTitle" />,
    Component: SubscriptionSelection,
    isCompleted: (state) => !!state.subscription,
  },
  {
    label: <Localize id="page.subscriptionDurationSelection.wizardTitle" />,
    Component: SubscriptionDurationSelection,
    isCompleted: (state) => !!state.subscriptionDurationKey,
  },
  {
    label: <Localize id="page.paymentMethodSelection.wizardTitle" />,
    Component: PaymentMethodSelection,
    isCompleted: (state) => !!state.paymentMethod,
  },
  {
    label: <Localize id="page.subscriptionSelectionReview.wizardTitle" />,
    Component: SubscriptionSelectionReview,
  },
];

const initialWizardState = {
  paymentProvider: null,
  paymentMethod: null,
  paymentLabel: null,
  subscription: null,
  subscriptionDurationKey:
    subscriptionsConstants.DURATION.KEYS.SIX_MONTHS_FORWARD,
};

const SubscriptionWizard = ({ subscriptionStatus, getPaymentProviders }) => {
  const wizardProps = useWizard(STEPS, initialWizardState);

  useEffect(() => {
    getPaymentProviders();
  }, [getPaymentProviders]);

  return subscriptionStatus?.lastTransactionStatus ===
    payment.PAYMENT_STATUS.IN_PROGRESS ? (
    <Redirect to={paths.SUBSCRIPTION_STATUS} />
  ) : (
    <Wizard {...wizardProps}>{wizardProps.Component}</Wizard>
  );
};

SubscriptionWizard.propTypes = {
  subscriptionStatus: PropTypes.shape({
    lastTransactionStatus: PropTypes.string,
  }).isRequired,
  getPaymentProviders: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  subscriptionStatus: selectors.subscriptionStatus.getSubscriptionStatus(state),
});

const mapDispatchToProps = {
  ...actions.payment,
};

export default compose(
  memo,
  connect(mapStateToProps, mapDispatchToProps)
)(SubscriptionWizard);
