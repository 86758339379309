import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';

import './index.scss';

const Title = ({ title, icon, children }) => {
  const [navbarRoot, setNavbarRoot] = useState(
    document.getElementById('navbar')
  );

  useEffect(() => {
    if (!navbarRoot) {
      setNavbarRoot(document.getElementById('navbar'));
    }
  }, [navbarRoot, setNavbarRoot]);

  return (
    <div className="navbar-title-container">
      {children ? (
        <div className="navbar-title-container__children">{children}</div>
      ) : (
        <>
          <div className="navbar-title-container__icon">{icon}</div>
          {title && (
            <div className="navbar-title-container__title">{title}</div>
          )}
        </>
      )}
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.element,
  ]),
  icon: PropTypes.element,
  children: PropTypes.node,
};

Title.defaultProps = {
  title: null,
  icon: null,
  children: null,
};

export default memo(Title);
