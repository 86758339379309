const SELECTED_STUDENT_KEY = 'selectedStudentId';

const setSelectedStudentId = (studentId) =>
  localStorage.setItem(SELECTED_STUDENT_KEY, studentId);

const getSelectedStudentId = () => localStorage.getItem(SELECTED_STUDENT_KEY);

const clearSelectedStudentId = () =>
  localStorage.removeItem(SELECTED_STUDENT_KEY);

export default {
  clearSelectedStudentId,
  setSelectedStudentId,
  getSelectedStudentId,
};
