import React, { memo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { gameKeys, paths } from 'src/constants';
import Container from 'src/components/pages/Activities/Discover/Levels/Container';

const TIMEOUT_DURATION = 500;

const DiscoverStepThreeLevels = ({
  currency,
  levels,
  getLevelsByCollectionId,
  hasLoaded,
  unlockCollection,
  updateFeedback,
  student,
}) => {
  const history = useHistory();
  const gameKey = gameKeys.DISCOVER_STEP_THREE;
  const { activityId, collectionId } = useParams();
  const groupKeyWithId = useParams().groupKey;
  const groupKey = groupKeyWithId.split('-')[0];

  const handleLevelAnswer = (levelId, isCorrect, currencyId) => {
    updateFeedback({ currencyId, gameKey, levelId, isCorrect });
  };

  const handleCollectionEnd = () => {
    unlockCollection({ gameKey, collectionId });
    setTimeout(() => {
      history.push(
        paths.build(paths.DISCOVER_STEP_THREE, groupKeyWithId, activityId)
      );
    }, TIMEOUT_DURATION);
  };

  useEffect(() => {
    getLevelsByCollectionId(collectionId);
  }, [getLevelsByCollectionId, collectionId]);

  return (
    <Container
      className="discover-levels-container--step-three"
      groupKey={groupKey}
      handleCollectionEnd={handleCollectionEnd}
      handleLevelAnswer={handleLevelAnswer}
      hasLoaded={hasLoaded}
      items={levels}
      currency={currency}
      student={student}
    />
  );
};

DiscoverStepThreeLevels.propTypes = {
  currency: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getLevelsByCollectionId: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  unlockCollection: PropTypes.func.isRequired,
  updateFeedback: PropTypes.func.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.discoverStepThreeLevels.getHasLoaded(state),
  currency: selectors.discoverStepThreeLevels.getCurrencyData(state),
  levels: selectors.discoverStepThreeLevels.getLevelsData(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.discoverStepThree,
  ...actions.feedback,
  ...actions.students,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(DiscoverStepThreeLevels);
