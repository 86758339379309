import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';

import colors from 'src/theme/colors.scss';

import { actions, selectors } from 'src/store';
import { notifications } from 'src/constants';

import { Icon } from 'src/components/common';

import './index.scss';

const resolveIconName = (type) => {
  switch (type) {
    case notifications.type.ERROR:
      return 'close';

    case notifications.type.SUCCESS:
      return 'check';

    case notifications.type.INFO:
      return 'exclamation';

    default:
      return '';
  }
};

const Notification = ({ type, message, duration, clearNotification }) => {
  const iconContainerClassNames = useMemo(
    () =>
      cn(
        'notification__icon-container',
        `notification__icon-container--${type}`
      ),
    [type]
  );

  useEffect(() => {
    if (message && duration) {
      setTimeout(clearNotification, duration);
    }
  }, [message, duration, clearNotification]);

  if (!message) {
    return null;
  }

  return (
    <div className="notification">
      <div className={iconContainerClassNames}>
        <Icon name={resolveIconName(type)} size={70} />
      </div>
      <div className="notification__message">{message}</div>
      <div className="notification__close-icon-container">
        <Icon
          className="notification__close-icon-container__icon"
          name="close"
          size={25}
          onClick={clearNotification}
          color={colors.lightBrown}
        />
      </div>
    </div>
  );
};

Notification.propTypes = {
  clearNotification: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  type: PropTypes.oneOf([
    notifications.type.SUCCESS,
    notifications.type.ERROR,
    notifications.type.INFO,
  ]).isRequired,
};

const mapDispatchToProps = {
  ...actions.notifications,
};

const mapStateToProps = (state) => ({
  type: selectors.notifications.getType(state),
  message: selectors.notifications.getMessage(state),
  duration: selectors.notifications.getDuration(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(Notification);
