import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.students.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getStudent = createSelector(
  (state) => state.students.data,
  (student = {}) => student
);
const getIsSubmitting = createSelector(
  (state) => state.students.isSubmitting,
  (isSubmitting) => isSubmitting
);
const getInvitedUsers = createSelector(
  (state) => state.students.data.invitedUsers,
  (invitedUsers = []) => invitedUsers
);
const getUserOnlyStudents = createSelector(
  (state) => state.students.data.studentsForUser?.userOnlyStudents,
  (userOnlyStudents = []) => userOnlyStudents
);
const getUserExtraStudents = createSelector(
  (state) => state.students.data?.studentsForUser?.extraStudents,
  (extraStudents = []) => extraStudents
);
const getUserInactiveExtraStudents = createSelector(
  (state) => state.students.data?.studentsForUser?.inactiveExtraStudents,
  (inactiveExtraStudents = []) => inactiveExtraStudents
);

export default {
  getHasLoaded,
  getIsSubmitting,
  getInvitedUsers,
  getStudent,
  getUserExtraStudents,
  getUserOnlyStudents,
  getUserInactiveExtraStudents,
};
