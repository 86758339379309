import React from 'react';

import { paths } from 'src/constants';

import Localize from '../../Localize';

import navbarItemNames from './navbarItemNames';

const ACTIVITIES_NAVIGATION_ITEMS = {
  navigation: [navbarItemNames.BACK, navbarItemNames.SETTINGS],
};

const COMMON_NAVIGATION_ITEMS = [
  navbarItemNames.BACK,
  navbarItemNames.SETTINGS,
];

const USER_SETTINGS_MENU_ITEMS = [
  navbarItemNames.HELP,
  navbarItemNames.STATISTICS,
  navbarItemNames.SPEECH_SOUND,
  navbarItemNames.PAYMENT,
  navbarItemNames.SETTINGS_COUPON,
  navbarItemNames.SETTINGS_SOUND,
  ...(process.env.REACT_APP_DAILY_MAP === 'true'
    ? [navbarItemNames.SETTINGS_DAILY_MAP]
    : []),
  navbarItemNames.SETTINGS_USER,
  navbarItemNames.LOGOUT,
];

const SPEECH_THERAPIST_MENU_ITEMS = [
  navbarItemNames.HELP,
  navbarItemNames.STATISTICS,
  navbarItemNames.SPEECH_SOUND,
  navbarItemNames.SETTINGS_SOUND,
  navbarItemNames.SPEECH_THERAPIST_STUDENT,
  navbarItemNames.SPEECH_THERAPIST_BACK,
  navbarItemNames.LOGOUT,
];

// order is important for regex matching
export default {
  [paths.ARTICULATION_GYMNASTICS_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.ARTICULATION_GYMNASTICS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.CLIMB_STEP_ONE_EXTRA_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.CLIMB_STEP_ONE_EXTRA]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.CLIMB_STEP_ONE_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.CLIMB_STEP_ONE]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.CLIMB_STEP_TWO_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.CLIMB_STEP_TWO]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.CLIMB_STEP_THREE_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.CLIMB_STEP_THREE]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.DISCOVER_STEP_ONE_EXTRA_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.DISCOVER_STEP_ONE_EXTRA]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.DISCOVER_STEP_ONE_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.DISCOVER_STEP_ONE]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.DISCOVER_STEP_TWO_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.DISCOVER_STEP_TWO]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.DISCOVER_STEP_THREE_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.DISCOVER_STEP_THREE]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FIND_WORDS_STEP_ONE_EXTRA_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FIND_WORDS_STEP_ONE_EXTRA]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FIND_WORDS_STEP_ONE_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FIND_WORDS_STEP_ONE]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FIND_WORDS_STEP_TWO_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FIND_WORDS_STEP_TWO]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FIND_WORDS_STEP_THREE_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FIND_WORDS_STEP_THREE]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FLASH_CARDS_STEP_ONE_EXTRA_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FLASH_CARDS_STEP_ONE_EXTRA]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FLASH_CARDS_STEP_ONE_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FLASH_CARDS_STEP_ONE]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FLASH_CARDS_STEP_TWO_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FLASH_CARDS_STEP_TWO]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FLASH_CARDS_STEP_THREE_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FLASH_CARDS_STEP_THREE]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.HELP]: {
    badgeIcon: 'questionmark',
    title: <Localize id="page.settings.navigation.help" />,
    navigation: COMMON_NAVIGATION_ITEMS,
  },
  [paths.LICENCE_STATUS]: {
    navigation: COMMON_NAVIGATION_ITEMS,
  },
  [paths.MAP]: {
    navigation: [navbarItemNames.SHOP, navbarItemNames.SETTINGS],
  },
  [paths.MEMORY_STEP_THREE_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.MEMORY_STEP_THREE_COLLECTIONS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.MEMORY_STEP_THREE]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.MEMORY_STEP_TWO_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.MEMORY_STEP_TWO_COLLECTIONS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.MEMORY_STEP_TWO]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.MEMORY_STEP_ONE_EXTRA_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.MEMORY_STEP_ONE_EXTRA_COLLECTIONS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.MEMORY_STEP_ONE_EXTRA]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.MEMORY_STEP_ONE_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.MEMORY_STEP_ONE_COLLECTIONS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.MEMORY_STEP_ONE]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.PAINTING_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.PAINTING_COLLECTIONS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.PAINTING]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.READ_AND_THINK_BONUS_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.READ_AND_THINK_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.READ_AND_THINK]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.READ_TOGETHER_BONUS_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.READ_TOGETHER_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.READ_TOGETHER]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.REPLENISHMENT]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.REPLENISHMENT_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.SUBSCRIPTION_PAYMENT]: { navigation: COMMON_NAVIGATION_ITEMS },
  [paths.PAYMENT_ROUTER]: {
    navigation: COMMON_NAVIGATION_ITEMS,
  },
  [paths.SUBSCRIPTIONS]: {
    badgeIcon: 'card',
    navigation: COMMON_NAVIGATION_ITEMS,
    title: <Localize id="page.settings.navigation.subscription" />,
  },
  [paths.SUBSCRIPTION_STATUS]: {
    badgeIcon: 'card',
    title: <Localize id="page.settings.navigation.subscription" />,
    navigation: COMMON_NAVIGATION_ITEMS,
  },
  [paths.SETTINGS_COUPON]: {
    badgeIcon: 'gift',
    title: <Localize id="page.settings.navigation.coupon" />,
    navigation: COMMON_NAVIGATION_ITEMS,
  },
  [paths.SETTINGS_DAILY_MAP]: {
    badgeIcon: 'map',
    title: <Localize id="page.settings.navigation.dailyMapSettings" />,
    navigation: COMMON_NAVIGATION_ITEMS,
  },
  [paths.SETTINGS_USER]: {
    badgeIcon: 'user',
    title: <Localize id="page.settings.navigation.userData" />,
    navigation: COMMON_NAVIGATION_ITEMS,
  },
  [paths.SETTINGS_SOUND]: {
    badgeIcon: 'sound',
    title: <Localize id="page.settings.navigation.soundSettings" />,
    navigation: COMMON_NAVIGATION_ITEMS,
  },
  [paths.SETTINGS_STUDENT]: {
    navigation: COMMON_NAVIGATION_ITEMS,
  },
  [paths.SETTINGS]: {
    navigation: [navbarItemNames.MAP],
  },
  [paths.SPEECH_SOUND_SELECTION]: {
    badgeIcon: 'sound-selection',
    navigation: [navbarItemNames.MAP, navbarItemNames.SETTINGS],
    title: <Localize id="page.settings.navigation.speechSoundSelection" />,
  },
  [paths.REGISTER_SPEECH_THERAPIST]: { navigation: [] },
  [paths.SPEECH_THERAPIST]: {
    navigation: [
      navbarItemNames.MAP,
      navbarItemNames.HELP,
      navbarItemNames.LOGOUT,
    ],
  },
  [paths.SHOP]: {
    badgeIcon: 'shop',
    navigation: [navbarItemNames.BACK, navbarItemNames.SETTINGS],
    title: <Localize id="page.settings.navigation.shop" />,
  },
  [paths.STATISTICS]: {
    badgeIcon: 'statistic',
    navigation: COMMON_NAVIGATION_ITEMS,
    title: <Localize id="page.settings.navigation.statistics" />,
  },
  [paths.TREASURE_HUNT_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.TREASURE_HUNT_COLLECTIONS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.TREASURE_HUNT]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.WHEEL_OF_FORTUNE_LEVELS]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.WHEEL_OF_FORTUNE]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.FORBIDDEN]: {
    navigation: COMMON_NAVIGATION_ITEMS,
  },
  [paths.DAILY_MAP]: { navigation: [navbarItemNames.SETTINGS] },
  // activity and subactivity must be last in this order
  [paths.SUBACTIVITY]: ACTIVITIES_NAVIGATION_ITEMS,
  [paths.ACTIVITY]: {
    navigation: [navbarItemNames.MAP, navbarItemNames.SETTINGS],
  },
  USER_SETTINGS_MENU_ITEMS,
  SPEECH_THERAPIST_MENU_ITEMS,
};
