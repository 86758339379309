import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const FindWordsStepOneCollections = ({
  findWordsStepOneCollections,
  findWordsStepOneCurrency,
  getCollections,
  hasLoaded,
  pageCount,
  student,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="find-words-collections-container--step-one"
      collectionsPath={paths.FIND_WORDS_STEP_ONE}
      currency={findWordsStepOneCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={findWordsStepOneCollections}
      levelsPath={paths.FIND_WORDS_STEP_ONE_LEVELS}
      pageCount={pageCount}
      student={student}
      title={messages.game.findWords}
    />
  );
};

FindWordsStepOneCollections.propTypes = {
  findWordsStepOneCollections: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  findWordsStepOneCurrency: PropTypes.shape({}).isRequired,
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.findWordsStepOneCollections.getHasLoaded(state),
  findWordsStepOneCurrency: selectors.findWordsStepOneCollections.getCurrencyData(
    state
  ),
  findWordsStepOneCollections: selectors.findWordsStepOneCollections.getCollectionsData(
    state
  ),
  pageCount: selectors.findWordsStepOneCollections.getPageCount(state),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.findWordsStepOne,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(FindWordsStepOneCollections);
