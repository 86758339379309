import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Modal, FeedbackComponent } from 'src/components/common';
import { selectors } from 'src/store';
import { useIntlMessages } from 'src/hooks';

import './index.scss';

const ModalScore = ({ children, close, show, stats, title }) => {
  const messages = useIntlMessages();

  return (
    <Modal
      show={show}
      onClose={close}
      onOutsideClick={close}
      className="daily-map-modal"
    >
      <Modal.Title>{title}</Modal.Title>
      <Modal.Description className="daily-map-modal__info">
        <div className="daily-map-modal__info__title">
          {messages.modal.dailyMap.scoreSolved} <b>{stats.solved}</b>
        </div>
        <div className="daily-map-modal__info__stats">
          <div>
            {messages.modal.dailyMap.scoreCorrect} <b>{stats.correctAnswers}</b>
            <FeedbackComponent collapsed hideText isFeedbackPositive />
          </div>
          <div>
            {messages.modal.dailyMap.scoreWrong} <b>{stats.wrongAnswers}</b>
            <FeedbackComponent collapsed hideText />
          </div>
        </div>
        {children}
      </Modal.Description>
    </Modal>
  );
};

ModalScore.propTypes = {
  children: PropTypes.node,
  close: PropTypes.func.isRequired,
  show: PropTypes.bool,
  stats: PropTypes.shape({
    solved: PropTypes.string,
    correctAnswers: PropTypes.string,
    wrongAnswers: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

ModalScore.defaultProps = {
  children: null,
  show: null,
};

const mapStateToProps = (state) => ({
  stats: selectors.dailyMap.getDailyMapFeedback(state),
});

export default compose(connect(mapStateToProps), memo)(ModalScore);
