import { actions, paths } from 'src/constants';

export default {
  activateCoupon: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.COUPONS_ACTIVATE_REQUEST,
        actions.COUPONS_ACTIVATE_SUCCESS,
        actions.COUPONS_ACTIVATE_FAILURE,
      ],
      promise: (client) => client.post(paths.api.COUPONS_ACTIVATE, data),
    },
  }),

  getStatus: () => ({
    [actions.API_CALL]: {
      types: [
        actions.COUPONS_STATUS_GET_REQUEST,
        actions.COUPONS_STATUS_GET_SUCCESS,
        actions.COUPONS_STATUS_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.api.COUPONS_STATUS),
    },
  }),
};
