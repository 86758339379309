export default {
  // game colors
  ARTICULATION_GYMNASTICS: '#FFA69E',
  TREASURE_HUNT: '#F9A03F',
  PAINTING: '#50B2C0',
  CLIMB: '#FF784F',
  DISCOVER: '#0CCA4A',
  FLASH_CARDS: '#E55381',
  FIND_WORDS: '#247BA0',
  MEMORY: '#595F72',
  WHEEL_OF_FORTUNE: '#F87666',
  REPLENISHMENT: '#5E239D',
  READ_TOGETHER: '#f7c104',
  READ_AND_THINK: '#6F1A07',

  // island colors
  GOLD: '#ffcc00',
  ORANGE: '#ff9933',
  ORANGE_SPECIAL: '#ffad33',
  GREEN: '#33cc33',
  PURPLE: '#6666ff',
  RED: '#cc3300',
  BLUE: '#0099ff',
};
