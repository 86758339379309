import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { common } from 'src/utils';
import { feedback, video } from 'src/constants';
import { useAudio, useIntlMessages } from 'src/hooks';

import {
  Container,
  FeedbackContainer,
  FeedbackPanel,
  FeedbackPreview,
  GameCoins,
  LevelContent,
  PageLoader,
  Navbar,
  Zoom,
  Topbar,
} from 'src/components/common';

import LevelCard from './components/LevelCard';

import './index.scss';

const ORIENTATIONS = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
const ORIENTATION_POSITIONS = [1, 2, 3, 4];
const SHAKE_DURATION = 500;
const TERMS_COUNT = 8;

const FindWordsContainer = ({
  className,
  coverImage,
  currency,
  handleCollectionEnd,
  handleLevelAnswer,
  hasLoaded,
  items,
  navigation,
  student,
}) => {
  const messages = useIntlMessages();

  const [cleanedTerms, setCleanedTerms] = useState([]);
  const [collectedCoins, setCollectedCoins] = useState(0);
  const [discoveredTerms, setDiscoveredTerms] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [sceneItems, setSceneItems] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState({});
  const [shake, setShake] = useState(false);

  const [playAudio, isAudioPlaying] = useAudio();

  const scenePositions = useMemo(
    () =>
      ORIENTATIONS.map(
        (orientation) =>
          `${orientation}-${
            ORIENTATION_POSITIONS[
              Math.floor(Math.random() * ORIENTATION_POSITIONS.length)
            ]
          }`
      ),
    []
  );

  const handleAnswer = useCallback(
    (isCorrect) => {
      playAudio(feedback.COIN_COLLECT_SOUND_URL, () => {
        if (collectedCoins + 1 >= items.length && items.length > 0) {
          playAudio(feedback.LEVEL_END_SOUND_URL, handleCollectionEnd);
        }
      });

      handleLevelAnswer(selectedTerm.id, isCorrect, currency?.id);
      setCollectedCoins((prevCollectedCoins) => prevCollectedCoins + 1);
      setDisplayModal(false);

      setCleanedTerms([
        ...cleanedTerms,
        {
          selectedTermKey: selectedTerm.key,
          isHidden: true,
        },
      ]);
    },
    [
      cleanedTerms,
      collectedCoins,
      currency,
      handleCollectionEnd,
      handleLevelAnswer,
      items.length,
      playAudio,
      selectedTerm.id,
      selectedTerm.key,
    ]
  );

  const handleReveal = useCallback(
    (term) => {
      if (
        discoveredTerms.length > cleanedTerms.length ||
        discoveredTerms.some(
          (discoveredTerm) => discoveredTerm.selectedTermKey === term.key
        ) ||
        isAudioPlaying
      ) {
        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, SHAKE_DURATION);
        return;
      }

      if (term.key.includes('distractor')) {
        if (student.hasEnabledErrorSound) {
          playAudio(feedback.BAD_SOUND_URL);
        }

        setCleanedTerms([
          ...cleanedTerms,
          {
            selectedTermKey: term.key,
            isHidden: true,
          },
        ]);
      } else {
        playAudio(feedback.HAPPY_SOUND_URL, () => {
          if (term.audioUrl !== '') {
            playAudio(term.audioUrl, () => {
              if (!displayModal) {
                setDisplayModal(true);
                setSelectedTerm(term);
              }
            });
          } else if (!displayModal) {
            setDisplayModal(true);
            setSelectedTerm(term);
          }
        });
      }

      setDiscoveredTerms([
        ...discoveredTerms,
        {
          selectedTermKey: term.key,
          isHidden: true,
        },
      ]);
    },
    [
      cleanedTerms,
      discoveredTerms,
      displayModal,
      isAudioPlaying,
      playAudio,
      student.hasEnabledErrorSound,
    ]
  );

  useEffect(() => {
    if (!hasLoaded) {
      return;
    }

    const distractors = [];
    for (let i = 0; i < TERMS_COUNT - items.length; i += 1) {
      distractors.push({
        id: -1,
        key: `${i}-distractor`,
        audioUrl: '',
        imageUrl: '',
      });
    }

    let gameItems = items.concat(distractors);
    gameItems = common.shuffle(gameItems);

    setSceneItems(
      scenePositions.map((scenePosition, index) => ({
        ...gameItems[index],
        scenePosition,
      }))
    );
  }, [items, hasLoaded, scenePositions]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      {navigation}
      <Navbar.GameNavbar
        currency={currency}
        title={messages.game.findWords}
        videoName={video.NAME.FIND_WORDS}
      />
      <Topbar>
        <GameCoins
          type={currency.key}
          totalCoinsCount={items.length}
          collectedCoinsCount={collectedCoins}
          coinSize="small"
        />
      </Topbar>
      <Container className={cn('find-words-levels-container', className)}>
        <FeedbackContainer isVisible={displayModal}>
          <FeedbackPreview.Sound
            handleListenAgain={() => playAudio(selectedTerm.audioUrl)}
          >
            <LevelContent.Text label={selectedTerm.key} size="small" />
            <LevelContent.Image
              alt={selectedTerm.key}
              src={selectedTerm.imageUrl}
            />
          </FeedbackPreview.Sound>
          <FeedbackPanel.Grades onClick={handleAnswer} />
        </FeedbackContainer>
        <Zoom mobileWidth={340} mobileHeight={512}>
          <div className="find-words-levels-container__body">
            {sceneItems.map((item, index) => (
              <LevelCard
                key={index}
                level={item}
                cleanedTerms={cleanedTerms}
                coverImage={coverImage}
                discoveredTerms={discoveredTerms}
                shake={shake}
                handleReveal={handleReveal}
              />
            ))}
          </div>
        </Zoom>
      </Container>
    </PageLoader>
  );
};

FindWordsContainer.propTypes = {
  className: PropTypes.string.isRequired,
  coverImage: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    imageUrl: PropTypes.string,
    placeholderImageUrl: PropTypes.string,
  }),
  handleCollectionEnd: PropTypes.func.isRequired,
  handleLevelAnswer: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      audioUrl: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
  navigation: PropTypes.node,
  student: PropTypes.shape({
    hasEnabledErrorSound: PropTypes.bool.isRequired,
  }).isRequired,
};

FindWordsContainer.defaultProps = {
  navigation: null,
  currency: null,
  items: [],
};

export default memo(FindWordsContainer);
