import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useIntlMessages } from 'src/hooks';

import { Badge, Image } from 'src/components/common';

import cancel from 'src/images/store/cancel.png';
import wardrobe from 'src/images/store/wardrobe.png';

import './index.scss';

const ShopItem = ({
  costs,
  isBought,
  isSelected,
  isDressed,
  label,
  onBuy,
  onCancel,
  onClick,
  onUndress,
  thumbnailUrl,
}) => {
  const messages = useIntlMessages();

  const shopItemClassName = cn('shop-item', {
    'shop-item--bought': isBought,
  });

  return (
    <div
      className={shopItemClassName}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      {(isSelected || isDressed) && (
        <div className="shop-item__overlay">
          {!isDressed && (
            <Image
              className="shop-item__overlay__cancel-icon"
              src={cancel}
              alt="cancel"
              onClick={onCancel}
            />
          )}
          <div className="shop-item__overlay__buy-container">
            <div className="shop-item__overlay__buy-container__label">
              {isDressed
                ? messages.page.shop.button.returnToLocker
                : messages.page.shop.button.buy}
            </div>
            <Badge
              iconName={isDressed ? 'wardrobe' : 'cart'}
              type={isDressed ? 'primary' : 'success'}
              size="small"
              onClick={isDressed ? onUndress : onBuy}
            />
          </div>
        </div>
      )}
      <Image className="shop-item__thumbnail" src={thumbnailUrl} alt={label} />
      {isBought ? (
        <Image
          className="shop-item__bought-icon"
          src={wardrobe}
          alt="wardrobe"
        />
      ) : (
        <div className="shop-item__costs">
          {costs.map((cost) => (
            <div key={cost.currency.key} className="shop-item__costs__display">
              <Image
                className="shop-item__costs__display__currency-img"
                alt={cost.currency.key}
                src={cost.currency.imageUrl}
              />
              <div className="shop-item__costs__display__amount">
                {cost.amount}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

ShopItem.propTypes = {
  costs: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      currency: PropTypes.shape({
        key: PropTypes.string.isRequired,
        imageUrl: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  isBought: PropTypes.bool,
  isDressed: PropTypes.bool,
  isSelected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onBuy: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onUndress: PropTypes.func.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
};

ShopItem.defaultProps = {
  isSelected: false,
  isBought: false,
  isDressed: false,
  onClick: () => {},
};

export default memo(ShopItem);
