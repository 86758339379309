import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import { useIntlMessages } from 'src/hooks';

import { Container } from './components';

const ClimbStepThreeCollections = ({
  climbStepThreeCollections,
  climbStepThreeCurrency,
  getCollections,
  hasLoaded,
  student,
}) => {
  const messages = useIntlMessages();

  return (
    <Container
      className="climb-collections-container--step-three"
      currency={climbStepThreeCurrency}
      getCollections={getCollections}
      hasLoaded={hasLoaded}
      items={climbStepThreeCollections}
      levelsPath={paths.CLIMB_STEP_THREE_LEVELS}
      student={student}
      title={messages.subactivity.climbStepThree}
    />
  );
};

ClimbStepThreeCollections.propTypes = {
  climbStepThreeCollections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  climbStepThreeCurrency: PropTypes.shape({}).isRequired,
  getCollections: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  student: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.climbStepThreeCollections.getHasLoaded(state),
  climbStepThreeCollections: selectors.climbStepThreeCollections.getCollectionsData(
    state
  ),
  climbStepThreeCurrency: selectors.climbStepThreeCollections.getCurrencyData(
    state
  ),
  student: selectors.authentication.getStudent(state),
});

const mapDispatchToProps = {
  ...actions.climbStepThree,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ClimbStepThreeCollections);
