import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import cn from 'classnames';

import './index.scss';

const Title = ({ className, children }) => {
  const titleClassNames = useMemo(() => cn('modal-title', className), [
    className,
  ]);

  return (
    <div className={titleClassNames}>
      <div>{children}</div>
    </div>
  );
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Title.defaultProps = {
  className: '',
};

export default memo(Title);
