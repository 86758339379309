import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import _startCase from 'lodash/startCase';

import { actions } from 'src/store';
import {
  notifications,
  subscriptions as subscriptionsConstants,
} from 'src/constants';
import { useIntlMessages, useMakePayment, useQuery } from 'src/hooks';
import { subscriptions as subscriptionsUtils } from 'src/utils';

import { InfoField, Localize, Title, Page } from 'src/components/common';

import './index.scss';
import ButtonNext from '../ButtonNext';

const SubscriptionSelectionReview = ({ showNotification, wizardState }) => {
  const messages = useIntlMessages();
  const query = useQuery();

  const {
    subscription,
    subscriptionDurationKey,
    paymentProvider,
    paymentMethod,
    paymentLabel,
  } = wizardState;

  const makePayment = useMakePayment(
    wizardState.subscription.id,
    wizardState.subscriptionDurationKey,
    wizardState.paymentLabel,
    wizardState.subscription.key
  );

  const totalPrice = subscriptionsUtils
    .getDiscountedPriceForDuration(subscriptionDurationKey, subscription.price)
    .toFixed(2);
  const totalAlternatePrice = subscriptionsUtils
    .getDiscountedPriceForDuration(
      subscriptionDurationKey,
      subscription.alternatePrice
    )
    .toFixed(2);
  const durationLabel = messages()[
    subscriptionsConstants.DURATION[subscriptionDurationKey]
  ];

  useEffect(() => {
    if (query.has('failed')) {
      showNotification(
        messages.notification.paymentFailed,
        notifications.type.ERROR,
        notifications.duration.SHORT
      );
    }
  }, [messages, query, showNotification]);

  return (
    <>
      <Page.Body className="selection-review-page">
        <Title
          className="selection-review-page__title"
          text={messages.page.subscriptionSelectionReview.title}
        />
        <InfoField
          className="selection-review-page__info-field"
          label={messages.label.selectedSubscription}
          value={
            messages({ subscriptionLabel: _startCase(subscription.label) })[
              'label.selectedSubscription.value'
            ]
          }
        />
        <InfoField
          className="selection-review-page__info-field"
          label={messages.label.selectedSubscriptionDuration}
          value={
            messages({
              duration: durationLabel,
            })['label.selectedSubscriptionDuration.value']
          }
        />
        <InfoField
          className="selection-review-page__info-field"
          label={messages.label.selectedPaymentMethod}
          value={paymentLabel}
        />
        <InfoField
          className="selection-review-page__info-field"
          valueClassName="selection-review-page__total-price"
          label={messages.label.totalPrice}
          value={
            messages({
              amount: (
                <Localize.Currency
                  id="meta.currency"
                  value={totalPrice}
                  currencyDisplay="code"
                />
              ),
              alternateAmount: totalAlternatePrice !== '0.00' && (
                <Localize.Currency
                  id="meta.alternateCurrency"
                  value={totalAlternatePrice}
                  currencyDisplay="code"
                  locale="de"
                />
              ),
              duration: durationLabel,
            })[
              totalAlternatePrice !== '0.00'
                ? 'label.selectedPaymentMethod.unitAlternate'
                : 'label.selectedPaymentMethod.unit'
            ]
          }
        />
      </Page.Body>
      <Page.Footer>
        <ButtonNext
          variant="register"
          onClick={() => makePayment(paymentProvider, paymentMethod)}
        >
          {messages.page.subscriptionSelectionReview.button}
        </ButtonNext>
      </Page.Footer>
    </>
  );
};

SubscriptionSelectionReview.propTypes = {
  showNotification: PropTypes.func.isRequired,
  wizardState: PropTypes.shape({
    paymentMethod: PropTypes.string,
    subscription: PropTypes.shape({
      id: PropTypes.number,
      key: PropTypes.string,
      label: PropTypes.string,
      price: PropTypes.number,
      alternatePrice: PropTypes.number,
    }),
    subscriptionDurationKey: PropTypes.string,
    paymentProvider: PropTypes.string,
    paymentLabel: PropTypes.string,
  }).isRequired,
};

const mapDispatchToProps = {
  ...actions.notifications,
  ...actions.subscriptions,
};

export default compose(
  connect(null, mapDispatchToProps),
  memo
)(SubscriptionSelectionReview);
