import { actions, paths } from 'src/constants';
import { MAX_PAGE_SIZE } from 'src/constants/common';

export default {
  getCollections: (page, pageSize = MAX_PAGE_SIZE) => ({
    [actions.API_CALL]: {
      types: [
        actions.FIND_WORDS_STEP_ONE_EXTRA_COLLECTIONS_GET_REQUEST,
        actions.FIND_WORDS_STEP_ONE_EXTRA_COLLECTIONS_GET_SUCCESS,
        actions.FIND_WORDS_STEP_ONE_EXTRA_COLLECTIONS_GET_FAILURE,
      ],
      promise: (client) =>
        client.get(paths.api.FIND_WORDS_STEP_ONE_EXTRA, {
          params: {
            page,
            pageSize,
          },
        }),
    },
  }),
  getLevelsByCollectionId: (collectionId) => ({
    [actions.API_CALL]: {
      types: [
        actions.FIND_WORDS_STEP_ONE_EXTRA_LEVELS_GET_REQUEST,
        actions.FIND_WORDS_STEP_ONE_EXTRA_LEVELS_GET_SUCCESS,
        actions.FIND_WORDS_STEP_ONE_EXTRA_LEVELS_GET_FAILURE,
      ],
      promise: (client) =>
        client.get(
          paths.build(
            paths.api.FIND_WORDS_STEP_ONE_EXTRA_COLLECTIONS_ID_LEVELS,
            collectionId
          )
        ),
    },
  }),
};
