import React from 'react';

import { Button, Image, Localize } from 'src/components/common';
import arrowRight from 'src/images/arrowRight.png';

import './index.scss';

const ButtonNext = ({ children, ...props }) => (
  <Button className="button-next" collapsed {...props}>
    {children || <Localize id="button.forward" />}
    <Image src={arrowRight} alt="" height="20px" />
  </Button>
);

ButtonNext.propTypes = Button.propTypes;

export default ButtonNext;
