import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.speechSounds.hasLoaded,
  (hasLoaded) => hasLoaded
);

const getSpeechSoundsData = createSelector(
  (state) => state.speechSounds.data,
  (studentSpeechSounds) => studentSpeechSounds
);

const getSpeechSoundDataById = createSelector(
  (state, speechSoundId) => {
    const parsed = parseInt(speechSoundId, 10);

    return state.speechSounds.data.find(({ id }) => id === parsed);
  },
  (speechSoundData = {}) => speechSoundData
);

const getSpeechSound = createSelector(
  (state) => state.speechSounds.data[0],
  (studentSpeechSound = {}) => studentSpeechSound
);

const getIsLoading = createSelector(
  (state) => state.speechSounds.isLoading,
  (isLoading) => isLoading
);

export default {
  getHasLoaded,
  getSpeechSoundsData,
  getSpeechSoundDataById,
  getSpeechSound,
  getIsLoading,
};
