import React, { memo } from 'react';

import { useIntlMessages } from 'src/hooks';

import { Page } from 'src/components/common';

import './index.scss';

const ForbiddenPage = () => {
  const messages = useIntlMessages();
  // @TODO Design it
  return (
    <Page containerClassName="forbidden-page-container">
      <div>{messages.label.forbiddenPage}</div>
    </Page>
  );
};

export default memo(ForbiddenPage);
