import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from 'src/store';

const getUnplayedVideosCount = (videos, userPlayedVideos) =>
  videos.reduce((count, video) => {
    if (!userPlayedVideos[video.videoName]) {
      // eslint-disable-next-line no-param-reassign
      count += 1;
    }
    return count;
  }, 0);

const useUserVideos = (videos) => {
  const dispatch = useDispatch();

  const userPlayedVideos = useSelector(selectors.user.getUserPlayedVideos);

  const unplayedVideosCount = getUnplayedVideosCount(videos, userPlayedVideos);

  const markPlayed = useCallback(
    (videoName) => {
      if (!userPlayedVideos[videoName]) {
        dispatch(actions.userMeta.setVideoPlayed(videoName));
      }
    },
    [dispatch, userPlayedVideos]
  );

  return [unplayedVideosCount, markPlayed];
};

export default useUserVideos;
