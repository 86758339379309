import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { useIntlMessages } from 'src/hooks';

import { Form, PasswordInput } from 'src/components/common';

import validationSchema from './validationSchema';

import './index.scss';

const PasswordSetup = ({ onSubmit }) => {
  const messages = useIntlMessages();

  return (
    <div className="password-setup-container">
      <span className="password-setup-container__title">
        {messages.label.setupNewPassword}
      </span>
      <Form
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        buttonClassName="collapsed"
        submitBtnLabel={messages.button.savePassword}
        formClassName="password-setup-container__form"
      >
        <PasswordInput
          label={messages.label.currentPassword}
          name="currentPassword"
          required
        />
        <PasswordInput
          label={messages.label.newPassword}
          name="password"
          required
        />
        <PasswordInput
          label={messages.label.repeatNewPassword}
          name="passwordConfirmation"
          required
        />
      </Form>
    </div>
  );
};

PasswordSetup.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default memo(PasswordSetup);
