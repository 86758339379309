import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Image, Zoom } from 'src/components/common';
import LevelSection from '../LevelSection';
import LevelAwardCover from '../LevelAwardCover';

import './index.scss';

const LevelScene = ({
  cleanTerm,
  cleanedTerms,
  discoveredPositions,
  canPaintPairs,
  sceneTerms,
  selectTerm,
  selectedTermId,
  shakeTermId,
  groupKey,
}) => {
  return (
    <Zoom
      mobileWidth={280}
      mobileHeight={529}
      mobileRatio="calc(100% - 100px)"
      align="center"
    >
      <div className="discover-level-scene">
        <div className="discover-level-scene__cards-container">
          <LevelSection
            cleanTerm={cleanTerm}
            cleanedTerms={cleanedTerms}
            items={sceneTerms}
            canPaintPairs={canPaintPairs}
            selectTerm={selectTerm}
            selectedTermId={selectedTermId}
            shakeTermId={shakeTermId}
          />
        </div>
        <Zoom
          mobileWidth={280}
          mobileHeight={280}
          mobileRatio="280px"
          align="center"
        >
          <LevelAwardCover
            discoveredPositions={discoveredPositions}
            groupKey={groupKey}
          />
          {!!discoveredPositions.length && (
            <Image
              className="discover-level-scene__award"
              alt="award_image"
              src={sceneTerms[0].awardImageUrl}
            />
          )}
        </Zoom>
      </div>
    </Zoom>
  );
};

LevelScene.propTypes = {
  cleanTerm: PropTypes.func.isRequired,
  cleanedTerms: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  discoveredPositions: PropTypes.arrayOf(PropTypes.number.isRequired)
    .isRequired,
  canPaintPairs: PropTypes.bool.isRequired,
  sceneTerms: PropTypes.arrayOf(
    PropTypes.shape({
      awardImageUrl: PropTypes.string.isRequired,
    })
  ),
  selectTerm: PropTypes.func.isRequired,
  selectedTermId: PropTypes.number,
  shakeTermId: PropTypes.number,
  groupKey: PropTypes.string.isRequired,
};

LevelScene.defaultProps = {
  sceneTerms: [],
  selectedTermId: null,
  shakeTermId: null,
};

export default memo(LevelScene);
