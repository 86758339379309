import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';

const WalletProvider = ({
  children,
  getWallet,
  hasWalletLoaded,
  selectedStudentId,
}) => {
  useEffect(() => {
    const shouldLoadWallet = !!selectedStudentId;

    if (shouldLoadWallet && !hasWalletLoaded) {
      getWallet();
    }
  }, [getWallet, hasWalletLoaded, selectedStudentId]);

  return children;
};

WalletProvider.propTypes = {
  getWallet: PropTypes.func.isRequired,
  hasWalletLoaded: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  hasWalletLoaded: selectors.wallet.getHasLoaded(state),
  selectedStudentId: selectors.student.getSelectedStudentId(state),
});

const mapDispatchToProps = {
  ...actions.wallet,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(WalletProvider);
