import { subscriptions } from 'src/constants';

const ACTIVE_TAB_ID = 'activeTabId';

const getSpeechTherapistData = (user, messages, userOnlyStudentsCount) => {
  const unusedStudentAccounts =
    user.studentAccountsCapacity - userOnlyStudentsCount;

  const usedProfileString =
    userOnlyStudentsCount === 2
      ? messages.page.speechTherapistsHome.packageStudents.usedProfileString
      : messages.page.speechTherapistsHome.packageStudents
          .usedProfileStringPlural;
  const unusedProfileString =
    unusedStudentAccounts === 1
      ? messages.page.speechTherapistsHome.packageStudents.unusedProfileString
      : messages.page.speechTherapistsHome.packageStudents
          .unusedProfileStringPlural;

  let packageStudentsInfo = '';
  if (user.studentAccountsCapacity === 1) {
    packageStudentsInfo =
      messages.page.speechTherapistsHome.packageStudents.emptySlots;
  } else if (unusedStudentAccounts) {
    packageStudentsInfo = messages({
      totalUsedAccounts: userOnlyStudentsCount - 1,
      unusedStudentAccounts,
      usedProfileString,
      unusedProfileString,
      subscription: subscriptions[user.subscriptionKey].LABEL,
    }).page.speechTherapistsHome.packageStudents.slotsInfo;
  } else {
    packageStudentsInfo = messages({
      totalUsedAccounts: userOnlyStudentsCount - 1,
      usedProfileString,
      unusedProfileString,
      subscription: subscriptions[user.subscriptionKey].LABEL,
    }).page.speechTherapistsHome.packageStudents.noAvailableSlots;
  }

  return { unusedStudentAccounts, packageStudentsInfo };
};

const getActiveTabId = () =>
  localStorage.getItem(ACTIVE_TAB_ID)
    ? parseInt(localStorage.getItem(ACTIVE_TAB_ID), 10)
    : 1;

const setActiveTabId = (tabId) => localStorage.setItem(ACTIVE_TAB_ID, tabId);

export default {
  getActiveTabId,
  getSpeechTherapistData,
  setActiveTabId,
};
