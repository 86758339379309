import React, { memo } from 'react';
import PropTypes from 'prop-types';

import LevelAwardCoverItemsSection from '../LevelAwardCoverItemsSection';

import './index.scss';

const positionNames = ['first', 'second', 'third', 'fourth', 'fifth'];
const LevelAwardCover = ({ discoveredPositions, groupKey }) => {
  return (
    <div className="discover-level-award-cover">
      <LevelAwardCoverItemsSection
        discoveredPositions={discoveredPositions}
        positionNames={positionNames}
        groupKey={groupKey}
      />
    </div>
  );
};

LevelAwardCover.propTypes = {
  discoveredPositions: PropTypes.arrayOf(PropTypes.number.isRequired)
    .isRequired,
  groupKey: PropTypes.string.isRequired,
};

export default memo(LevelAwardCover);
