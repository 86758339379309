import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Image from '../../Image';
import './index.scss';

const LevelContentImage = ({ key, src }) => {
  return <Image className="level-content-image" alt={key} src={src} />;
};

LevelContentImage.propTypes = {
  key: PropTypes.string,
  src: PropTypes.string,
};

LevelContentImage.defaultProps = {
  key: undefined,
  src: '',
};

export default memo(LevelContentImage);
