import { createSelector } from 'reselect';

const getHasLoaded = createSelector(
  (state) => state.help.hasLoaded,
  (hasLoaded) => hasLoaded
);
const getHelpData = createSelector(
  (state) => state.help.data,
  (activitiesData) => activitiesData
);

export default {
  getHasLoaded,
  getHelpData,
};
